import * as _ from 'lodash';

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';
import {ModalService} from '../../services/modal.service';

import {settings} from '../../settings';
import {config} from '../../config';
import {Observable, Subscription} from 'rxjs';

@Component({
    templateUrl: './client-homework.component.html',

})
export class ClientHomeworkComponent implements OnInit, OnDestroy {
    settings;
    filter;
    version;
    messageStatus = {
        success: false,
        error: false
    };

    user;
    clientData;
    types;
    typesSorted = {
        reading: {
            0: [],
            1: [],
            2: [],
            3: [],
        },
        writing: {
            0: [],
            1: [],
            2: [],
            3: [],
        },
        comprehension: {
            0: [],
            1: [],
            2: [],
            3: [],
        },
        speech: {
            0: [],
            1: [],
            2: [],
            3: [],
        }
    };

    private oUser;
    private oClientData;
    private oTypes;

    constructor(private cdr: ChangeDetectorRef,
                private location: Location,
                private sanitizer: DomSanitizer,
                private stimuliService: StimuliService,
                private router: Router,
                private modalService: ModalService,
                private userService: UserService) {
        this.settings = settings;
        this.version = config.version;
    }

    ngOnInit(): void {
        this.oUser = this.userService.bsUser.subscribe(result => this.user = result);

        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            if (result) {
                result = _.sortBy(result, 'visibleName');
                this.types = result;

                _.forEach(result, type => {
                    if (type.worksOn.reading) {
                        this.typesSorted.reading[type.menu.level[0]].push(type);
                    }
                    if (type.worksOn.writing) {
                        this.typesSorted.writing[type.menu.level[0]].push(type);
                    }
                    if (type.worksOn.comprehension) {
                        this.typesSorted.comprehension[type.menu.level[0]].push(type);
                    }
                    if (type.worksOn.speech) {
                        this.typesSorted.speech[type.menu.level[0]].push(type);
                    }
                });
            }

            this.oClientData = this.userService.getClientList().subscribe(clients => {
                if (clients) {
                    this.clientData = _.find(clients, {'_id': this.location.path(true).split('/')[2]});

                    if (this.clientData && this.clientData.homework) {
                        this.clientData.homework = _.sortBy(this.clientData.homework, 'module');
                    }

                    if (!this.clientData) {
                        console.error('User not found!');
                    }
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
        this.oTypes.unsubscribe();
        this.oClientData.unsubscribe();
    }


    isAssigned(type): boolean {
        if (this.clientData && this.clientData.homework) {
            const has = _.find(this.clientData.homework, {module: type.name});
            if (has) {
                return true;
            }
        }
        return false;
    }

    isDone(type) {
        if (this.clientData && this.clientData.homework) {
            const has = _.find(this.clientData.homework, {module: type.name});
            if (has) {
                // @ts-ignore
                if (has.done && has.done >= has.todo) {
                    return true;
                }
            }
        }
        return false;
    }

    getName(module): any {
        if (this.clientData) {
            const temp = _.find(this.types, ['name', module]);

            if (temp) {
                return temp.visibleName;
            }
            else {
                return temp.name;
            }
        }
    }

    clientLevel(name): any {
        if (this.clientData) {
            const temp = _.find(this.clientData.tasks, ['task', name]);
            if (temp) {
                return temp.onLevel;
            }
        }
    }

    setLevel(lev): void {
        this.settings.menuLevel = lev;
        this.filter = null;
    }

    selectedLevel(lev): any {
        if (this.settings.menuLevel === lev) {
            return 'level-disabled';
        }
    }

    selectedType(type): any {
        if (this.filter === type) {
            return 'level-disabled';
        }
    }

    isAlpha(type): any {
        if (!type.visible) {
            return true;
        }
    }

    isBeta(type): any {
        if (type.active && !type.visible) {
            return true;
        }
        else if (type.active && type.visible && !type.visibility.web) {
            return true;
        }
    }

    getClass(type): any {
        let returnData = 'p-2 btn-primary ';

        if (type.menu && type.menu.color) {
            returnData = returnData + ' ' + type.menu.color;
        }
        else {
            returnData = returnData + ' fg-default ';
        }
        if (type.menu && type.menu.backgroundColor) {
            returnData = returnData + ' ' + type.menu.backgroundColor;
        }
        else {
            returnData = returnData + ' bg-default ';
        }
        return returnData;
    }

    getImage(type): string {
        if (type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }

    showProgram(type, filter): boolean {
        if (type.active && filter && (!type.minimumVersion || type.minimumVersion <= this.version)) {
            if (type.visible && type.visibility.ios) {
                if (type.worksOn[filter] && settings.menuLevel && type.menu.level.includes(settings.menuLevel)) {
                    return true;
                }
            }
        }
        return false;
    }

    remove(task): Subscription {

        if (this.clientData && this.clientData.homework) {
            const has = _.find(this.clientData.homework, {module: task.module});

            if (has) {
                _.remove(this.clientData.homework, (currentObject) => {
                    return (currentObject as any).module === task.module;
                });
            }
            else {
                this.clientData.homework.push({module: task.name, created: new Date(), done: null, todo: 0});
            }

        }
        else {
            this.clientData.homework = [{module: task.name, created: new Date(), done: null, todo: 0}];
        }

        this.clientData.homework = _.sortBy(this.clientData.homework, 'module');
        return this.userService.saveHomework(this.clientData).subscribe();
    }

    reset(task): Subscription {
        const newTask = JSON.parse(JSON.stringify(task));

        if (this.clientData && this.clientData.homework) {
            const has = _.find(this.clientData.homework, {module: task.module});

            if (has) {
                _.remove(this.clientData.homework, currentObject => {
                    return (currentObject as any).module === task.module;
                });
            }
            this.clientData.homework.push({module: newTask.module, created: new Date(), done: null, todo: task.todo});
            this.clientData.homework = _.sortBy(this.clientData.homework, 'module');
        }

        return this.userService.saveHomework(this.clientData).subscribe();
    }

    toggleAssigned(type): Subscription {
        if (this.clientData && this.clientData.homework) {
            const has = _.find(this.clientData.homework, {module: type.name});

            if (has) {
                _.remove(this.clientData.homework, currentObject => {
                    return (currentObject as any).module === type.name;
                });
            }
            else {
                this.clientData.homework.push({module: type.name, created: new Date(), done: null, todo: 0});
            }

        }
        else {
            this.clientData.homework = [{module: type.name, created: new Date(), done: null, todo: 0}];
        }

        this.clientData.homework = _.sortBy(this.clientData.homework, 'module');
        return this.userService.saveHomework(this.clientData).subscribe();
    }

    clear(): Subscription {
        this.clientData.homework = [];
        return this.userService.saveHomework(this.clientData).subscribe();
    }

    send(): Subscription {
        return this.userService.saveHomeworkNotify(this.clientData).subscribe(res => {
            this.messageStatus.success = res.success;
            this.messageStatus.error = res.error;
        });
    }


    hide(task): any {
        return !(parseInt(task.todo, 10) > 0 && parseInt(task.done, 10) >= parseInt(task.todo, 10));
    }

    updateHomework(type, count): Subscription {
        const hw = _.find(this.clientData.homework, {module: type.name});
        // @ts-ignore
        hw.todo = count;
        return this.userService.saveHomework(this.clientData).subscribe();
    }

    getCount(type): any {
        const hw: any = _.find(this.clientData.homework, {module: type.name});
        if (hw) {
            return hw.todo;
        }
    }

    deleteHomework(type): Promise<any> {
        return this.modalService.deleteHomework().then(res => {
            return this.remove(type);
        }, (err) => {
            // console.error(new Error(err));
        });
    }

    back(): void {
        return this.location.back();
    }

    removeVideo(video): any {
        if (this.clientData && this.clientData.homeworkVideos) {
            const has = _.find(this.clientData.homeworkVideos, {video: video.video});

            if (has) {
                _.remove(this.clientData.homeworkVideos, function (currentObject) {
                    return (currentObject as any).video === video.video;
                });

                return this.userService.saveHomeworkVideos(this.clientData).subscribe();
            }
        }
    }


    open(videoUrl): any {
        if (!this.userService.userInfo.basicSubscriber) {
            return this.router.navigate(['/account']);
        }
        else {
            return this.modalService.video(videoUrl);
        }
    }

    clearVideos(): any {
        if (this.clientData && this.clientData.homeworkVideos) {
            this.clientData.homeworkVideos = [];
            return this.userService.saveHomeworkVideos(this.clientData).subscribe();
        }
    }


}
