export const config = {
    whichWordExclude: [
        'the',
        'a',
        'an',
        'he',
        'she',
        'it',
        'if',
        'into',
        'I',
        'I\'m',
        'you',
        'your',
        'me',
        'my',
        'they',
        'is',
        'are',
        'on',
        'at',
        'in',
        'for',
        'with',
        'before',
        'after',
        'can',
        'has',
        'not',
        'new',
        'only',
    ],
    version: 26,
    skipRecentStimuli: false,
    skipRetryCount: 30,
    audioWait: 1000,
    writingTimeout: {
        1: 3000,
        2: 3000,
        3: 3000,
        4: 5000,
        5: 5000,
        6: 5000,
    },

    stripeKey: 'pk_test_0igDTi3SkY2rMkEhTKjSEYHI',

    baseUrl: 'https://stage.myaphasiacoach.com',
    server: 'https://stage.myaphasiacoach.com/api/',
    webSocket: 'wss://stage.myaphasiacoach.com/ws',
    mediaServer: 'https://stage.myaphasiacoach.com/media/',

    /*
        baseUrl: 'http://192.168.1.10:3000',
        server: 'http://192.168.1.10:3000/api/',
        mediaServer: 'http://192.168.1.10:3000/media/',
        webSocket: 'ws://192.168.1.10:3000/ws',
    */
};
