import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {User} from '../../models/user';

import {settings} from '../../settings';


@Component({
    templateUrl: './modules-videos.component.html'
})
export class ModulesVideosComponent implements OnInit, OnDestroy {
    user: User | null = null;
    types;
    filter;
    settings;

    private oUser;
    private oTypes;

    constructor(private router: Router,
                private userService: UserService,
                private stimuliService: StimuliService) {
        this.settings = settings;
    }

    ngOnInit(): void {
        this.oUser = this.userService.getUserInfo().subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        });

        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            this.types = _.sortBy(result, ['sortCode']);
        });
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
        this.oTypes.unsubscribe();
    }

    isBasicUser(): boolean {
        return this.userService.userInfo.basicSubscriber;
    }

}
