<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 center p-t-10 bg-teal">
        <h1>What is Primary Progressive aphasia?</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 p-15">

    </div>

      <div class="row m-t-30">
        <div class="col-sm-12 center p-t-10 bg-teal">
          <h3>Reading Exercises</h3>
        </div>
      </div>
      <div class="row">
        <div  class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div  style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
            <img  width="100%" src="/images/modules/definitions.png">
            <div  class="p-10 text-center"><h5 > Definition Choice 1 </h5> Choose the defined word
            </div>
          </div>
        </div>
        <div  class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div  style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
            <img  width="100%" src="/images/modules/definitions.png">
            <div  class="p-10 text-center"><h5 > Definition Choice 1 </h5> Choose the defined word
            </div>
          </div>
        </div>
        <div  class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div  style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
            <img  width="100%" src="/images/modules/definitions.png">
            <div  class="p-10 text-center"><h5 > Definition Choice 1 </h5> Choose the defined word
            </div>
          </div>
        </div>
        <div  class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div  style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
            <img  width="100%" src="/images/modules/definitions.png">
            <div  class="p-10 text-center"><h5 > Definition Choice 1 </h5> Choose the defined word
            </div>
          </div>
        </div>
      </div>

      <div class="row m-t-30">
        <div class="col-sm-12 center p-t-10 bg-teal">
          <h3>Writing Exercises</h3>
        </div>
      </div>
      <div class="row">
        <div  class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div  style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
            <img  width="100%" src="/images/modules/definitions.png">
            <div  class="p-10 text-center"><h5 > Definition Choice 1 </h5> Choose the defined word
            </div>
          </div>
        </div>
        <div  class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div  style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
            <img  width="100%" src="/images/modules/definitions.png">
            <div  class="p-10 text-center"><h5 > Definition Choice 1 </h5> Choose the defined word
            </div>
          </div>
        </div>
        <div  class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div  style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
            <img  width="100%" src="/images/modules/definitions.png">
            <div  class="p-10 text-center"><h5 > Definition Choice 1 </h5> Choose the defined word
            </div>
          </div>
        </div>
        <div  class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div  style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
            <img  width="100%" src="/images/modules/definitions.png">
            <div  class="p-10 text-center"><h5 > Definition Choice 1 </h5> Choose the defined word
            </div>
          </div>
        </div>
      </div>

    </div>
</div>
<app-my-footer></app-my-footer>
