<div class="container-fluid bg-white vh-100">
  <div class="container p-t-20">

  <div class="row">

    <div class="col-sm-4">
      <h1>Shared Access</h1>

      <div *ngIf="!status || (!status.success && !status.message)">
        Welcome to My Aphasia Coach. You must be logged in to get access. After you have created an account or signed in,
        click on the link in the email again to get access.<br/>
        <br/>
        <a routerLink="/register">Create an account</a>, or <a routerLink="/login">sign in</a>.
      </div>

      <div *ngIf="status && !status.success && status.message">
        {{status.message}}
      </div>


      <div>
        <div class="p-b-10">You can now view your patient's progress stats.</div>
        <div>Choose <b>Progress</b> on the menu, then select <b>My Patients</b> to view.</div>
        <!--Please refresh your browser if the menu item doesn't appear.-->
      </div>

    </div>
    <div class="col-sm-8" id="get-access">
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
</div>
