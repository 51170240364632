<div class="container p-t-20">
  <div class="row">
    <div class="col-sm-12 p-15">
      <h1>Programs</h1>

      My Aphasia Coach has the following exercises.

      <div class="container">
        <div class="h4 m-t-15 m-b-5 text-center">
          Where can I start?
        </div>
        <div class="row">
          <div (click)="setLevel(1)" class="col-md-4 pointer">
            <div [class]="'m-1 bg-green p-20 text-center ' + selectedLevel(1)" style="min-height: 190px;">
              <h4>Level 1 (Beginning)</h4>
              <h5>Severe Global or Wenicke's Aphasia. Word level tasks</h5>
            </div>
          </div>
          <div (click)="setLevel(2)" class="col-md-4 pointer">
            <div [class]="'m-1 bg-red p-20 text-center ' + selectedLevel(2)" style="min-height: 190px;">
              <h4>Level 2 (Emerging)</h4>
              <h5>Moderate Conduction or Broca's Aphasia Words and short sentences level tasks</h5>
            </div>
          </div>
          <div (click)="setLevel(3)" class="col-md-4 pointer">
            <div [class]="'m-1 bg-blue p-20 text-center ' + selectedLevel(3)" style="min-height: 190px;">
              <h4>Level 3 (Challenging)</h4>
              <h5>Mild Anomia. Sentences and paragraphs level tasks</h5>
            </div>
          </div>
        </div>

        <div *ngIf="settings.menuLevel" class="h4 m-t-20 m-b-5 text-center">
          Types of exercises
        </div>

        <div *ngIf="settings.menuLevel" class="row">
          <div (click)="filter='reading'" class="col-md-3 col-sm-6 pointer">
            <div [class]="'m-1 btn-primary p-t-20 p-b-20 ' + selectedType('reading')">
              <h3 class="text-center">
                <span class="material-icons h1">menu_book</span><br/>
                Reading
              </h3>
            </div>
          </div>

          <div (click)="filter='writing'" class="col-md-3 col-sm-6 pointer">
            <div [class]="'m-1 btn-primary p-t-20 p-b-20 ' + selectedType('writing')">
              <h3 class="text-center">
                 <span class="material-icons h1">edit</span><br/>
                Writing
              </h3>
            </div>
          </div>
          <div (click)="filter='comprehension'" class="col-md-3 col-sm-6 pointer">
            <div [class]="'m-1 btn-primary p-t-20 p-b-20 ' + selectedType('comprehension')">
              <h3 class="text-center btn-primary">
                 <span class="material-icons h1">headphones</span><br/>
                Listening</h3>
            </div>
          </div>
          <div (click)="filter='speech'" class="col-md-3 col-sm-6 pointer">
            <div class="m-1 btn-primary p-t-20 p-b-20">
              <h3 class="text-center">
                <span class="material-icons h1">message</span><br/>
                Speech
              </h3>
            </div>
          </div>
        </div>

        <div *ngIf="filter" class="h4 m-t-20 m-b-5 text-center">
          Exercises
        </div>
        <a routerLink="/register">
          <div class="row">
            <div *ngFor="let type of types" [hidden]="!showProgram(type, filter)" class="col-md-3 col-sm-6 pointer p-15">
              <div [class]="getClass(type)" style="height:100%;">
                <img [src]="getImage(type)" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    {{type.visibleName || type.name}}
                    <span [hidden]="!type.highlight" class="text-warning"><span class="material-icons middle">star</span>  <strong>New!</strong></span>
                  </h5>
                  {{type.description}}
                </div>
              </div>
            </div>
            <div *ngIf="settings.menuLevel === 1 && filter === 'speech'" class="col-md-3 col-sm-6 pointer p-15">
              <div class="bg-blue" style="height:100%;">
                <img src="/images/modules/video-1.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>Apraxia Video Level 1
                  </h5>

                </div>
              </div>
            </div>
            <div *ngIf="settings.menuLevel === 1 && filter === 'speech'" class="col-md-3 col-sm-6 pointer p-15">
              <div class="bg-blue" style="height:100%;">
                <img src="/images/modules/video-2.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>Apraxia Video Level 2
                  </h5>

                </div>
              </div>
            </div>
            <div *ngIf="settings.menuLevel === 1 && filter === 'speech'" class="col-md-3 col-sm-6 pointer p-15">
              <div class="bg-blue" style="height:100%;">
                <img src="/images/modules/video-3.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>Apraxia Video Level 3
                  </h5>

                </div>
              </div>
            </div>
            <div *ngIf="settings.menuLevel === 2 && filter === 'speech'" class="col-md-3 col-sm-6 pointer p-15">
              <div class="bg-blue" style="height:100%;">
                <img src="/images/modules/video-4.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>Apraxia Video Level 4
                  </h5>

                </div>
              </div>
            </div>
            <div *ngIf="settings.menuLevel === 2 && filter === 'speech'" class="col-md-3 col-sm-6 pointer p-15">
              <div class="bg-blue" style="height:100%;">
                <img src="/images/modules/video-5.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>Apraxia Video Level 5
                  </h5>

                </div>
              </div>
            </div>
            <div *ngIf="settings.menuLevel === 2 && filter === 'speech'" class="col-md-3 col-sm-6 pointer p-15">
              <div class="bg-blue" style="height:100%;">
                <img src="/images/modules/video-6.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>Apraxia Video Level 6
                  </h5>

                </div>
              </div>
            </div>
            <div *ngIf="settings.menuLevel === 3 && filter === 'speech'" class="col-md-3 col-sm-6 pointer p-15">
              <div class="bg-blue" style="height:100%;">
                <img src="/images/modules/video-7.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>Apraxia Video Level 7
                  </h5>

                </div>
              </div>
            </div>
            <div *ngIf="settings.menuLevel === 3 && filter === 'speech'" class="col-md-3 col-sm-6 pointer p-15">
              <div class="bg-blue" style="height:100%;">
                <img src="/images/modules/video-8.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>Apraxia Video Level 8
                  </h5>
                </div>
              </div>
            </div>
            <div *ngIf="settings.menuLevel === 3 && filter === 'speech'" class="col-md-3 col-sm-6 pointer p-15">
              <div class="bg-blue" style="height:100%;">
                <img src="/images/modules/video-9.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>Apraxia Video Level 9
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
