// @ts-nocheck
import {Subscription} from 'rxjs';
import * as _ from 'lodash';

import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';

import {UserService} from '../../services/user.service';
import {config} from '../../config';
import {settings} from '../../settings';

@Component({
    selector: 'csat-cards',
    templateUrl: './csat-cards.component.html',
    styleUrls: ['./csat-cards.component.css']
})
export class CsatCardsComponent implements OnInit, OnDestroy, AfterViewInit {
    alternatives = [];
    client;
    clientId;
    inter;
    settings;
    type = 'cards';
    user;
    userInfo;
    ws;
    cards = [{cardValue: ''}];
    message = {
        messageType: 'csat',
        therapistMessage: true,
        from: null,
        to: null,
        text: null,
        cards: this.cards,
        type: null,
        typeSettings: null
    };
    response = {
        from: '',
        text: ''
    };
    private subscriptions = new Subscription();
    private config;

    constructor(private userService: UserService,
                private cdr: ChangeDetectorRef,
                private location: Location) {
        this.config = config;
        this.settings = settings;
        this.clientId = this.location.path(true).split('/')[2];
    }

    ngOnInit(): void {
        this.ws = new $WebSocket(this.config.webSocket, null, {'reconnectIfNotNormalClose': true}, 'arraybuffer');

        this.subscriptions.add(this.userService.getUserInfo().subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                this.message.from = this.user._id;
            }
        }));

        this.subscriptions.add(this.userService.getClientList().subscribe(result => {
            const client = _.find(result, ['_id', this.clientId]);
            this.client = client.local.name + ', (' + client.local.email + ')';
            this.message.to = client._id;
        }));

        return this.ws.getDataStream().subscribe(
            (msg: MessageEvent) => {
                return this.receiveMessage(msg);
            },
            (err) => {
                console.error(new Error(err));
            });
    }

    ngOnDestroy(): void {
        this.ws.close();
        clearInterval(this.inter);
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit() {
        this.inter = setInterval(() => {
            return this.check();
        }, 1000);

        return this.check();
    }

    receiveMessage(msg?) {
        try {
            const message = JSON.parse(msg.data);

            // tslint:disable-next-line:triple-equals
            if (this.user && message.client && message.to == this.user._id && message.from == this.clientId) {
                if (message.text) {
                    this.response.text = message.text;
                }
            }
        } catch (err: any) {
            console.error(new Error(err));
        }
    }

    sendAnswer(id) {
        this.message.cards = null;
        this.message.text = id;
        return this.send();
    }

    sendAlternatives() {
        this.message.cards = this.cards;
        return this.send();
    }

    send() {
        return this.ws.send(this.message).subscribe(
            (res) => {
                // console.log(res);
            },
            (err) => {
                // console.error(new Error(err));
                return setTimeout(() => {
                    return this.send();
                }, 500);
            });
    }

    check() {
        if (this.user && this.clientId) {
            const msg = {
                check: true,
                from: this.clientId,
                to: this.user._id,
            }
            return this.ws.send(msg).subscribe();
        }
    }


    selected(card) {
        // tslint:disable-next-line:triple-equals
        if (card.cardValue && card.cardValue == this.response.text) {
            return true;
        }
    }

    correct(card) {
        // tslint:disable-next-line:triple-equals
        if (this.message.text && card.cardValue && card.cardValue === this.message.text) {
            return true;
        }
    }

    select(card) {
        // tslint:disable-next-line:triple-equals
        if (this.message.text != card.cardValue) {
            this.message.text = card.cardValue;
        }
        else {
            this.message.text = null;
        }

        return this.send();
    }

    back() {
        return this.location.back();
    }

    addCard = function () {
        this.cards.push({
            cardValue: ''
        });
    };

    clearCards = function () {
        this.cards = [{cardValue: ''}];
        this.message.cards = null;
        return this.send();
    };
}
