import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as _ from 'lodash';

import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

import {ModalService} from './modal.service';
import {BuilderService} from './builder.service';
import {StimuliService} from './stimuli.service';
import {UserService} from './user.service';
import {MessagingService} from './messaging.service';

import {config} from '../config';
import {AudioService} from './audio.service';
import {ScreenService} from './screen.service';

@Injectable()
export class AnswerService {
    type;
    correctDoesNotCount = false;
    attempts = 0;
    help: BehaviorSubject<any> = new BehaviorSubject(0);
    wrongCount: BehaviorSubject<any> = new BehaviorSubject(0);
    correct: BehaviorSubject<any> = new BehaviorSubject(false);
    wordsPlaced: BehaviorSubject<any> = new BehaviorSubject(0);
    private urlType;
    private urlName;

    constructor(private modalService: ModalService,
                private builderService: BuilderService,
                private screenService: ScreenService,
                private audioService: AudioService,
                private location: Location,
                private router: Router,
                private stimuliService: StimuliService,
                private userService: UserService,
                private messagingService: MessagingService) {
        this.urlType = decodeURI(this.location.path(true).split('/')[2]);
        this.urlName = decodeURI(this.location.path(true).split('/')[3]);
    }

    init(): void {
        this.messagingService.setMessage(null);
        this.urlType = decodeURI(this.location.path(true).split('/')[2]);
        this.urlName = decodeURI(this.location.path(true).split('/')[3]);
    }

    click(id): Observable<any> {
        return of(this.audioService.cleanupAudioObjects().then(() => {
            this.init();
            this.screenService.bsNewLevel.next(false);

            if (id) {

                if (document.getElementById(id)) {
                    (document.getElementById(id) as any).disabled = true;
                }

                if (document.getElementById('image_' + id) && (document.getElementById('image_' + id) as any).classList.contains('image-disabled')) {
                    return false;
                }

                if (document.getElementById('image_' + id)) {
                    (document.getElementById('image_' + id) as any).classList.add('image-disabled');
                }

                const payload = {
                    type: this.stimuliService.bsType.value.name,
                    level: this.builderService.onLevel,
                    question: this.stimuliService.bsStimuli.value._id,
                    answer: id,
                    correctDoesNotCount: false
                };

                if (this.correctDoesNotCount) {
                    payload.correctDoesNotCount = this.correctDoesNotCount;
                }


                return of(this.stimuliService.checkAnswer(payload).subscribe(result => {
                    if (result.correct) {
                        return this.displayModal(true).subscribe();
                    }
                    else {
                        return this.displayModal(false).subscribe();
                    }
                }));
            }
            else {
                this.messagingService.setMessage('No id please close the application and try again', false);
                return of(false);
            }
        }));
    }

    writing(input): Observable<any> {
        return of(this.audioService.cleanupAudioObjects().then(() => {
            this.init();
            this.screenService.bsNewLevel.next(false);

            let answer = ' ';
            _.forEach(input, key => {
                answer = answer + key + ' ';
            });

            answer = answer.trim();

            const payload = {
                correctDoesNotCount: false,
                type: this.stimuliService.bsType.value.name,
                level: this.builderService.onLevel,
                question: this.stimuliService.bsStimuli.value._id,
                answer
            };

            if (this.correctDoesNotCount) {
                payload.correctDoesNotCount = this.correctDoesNotCount;
            }

            return of(this.stimuliService.checkAnswer(payload).subscribe(result => {
                if (result.correct) {
                    this.correctDoesNotCount = false;
                    return this.displayModal(true).subscribe();
                }
                else {
                    let moveOn = false;
                    this.help.next(this.help.value + 1);

                    if (this.urlType === 'writing1' && this.help.value > 1) {
                        moveOn = true;
                    }
                    else if (this.urlType === 'writing2' && this.help.value > 2) {
                        moveOn = true;
                    }
                    else if (this.help.value > 2) {
                        moveOn = true;
                    }

                    if (moveOn) {
                        this.help.next(0);
                        this.correctDoesNotCount = false;
                        return this.displayModal(false, true).subscribe();
                    }
                    else {
                        this.correctDoesNotCount = true;
                        let skip = false;

                        if (this.stimuliService.bsTypeSettings.value.soundOnce) {
                            skip = true;
                        }

                        return this.displayModal(false, false, skip).subscribe();
                    }

                }
            }));
        }));
    }

    scramble(answer): Promise<any> {
        //New using bs
        return this.audioService.cleanupAudioObjects().then(() => {
            this.init();
            this.screenService.bsNewLevel.next(false);

            const payload = {
                correctDoesNotCount: false,
                type: this.stimuliService.bsType.value.name,
                level: this.builderService.onLevel,
                question: this.stimuliService.bsStimuli.value._id,
                answer
            };

            if (this.correctDoesNotCount) {
                payload.correctDoesNotCount = this.correctDoesNotCount;
            }

            return Promise.resolve(this.stimuliService.checkAnswer(payload).subscribe((result: any) => {
                this.correct.next(result.correct);
                if (result.correct) {
                    this.wrongCount.next(0);
                    return this.displayModal(true).subscribe();
                }
                else if (this.wrongCount.value === 2) {
                    this.wrongCount.next(0);
                    return this.displayModal(false, false, true).subscribe();
                }
                else {
                    let temp = this.wrongCount.value;
                    if (typeof temp === 'number') {
                        temp = temp + 1;
                        this.wrongCount.next(temp);
                        return this.displayModal(false, false, true).subscribe();
                    }
                    else {
                        console.error(new Error(temp));
                        return true;
                    }
                }
            }));
        });
    }

    scrambling(answer): Observable<any> {
        // old. To be removed
        return of(this.audioService.cleanupAudioObjects().then(() => {
            this.init();
            this.screenService.bsNewLevel.next(false);

            const payload = {
                correctDoesNotCount: false,
                type: this.stimuliService.bsType.value.name,
                level: this.builderService.onLevel,
                question: this.stimuliService.bsStimuli.value._id,
                answer
            };

            if (this.correctDoesNotCount) {
                payload.correctDoesNotCount = this.correctDoesNotCount;
            }

            return this.stimuliService.checkAnswer(payload).subscribe((result: any) => {
                if (result.correct) {
                    this.wrongCount.next(0);
                    return this.displayModal(true).subscribe();
                }
                else if (this.wrongCount.value === 2) {
                    this.wrongCount.next(0);
                    return this.displayModal(false, true, true).subscribe();
                }
                else {
                    let temp = this.wrongCount.value;
                    if (typeof temp === 'number') {
                        temp = temp + 1;
                        this.wrongCount.next(temp);
                        return this.displayModal(false).subscribe();
                    }
                    else {
                        console.error(new Error(temp));
                        return true;
                    }
                }
            });
        }));
    }

    numbersScramble(answer): Observable<any> {
        return of(this.audioService.cleanupAudioObjects().then(() => {
            const wc = this.wrongCount.getValue();
            this.init();
            this.attempts++;

            let correct = true;

            _.forEach(this.builderService.levels, (level, i) => {
                if (answer[i] !== level.alternateAnswer) {
                    correct = false;
                }
            });

            this.type = this.stimuliService.bsType.getValue().name;

            const payload = {
                correct,
                correctDoesNotCount: this.correctDoesNotCount,
                type: this.stimuliService.bsType.value.name,
                level: this.builderService.onLevel,
            };

            return this.stimuliService.updateStats(payload).pipe(map(() => {
                if (correct) {
                    this.wrongCount.next(null);
                    return of(this.displayModal(true).subscribe());
                }
                else if (wc === 2) {
                    this.wrongCount.next(null);
                    return this.displayModal(false, true, true);
                }
                else {
                    this.wrongCount.next(wc + 1);
                    return of(this.displayModal(false).pipe(map(() => {
                        return of(this.wrongCount.getValue());
                    })));
                }
            })).subscribe();
        }));
    }

    displayModal(correctAnswer, moveOn?, skipSound?): Observable<any> {
        if (!correctAnswer) {
            this.correctDoesNotCount = true;
        }

        if (correctAnswer && !this.correctDoesNotCount) {
            this.builderService.correctSetItems = this.builderService.correctSetItems + 1;
        }

        return of(this.modalService.therapyResponse(correctAnswer, this.builderService.onLevel).subscribe(() => {
            if (correctAnswer || moveOn) {
                this.correctDoesNotCount = false;
                return this.navigator().subscribe();
            }
            else if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.sound && !skipSound) {
                return this.audioService.buildAudio(this.correctDoesNotCount).subscribe();
            }
            else if (this.urlType === 'numbers7') {
                return this.audioService.buildAudio(this.correctDoesNotCount).subscribe();
            }
            else {
                return of(false);
            }
        }, () => {
            if (correctAnswer || moveOn) {
                this.correctDoesNotCount = false;
                return this.navigator().subscribe();
            }
            else if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.sound && !skipSound) {
                return this.audioService.buildAudio(this.correctDoesNotCount).subscribe();
            }
            else if (this.urlType === 'numbers7') {
                return this.audioService.buildAudio(this.correctDoesNotCount).subscribe();
            }
            else {
                return of(false);
            }
        }));
    }

    displayModalOnly(correctAnswer): Observable<any> {
        return of(this.modalService.therapyResponse(correctAnswer, this.builderService.onLevel).subscribe(() => {
            return of(true);
        }, () => {
            return of(true);
        }));
    }

    navigator(): Observable<any> {
        this.init();
        if (this.stimuliService.bsType.value.multipleQuestions && this.stimuliService.bsStimuli.value.levels.length > 1) {
            this.stimuliService.bsStimuli.value.levels.shift();
            return of(this.builderService.builderSelector());
        }
        else if (this.urlName === 'Scrambling2') {
            if (this.urlType === 'scramble') {
                const link = ['/therapy/repetition/Scrambling2'];
                return from(this.router.navigate(link));
            }
            else if (this.urlType === 'sentenceWriting') {
                this.stimuliService.bsStimuli.next(null);
                const link = ['/therapy/scramble/Scrambling2'];
                return from(this.router.navigate(link));
            }
            else {
                this.stimuliService.bsAlternatives.next(null);
                this.stimuliService.bsStimuli.next(null);
                return this.builderService.getUserInfo();
            }
        }
        else if (this.urlType === 'numbers2') {
            const link = ['/therapy/numbers3/' + this.urlName];
            return from(this.router.navigate(link));
        }
        else if (this.urlType === 'numbers3') {
            const link = ['/therapy/numbers4/' + this.urlName];
            return from(this.router.navigate(link));
        }
        else if (this.urlType === 'numbers4') {
            if (this.audioService.currentNumber + 1 < this.builderService.levels.length) {
                return this.builderService.numbers4(true);
            }
            else {
                this.audioService.bsListen.next(false);
                const link = ['/therapy/numbers5/' + this.urlName];
                return from(this.router.navigate(link));
            }
        }
        else if (this.urlType === 'numbers5') {
            if (this.audioService.currentNumber + 1 < this.builderService.levels.length) {
                return of(this.builderService.numbers5(true).subscribe());
            }
            else {
                const link = ['/therapy/numbers6/' + this.urlName];
                return from(this.router.navigate(link));
            }
        }
        else if (this.urlType === 'numbers6') {
            const link = ['/therapy/numbers7/' + this.urlName];
            return from(this.router.navigate(link));
        }
        else if (this.urlType === 'numbers7') {
            if (this.audioService.currentNumber + 1 < this.builderService.levels.length) {
                return of(this.builderService.numbers7(true).subscribe());
            }
            else {
                const link = ['/therapy/numbers8/' + this.urlName];
                return from(this.router.navigate(link));
            }
        }
        else if (this.urlType === 'numbers8') {
            if (this.audioService.currentNumber + 1 < this.builderService.levels.length) {
                return of(this.builderService.numbers8(true).subscribe());
            }
            else {
                const payload = {
                    type: this.stimuliService.bsStimuli.value.type,
                    level: 1,
                    correct: true,
                };

                return of(this.stimuliService.updateStats(payload).subscribe(() => {
                    return this.userService.getUserInfo().subscribe(() => {
                        this.stimuliService.bsAlternatives.next(null);
                        const link = ['/therapy/numbers/' + this.urlName];
                        return from(this.router.navigate(link));
                    });
                }));
            }
        }
        else {
            return of(setTimeout(() => {
                return of(this.builderService.getUserInfo().subscribe());
            }, config.audioWait));
        }
    }
}
