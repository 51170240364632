import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';

import {ModalService} from '../../services/modal.service';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    templateUrl: './my-therapist.component.html',
})
export class MyTherapistComponent implements OnInit, OnDestroy {
    alerts;
    form = {email: ''};
    user;
    sharedWith;
    emailSent = false;

    constructor(private modalService: ModalService,
                private userService: UserService) {
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            this.user = result;

            return this.userService.getTherapists().subscribe(result2 => {
                this.sharedWith = result2;
            });

        });
    }

    ngOnDestroy(): void {
    }

    inviteTherapist(): Subscription {
        return this.userService.inviteTherapist(this.form).subscribe((res) => {
            if (res.success) {
                this.form.email = '';
                this.emailSent = true;
                this.ngOnInit();
            }
        });
    }

    revokeAccess(id): Promise<Subscription> {
        return this.modalService.revokeAccess().then(() => {
            return this.userService.removeTherapist(id).subscribe(() => {
                _.remove(this.sharedWith, ['_id', id]);
            }, () => {
            });
        });
    }

    closeAlert(index): void {
        this.alerts.splice(index, 1);
    }

}
