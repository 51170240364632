import {Subscription} from 'rxjs';

import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

import {$WebSocket, WebSocketSendMode} from 'angular2-websocket/angular2-websocket';

import {ModalService} from './modal.service';
import {BuilderService} from './builder.service';
import {StimuliService} from './stimuli.service';
import {UserService} from './user.service';

import {config} from '../config';
import {settings} from '../settings';
import {User} from '../models/user';

export interface IMessage {
    messageType: string;
    from: string | null;
    to: string | null;
    unique?: number;

    announce?: boolean;
    clear?: boolean;

    therapistMessage?: boolean;
    clientMessage?: boolean;

    whiteboard?: boolean;
    initText?: boolean;
    text?: boolean;
    cards?: boolean;
    click?: boolean;
    writing?: boolean;
    scramble?: boolean;

    type?: {};
    typeSettings?: any;

    image?: null;
    question?: null;
    alternatives?: Array<any>;
    description?: string;

    selection?: string;
    answer?: string;
    correctAnswer?: string | null;

    // payload?: string
}

export class Message {
    messageType: string;
    from: string;
    to: string;

    constructor(from: string, to: string) {
        this.messageType = 'csat';
        this.from = from;
        this.to = to;
    }
}

@Injectable()
export class CsatService {
    clientId: string | undefined;
    ws: $WebSocket;
    user: User | null = null;
    incoming: IMessage = {
        messageType: 'csat',
        from: null,
        to: null,
    };
    outgoing: IMessage = {
        messageType: 'csat',
        from: null,
        to: null,
    };
    private subscriptions = new Subscription();
    private config;
    private settings;

    constructor(private modalService: ModalService,
                private builderService: BuilderService,
                private location: Location,
                private router: Router,
                private stimuliService: StimuliService,
                private userService: UserService) {
        this.config = config;
        this.settings = settings;
        this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');
    }

    announce(): Promise<any> {
        if (!this.userService.bsUser.getValue()) {
            return new Promise((resolve, reject) => {
                return this.userService.getUserInfo().subscribe(() => {
                    return this.announce2();
                }, (err) => {
                    console.error(new Error(err));
                });
            });
        }
        else {
            return this.announce2();
        }
    }

    announce2(): Promise<any> {
        this.user = this.userService.bsUser.getValue();


        this.outgoing.announce = true;
        if (this.user) {
            this.outgoing.from = this.user._id;
        }
        this.outgoing.unique = Date.now();

        if (this.clientId) {
            this.outgoing.to = this.clientId;
        }

        return this.ws.send(this.outgoing, WebSocketSendMode.Promise).then(() => {
            this.outgoing.announce = false;
        }, (err) => {
            console.error(new Error(err));
        });
    }

    receiveMessage(msg?): Promise<any> {
        try {
            const message = JSON.parse(msg.data);

            if (message.unique !== this.outgoing.unique) {
                this.incoming = message;
            }
            else {
                // FIXME ack
            }

        } catch (err: any) {
            console.error(new Error(err));
        }

        return Promise.resolve();
    }

    send(): Promise<any> {
        if (this.user) {

            this.outgoing.from = this.user._id;
            this.outgoing.unique = Date.now();

            return this.ws.send(this.outgoing, WebSocketSendMode.Promise).then(() => {
                    return Promise.resolve();
                },
                (err) => {
                    console.error(new Error(err));
                    return Promise.reject();
                });
        }
        else {
            return Promise.reject();
        }
    }

}
