<div class="container p-t-20">
  <div class="row">
    <div class="col-sm-12 p-15">
      <h1>Please update your web browser</h1>
      You are using Internet Explorer. My Aphasia Coach uses technologies that are not available in Internet Explorer to deliver therapy. To ensure a high quality experience, your browser must be updated.
      <br><br>
      Please switch to
      <a href="https://www.google.com/chrome/" target="_blank">Chrome</a>,
      <a href="https://www.mozilla.org/en-US/firefox/" target="_blank">Firefox</a> or
      <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">Edge</a>.

      It is also possible to use one of our apps on either
      <a href="https://play.google.com/store/apps/details?id=com.myaphasiacoach" target="_blank">Android</a> or
      <a href="https://itunes.apple.com/us/app/my-aphasia-coach/id1163288893?ls=1&mt=8" target="_blank">iOS</a> devices.

      <br><br>
      You will not be able to access all modules, if you choose to continue to use Internet Explorer.
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
