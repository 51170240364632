// @ts-nocheck
import {Subscription} from 'rxjs';
import * as _ from 'lodash';

import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {UserService} from '../../services/user.service';
import {AnswerService} from '../../services/answer.service';
import {BuilderService} from '../../services/builder.service';
import {StimuliService} from '../../services/stimuli.service';
import {CsatService} from '../../services/csat.service';

import {config} from '../../config';
import {settings} from '../../settings';

@Component({
    templateUrl: './csat-scramble.component.html',
})
export class CsatScrambleComponent implements OnInit, OnDestroy, AfterViewInit {
    alternatives: Array<any> = [];
    client;
    clientId;
    onLevel = 1;
    settings;
    stimuli;
    type;
    typeId;
    typeSettings: object = {};
    user;
    userInfo;
    ws;
    private subscriptions = new Subscription();
    private config;

    constructor(private csatService: CsatService,
                private userService: UserService,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.config = config;
        this.settings = settings;
        this.clientId = this.location.path(true).split('/')[2];
        this.typeId = decodeURI(this.location.path(true).split('/')[4]);

        this.csatService.outgoing.therapistMessage = true;
        this.csatService.outgoing.scramble = true;
    }

    ngOnInit(): void {
        this.builderService.bsOnLevel.next(this.onLevel);
        this.ws = this.csatService.ws;

        this.subscriptions.add(this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
                this.csatService.outgoing.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.stimuli.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.stimuli = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.getType(this.typeId).subscribe(() => {
            this.builderService.type = this.stimuliService.bsType.value;
            return this.get();
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
                this.csatService.outgoing.typeSettings = result;
            }
        }));

        this.builderService.skipPlay = true;
    }

    ngOnDestroy(): void {
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit() {
    }

    checkAnswer(): Subscription {
        const correctAnswer = this.stimuli.levels[0].word.split(' ');

        _.forEach(this.alternatives, (alternative, iter) => {
            _.forEach(answerArray, (ans, iter2) => {
                if (alternative === ans) {
                    // @ts-ignore
                    document.getElementById('land' + iter2).appendChild(document.getElementById(iter + '' + ans));
                }
            });
        });

        _.forEach(correctAnswer, (alternative, iter) => {
            if (iter <= this.answerService.wrongCount.value) {
                _.forEach(this.alternatives, (alt, iter2) => {
                    if (alternative === alt) {
                        console.log(document.getElementById(iter2 + '' + alt));
                        // @ts-ignore
                        document.getElementById('land' + iter).appendChild(document.getElementById(iter2 + '' + alt));
                    }
                });
            }
        });
    }

    sendAnswer(id) {
        this.csatService.outgoing.selection = id;
        return this.csatService.send();
    }

    send() {
        if (this.stimuli) {
            this.csatService.outgoing.image = this.stimuli.image;
            this.csatService.outgoing.alternatives = (this.alternatives as any);
            this.csatService.outgoing.description = this.stimuli.description;
            return this.csatService.send();
        }
    }

    sendText() {
        if (this.stimuli) {
            this.csatService.outgoing.description = this.stimuli.description;
            return this.csatService.send();
        }
    }

    sendImage() {
        if (this.stimuli) {
            this.csatService.outgoing.image = this.stimuli.image;
            return this.csatService.send();
        }
    }

    sendAlternatives() {
        if (this.stimuli) {
            this.csatService.outgoing.alternatives = (this.alternatives as any);
            return this.csatService.send();
        }
    }

    get() {
        this.csatService.outgoing.clear = false;
        this.builderService.onLevel = this.onLevel;
        return this.builderService.getStimuli().subscribe();
    }


    getAnswer() {
        return this.csatService.incoming.alternatives;
    }

    clear() {
        this.csatService.outgoing.clear = true;
        this.alternatives = null;
        this.stimuli = null;
        return this.csatService.send();
    }

    dragDropReplacement(id) {
        const selected = document.getElementsByClassName('scrambling-selected');
        const clicked = document.getElementById(id);
        let setClass = true;

        if (clicked.children.length > 0 && clicked.children[0] === selected[0]) {
            if ((clicked.children[0] as any).classList) {
                clicked.children[0].classList.remove('scrambling-selected');
            }
            setClass = false;
        }

        if (selected.length > 0 && (clicked as any).children.length === 0) {
            clicked.appendChild(selected[0]);
            setClass = false;
        }

        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        _.forEach(document.getElementsByClassName('scrambling-start'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        // Highlight selected
        if (setClass && (clicked.children[0] !== selected[0])) {
            const elem = document.getElementById(id).children;
            let word;

            _.forEach(elem, (child) => {
                (child as any).classList.add('scrambling-selected');
                word = (child as any).innerText;
            });
        }

        this.csatService.outgoing.alternatives = [];
        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                this.csatService.outgoing.alternatives.push((child as any).innerText);
            });
        });
    }

    drop(el) {
        const id = el.dataTransfer.getData('Text');
        if (!el.target.classList.contains('scrambling-word')) {
            if (document.getElementById(id)) {
                el.target.appendChild(document.getElementById(id));
            }
        }

        const words = el.target.getElementsByTagName('p');

        el.stopPropagation();
        event.preventDefault();

        this.csatService.outgoing.alternatives = [];
        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                this.csatService.outgoing.alternatives.push((child as any).innerText);
            });
        });
    }

    dragStart(el) {
        if (el && el.target) {
            el.dataTransfer.effectAllowed = 'move';
            el.dataTransfer.setData('Text', el.target.getAttribute('id'));
            el.dataTransfer.setDragImage(el.target, 30, 20);
        }
        return true;
    }

    dragEnter() {
        event.preventDefault();
        return true;
    }

    dragOver() {
        return false;
    }

    back(): Promise<any> {
        this.csatService.outgoing.image = null;
        this.csatService.outgoing.alternatives = [];

        return this.csatService.send().then(() => {
            return this.location.back();
        });

    }
}
