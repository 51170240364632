<div class="modal-header">
  <h3 class="modal-title" id="modal-title">Are you sure?</h3>
</div>
<div class="modal-body" id="modal-body">
  <p>Are you sure you want to delete your account?</p>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('ok')" class="btn btn-danger" type="submit">OK</button>
  <button (click)="activeModal.dismiss('cancel')" class="btn btn-primary" type="submit">Cancel</button>
</div>
