<div class="container p-t-30 p-b-30" style="clear: both">
  <div class="row p-b-15">
    <div class="col-sm-12">
      <button (click)="back()" class="btn float-right stop" type="button"><span class="material-icons middle">arrow_back_ios</span>  Exit</button>
      <b>You are connected with your therapist {{therapist}}</b>
    </div>
  </div>

  <div *ngIf="incoming.click || incoming.writing" class="center">
    <div *ngIf="incoming.typeSettings && incoming.typeSettings.mainImage && incoming.image" class="p-b-30">
      <img [src]="getAnswerImage(incoming.image)"/>
    </div>
    <div *ngIf="incoming.typeSettings && incoming.typeSettings.question && incoming.description" class="h1 p-b-30">
      {{incoming.description}}
    </div>
    <div (click)="clickAnswer(answer.id)" *ngFor="let answer of incoming.alternatives; let index=index; let isOdd=odd; let isEven=even" class="inline" style="width: 50%">

      <div *ngIf="answer.image && incoming.typeSettings && incoming.typeSettings.image" class="picture-container" style="border:4px solid white">
        <div class="picture-dummy"></div>
        <div [class.correct]="correct(answer.id)" [class.picture-even]="isEven" [class.picture-odd]="isOdd" [class.selected]="selected(answer.id)" class="picture-element">
          <img [src]="getAnswerImage(answer.image)" class="picture" width="100%"/>
        </div>
      </div>

      <button *ngIf="incoming.typeSettings && incoming.typeSettings.buttons && answer.word" [class.correct]="correct(answer.id)" [class.selected]="selected(answer.id)" class="answerbutton btn btn-lg btn-primary">
        {{answer.word}}
      </button>
    </div>
    <form *ngIf="incoming.writing && incoming.typeSettings" name="answerForm">
      <h1>{{incoming.correctAnswer}}</h1>
      <div class="inline p-10 center">
        <input #inputField [(ngModel)]="writingInput" autocomplete="off" class="text-lowercase writing2-input" name="message" style="border-bottom: 1px solid gray; text-align: center; width: 50%"></div>

      <button (click)="sendAnswer(inputField.value)" class="btn btn-primary m-l-15">SEND ANSWER</button>
    </form>
  </div>


  <div *ngIf="incoming.scramble" class="center">
    <div (click)="dragDropReplacement('land' + index)"
         (dragenter)="dragEnter()"
         (dragover)="dragOver()"
         (drop)="drop($event)"
         *ngFor="let letter of incoming.alternatives; let index = index"
         class="scrambling-drop"
         draggable="false"
         id="land{{index}}">
    </div>

    <hr *ngIf="incoming.alternatives && incoming.alternatives.length>0" width="50%">

    <div>
      <div (click)="dragDropReplacement('start' + index)"
           (dragenter)="dragEnter()"
           (dragover)="dragOver()"
           (drop)="drop($event)"
           *ngFor="let letter of incoming.alternatives; let index = index"
           class="scrambling-start"
           draggable="false"
           id="start{{index}}">

        <p (dragstart)="dragStart($event)" [innerHTML]="letter" class="scrambling-word pointer" draggable="true" id="{{index + letter}}"></p>

      </div>
    </div>
  </div>

</div>
