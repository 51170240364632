import {Subscription} from 'rxjs';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {HttpClient} from '@angular/common/http';

import {config} from '../../config';
import * as _ from 'lodash';

declare var Stripe: any;


@Component({
    selector: 'change-card',
    templateUrl: './change-card.component.html',
    styleUrls: ['./change-card.component.css']
})
export class ChangeCardComponent implements OnInit, OnDestroy {
    submitted = false;
    user;
    userInfo;
    isFormDisabled = false;
    waitMessage = null;
    error;
    status = {message: '', error: false};
    formData = {
        auth: false,
    };
    private cardToken: any;
    private stripe: any;
    private elements: any;
    private card: any;

    constructor(private userService: UserService,
                private http: HttpClient) {
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                this.setUpCard();
            }
        });
    }

    ngOnDestroy(): void {
    }

    getCardToken(): any {
        this.submitted = true;
        this.status = {message: '', error: false};

        if (this.formData.auth && !this.isFormDisabled) {
            this.isFormDisabled = true;

            return this.stripe.createToken(this.card).then(status => {
                if (status.token) {
                    this.cardToken = status.token.id;

                    const payload = {
                        token: this.cardToken,
                        subscriptionType: this.user.payment.subscriptionType
                    };

                    return this.http.post(config.server + 'formhandler/subscriptionCreate', payload, {withCredentials: true})
                        .subscribe(res => {
                            this.submitted = false;
                            this.isFormDisabled = false;
                            if ((res as any).success) {
                                this.status = {message: 'The card on file has been updated.', error: false};
                            }

                        }, err => {
                            this.submitted = false;
                            this.isFormDisabled = false;
                        });
                }
                else {
                    this.submitted = false;
                    this.isFormDisabled = false;
                    this.status = {message: status.error.message, error: true};
                }

                return false;
            }, (err) => {
                this.isFormDisabled = false;
                this.status = {message: err.error.message, error: true};
            });
        }
        else {
        }
    }

    setUpCard(): any {
        return setTimeout(() => {
            this.stripe = Stripe(config.stripeKey);
            this.elements = this.stripe.elements();
            this.card = this.elements.create('card');
            if (this.card && this.card.mount) {
                this.card.mount('#card-element');
            }
        }, 100);
    }

    authClass(): string {
        if (this.submitted && !this.formData.auth) {
            return 'col-9 p-5 highlight';
        }
        else {
            return 'col-9 p-5';
        }
    }

}
