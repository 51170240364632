<div class="container-fluid bg-light-gray">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 p-15">
        <button class="btn btn-primary">
          <span class="material-icons middle">arrow_circle_left</span> Back
        </button>
        <div *ngIf="user && hasRecurringSubscription">
          <h1>Delete My Account</h1>
          <p>Tap the button below to delete your account.</p>
          <p>Upon tapping the button, you will lose access to your account and be signed out immediately.</p>
          <p><strong>If you are a paying subscriber and you subscribed from the myaphasiacoach.com websites,</strong> 
            your subscription will be cancelled automatically when you click the Delete My Account button.</p>
          <p><strong>Partial refunds are not issued if you delete your account before your current subscription term ends.</strong></p>
          <p><strong>Account deletions are permanent.</strong></p>
        </div>
        <div *ngIf="user && !hasRecurringSubscription">
          <h1>Delete My Account</h1>
          <p>Tap the button below to delete your account.</p>
          <p>Upon tapping the button, you will lose access to your account and be signed out immediately.</p>
          <p><strong>Account deletions are permanent.</strong></p>
        </div>
        <button (click)="openCloseAccountModal()" class="btn btn-primary">
          Delete My Account
        </button>
      </div>
    </div>
  </div>
  <app-my-footer></app-my-footer>
</div>
