<div class="container p-t-20">
  <div class="row">
    <div class="col-sm-12 p-15">
      <h1>Beta Program Feedback</h1>


      <div *ngIf="emailSent" class="alert alert-success">Your request has been received.</div>
      <div *ngIf="errorMessage" class="alert alert-danger">{{errorMessage}}</div>

      <form (ngSubmit)="processContact()">
        <div class="form-group">
          <label>Name</label>
          <input [(ngModel)]="formData.name" class="form-control" name="name" placeholder="Your Name" type="text">
        </div>
        <div class="form-group">
          <label>Email</label>
          <input [(ngModel)]="formData.email" class="form-control" name="email" placeholder="your@email.address" type="email">
        </div>

        <div class="form-group">
          <label>In which module did the error happen</label>
          <input [(ngModel)]="formData.errorLocation" class="form-control" name="errorLocation" type="text">
        </div>

        <div class="form-group">
          <label>What happened?</label>
          <textarea [(ngModel)]="formData.message" class="form-control" name="message" placeholder="" rows="5"></textarea>
        </div>
        <button class="btn btn-primary btn-lg float-right " type="submit"><span class="material-icons middle">drafts</span>  Send</button>

        <div class="form-group">
          <label>Screenshot</label>
          <div>
            <input #fileInput type="file"/>
          </div>
        </div>

      </form>

    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
