<div>
  <div (click)="playMainSound()" *ngIf="showMainSoundHint()" class="btn hint high-z center-block">
    <span class="material-icons middle">headphones</span>
    <span class="m-l-5">Hint</span>
  </div>

  <div (click)="playPostSound()" *ngIf="showPostSoundHint()" class="btn hint high-z center-block">
    <span class="material-icons middle">headphones</span>
    <span class="m-l-5">Hint</span>
  </div>

  <div (click)="playPreSound()" *ngIf="showPreSoundHint()" class="btn hint high-z center-block">
    <span class="material-icons middle">headphones</span>
    <span class="m-l-5">Hint</span>
  </div>

  <div (click)="playAnswerSound()" *ngIf="showAnswerSoundHint()" class="btn hint high-z center-block">
    <span class="material-icons middle">headphones</span>
    <span class="m-l-5">Hint</span>
  </div>
</div>
