<div class="container p-0 m-t-20 bg-white" [style.zoom]="zoom">
  <promotion></promotion>
  <div style="min-height:60vh">
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="settings && typeSettings" class="bg-dark-teal d-flex justify-content-between">
    <div>
      <div (click)="exit()" class="audioButton pointer btn listen-again p-15">
        <span class="material-icons middle h1 m-0">arrow_circle_left</span><br/>
        <div>Back</div>
      </div>
    </div>

    <div>
      <div (click)="playMainSound()" *ngIf="showMainSoundListen()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen</div>
      </div>

      <div (click)="playMainSound()" *ngIf="showMainSoundListenAgain() && !swapListenAgain()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen<br/>Again</div>
      </div>

      <div (click)="playWhichWordIs()" *ngIf="swapListenAgain()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen<br/>Again</div>
      </div>

      <div (click)="playPreSound()" *ngIf="showPreSoundListen()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen</div>
      </div>

      <div (click)="playPreSound()" *ngIf="showPreSoundListenAgain()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen</div>
      </div>

      <div (click)="playPostSound()" *ngIf="showPostSoundListen()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen</div>
      </div>

      <div (click)="playPostSound()" *ngIf="showPostSoundListenAgain()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen</div>
      </div>

      <div (click)="playAnswerSound()" *ngIf="showAnswerSoundListen()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen</div>
      </div>

      <div (click)="playAnswerSound()" *ngIf="showAnswerSoundListenAgain()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen</div>
      </div>

      <div (click)="playAudioObjects()" *ngIf="showAudioObjectsListenAgain()" class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen</div>
      </div>

      <div *ngIf="showNumbersTutorial()" (click)="playNumbers()"  class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">play_circle</span><br/>
        <div>Listen Again</div>
      </div>

    </div>

    <div>
      <div *ngIf="showTutorial() && !(showSstTutorial() || showNumbersTutorial())" (click)="playTutorial()"  class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">help</span><br/>
        <div>Help</div>
      </div>

      <div *ngIf="showSstTutorial()" (click)="playSstTutorial()"  class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">help</span><br/>
        <div>Help</div>
      </div>

      <div *ngIf="showNumbersTutorial()" (click)="playNumbersTutorial()"  class="audioButton pointer btn listen-again">
        <span class="material-icons middle h1 m-0">help</span><br/>
        <div>Help</div>
      </div>

    </div>
  </div>


  <div class="p-t-20 bg-dark-teal">
  </div>

</div>
