import * as reSampler from '../../../audio.service';

import {$WebSocket} from 'angular2-websocket/angular2-websocket';

declare var Vimeo: any;

import * as _ from 'lodash';

import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {of} from 'rxjs';
import {MessagingService} from '../../services/messaging.service';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {ScreenService} from '../../services/screen.service';
import {AudioService} from '../../services/audio.service';
import {AnswerService} from '../../services/answer.service';
import {BuilderService} from '../../services/builder.service';
import {StimuliService} from '../../services/stimuli.service';

import {settings} from '../../settings';
import {config} from '../../config';


@Component({
    templateUrl: './video-modal.component.html'
})

export class VideoModalComponent implements OnInit {
    @Input() videoUrl;
    vimeoPlayer;
    playing = true;

    highlight;
    processor;
    source;
    ws;
    context;
    recording;
    attempts = 0;
    message;
    settings;
    config;
    alternatives: Array<any> = [];
    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;
    showIEWarning;
    timer;


    constructor(public activeModal: NgbActiveModal,
                private sanitizer: DomSanitizer,
                private messagingService: MessagingService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private userService: UserService,
                private screenService: ScreenService,
                private audioService: AudioService,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService,
                private zone: NgZone) {
        this.settings = settings;
        this.config = config;
    }

    ngOnInit(): void {
        let url;
        if (this.videoUrl && this.videoUrl.url) {
            url = this.videoUrl.url;
        }
        else {
            url = this.videoUrl;
        }
        const vimeoOptions = {
            url,
            width: 900,
            autoplay: true,
            controls: true
        };
        this.vimeoPlayer = new Vimeo.Player('vimeo', vimeoOptions);
    }

    pausePlayer(): void {
        this.playing = false;
        this.vimeoPlayer.pause();
    }

    playPlayer(): void {
        this.playing = true;
        this.vimeoPlayer.play();
    }

    record(): any {
        this.vimeoPlayer.pause();
        this.playing = false;

        return this.audioService.startSound().then(() => {
            this.recording = true;

            try {
                this.context = new AudioContext();
                this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');
            } catch (err: any) {
                console.error(new Error(err));
            }

            this.ws.getDataStream().subscribe((msg: MessageEvent) => {
                return this.receiveMessage(msg);
            });

            return navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
                return this.zone.run(() => {
                    this.source = this.context.createMediaStreamSource(stream);
                    this.processor = this.context.createScriptProcessor(8192, 1, 1);

                    this.source.connect(this.processor);
                    this.processor.connect(this.context.destination);

                    return this.processor.onaudioprocess = (data) => {
                        return this.zone.run(() => {
                            return reSampler(data.inputBuffer, 16000, (event) => {
                                return this.zone.run(() => {
                                    try {
                                        if (this.ws && this.ws.send()) {
                                            return this.ws.send(this.convertFloat32ToInt16(event.getAudioBuffer().getChannelData(0)), undefined, true).subscribe();
                                        }
                                    } catch (err: any) {
                                        console.error(new Error(err));
                                    }
                                });
                            });
                        });
                    };
                });
            }, () => {
                this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
            });
        });
    }

    stop(skip?): Promise<any> {
        this.audioService.bsListen.next(false);
        clearTimeout(this.builderService.timer);

        if (this.ws) {
            this.ws.send('stop');
            this.ws.close();
            this.ws = null;
        }

        if (this.processor) {
            this.processor.disconnect();
            this.processor = null;
        }

        this.source = null;
        this.recording = false;
        this.builderService.timer = null;

        if (!skip) {
            return this.audioService.stopSound();
        }
        else {
            return Promise.resolve(true);
        }
    }

    receiveMessage(msg?): any {
        return this.zone.run(() => {
            let message;
            try {
                message = JSON.parse(msg.data);
            } catch (err: any) {
            }

            if (message) {
                return this.stop().then(() => {
                    let correct = false;

                    if (message && message[0] && message[0].alternatives) {
                        _.forEach(message[0].alternatives, (answer) => {
                            if (_.toLower(answer.transcript).includes(_.toLower((this.videoUrl.name.replace(/[^\w\s]/gi, ''))))) {
                                correct = true;
                            }
                        });
                        _.forEach(this.videoUrl?.speech, (answer) => {
                            const result = _.filter((message[0].alternatives as any).value, (alt) => {
                                if (_.toLower(alt.trim()).includes(_.toLower(answer.word.trim()))) {
                                    return true;
                                }
                                return false;
                            });

                            if (result.length > 0) {
                                correct = true;
                            }
                        });
                    }

                    if (correct) {
                        this.attempts = 0;
                        this.stimuli = null;
                        return this.answerService.displayModalOnly(true).subscribe();
                    }
                    else {
                        return this.answerService.displayModalOnly(false).subscribe();
                    }
                });
            }
            else {
                console.log('no msg');
            }
            return of(false);
        });
    }

    convertFloat32ToInt16(buffer): any {
        let l = buffer.length;
        const buf = new Int16Array(l);
        while (l--) {
            buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
        }
        return buf.buffer;
    }

}
