<div class="container-fluid bg-white vh-100">
  <div class="container p-t-20">
  <div class="row">
    <div *ngIf="user" class="col-sm-12 p-15">
      <div *ngIf="clientData && clientData.length">

        <ng-container *ngFor="let user of clientData">
          <div *ngIf="user._id === clientId">
            <h2>{{user.local.name || user.local.email}}</h2>
            <app-stats-clients [user]="user"></app-stats-clients>

            <button (click)="back()" class="btn stop float-right m-l-15" type="button"><span class="material-icons middle">arrow_back_ios</span> Back</button>
            <a [routerLink]="'/client-homework/' + user._id" class="btn btn-primary float-right"><span class="material-icons middle">home_work</span> Homework</a>

          </div>

        </ng-container>
      </div>


    </div>
  </div>
</div>
  <app-my-footer></app-my-footer></div>
