import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {User} from '../../models/user';

import {settings} from '../../settings';
import {BuilderService} from '../../services/builder.service';
import {config} from '../../config';
import {ModalService} from '../../services/modal.service';

@Component({
    selector: 'app-therapy',
    templateUrl: './modules-search.component.html',
    styleUrls: ['./modules-search.component.css']
})
export class ModulesSearchComponent implements OnInit, OnDestroy {
    user: User | null = null;
    types;
    tasks: Array<any> = [];
    filter;
    more = false;
    settings;
    version;
    showModules = false;
    search = '';
    search2 = '';
    search3 = '';

    private oUser;
    private oTypes;

    constructor(private router: Router,
                private modalService: ModalService,
                private builderService: BuilderService,
                private userService: UserService,
                private stimuliService: StimuliService) {
        this.settings = settings;
        this.version = config.version;
    }

    ngOnInit(): void {
        this.oUser = this.userService.getUserInfo().subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        }, err => {
            if (err.status === 401) {
                const route = ['/login'];
                return this.router.navigate(route);
            }
            return false;
        });

        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            this.types = _.sortBy(result, ['sortCode']);
            this.makeList();
        });
    }

    ngOnDestroy(): void {
        if (this.oUser) {
            this.oUser.unsubscribe();
        }
        if (this.oTypes) {
            this.oTypes.unsubscribe();
        }
    }

    isPremiumUser(): boolean {
        return this.userService.userInfo.premiumSubscriber;
    }

    isAdmin(): boolean {
        return this.userService.isAdmin();
    }

    navigate(type, event): Promise<any> {
        if (!this.userService.userInfo.premiumSubscriber) {
            if (this.userService.userInfo.basicSubscriber) {
                const route = ['/modules-videos'];
                return this.router.navigate(route);
            }
            else {
                const route = ['/account'];
                return this.router.navigate(route);
            }
        }
        else {
            const route = ['/therapy/' + type.templateType + '/' + type.name];
            if (event.button === 1) {
                window.open(route[0], '_blank');
                return Promise.resolve();
            }
            else {
                return this.router.navigate(route);
            }
        }
    }

    isTherapist(): boolean {
        return this.userService.isTherapist();
    }

    getClass(type): string {
        let returnData = 'p-1 rounded-bottom ';

        if (type.menu && type.menu.color) {
            returnData = returnData + ' ' + type.menu.color;
        }
        else {
            returnData = returnData + ' fg-default ';
        }
        if (type.menu && type.menu.backgroundColor) {
            returnData = returnData + ' ' + type.menu.backgroundColor;
        }
        else {
            returnData = returnData + ' bg-default ';
        }
        return returnData;
    }

    getImage(type): string {
        const task = _.find(this.user?.tasks, {task: type.name});
        if (task && task.onLevel > type.maxLevel) {
            return '/images/modules/finished.png';
        }
        else if (type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }

    makeList(): void {
        const allTypes: any[] = [];
        const types: any[] = [];
        const types2: any[] = [];
        const types3: any[] = [];

        _.forEach(this.types, type => {
            if (this.userService.isAdmin() || (type.visible && type.visibility.web) || (type.visible && this.user?.beta)) {
                allTypes.push(type);
            }
        });

        _.forEach(allTypes, (type, ix) => {
            if (type.search[this.search] === true) {
                types.push(allTypes[ix]);
            }
        });

        _.forEach(types, (type, ix) => {
            if (type.search2[this.search2] === true || !this.search2) {
                types2.push(types[ix]);
            }
        });
        _.forEach(types2, (type, ix) => {
            if (type.search3[this.search3] === true || !this.search3) {
                types3.push(types2[ix]);
            }
        });
        this.tasks = _.compact(types3);
        this.showModules = true;
    }

    resetForm(): void {
        _.forEach(this.search, (key, ix) => {
            this.search = '';
        });
        _.forEach(this.search2, (key, ix) => {
            this.search2 = '';
        });
        _.forEach(this.search3, (key, ix) => {
            this.search3 = '';
        });
        this.showModules = false;
    }

    hideFormatMenu(): boolean{
        if (this.search === 'reading' && this.search2 === 'sentences') {
            return false;
        }

        if (this.search === 'writing' && (this.search2 === 'words')) {
            return false;
        }

        if (this.search === 'speech' && (this.search2 === 'numbers' || this.search2 === 'words' || this.search2 === 'sentences')) {
            return false;
        }

        return true;
    }

    showType(type: string): boolean{
        if (this.search === 'listening') {
            if (type === 'pictures') {
                return true;
            }
        }
        return false;
    }

    showFormat(format: string): boolean {
        if (this.search === 'reading' && this.search2 === 'sentences') {
            if (format === 'multiple' || format === 'fill' || format === 'questions' || format === 'scrambled') {
                return true;
            }
        }
        if (this.search === 'writing' && this.search2 === 'words') {
            if (format === 'pictures' || format === 'dictation' || format === 'naming') {
                return true;
            }
        }
        if (this.search === 'speech' && this.search2 === 'numbers') {
            if (format === 'repetition' || format === 'oral') {
                return true;
            }
        }
        if (this.search === 'speech' && (this.search2 === 'words' || this.search2 === 'sentences')) {
            if (format === 'repetition' || format === 'oral' || format === 'videos') {
                return true;
            }
        }

        return false;
    }

}
