import * as _ from 'lodash';

import {of, Subscription} from 'rxjs';

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {BuilderService} from '../../services/builder.service';

import {settings} from '../../settings';

@Component({
    selector: 'app-speech-pacing',
    templateUrl: './speech-pacing.component.html'
})

export class SpeechPacingComponent implements OnInit, OnDestroy {
    message;
    settings = settings;
    alternatives = [];
    typeSettings: object = {};
    user;
    userInfo;
    type;
    stimuli;

    sequences = [
        {
            sequence: 0,
            sequenceGapTime: 300,
            words: [
                {
                    word: 'I',
                    sound: 'fnord.mp3',
                    displayTime: 200
                },
                {
                    word: 'told',
                    sound: 'fnord.mp3',
                    displayTime: 300
                },
                {
                    word: 'my',
                    sound: 'fnord.mp3',
                    displayTime: 300
                },
                {
                    word: 'father',
                    sound: 'fnord.mp3',
                    displayTime: 500
                }
            ]
        },
        {
            sequence: 1,
            sequenceGapTime: 1000,
            words: [
                {
                    word: 'that',
                    sound: 'fnord.mp3',
                    displayTime: 200
                },
                {
                    word: 'I',
                    sound: 'fnord.mp3',
                    displayTime: 200
                },
                {
                    word: 'wanted',
                    sound: 'fnord.mp3',
                    displayTime: 400
                }
            ]
        },
        {
            sequence: 2,
            sequenceGapTime: 500,
            words: [
                {
                    word: 'ice',
                    sound: 'fnord.mp3',
                    displayTime: 300
                },
                {
                    word: 'cream',
                    sound: 'fnord.mp3',
                    displayTime: 400
                }
            ]
        }
    ];

    words: Array<any> = [];
    index = 0;
    s = 0;
    hw = 0;


    private oUser;
    private oType;
    private oTypeSettings;


    constructor(private cdr: ChangeDetectorRef,
                private router: Router,
                private userService: UserService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
    }

    ngOnInit(): Subscription {
        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;

                if (this.userService.userInfo.loggedIn) {
                    if (!this.userService.userInfo.premiumSubscriber && this.userService.userInfo.basicSubscriber) {
                        const route = ['/modules-videos'];
                        return this.router.navigate(route);
                    }
                    else if (!this.userService.userInfo.basicSubscriber) {
                        const route = ['/account'];
                        return this.router.navigate(route);
                    }
                }
            }
            return false;
        });

        this.oType = this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        });

        this.oTypeSettings = this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        });

        return this.builderService.getType().subscribe(() => {
            return this.theLoop(this.sequences.length);
        });
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
        this.oType.unsubscribe();
        // this.oStimuli.unsubscribe();
        // this.oAlternatives.unsubscribe();
        this.oTypeSettings.unsubscribe();
    }

    theLoop(timesLeft?: number): any {
        return of(setTimeout(() => {
            if (this.sequences.length > this.s) {
                this.words = this.sequences[this.s].words;
                this.cdr.detectChanges();
                this.s++;
            }

            if (timesLeft && timesLeft > 0) {
                // console.log('more times');
                timesLeft = --timesLeft;
                if (this.hw < this.words.length) {
                    return of(this.highlighter(this.words.length, timesLeft));
                }
                else {
                    this.words = [];
                    return of(this.theLoop(timesLeft));
                }
            }
            else {
                return false;
            }
        }, 0));
    }

    highlighter(hw: number, timesLeft?: number): any {
        let wait;

        if (this.words.length > this.hw) {
            wait = this.words[this.hw].displayTime;
        }
        else {
            wait = (this.words[this.hw - 1].displayTime);
        }

        return of(setTimeout(() => {
            if (hw > 0) {
                if (document.getElementById('s_' + (this.s - 1) + '_w_' + (this.hw))) {
                    // @ts-ignore
                    document.getElementById('s_' + (this.s - 1) + '_w_' + (this.hw)).classList.add('highlight-word');
                }

                if (document.getElementById('s_' + (this.s - 1) + '_w_' + (this.hw - 1))) {
                    // @ts-ignore
                    document.getElementById('s_' + (this.s - 1) + '_w_' + (this.hw - 1)).classList.remove('highlight-word');
                }

                this.hw++;
                hw = --hw;

                return of(this.highlighter(hw, timesLeft));
            }
            else {

                if (document.getElementById('s_' + (this.s - 1) + '_w_' + (this.words.length - 1))) {
                    // @ts-ignore
                    document.getElementById('s_' + (this.s - 1) + '_w_' + (this.words.length - 1)).classList.remove('highlight-word');
                }
                this.hw = 0;

                return of(setTimeout(() => {
                    return of(this.theLoop(timesLeft));
                }, this.sequences[this.s - 1].sequenceGapTime));
            }
        }, wait));
    }

    readAgain(): any {
        this.s = 0;
        return this.theLoop(this.sequences.length);
    }
}
