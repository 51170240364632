<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 center p-t-15 p-b-10 bg-teal">
        <h1>What is Global aphasia?</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 p-15 center">
        <img src="/images/global.png" style="height: 350px;"/><br/>
      </div>
      <div class="col-sm-12 p-15">
        <b>Global aphasia</b> is a language disorder that can happen when a large area of the left hemisphere of the brain is damaged from a stroke or brain injury. Persons with global aphasia are typically (but not always) severely physically
        affected by the stroke. Since the motor center to speak is also located in this large area of the brain, they may also have severe apraxia of speech.
        <br/><br/>
        These exercises are designed to target specific processes that begin at their current skill level, building on existing strengths. <b>To get the fastest results, we recommend that learners with aphasia work independently after they get
        started.</b>
        <br/><br/>
        <b>Global aphasia severely affects the ability to speak, write, read and understand information.</b> It’s the most severe form of aphasia, but the diagnosis can resolve into another type of aphasia over time or remain Global aphasia. Some
        language skills may be slightly better than others. For example, a person with Global aphasia may understand what’s said to them a little better than they can speak.
        <br/><br/>
        <b>Speech:</b> The person with Global aphasia may not speak at all, or they may say the same phrases or words over and over again with different intonation. For example, they may only say “it’s better than that” and a few words of ‘automatic
        speech’. <b>Apraxia of speech</b>, if present, also makes repeating or speaking purposefully more difficult.
        <br/><br/>
        <b>Writing:</b> Writing will be severely affected in the dominant (right) hand. Even when learning to write with a non-dominant hand, forming letters may be difficult. They may use their entire arm to write instead of moving their wrists or
        fingers. They need to begin by copying words or single letters.
        <br/><br/>
        <b>Reading:</b> They may recognize a few single words, but pairing photos with words or short sentences will help. The number of words and photos matched may start with 2 choices at a time.
        <br/><br/>
        <b>Understanding:</b> This skill is severely affected, but improves when speakers use gestures and speak slowly and concretely. Understanding in context is best and they may appear to understand more than they actually do. Persons with Global
        aphasia need a lot of support to understand structured or direct therapy tasks.
        <br/><br/>
        <b>How Can My Aphasia Coach Software Help Global Aphasia?</b>
        <br/><br/>
        Learners with Global aphasia can improve within each language system–recognizing words and matching sentences to photos, copying letters and functional words, naming objects or repeating words with a lot of support, understanding words and
        sentences and more!


        <br/><br/>
        These exercises are designed to target specific processes that begin at their current skill level, building on existing strengths. <b>To get the fastest results, we recommend that learners with aphasia work independently after they get
        started.</b>
        <br/><br/>

        <b>Where Should I Start My Aphasia Coach?</b>
        <br/>
        Take the <a [routerLink]="'/where-do-i-start'">placement quiz</a> to get recommendations.
        <br/><br/>
        You can also try these exercises:
        <br/><br/>
        <h5>Reading</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/PracticeVerbNaming">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/training-verb-naming.png">
              <div class="p-10 text-center"><h5>Practice Action Choice</h5>
                Choose the verb that matches the photo
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/PracticePictureMatchVerbs">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/training-picture-match-verbs.png">
              <div class="p-10 text-center"><h5>Practice Picture Match Verbs</h5>
                Choose photos for written actions
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/Naming">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/naming.png">
              <div class="p-10 text-center"><h5>Noun Choice 1</h5>
                Choose the defined word
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/4pics1word">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/word-picture-match.png">
              <div class="p-10 text-center"><h5>Picture Match Nouns 1</h5>
                Choose photos for written nouns
              </div>
            </div>
          </div>
        </div>
        <br/><br/>
        <h5>Writing</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing/PracticeNounPictures">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/practice-noun-pictures.png">
              <div class="p-10 text-center"><h5>Practice Noun Pictures</h5>
                Type picture names
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing1/Naming Writing'">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/word-writing-nouns.png">
              <div class="p-10 text-center"><h5>Noun Pictures 1</h5>
                Type picture names
              </div>
            </div>
          </div>
        </div>

        <br/><br/>
        <h5>Speech</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/1" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-1.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 1
                </h5>
                1 syllable, 2 sounds
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/2" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-2.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 2
                </h5>
                1 syllable, 3 sounds
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/3" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-3.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 3
                </h5>
                1 syllable, blends
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/speech/Naming Speech">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2"><img width="100%" src="/images/modules/naming-speech.png">
              <div class="p-10 text-center"><h5> Repeating Nouns </h5> Say the word you hear</div>
            </div>
          </div>
        </div>


        <br/><br/>
        <h5>Listening</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/PracticeAudioPictureResponse">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/training-audio-picture-response.png">
              <div class="p-10 text-center"><h5>Practice Picture Matching</h5>
                Match the word to the picture
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/PracticeNounChoice">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/training-noun-choice.png">
              <div class="p-10 text-center"><h5>Practice Noun Choice</h5>
                Choose the picture name
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/AudioPicSent">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/auditory-picture-sentence-match.png">
              <div class="p-10 text-center"><h5>Verb Sentence Match 1</h5>
                Match spoken sentence to photos
              </div>
            </div>
          </div>
        </div>


        <br/><br/>
        <h5>Numbers</h5>
        Not recommended.
        <br/><br/>
        Global aphasia can improve, even years after a stroke or brain injury. <b>There is no plateau or 6-month time limit–you can always make progress.</b> The best results occur when you practice My Aphasia Coach <b>for at least 30 minutes a
        day</b>.


      </div>
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
