import {Component, OnDestroy, OnInit} from '@angular/core';

import {UserService} from '../../services/user.service';

@Component({
    selector: 'module-completed',
    templateUrl: './therapist-welcome.component.html'
})
export class TherapistWelcomeComponent implements OnInit, OnDestroy {
    user;

    private oUser;

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
        this.oUser = this.userService.bsUser.subscribe(result => {
            this.user = result;
        });
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
    }

}
