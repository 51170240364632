<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 center p-t-15 p-b-10 bg-teal">
        <h1>What is Broca's aphasia?</h1>
      </div>
    </div>
    <div class="col-sm-12 p-15 center">
      <img src="/images/brocas.png" style="height: 350px;"/><br/>
    </div>

    <div class="row">
      <div class="col-sm-12 p-15">

        <b>Broca's aphasia</b> is a language disorder that can happen when a specific area of the brain, Broca’s area, is damaged from a stroke or brain injury. <b>Broca’s area</b> is very close to the motor control centers for the right arm and
        leg, so someone with Broca’s aphasia often has weakness in their right arm or leg. Since the motor center to talk is also located in this area, they may also have <b>apraxia of speech</b>.
        <br/><br/>
        <b>Broca’s aphasia affects the ability to speak, write, read and understand information to different degrees</b>. Some language skills may be better than others. For example, a person with Broca’s aphasia may understand what’s said to them
        better than they can speak.
        <br/><br/>
        <b>Speech:</b> The person with Broca’s aphasia speaks 1-4 words (usually nouns) at a time. This can be <b>“telegraphic speech”</b>, because important words like verbs and articles are missing, like a telegraphed message. The listener may have
        to figure out how those words go together to understand what the person with aphasia is trying to say.
        <br/><br/>
        Problems with <b>word retrieval (anomia)</b>, or finding the words they want to say, is a hallmark of aphasia, in general. <b>Apraxia of speech</b>, if present, also makes repeating or speaking more difficult.
        <br/><br/>
        <b>Writing:</b> Writing may be difficult due to severe muscle weakness in the dominant (right) hand. Even when learning to write with a non-dominant hand, forming letters may be difficult. Many people with Broca’s aphasia don’t easily write
        words independently and need to begin by copying words.
        <br/><br/>
        <b>Reading:</b> Recognizing some words, from single words to short sentences, may be a relative strength. Reading will require a lot of context, however, and is unusually limited to single words or short sentences.
        <br/><br/>
        <b>Understanding:</b> This tends to be the strongest skill when compared to writing, speaking and reading, but it is still affected. Persons with Broca’s aphasia will often watch the speaker’s facial cues and gestures to help them understand.
        <br/><br/>
        <b>How Can My Aphasia Coach Software Help Broca’s Aphasia?</b>
        <br/>
        Learners with Broca’s aphasia can improve within each language system–reading words and sentences, writing words, naming actions and objects, repeating words and sentences, understanding sentences, learning numbers and more!
        These exercises are designed to target specific processes that begin at their current skill level, building on existing strengths. <b>To get the fastest results to improve aphasia, we recommend that learners with aphasia work independently
        after they get started.</b>
        <br/><br/>
        <b>Where Should I Start My Aphasia Coach?</b>
        <br/>
        Take the <a [routerLink]="'/where-do-i-start'">placement quiz</a> to get recommendations.
        <br/><br/>
        You can also try these exercises:
        <br/><br/>
        <h5>Reading</h5>
        Any level 1 task. If these are too easy, change to level 2.
        <br/><br/>
        <h5>Writing</h5>
        Any Level 1 writing exercise. If these are too easy, change to Level 2.
        <br/><br/>
        <h5>Speech</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/1" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-1.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 1
                </h5>
                1 syllable, 2 sounds
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/2" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-2.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 2
                </h5>
                1 syllable, 3 sounds
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/3" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-3.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 3
                </h5>
                1 syllable, blends
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/4" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-4.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 4
                </h5>
                3 word sentences, 3-5 syllable words
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/5" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-5.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 5
                </h5>
                3 word sentences, harder sounds
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/speech/Speech2Cloze">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2"><img width="100%" src="/images/modules/speech-2-cloze.png">
              <div class="p-10 text-center"><h5> Naming Nouns 1 </h5> Say the pictured word</div>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/speech/Naming Speech">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2"><img width="100%" src="/images/modules/naming-speech.png">
              <div class="p-10 text-center"><h5> Repeating Nouns </h5> Say the word you hear</div>
            </div>
          </div>

        </div>

        <br/><br/>
        <h5>Listening</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/AudioPicSent">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/auditory-picture-sentence-match.png">
              <div class="p-10 text-center"><h5>Verb Sentence Match 1</h5>
                Match spoken sentence to photos
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/scramble/SentenceConstruction1">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2"><img width="100%" src="/images/modules/sentence-construction-1.png">
              <div class="p-10 text-center"><h5> Sentence Construction</h5> Move the words to form a sentence</div>
            </div>
          </div>
        </div>
        Any exercise in Level 1 Listening.
        <br/><br/>
        <h5>Numbers</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/scramble/SentenceConstruction1">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2"><img width="100%" src="/images/modules/numbers.png">
              <div class="p-10 text-center"><h5>Learn Numbers</h5>
                Learn numbers 0-9
              </div>
            </div>
          </div>
        </div>

        <br/><br/>
        Broca’s aphasia can improve, even years after a stroke or brain injury. <b>There is no plateau or 6-month time limit–you can always make progress.</b> The best results occur when you practice My Aphasia Coach <b>for at least 30 minutes a
        day</b>.
      </div>
    </div>

  </div>
</div>
<app-my-footer></app-my-footer>
