import * as _ from 'lodash';
import {of, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {Router} from '@angular/router';

import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html'
})
export class MessagesComponent implements OnInit, OnDestroy {
    types = [];
    user;

    private subscriptions = new Subscription();

    constructor(private stimuliService: StimuliService,
                private userService: UserService,
                private router: Router,
                private sanitizer: DomSanitizer) {
        this.user = this.userService.bsUser.value;
    }

    ngOnInit(): void {
        this.subscriptions.add(this.userService.bsUser.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!(_.isEmpty(result))) {
                this.user = result;

                if (this.user.messages) {
                    this.user.messages  = _.sortBy(this.user.messages, ['messageDate']);
                    this.user.messages  = _.reverse(this.user.messages);
                }
            }
        }));

        this.subscriptions.add(this.stimuliService.getTypes().pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.types = result;
            }
        }));

        this.userService.getUserInfo().subscribe();
    }

    ngOnDestroy(): void {
        this.stimuliService.unsubscribe.next();
this.stimuliService.unsubscribe.complete();
this.subscriptions.unsubscribe();
    }

    getVisibleName(name): string {
        const match = _.find(this.types, {name});
        if (match && (match as any).visibleName) {
            return (match as any).visibleName;
        }
        else {
            return name;
        }
    }

    getLinkByName(name): string | void {
        const type: any = _.find(this.types, {name});
        if (type) {
            return '/therapy/' + (type as any).templateType + '/' + type.name;
        }
    }

    showMarkRead(message): boolean {
        if (message.inviteClient || message.inviteTherapist || message.homeworkMessage) {
            return false;
        }
        else if (!message.ack) {
            return true;
        }
        return false;
    }

    showConnect(message): boolean {
        if ((message.inviteClient || message.inviteTherapist) && !(message.acceptInvite || message.rejectInvite)) {
            return true;
        }
        return false;
    }

    markMessageRead(message): any {
        _.remove(this.user.messages, ['_id', message._id]);
        message.ack = true;
        message.ackDate = new Date();

        this.user.messages.push(message);
        const payload = {messages: this.user.messages};
        return this.userService.updateMessages(payload).subscribe();
    }

    deleteMessage(message): any {
        _.remove(this.user.messages, ['_id', message._id]);
        const payload = {messages: this.user.messages};
        return this.userService.updateMessages(payload).subscribe();
    }

    acceptInvite(message): any {
        _.remove(this.user.messages, ['_id', message._id]);

        message.acceptInvite = true;
        message.ack = true;
        message.ackDate = new Date();

        this.user.messages.push(message);

        const payload = {
            messages: this.user.messages,
            acceptInvite: true,
            connectId: message.connectId,
            inviteClient: message.inviteClient,
            inviteTherapist: message.inviteTherapist,
        };

        return this.userService.updateMessages(payload).subscribe();
    }

    rejectInvite(message): any {
        _.remove(this.user.messages, ['_id', message._id]);
        message.rejectInvite = true;
        message.ack = true;
        message.ackDate = new Date();

        this.user.messages.push(message);
        this.userService.bsUser.next(this.user);
        const payload = {
            messages: this.user.messages,
            rejectInvite: true,
            connectId: message.connectId,
            inviteClient: message.inviteClient,
            inviteTherapist: message.inviteTherapist,
        };
        return this.userService.updateMessages(payload).subscribe();
    }

    navigateHomework(message): any {
        _.remove(this.user.messages, ['_id', message._id]);
        message.ack = true;
        message.ackDate = new Date();

        this.user.messages.push(message);
        this.userService.bsUser.next(this.user);
        const payload = {
            messages: this.user.messages,
        };
        return this.userService.updateMessages(payload).subscribe(() => {
            const route = ['/my-homework'];
            return of(this.router.navigate(route));
        });
    }

    navigateSuggestion(message, event): any {
        const type = _.find(this.types, {name: message.task}) as any;
        const route = ['/therapy/' + type.templateType + '/' + type.name];

        if (event.button === 1) {
            window.open(route[0], '_blank');
            return Promise.resolve();
        }
        else {
            return this.router.navigate(route);
        }
    }

    getImage(module): string {
        const type = _.find(this.types, {name: module.task}) as any;

        if (type && type.onLevel > type.maxLevel) {
            return '/images/modules/finished.png';
        }
        else if (type && type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }

    getName(module): string {
        const type = _.find(this.types, {name: module.task}) as any;

        if (type && type.visibleName) {
            return type.visibleName;
        }
        else if (type) {
            return type.name;
        }
        else {
            return '';
        }
    }

    getDescription(module): string {
        const type = _.find(this.types, {name: module.task}) as any;

        if (type && type.description) {
            return type.description;
        }
        else {
            return '';
        }
    }

    navigatePromotionLink(message): any {
        _.remove(this.user.messages, ['_id', message._id]);
        message.ack = true;
        message.ackDate = new Date();

        this.user.messages.push(message);
        this.userService.bsUser.next(this.user);
        const payload = {
            messages: this.user.messages,
        };

        this.userService.updateMessages(payload).subscribe(() => {
            const type = _.find(this.types, {name: message.completedModule}) as any;
            if (type) {
                const route = ['/therapy/' + type.templateType + '/' + type.name];
                return route;
            }
            else {
                return null;
            }
        });
    }

    getPromotionName(message): any {
        const type = _.find(this.types, {name: message.completedModule}) as any;

        if (type && type.visibleName) {
            return type.visibleName;
        }
        else if (type) {
            return type.name;
        }
        else {
            return '';
        }

    }

}
