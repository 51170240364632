<div *ngIf="postSounds" class="modalBody h1">
  <div class="center modalWords">
    <div *ngFor="let sound of stimuli.postSounds; let index = index" [ngClass]="{'highlight-word': index === 0}" class="inline modalWord p-5" id="word{{index}}">
      {{sound.word}}
    </div>
  </div>
</div>

<div *ngIf="!postSounds" class="modalBody p-30 h1">
  <div *ngIf="correctAnswer" class="modalWords">
    <span class="material-icons middle text-success h1">done</span>
    <!--
            <span *ngIf="random === 0"> Correct!</span>
            <span *ngIf="random === 1"> That's right!</span>
    -->
  </div>

  <div *ngIf="!correctAnswer" class="modalWords">
    <span class="material-icons middle text-danger h1">clear</span>
    <!--
            <span *ngIf="random === 0"> Try again</span>
            <span *ngIf="random === 1"> That's not right</span>
    -->
  </div>
</div>
