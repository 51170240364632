import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-close-account',
    templateUrl: './close-account.component.html'
})
export class CloseAccountComponent implements OnInit {
    user: any = {};
    hasRecurringSubscription = false;

    constructor(private userService: UserService,
        private modalService: ModalService
    ) {
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                this.hasRecurringSubscription = _.get(result, 'payment.autoRenewing', false);
            }
        });
    }

    openCloseAccountModal(): Promise<any> {
        return this.modalService.closeAccount().then(res => {
            // console.info("close account confirmed", res);
            this.userService.deleteAccount({ userid: this.user._id }).subscribe(() => {
                this.logout();
            }, (err) => {
                console.error(err);
            });
        }, (err) => {
            console.error(err);
        });
    }

    logout(): void {
        this.userService.logout().subscribe();
    }
}
