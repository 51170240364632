<div class="container-fluid bg-white vh-100">
  <div class="container bg-white p-t-20">
    <div class="row">
      <div *ngIf="clientData" class="col-sm-12">
        <div class="float-right">
          <button (click)="send()" class="btn btn-primary m-l-10"> Notify Patient</button>
          <button (click)="back()" class="btn stop m-l-10" type="button"><span class="material-icons middle">arrow_back_ios</span> Back</button>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-sm-12 p-15">
        <h1>


          Assign Homework Videos
        </h1>

        <div class="row m-t-30">
          <div class="col-sm-6">
            <h2>Search</h2>
            <hr class="hr-2"/>

            <form #searchForm="ngForm" class="m-b-15">
              Please enter a search term
              <div class="input-group m-t-10">
                <div class="input-group-prepend">
                  <span class="input-group-text"><span class="material-icons middle">search</span> </span>
                </div>

                <select [formControl]="filterType" [value]="'startsWith'" class="form-control">
                  <option selected value="startsWith">Starts with</option>
                  <option value="contains">Contains</option>
                </select>

                <input [formControl]="filterInput" class="form-control" id="name" type="text">

                <div (click)="resetSearch()" class="input-group-append">
                  <span class="input-group-text"><span class="material-icons middle">clear</span> </span>
                </div>
              </div>
            </form>


            <div class="m-b-15">
              Or choose a level<br/>
              <br/>
              <button (click)="level(1)" class="btn btn-primary m-r-10 m-b-10">1</button>
              <button (click)="level(2)" class="btn btn-primary m-r-10 m-b-10">2</button>
              <button (click)="level(3)" class="btn btn-primary m-r-10 m-b-10">3</button>
              <button (click)="level(4)" class="btn btn-primary m-r-10 m-b-10">4</button>
              <button (click)="level(5)" class="btn btn-primary m-r-10 m-b-10">5</button>
              <button (click)="level(6)" class="btn btn-primary m-r-10 m-b-10">6</button>
              <button (click)="level(7)" class="btn btn-primary m-r-10 m-b-10">7</button>
              <button (click)="level(8)" class="btn btn-primary m-r-10 m-b-10">8</button>
            </div>

            <div *ngFor="let video of videosFiltered" class="m-b-10 bg-light-gray">

              <div class="bg-white inline-block p-r-5">
                <label (click)="toggleAssigned(video)" [class.btn-primary]="isAssigned(video)"
                       class="btn btn-sm btn-secondary m-0">
                  <div *ngIf="isAssigned(video)" class="btn-homework">Assigned</div>
                  <div *ngIf="!isAssigned(video)" class="btn-homework">Unassigned</div>
                </label>
              </div>
              <div class="bg-light-gray inline-block ">
                <a (click)="open(video.url)" class="pointer p-l-10">{{video.name}}</a>
              </div>
            </div>


          </div>

          <div *ngIf="clientData && clientData.homeworkVideos" class="col-sm-6">

            <h2>
              <button (click)="clear()" class="btn btn-secondary float-right"> Clear Videos</button>
              Assigned Videos
            </h2>
            <hr class="hr-2"/>

            <div [hidden]="!messageStatus.success" class="alert alert-success">Your patient has been notified.</div>
            <div [hidden]="!messageStatus.error" class="alert alert-danger">Something went wrong. Please contact support</div>

            <div *ngFor="let video of clientData.homeworkVideos" class="m-b-5">
              <label (click)="removeVideo(video)" class="btn btn-sm btn-primary">
                <div class="">Remove</div>
              </label>

              <a (click)="open(video.url)" class="pointer p-l-10">{{video.name}}</a>
            </div>


          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div *ngIf="clientData" class="col-sm-12">
        <div class="float-right m-t-50">
          <button (click)="send()" class="btn btn-primary m-l-10"> Notify Patient</button>
          <button (click)="back()" class="btn stop m-l-10" type="button"><span class="material-icons middle">arrow_back_ios</span> Back</button>
        </div>
      </div>
    </div>
  </div>
  <app-my-footer></app-my-footer>
</div>
