import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'homework-modal',
    templateUrl: './homework-modal.component.html'
})
export class HomeworkModalComponent implements OnInit, OnDestroy {
    @Input() level;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }


}
