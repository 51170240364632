import * as _ from 'lodash';

import {Subscription, zip} from 'rxjs';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, OnInit} from '@angular/core';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';

@Component({
    selector: 'progress-modal',
    templateUrl: './progress-modal.component.html',
    styleUrls: ['./progress-modal.component.css']
})
export class ProgressModalComponent implements OnInit {
    user;
    type;
    stats;

    constructor(private userService: UserService,
                private stimuliService: StimuliService,
                public activeModal: NgbActiveModal) {
    }

    ngOnInit(): Subscription {
        return zip(
            this.stimuliService.type,
            this.userService.bsUser,
        ).subscribe((result) => {
            if (result[0] !== null) {
                this.type = result[0];
                this.user = result[1];

                const match = _.filter(this.user.tasks, {task: this.type.name});
                this.stats = match[0];
            }

            return setTimeout(() => {
                return this.activeModal.close('Close click');
            }, 3000);
        });
    }

    correctPercent(lev): number {
        return _.round(lev.answeredCorrectly / lev.answered * 100, 0);
    }

    stroke(task): string {
        if (this.type) {
            if (this.type.worksOn.reading) {
                return '#22FF00';
            }
            else if (this.type.worksOn.writing) {
                return '#ce4b99';
            }
            else if (this.type.worksOn.comprehension) {
                return '#4444FF';
            }
            else {
                return '#EEEE22';
            }
        }
        else {
            return '#f0f0f0';
        }
    }

}
