import * as _ from 'lodash';
import * as reSampler from '../../../audio.service';

import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';

import {AnswerService} from '../../services/answer.service';
import {AudioService} from '../../services/audio.service';
import {BuilderService} from '../../services/builder.service';
import {ModalService} from '../../services/modal.service';
import {ScreenService} from '../../services/screen.service';
import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';
import {MessagingService} from '../../services/messaging.service';

import {settings} from '../../settings';
import {takeUntil} from 'rxjs/operators';
import {config} from '../../config';
import {$WebSocket} from 'angular2-websocket';

@Component({
    selector: 'app-sst-writing2',
    templateUrl: './sst-speech2.component.html'
})

export class SstSpeech2Component implements OnInit, OnDestroy, AfterViewInit {
    settings = settings;
    config = config;

    playPromise;
    audioObjects: Array<any> = [];
    soundListener;

    processor;
    source;
    ws;
    context;
    recording;
    attempts = 0;

    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;
    show = true;
    alternatives;
    message;
    moveOn = true;
    highlight;

    input: any = {};
    answer: Array<any> = [];

    onStep = 0;
    answered = 0;
    stimuliImage: SafeResourceUrl = '';

    @ViewChild('box') box: ElementRef | any;
    @ViewChild('box2') box2: ElementRef | any;

    wrongCount: BehaviorSubject<any> = new BehaviorSubject(0);
    wordsPlaced: BehaviorSubject<any> = new BehaviorSubject(0);

    wrongAnswerCount = 0;
    lastWrong;
    wrongAnswers = '';

    private subscriptions = new Subscription();

    constructor(private router: Router,
                private zone: NgZone,
                private cdr: ChangeDetectorRef,
                private messagingService: MessagingService,
                private userService: UserService,
                private answerService: AnswerService,
                private modalService: ModalService,
                private audioService: AudioService,
                private screenService: ScreenService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
        this.highlight = this.audioService.highlight;
    }

    ngOnInit(): any {
        this.subscriptions.add(this.screenService.bsOnStep.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            this.onStep = result;

            if (this.onStep === 0) {
                return this.builderService.getType().subscribe();
            }
            else if (this.onStep === 1) {

                const name = this.stimuliService.bsType.value.name;
                const stats = _.find(this.userService.bsUser.value?.tasks, {task: name});
                if (stats) {

                    this.audioObjects = [];
/*
                    const temp = new Audio();
                    temp.src = this.config.mediaServer + 'blob.mp3/5851f3e4ca00182900846b5a';
                    (temp as any).word = 'now say';
                    this.audioObjects.push(temp);
*/


                    _.forEach(this.stimuliService.bsStimuli.value.postSounds, (sound: any) => {
                        if (sound.sound) {
                            const temp2 = new Audio();
                            temp2.src = this.config.mediaServer + 'blob.mp3/' + sound.sound;
                            (temp2 as any).word = sound.word;
                            this.audioObjects.push(temp2);
                        }
                    });

                    setTimeout(() => {
                        if (this.audioObjects.length > 0) {
                            this.playAudioObjects(0);
                        }
                    }, 300);

                }
                else {
                    return of(this.audioService.playSstIntro(1));
                }


            }
            else if (this.onStep === 2) {
                const type = JSON.parse(JSON.stringify(this.stimuliService.bsType.value));
                type.buildType = 'whichWordIs';
                this.stimuliService.bsType.next(type);

                return setTimeout(() => {
                    return this.builderService.findPostSound(null, true).subscribe();
                }, 1000);

            }

            return false;

        }));

        this.subscriptions.add(this.userService.bsUser.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                if (this.userService.userInfo.loggedIn) {
                    if (!this.userService.userInfo.premiumSubscriber && this.userService.userInfo.basicSubscriber) {
                        const route = ['/modules-videos'];
                        return this.router.navigate(route);
                    }
                    else if (!this.userService.userInfo.basicSubscriber) {
                        const route = ['/payment'];
                        return this.router.navigate(route);
                    }
                }
            }
            return false;
        }));

        this.subscriptions.add(this.stimuliService.type.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsStimuli.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {

                this.input = {};
                this.answer = [];
                this.answered  = 0;

                this.stimuliImage = this.screenService.getStimuliImage();
                this.show = false;
                this.cdr.detectChanges();

                const tempResult = _.forEach(result.postSounds, sound => {
                    sound.scrambled = _.toArray(sound.word);
                    sound.scrambled = _.shuffle(sound.scrambled);
                    sound.scrambled = _.shuffle(sound.scrambled);
                    sound.scrambled = _.shuffle(sound.scrambled);
                });

                result.postSounds = tempResult;
                this.stimuli = result;
                this.show = true;
                this.cdr.detectChanges();
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        }));

        this.subscriptions.add(this.audioService.bsStopListen.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe((result: boolean) => {
            if (result === true) {
                this.stop(true).then();
            }
        }));


    }

    ngOnDestroy(): void {
        this.screenService.bsOnStep.next(0);
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): any {
        return setTimeout(() => {
            if (this.box?.nativeElement?.offsetHeight > 100) {
                this.box.nativeElement.style.textAlign = 'left';
                this.box2.nativeElement.style.textAlign = 'left';
            }
        }, 500);
    }

    checkScrambling(): any {
        let answer = '';
        const answer2: Array<any> = [];

        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                answer = answer + (child as any).innerText + ' ';
                answer2.push((child as any).innerText);
            });
        });

        answer = answer.trim();

        return of(this.audioService.cleanupAudioObjects().then(() => {
            let correct = false;

            if (answer.toLowerCase() === this.stimuliService.bsStimuli.value.levels[0].word.toLowerCase()) {
                correct = true;
            }

            const payload = {
                correct,
                correctDoesNotCount: this.answerService.correctDoesNotCount,
                type: this.stimuliService.bsType.value.name,
                level: this.builderService.onLevel,
                question: this.stimuliService.bsStimuli.value._id,
            };

            this.stimuliService.updateStats(payload).subscribe();

            if (correct) {
                this.wrongCount.next(null);
                return this.modalService.therapyResponse(true, 1).subscribe(() => {
                    let temp = this.screenService.bsOnStep.value;
                    temp = temp + 1;
                    this.screenService.bsOnStep.next(temp);
                });
            }
            else if (this.wrongCount.value === 3) {
                this.wrongCount.next(null);
                return this.modalService.therapyResponse(false, 1).subscribe(() => {
                    return this.checkScrambling2(answer2);
                });
            }
            else {
                this.wrongCount.next(this.wrongCount.value + 1);
                return this.modalService.therapyResponse(false, 1).subscribe(() => {
                    return this.checkScrambling2(answer2);
                });
            }
        }));
    }

    checkScrambling2(answerArray): void {
        if (this.wrongCount.value > 0) {
            this.show = false;
            this.cdr.detectChanges();
            this.show = true;
            this.cdr.detectChanges();

            const correctAnswer = this.stimuli.levels[0].word.split(' ');

            _.forEach(correctAnswer, (correct, ix: number) => {
                if (answerArray[ix] === correct || this.wrongCount.value >= 3) {
                    _.forEach(this.alternatives, (alt, iter2) => {
                        if (correct.toLowerCase() === alt.toLowerCase()) {
                            // @ts-ignore
                            document.getElementById('land' + ix).appendChild(document.getElementById(iter2 + '' + alt));
                        }
                    });
                }
            });
        }
    }

    alignment(): string {
        this.cdr.detectChanges();
        if (this.box) {
            return 'left';
        }
        else {
            return 'center';
        }
    }

    dragDropReplacement(id): Observable<any> {
        const selected = document.getElementsByClassName('scrambling-selected');
        const clicked: any = document.getElementById(id);
        let setClass = true;

        if (clicked.children.length > 0 && clicked.children[0] === selected[0]) {
            if ((clicked.children[0] as any).classList) {
                clicked.children[0].classList.remove('scrambling-selected');
            }
            setClass = false;
        }

        if (selected.length > 0 && (clicked as any).children.length === 0) {
            clicked.appendChild(selected[0]);
            setClass = false;
        }

        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        _.forEach(document.getElementsByClassName('scrambling-start'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        // Highlight selected
        if (setClass && (clicked.children[0] !== selected[0]) && this.wrongCount.value < 3) {
            // @ts-ignore
            const elem = document.getElementById(id).children;
            let word;

            _.forEach(elem, (child) => {
                (child as any).classList.add('scrambling-selected');
                word = (child as any).innerText;
            });


            if (word) {
                return this.audioService.buildDragAndDropAudio(word);
            }
        }
        return of(false);
    }

    drop(el): void {
        const id = el.dataTransfer.getData('Text');
        if (!el.target.classList.contains('scrambling-word')) {
            if (document.getElementById(id)) {
                el.target.appendChild(document.getElementById(id));
            }
        }
        el.stopPropagation();
        // @ts-ignore
        event.preventDefault();

    }

    dragStart(el): boolean {
        if (el && el.target) {
            el.dataTransfer.effectAllowed = 'move';
            el.dataTransfer.setData('Text', el.target.getAttribute('id'));
            el.dataTransfer.setDragImage(el.target, 30, 20);
        }
        return true;
    }

    dragEnter(event): boolean {
        event.preventDefault();
        return true;
    }

    dragOver(event): boolean {
        event.preventDefault();
        return true;
    }

    highlight2(ix): string {
        if (ix === 0 && this.highlight['highlight-drop']) {
            return 'highlight-drop';
        }
        else {
            return '';
        }
    }

    wwIs(word: any): any {
        this.answered++;
        console.log(this.answered);
        const temp = this.audioService.audioObjects[1];
        if (word === temp.word) {
            return this.modalService.therapyResponse(true, 1).subscribe(() => {
                this.alternatives = [];
                this.screenService.bsOnStep.next(0);
            });
        }
        else {
            return this.modalService.therapyResponse(false, 1).subscribe(() => {
                if (this.answered === 2) {
                    this.alternatives = [];
                    this.screenService.bsOnStep.next(0);
                }
                else {
                    this.audioService.playAudioObjects(0).then();
                }
            });
        }
    }

    playAudioObjects(ix?: number): any {
        let index = 0;

        if (typeof (ix) === 'number') {
            index = ix;
        }

        this.addHighlight(index);
        this.cdr.detectChanges();

        this.playPromise = this.audioObjects[index];

        let valid = null;
        if (this.playPromise) {
            valid = this.playPromise.src.match(/blob.mp3/);
        }

        if (valid !== null && this.playPromise && typeof this.playPromise.play === 'function') {
            this.soundListener = () => {
                return this.afterSound(index);
            };

            this.playPromise.addEventListener('ended', this.soundListener);

            setTimeout(() => {
                return this.playPromise.play().catch(() => {
                    return this.noSound(index);
                });
            }, 500);

        }
        else {
            return this.noSound(index);
        }
    }

    addHighlight(index): void {
        if (document.getElementById('word' + (index ))) {
            // @ts-ignore
            document.getElementById('word' + (index )).classList.add('highlight-word');
        }
    }

    removeHighlight(): void {
        const arr: HTMLCollectionOf<Element> = document.getElementsByClassName('highlight-word');

        if (arr && arr.length > 0) {
            _.forEach(arr, elem => {
                try {
                    elem.classList.remove('highlight-word');
                } catch (err: any) {
                }
            });
        }
    }

    private noSound(index): any {
        return this.afterSound(index);
    }

    private afterSound(index): any {
        if (this.playPromise) {
            this.playPromise.removeEventListener('ended', this.soundListener);
        }

        this.removeHighlight();

        index++;

        if (index < this.audioObjects.length) {
            return this.playAudioObjects(index);
        }
        else {
            this.start();
        }
    }

    start(): Promise<any> {
        return this.audioService.startSound().then(() => {
            this.recording = true;

            try {
                this.context = new AudioContext();
                this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');
            } catch (err: any) {
                this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
            }

            this.ws.getDataStream().subscribe((msg: MessageEvent) => {
                return this.receiveMessage(msg);
            });

            return navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
                this.builderService.timer = setTimeout(() => {
                    return this.stop().then();
                }, 8000);


                return this.zone.run(() => {
                    this.source = this.context.createMediaStreamSource(stream);
                    this.processor = this.context.createScriptProcessor(8192, 1, 1);

                    this.source.connect(this.processor);
                    this.processor.connect(this.context.destination);

                    return this.processor.onaudioprocess = (data) => {
                        return this.zone.run(() => {
                            return reSampler(data.inputBuffer, 16000, (event) => {
                                return this.zone.run(() => {
                                    try {
                                        if (this.ws && this.ws.send()) {
                                            return this.ws.send(this.convertFloat32ToInt16(event.getAudioBuffer().getChannelData(0)), undefined, true).subscribe();
                                        }
                                    } catch (err: any) {
                                        console.error(new Error(err));
                                    }
                                });
                            });
                        });
                    };
                });
            }, () => {
                this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
            });
        });
    }

    stop(skip?): Promise<any> {
        this.audioService.bsListen.next(false);
        clearTimeout(this.builderService.timer);

        if (this.ws) {
            this.ws.send('stop');
            this.ws.close();
            this.ws = null;
        }

        if (this.processor) {
            this.processor.disconnect();
            this.processor = null;
        }

        this.source = null;
        this.recording = false;
        this.builderService.timer = null;

        if (!skip) {
            return this.audioService.stopSound();
        }
        else {
            return Promise.resolve(true);
        }
    }

    receiveMessage(msg?): any {
        return this.zone.run(() => {
            let message;
            try {
                message = JSON.parse(msg.data);
            } catch (err: any) {
            }

            if (message) {
                return this.stop().then(() => {
                    let correct = false;

                    if (message && message[0] && message[0].alternatives) {

                        _.forEach(this.stimuli.levels, (answer) => {
                            const result = _.filter(message[0].alternatives, (alternative) => {
                                if (_.toLower(alternative.transcript).includes(_.toLower(answer.word.trim()))) {
                                    return true;
                                }
                                return false;
                            });

                            if (result.length > 0) {
                                correct = true;
                            }
                        });

                        if (!correct) {
                            this.answerService.correctDoesNotCount = true;
                        }
                    }

                    this.attempts = this.attempts + 1;

                    if (correct) {
                        this.attempts = 0;
                        return this.answerService.displayModalOnly(true).subscribe(() => {
                            this.screenService.bsOnStep.next(2);
                        });
                    }
                    else {
                        return this.answerService.displayModalOnly(false).subscribe(() => {
                            if (this.attempts > 2) {
                                return setTimeout(() => {
                                    console.log('gave up');
                                    this.screenService.bsOnStep.next(2);
                                }, 1500);
                            }
                            else {
                                return setTimeout(() => {
                                    console.log('try again');
                                }, 1500);
                            }
                        });
                    }
                });
            }
            else {
                console.log('no msg');
            }
            return of(false);
        });
    }

    convertFloat32ToInt16(buffer): any {
        let l = buffer.length;
        const buf = new Int16Array(l);
        while (l--) {
            buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
        }
        return buf.buffer;
    }


}
