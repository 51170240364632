import * as _ from 'lodash';
import {Subscription} from 'rxjs';

import {SafeResourceUrl} from '@angular/platform-browser';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {User} from '../../models/user';
import {settings} from '../../settings';

import {AnswerService} from '../../services/answer.service';
import {AudioService} from '../../services/audio.service';
import {BuilderService} from '../../services/builder.service';
import {ScreenService} from '../../services/screen.service';
import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

@Component({
    templateUrl: './writing.component.html',
})
export class WritingComponent implements OnInit, OnDestroy {
    settings = settings;
    highlight;

    typeSettings: any = {};
    user: User | null = null;
    userInfo: any;
    type: any;
    stimuli: any;

    wrongAnswerCount = 0;
    answer: Array<any> = [];
    // input: Array<any> = [];
    input = {};


    lastWrong;
    question;
    wrongAnswers = '';
    focus;

    oUser;
    oType;
    oStimuli;
    oTypeSettings;
    oAlternatives;
    oFocus;

    constructor(public userService: UserService,
                public audioService: AudioService,
                public answerService: AnswerService,
                public screenService: ScreenService,
                public builderService: BuilderService,
                public location: Location,
                public stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
        this.highlight = this.audioService.highlight;
    }

    ngOnInit(): Subscription {
        this.wrongAnswerCount = 0;
        this.wrongAnswers = '';

        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        });

        this.oType = this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        });

        this.oStimuli = this.stimuliService.stimuli.subscribe(result => {
            this.stimuli = null;

            if (!_.isEmpty(result)) {
                this.input = {};
                this.wrongAnswers = '';
                this.wrongAnswerCount = 0;
                this.answer = [];
                this.stimuli = result;

                const answer = _.sortBy(this.stimuli.levels, ['sortCode']);
                _.forEach(answer, (i) => {
                    this.answer.push(i.word.split(' '));
                });
                this.answer = _.flatten(this.answer);

                this.setFocus();
            }
        });

        this.oAlternatives = this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.question = result;
            }
        });

        this.oTypeSettings = this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        });

        this.oFocus = this.screenService.bsSetFocus.subscribe(result => {
            if (result) {
                this.screenService.bsSetFocus.next(false);
                this.setFocus();
            }
        });

        return this.builderService.getType().subscribe();

    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
        this.oType.unsubscribe();
        this.oStimuli.unsubscribe();
        this.oAlternatives.unsubscribe();
        this.oTypeSettings.unsubscribe();
        this.oFocus.unsubscribe();
    }

    checkAnswer(): void {
        if (this.input[0] && this.input[0].length > 0) {
            const temp = document.activeElement as HTMLElement;
            temp.blur();

            setTimeout(() => {
                this.answerService.writing(this.input).subscribe({
                    next: (res) => {
                        this.input = {};
                        this.removeClasses();
                        this.setFocus();
                    },
                    error: (err) => {
                        console.error(err);
                    },
                });
            }, 100);
        }
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }

    highlightCorrect(ix): void {
        this.removeClasses(ix);
        return this.check(ix);
    }

    removeClasses(ix?): void {
        _.forEach(this.question, (word, iter1) => {
            let iter = iter1;
            if (ix) {
                iter = ix;
            }
            _.forEach(word, (letter, iter2) => {
                if (document.getElementById('letter_' + iter + '_' + iter2)) {
                    this.screenService.removeClass(document.getElementById('letter_' + iter + '_' + iter2), 'correct-char');
                }
            });
        });
    }

    check(ix): void {
        if (this.input[ix] && this.input[ix].length > 0) {

            if (_.startsWith(_.toUpper(this.answer[ix]), _.toUpper(this.input[ix]))) {
                let i;
                let j;

                for (i = 0; i < this.input[ix].length;) {

                    for (j = 0; j < this.question[ix].length;) {
                        if (this.question[ix][j] === this.input[ix][i]) {
                            if (this.screenService.hasClass(document.getElementById('letter_' + ix + '_' + j), 'correct-char') === false) {
                                this.screenService.addClass(document.getElementById('letter_' + ix + '_' + j), 'correct-char');
                                break;
                            }
                        }
                        j++;
                    }
                    i++;
                }

                if (this.answer[ix].length === this.input[ix].length) {
                    this.lastWrong = null;
                    const newField = ix + 1;
                    if (document.getElementById('input_' + newField)) {
                        // @ts-ignore
                        document.getElementById('input_' + newField).focus();
                    }
                }
            }
            else {
                this.updateWrongAnswers(ix);
                return this.check(ix);
            }
        }
    }

    updateWrongAnswers(ix): void {
        this.answerService.correctDoesNotCount = true;
        this.wrongAnswerCount = this.wrongAnswerCount + 1;

        const lastChar = this.input[ix].slice(-1).toUpperCase();
        this.lastWrong = _.cloneDeep(this.wrongAnswers.slice(-1).toUpperCase());

        if (lastChar !== this.lastWrong) {
            // this.wrongAnswers = this.wrongAnswers + this.input[ix].toString().slice(-1).toUpperCase();
            this.input[ix] = this.input[ix].slice(0, -1);
        }

        if (this.wrongAnswerCount === 3) {
            this.wrongAnswerCount = 0;
            this.wrongAnswers = '';
            this.input[ix] = this.input[ix] + this.answer[ix].charAt(this.input[ix].length);
        }
    }

    setFocus(): any {
        return setTimeout(() => {
            try {
                // @ts-ignore
                document.getElementById('input_0').focus();
            } catch {
            }
        }, 300);
    }
}

