<div class="container-fluid bg-white" id="faq-1">

  <a href="/"><img class="p-t-15 p-l-15" src="/images/logo2.png" width="300"/></a>

  <div class="container" id="fp-2">
    <div class="" id="fp-3">
      <br/>
      <br/>
      <h1 id="fp-4">Software for Faster Aphasia Progress</h1>
      <br/>
      <h3>Expert therapy you can do from home for stroke and brain injury
rehabilitation</h3>
      <br/>
      <br/>

      <div class="inline-block">
      <a [routerLink]="'/register'" class="btn trial p-30 h3">
        Get started for free
      </a>
      <br/><div class="text-small center">* No Credit Card Required</div>
    </div>
    </div>
  </div>
</div>


<div class="container-fluid bg-teal">
  <div class="container p-30 center">
    <h2>FAQ</h2>
  </div>
</div>
<div class="container-fluid bg-white">
  <div class="center">
    <svg class="p-b-30" style="width:100px" viewBox="0 0 42 21">
      <polygon class="triangle" points="0,0 42,0 21,21"/>
    </svg>
  </div>
</div>

<div class="container-fluid bg-white">

  <div class="container bg-white p-t-20">
    <!--<div class="row">
      <div class="col-sm-12 p-15">
        <h1>FAQ</h1>
      </div>
    </div>-->

    <div class="row">
      <div class="col-sm-6">

        <ngb-accordion>
          <ngb-panel title="On what devices can I use the software">
            <ng-template ngbPanelContent>
              You can use My Aphasia Coach on your Apple phone, iPad, Mac, PC, and Android phone or tablet. You must have an internet connection to use this app.
            </ng-template>
          </ngb-panel>
          <ngb-panel title="Where should I start?">
            <ng-template ngbPanelContent>
              Take the <a routerLink="/where-do-i-start">Quiz</a>  for recommended exercises. You can also choose a skill, then your level, and choose your own exercise.
            </ng-template>
          </ngb-panel>
          <ngb-panel title="How does this software help my aphasia?">
            <ng-template ngbPanelContent>
              You learn by practicing language at a level that is challenging but not frustrating. Words and photos are repeated across many different exercises so you learn the information in different ways.
            </ng-template>
          </ngb-panel>
          <ngb-panel title="I have no sound">
            <ng-template ngbPanelContent>
              Check that your volume is on, that your device isn't muted, or that you are not connected to Bluetooth devices. You must have an internet connection to use the software.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>

      </div>
      <div class="col-sm-6">
        <ngb-accordion>
          <ngb-panel title="Do I need to use this with a speech therapist?">
            <ng-template ngbPanelContent>
              No, you can use this software without a speech therapist. If you do have a therapist, you can connect to them by choosing "My Therapist" from the menu.
            </ng-template>
          </ngb-panel>
          <ngb-panel title="Do I need any extra equipment?">
            <ng-template ngbPanelContent>
              No other equipment is needed at this time.
            </ng-template>
          </ngb-panel>
          <ngb-panel title="How do I cancel my subscription?">
            <ng-template ngbPanelContent>
              Cancellation is hassle-free! If you signed up on your iPad, cancel in your iTunes account. If you signed up on Google Play, cancel in your Google Pay account.
              If you signed up on the computer, cancel <a class="bold" routerLink="/payment">here</a>.
            </ng-template>
          </ngb-panel>
          <ngb-panel title="The On Screen keyboard on my tablet is hiding the 'Done' button.">
            <ng-template ngbPanelContent>
              You can turn the tablet to portrait mode or press the Return key on the keyboard instead of the Done button.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
  <app-my-footer></app-my-footer>
</div>
