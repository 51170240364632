<div class="modal-header">
  <h3 class="modal-title" id="modal-title">Remove Access?</h3>
</div>
<div class="modal-body" id="modal-body">
  Remove access to progress reports?
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('revoke')" class="btn btn-danger" type="submit">Remove Access</button>
  <button (click)="activeModal.dismiss('Cancel')" class="btn btn-primary" type="submit">Cancel</button>
</div>
