<div class="container-fluid bg-white">
<div class="container bg-white p-t-20">
  <div class="row">
    <div *ngIf="user" class="col-sm-12 p-15">
      <h1>Change Password</h1>
      <!--
            Create new password here<br/><br/>
      -->

      <div [hidden]="!errorEmail || errorPassword" class="alert alert-danger p10 ">{{errorEmail || errorPassword}}</div>
      <div [hidden]="!successMessage" class="alert alert-success p10 ">{{successMessage}}</div>
      <!-- FORM -->
      <form (ngSubmit)="processLostPassReset()">
        <div class="form-group" id="password-group" ngClass="{ 'has-error' : errorPassword }">
          <label>Create New Password</label>
          <input [(ngModel)]="formData.password" class="form-control" name="password" type="password">
          <span [hidden]="!errorPassword" class="bg-warning p-5  help-block">{{ errorPassword }}</span>
        </div>
        <button class="btn btn-primary" type="submit">Save New Password</button>
      </form>
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
</div>
