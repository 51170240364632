import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {BuilderService} from '../../services/builder.service';
import {AnswerService} from '../../services/answer.service';
import {Location} from '@angular/common';

import {settings} from '../../settings';
import {Subscription} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ScreenService} from '../../services/screen.service';

@Component({
    templateUrl: './find-word.component.html',
    styleUrls: ['./find-word.component.css']
})
export class FindWordComponent implements OnInit, OnDestroy {
    settings = settings;
    alternatives: Array<any> = [];
    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;

    private oTypeSettings;
    private oUser;
    private oAlternatives;
    private oType;
    private oStimuli;
    private fetchCount = 0;

    constructor(private router: Router,
                private location: Location,
                private screenService: ScreenService,
                private userService: UserService,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
    }

    ngOnInit(): Subscription {
        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;

                if (this.userService.userInfo.loggedIn) {
                    if (!this.userService.userInfo.premiumSubscriber && this.userService.userInfo.basicSubscriber) {
                        const route = ['/modules-videos'];
                        return this.router.navigate(route);
                    }
                    else if (!this.userService.userInfo.basicSubscriber) {
                        const route = ['/account'];
                        return this.router.navigate(route);
                    }
                }


                this.fetchCount = this.fetchCount + 1;
                if (this.fetchCount < 2) {
                    return this.builderService.getType();
                }
            }

            return false;
        });

        this.oType = this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        });

        this.oStimuli = this.stimuliService.stimuli.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.stimuli = result;
            }
        });

        this.oAlternatives = this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        });

        this.oTypeSettings = this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        });

        const urlName = decodeURI(this.location.path(true).split('/')[3]);

        if (urlName === 'Scrambling2') {
            return this.builderService.sentenceWordScramble().subscribe();
        }
        else {
            return this.builderService.getType().subscribe();
        }
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
        this.oType.unsubscribe();
        this.oAlternatives.unsubscribe();
        this.oTypeSettings.unsubscribe();
        this.oStimuli.unsubscribe();
    }

    checkAnswer(id): Subscription {
        return this.answerService.click(id).subscribe();
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }
}
