import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
// import {ServiceWorkerModule} from '@angular/service-worker';
import {AnswerService} from './services/answer.service';
import {AudioService} from './services/audio.service';
import {CsatService} from './services/csat.service';
import {BuilderService} from './services/builder.service';
import {MessagingService} from './services/messaging.service';
import {ModalService} from './services/modal.service';
import {NumbersService} from './services/numbers.service';
import {ScreenService} from './services/screen.service';
import {StimuliService} from './services/stimuli.service';
import {UserService} from './services/user.service';
import {VideoService} from './services/video.service';

import {ObjectToArrayPipe} from './pipes/object-to-array.pipe';
import {SearchFilterPipe} from './pipes/search-filter.pipe';

import {AboutUsComponent} from './components/about-us/about-us.component';
import {AphasiaComponent} from './components/aphasia/aphasia.component';
import {AphasiaAnomicComponent} from './components/aphasia-anomic/aphasia-anomic.component';
import {AphasiaApraxiaComponent} from './components/aphasia-apraxia/aphasia-apraxia.component';
import {AphasiaBrocasComponent} from './components/aphasia-brocas/aphasia-brocas.component';
import {AphasiaConductionComponent} from './components/aphasia-conduction/aphasia-conduction.component';
import {AphasiaExpressiveComponent} from './components/aphasia-expressive/aphasia-expressive.component';
import {AphasiaGlobalComponent} from './components/aphasia-global/aphasia-global.component';
import {AphasiaPrimaryProgressiveComponent} from './components/aphasia-primary-progressive/aphasia-primary-progressive.component';
import {AphasiaReceptiveComponent} from './components/aphasia-receptive/aphasia-receptive.component';
import {AphasiaWernickesComponent} from './components/aphasia-wernickes/aphasia-wernickes.component';
import {AccountComponent} from './components/account/account.component';
import {AppComponent} from './app.component';
import {AssignHomeworkComponent} from './components/assign-homework/assign-homework.component';
import {AssignHomeworkVideosComponent} from './components/assign-homework-videos/assign-homework-videos.component';
import {AudioTestComponent} from './components/audio-test/audio-test.component';
import {BetaComponent} from './components/beta/beta.component';
import {ChangeCardComponent} from './components/change-card/change-card.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {ClickComponent} from './components/click/click.component';
import {ClientListComponent} from './components/client-list/client-list.component';
import {ClientHomeworkComponent} from './components/client-homework/client-homework.component';
import {ClientProgressComponent} from './components/client-progress/client-progress.component';
import {CloseAccountComponent} from './components/close-account/close-account.component';
import {ConnectTherapistComponent} from './components/connect-therapist/connect-therapist.component';
import {ContactComponent} from './components/contact/contact.component';
import {CsatCardsComponent} from './components/csat-cards/csat-cards.component';
import {CsatClickComponent} from './components/csat-click/csat-click.component';
import {CsatClientComponent} from './components/csat-client/csat-client.component';
import {CsatComponent} from './components/csat/csat.component';
import {CsatReadingComponent} from './components/csat-reading/csat-reading.component';
import {CsatScrambleComponent} from './components/csat-scramble/csat-scramble.component';
import {CsatTextComponent} from './components/csat-text/csat-text.component';
import {CsatTherapistComponent} from './components/csat-therapist/csat-therapist.component';
import {CsatWhiteboardComponent} from './components/csat-whiteboard/csat-whiteboard.component';
import {CsatWritingComponent} from './components/csat-writing/csat-writing.component';
import {DeleteAccountComponent} from './components/delete-account/delete-account.component';
import {ErrorMessageComponent} from './components/error-message/error-message.component';
import {FaqComponent} from './components/faq/faq.component';
import {FaqTherapistComponent} from './components/faq-therapist/faq-therapist.component';
import {FindPostSoundComponent} from './components/find-post-sound/find-post-sound.component';
import {FindWordComponent} from './components/find-word/find-word.component';
import {FooterComponent} from './components/footer/footer.component';
import {GetAccessComponent} from './components/get-access/get-access.component';
import {HomeworkModalComponent} from './components/homework-modal/homework-modal.component';
import {HintComponent} from './components/hint/hint.component';
import {HowItWorksComponent} from './components/how-it-works/how-it-works.component';
import {InviteClientComponent} from './components/invite-client/invite-client.component';
import {InvoicesComponent} from './components/invoices/invoices.component';
import {LandingDesktop2Component} from './components/landing-desktop2/landing-desktop2.component';
import {LandingDesktopComponent} from './components/landing-desktop/landing-desktop.component';
import {LandingMobile2Component} from './components/landing-mobile2/landing-mobile2.component';
import {LandingMobileComponent} from './components/landing-mobile/landing-mobile.component';
import {LoginComponent} from './components/login/login.component';
import {LostPasswordComponent} from './components/lost-password/lost-password.component';
import {LostPasswordResetComponent} from './components/lost-password-reset/lost-password-reset.component';
import {MainComponent} from './components/main/main.component';
import {MessagesComponent} from './components/messages/messages.component';
import {MenuComponent} from './components/menu/menu.component';
import {MessagingComponent} from './components/messaging/messaging.component';
import {MobileWarningComponent} from './components/mobile-warning/mobile-warning.component';
import {ModuleCompletedComponent} from './components/module-completed/module-completed.component';
import {ModuleCompletedModalComponent} from './components/module-completed-modal/module-completed-modal.component';
import {ModulesComponent} from './components/modules/modules.component';
import {ModulesSearchComponent} from './components/modules-search/modules-search.component';
import {ModulesVideosComponent} from './components/modules-videos/modules-videos.component';
import {MyHomeworkComponent} from './components/my-homework/my-homework.component';
import {MyTasksComponent} from './components/my-tasks/my-tasks.component';
import {MyVideosComponent} from './components/my-videos/my-videos.component';
import {NoInternetComponent} from './components/no-internet/no-internet.component';
import {NoStimuliComponent} from './components/no-stimuli/no-stimuli.component';
import {NotificationAddressesComponent} from './components/notification-addresses/notification-addresses.component';
import {NumbersComponent} from './components/numbers/numbers.component';
import {PaymentComponent} from './components/payment/payment.component';
import {PreRenderComponent} from './components/prerender/prerender.component';
import {PrivacyComponent} from './components/privacy/privacy.component';
import {PricingComponent} from './components/pricing/pricing.component';
import {ProgramsComponent} from './components/programs/programs.component';
import {ProgressComponent} from './components/progress/progress.component';
import {ProgressModalComponent} from './components/progress-modal/progress-modal.component';
import {PromotionComponent} from './components/promotion/promotion.component';
import {PromotionModalComponent} from './components/promotion-modal/promotion-modal.component';
import {RegisterClientComponent} from './components/register-client/register-client.component';
import {RegisterComponent} from './components/register/register.component';
import {RegisterTherapistComponent} from './components/register-therapist/register-therapist.component';
import {RepetitionComponent} from './components/repetition/repetition.component';
import {RevokeAccessModalComponent} from './components/revoke-access-modal/revoke-access-modal.component';
import {ScrambleComponent} from './components/scramble/scramble.component';
import {SetCompleteModalComponent} from './components/set-complete-modal/set-complete-modal.component';
import {MyTherapistComponent} from './components/my-therapist/my-therapist.component';
import {SpeechComponent} from './components/speech/speech.component';
import {SpeechDebugComponent} from './components/speech-debug/speech-debug.component';
import {SpeechPacingComponent} from './components/speech-pacing/speech-pacing.component';
import {StatsClientsComponent} from './components/stats-clients/stats-clients.component';
import {SstSpeech2Component} from './components/sst-speech2/sst-speech2.component';
import {SstWriting2Component} from './components/sst-writing2/sst-writing2.component';
import {StatsComponent} from './components/stats/stats.component';
import {TermsComponent} from './components/terms/terms.component';
import {TestComponent} from './components/test/test.component';
import {TherapistWelcomeComponent} from './components/therapist-welcome/therapist-welcome.component';
import {TherapyComponent} from './components/therapy/therapy.component';
import {TherapyResponseModalComponent} from './components/therapy-response-modal/therapy-response-modal.component';
import {TrainingModulesComponent} from './components/training-modules/training-modules.component';
import {TrialComponent} from './components/trial/trial.component';
import {TutorialModalComponent} from './components/tutorial-modal/tutorial-modal.component';
import {TutorialsComponent} from './components/tutorials/tutorials.component';
import {UpgradeBrowserComponent} from './components/upgrade-browser/upgrade-browser.component';
import {VersionComponent} from './components/version/version.component';
import {VideoModalComponent} from './components/video-modal/video-modal.component';
import {VideosComponent} from './components/videos/videos.component';
import {WhereDoIStartComponent} from './components/where-do-i-start/where-do-i-start.component';
import {WritingNumbersComponent} from './components/writing-numbers/writing-numbers.component';
import {Writing1Component} from './components/writing1/writing1.component';
import {Writing2Component} from './components/writing2/writing2.component';
import {Writing3Component} from './components/writing3/writing3.component';
import {WritingComponent} from './components/writing/writing.component';
import { CloseAccountModalComponent } from './components/close-account-modal/close-account-modal.component';

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollOffset: [0, 65],
    scrollPositionRestoration: 'top',
//    initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload'
};

const routes: Routes = [
    {
        path: 'csat/:id',
        component: CsatComponent,
        children: [
            {path: 'therapist', component: CsatTherapistComponent},
            {path: 'click/:type', component: CsatClickComponent},
            {path: 'writing/:type', component: CsatWritingComponent},
            {path: 'scramble/:type', component: CsatScrambleComponent},
            {path: 'reading/:type', component: CsatReadingComponent},
            {path: 'cards', component: CsatCardsComponent},
            {path: 'whiteboard', component: CsatWhiteboardComponent},
            {path: 'text', component: CsatTextComponent},
            {path: '', redirectTo: 'therapist', pathMatch: 'full'}
        ]
    },
    {path: 'aphasia/anomic', component: AphasiaAnomicComponent},
    {path: 'aphasia/apraxia', component: AphasiaApraxiaComponent},
    {path: 'aphasia/brocas', component: AphasiaBrocasComponent},
    {path: 'aphasia/conduction', component: AphasiaConductionComponent},
    {path: 'aphasia/expressive', component: AphasiaExpressiveComponent},
    {path: 'aphasia/global', component: AphasiaGlobalComponent},
    {path: 'aphasia/primary-progressive', component: AphasiaPrimaryProgressiveComponent},
    {path: 'aphasia/receptive', component: AphasiaReceptiveComponent},
    {path: 'aphasia/wernickes', component: AphasiaWernickesComponent},
    {path: 'aphasia', component: AphasiaComponent},
    {path: 'about-us', component: AboutUsComponent},
    {path: 'account', component: AccountComponent},
    {path: 'assign-homework-videos/:id', component: AssignHomeworkVideosComponent},
    {path: 'assign-homework/:id', component: AssignHomeworkComponent},
    {path: 'audio-test', component: AudioTestComponent},
    {path: 'beta', component: BetaComponent},
    {path: 'change-card', component: ChangeCardComponent},
    {path: 'change-password', component: ChangePasswordComponent},
    {path: 'client-list', component: ClientListComponent},
    {path: 'client-progress/:id', component: ClientProgressComponent},
    {path: 'client-homework/:id', component: ClientHomeworkComponent},
    {path: 'close-account', component: CloseAccountComponent},
    {path: 'connect-therapist', component: ConnectTherapistComponent},
    {path: 'connect-therapist/:id/:value', component: ConnectTherapistComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'csat', redirectTo: '/client-access', pathMatch: 'full'},
    {path: 'csat-client/:id', component: CsatClientComponent},
    {path: 'delete-account', component: DeleteAccountComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'faq-therapist', component: FaqTherapistComponent},
    {path: 'get-access', component: GetAccessComponent},
    {path: 'get-access/:id/:value', component: GetAccessComponent},
    {path: 'invite-client', component: InviteClientComponent},
    {path: 'invoices', component: InvoicesComponent},
    {path: 'how-it-works', component: HowItWorksComponent},
    {path: 'landing-desktop', component: LandingDesktopComponent},
    {path: 'landing-desktop2', component: LandingDesktop2Component},
    {path: 'landing-mobile', component: LandingMobileComponent},
    {path: 'landing-mobile2', component: LandingMobile2Component},
    {path: 'login', component: LoginComponent},
    {path: 'lost-password', component: LostPasswordComponent},
    {path: 'lost-password-reset/:id/:lostPass', component: LostPasswordResetComponent},
    {path: 'lostpassreset/:id/:lostPass', component: LostPasswordResetComponent},
    {path: 'messages', component: MessagesComponent},
    {path: 'module-completed', component: ModuleCompletedComponent},
    {path: 'module-completed/:module', component: ModuleCompletedComponent},
    {path: 'modules', component: ModulesComponent},
    {path: 'modules-search', component: ModulesSearchComponent},
    {path: 'modules-videos', component: ModulesVideosComponent},
    {path: 'my-homework', component: MyHomeworkComponent},
    {path: 'my-tasks', component: MyTasksComponent},
    {path: 'my-videos', component: MyVideosComponent},
    {path: 'no-internet', component: NoInternetComponent},
    {path: 'no-stimuli', component: NoStimuliComponent},
    {path: 'notification-addresses/:id', component: NotificationAddressesComponent},
    {path: 'payment', component: PaymentComponent},
    {path: 'prerender', component: PreRenderComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'programs', component: ProgramsComponent},
    {path: 'progress', component: ProgressComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'register-client', component: RegisterClientComponent},
    {path: 'register-therapist', component: RegisterTherapistComponent},
    {path: 'register-therapist/:id/:value', component: RegisterTherapistComponent},
    {path: 'register/:id/:value', component: RegisterComponent},
    {path: 'my-therapist', component: MyTherapistComponent},
    {
        path: 'speech-debug',
        component: SpeechDebugComponent,
    },
    {path: 'terms', component: TermsComponent},
    {path: 'test', component: TestComponent},
    {path: 'therapist-welcome', component: TherapistWelcomeComponent},
    {path: 'training-modules', component: TrainingModulesComponent},
    {path: 'tutorials', component: TutorialsComponent},
    {path: 'upgrade', component: UpgradeBrowserComponent},
    {path: 'videos', component: VideosComponent},
    {path: 'videos/:level', component: VideosComponent},
    {path: 'where-do-i-start', component: WhereDoIStartComponent},
    {path: 'therapy', redirectTo: 'progress', pathMatch: 'full'},
    {
        path: 'therapy',
        component: TherapyComponent,
        children: [
            {
                path: 'speech-pacing/:type/:level/:id',
                component: SpeechPacingComponent,
            },
            {
                path: 'numbers/:type/:level/:id',
                component: NumbersComponent,
            },
            {
                path: 'speech/:type/:level/:id',
                component: SpeechComponent,
            },
            {
                path: 'writing/:type/:level/:id',
                component: WritingComponent,
            },
            {
                path: 'writing2/:type/:level/:id',
                component: Writing2Component,
            },
            {
                path: 'writing3/:type/:level/:id',
                component: Writing3Component,
            },
            {
                path: 'sentenceWriting/:type/:level/:id',
                component: WritingComponent,
            },
            {
                path: 'repetition/:type/:level/:id',
                component: RepetitionComponent,
            },
            {
                path: 'findPostSound/:type/:level/:id',
                component: FindPostSoundComponent,
            },
            {
                path: 'findWord/:type/:level/:id',
                component: FindWordComponent,
            },
            {
                path: 'scramble/:type/:level/:id',
                component: ScrambleComponent,
            },
            {
                path: 'click/:type/:level/:id',
                component: ClickComponent,
            },
            {
                path: 'string/:type/:level/:id',
                component: WritingComponent,
            },
            {
                path: 'sst-writing2/:type/:level/:id',
                component: SstWriting2Component,
            },
            {
                path: 'sst-speech2/:type/:level/:id',
                component: SstSpeech2Component,
            },
            {
                path: 'speech-pacing/:type',
                component: SpeechPacingComponent,
            },
            {
                path: 'numbers/:type',
                component: NumbersComponent,
            },
            {
                path: 'speech/:type',
                component: SpeechComponent,
            },
            {
                path: 'sst-writing2/:type',
                component: SstWriting2Component,
            },
            {
                path: 'sst-speech2/:type',
                component: SstSpeech2Component,
            },
            {
                path: 'writing/:type',
                component: WritingComponent,
            },
            {
                path: 'writing-numbers/:type',
                component: WritingNumbersComponent,
            },
            {
                path: 'writing1/:type',
                component: Writing1Component,
            },
            {
                path: 'writing2/:type',
                component: Writing2Component,
            },
            {
                path: 'writing3/:type',
                component: Writing3Component,
            },
            {
                path: 'sentenceWriting/:type',
                component: WritingComponent,
            },
            {
                path: 'repetition/:type',
                component: RepetitionComponent,
            },
            {
                path: 'findPostSound/:type',
                component: FindPostSoundComponent,
            },
            {
                path: 'findWord/:type',
                component: FindWordComponent,
            },
            {
                path: 'scramble/:type',
                component: ScrambleComponent,
            },
            {
                path: 'click/:type',
                component: ClickComponent,
            },
            {
                path: 'string/:type',
                component: WritingComponent,
            }]
    },
    {path: '', component: MainComponent},
    {path: '**', component: MainComponent, redirectTo: ''},
    // {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
];

@NgModule({
    declarations: [
        AboutUsComponent,
        AphasiaComponent,
        AphasiaAnomicComponent,
        AphasiaApraxiaComponent,
        AphasiaBrocasComponent,
        AphasiaConductionComponent,
        AphasiaExpressiveComponent,
        AphasiaGlobalComponent,
        AphasiaPrimaryProgressiveComponent,
        AphasiaReceptiveComponent,
        AphasiaWernickesComponent,
        AccountComponent,
        AppComponent,
        AssignHomeworkComponent,
        AssignHomeworkVideosComponent,
        AudioTestComponent,
        BetaComponent,
        ChangeCardComponent,
        ChangePasswordComponent,
        ClickComponent,
        ClientListComponent,
        ClientHomeworkComponent,
        ClientProgressComponent,
        CloseAccountComponent,
        CloseAccountModalComponent,
        ConnectTherapistComponent,
        ContactComponent,
        CsatCardsComponent,
        CsatClickComponent,
        CsatClientComponent,
        CsatComponent,
        CsatReadingComponent,
        CsatScrambleComponent,
        CsatTextComponent,
        CsatTherapistComponent,
        CsatWhiteboardComponent,
        CsatWritingComponent,
        DeleteAccountComponent,
        ErrorMessageComponent,
        FaqComponent,
        FaqTherapistComponent,
        FindPostSoundComponent,
        FindWordComponent,
        FooterComponent,
        GetAccessComponent,
        HintComponent,
        HomeworkModalComponent,
        HowItWorksComponent,
        InviteClientComponent,
        InvoicesComponent,
        LandingDesktop2Component,
        LandingDesktopComponent,
        LandingMobile2Component,
        LandingMobileComponent,
        LoginComponent,
        LostPasswordComponent,
        LostPasswordResetComponent,
        MainComponent,
        MenuComponent,
        MessagesComponent,
        MessagingComponent,
        MobileWarningComponent,
        ModuleCompletedComponent,
        ModuleCompletedModalComponent,
        ModulesComponent,
        ModulesSearchComponent,
        ModulesVideosComponent,
        MyHomeworkComponent,
        MyTasksComponent,
        MyVideosComponent,
        NoInternetComponent,
        NoStimuliComponent,
        NotificationAddressesComponent,
        NumbersComponent,
        ObjectToArrayPipe,
        PaymentComponent,
        PreRenderComponent,
        PricingComponent,
        PrivacyComponent,
        ProgramsComponent,
        ProgressComponent,
        ProgressModalComponent,
        PromotionComponent,
        PromotionModalComponent,
        RegisterClientComponent,
        RegisterComponent,
        RegisterTherapistComponent,
        RepetitionComponent,
        RevokeAccessModalComponent,
        ScrambleComponent,
        SearchFilterPipe,
        SetCompleteModalComponent,
        SstWriting2Component,
        SstSpeech2Component,
        MyTherapistComponent,
        SpeechComponent,
        SpeechDebugComponent,
        SpeechPacingComponent,
        StatsClientsComponent,
        StatsComponent,
        TermsComponent,
        TestComponent,
        TherapistWelcomeComponent,
        TherapyComponent,
        TherapyResponseModalComponent,
        TrainingModulesComponent,
        TrialComponent,
        TutorialModalComponent,
        TutorialsComponent,
        UpgradeBrowserComponent,
        VersionComponent,
        VideoModalComponent,
        VideosComponent,
        WhereDoIStartComponent,
        Writing1Component,
        Writing2Component,
        Writing3Component,
        WritingComponent,
        WritingNumbersComponent,
    ],
    imports: [BrowserModule.withServerTransition({ appId: 'my-aphasia-coach' }),
        FormsModule,
        HttpClientModule,
        NgbModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, routerOptions),
        // ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ],
    exports: [],
    providers: [NumbersService, StimuliService, UserService, ModalService, MessagingService, VideoService, BuilderService, AnswerService, CsatService, AudioService, ScreenService, SearchFilterPipe],
    bootstrap: [AppComponent]
})

export class AppModule {
}
