import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'lost-password',
    templateUrl: './lost-password.component.html',
    styleUrls: ['./lost-password.component.css']
})
export class LostPasswordComponent implements OnInit {
    errorEmail;
    message;

    formData = {
        email: ''
    };

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
    }

    processLostPass() {
        this.errorEmail = null;
        this.message = null;

        return this.userService.lostPass(this.formData).subscribe(response => {
            if (response.success === false) {
                // if not successful, bind errors to error variables
                this.errorEmail = response.errors.email;
            }
            else {
                // if successful, bind success message to message
                this.message = response.message;
                this.errorEmail = '';
            }
        });
    }

}
