<div class="container m-t-20">
  <h1>Training Modules</h1>

  Words something? Link to Videos?


  <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
    <div (click)="navigate(type)" *ngFor="let type of types" class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
      <div [class]="getClass(type)" style="height:100%;">
        <img [src]="getImage(type)" width="100%"/>
        <div class="p-10 text-center">
          <h5>
            {{type.visibleName || type.name}}
          </h5>
          {{type.description}}
        </div>
      </div>
    </div>
  </div>


</div>
<app-my-footer></app-my-footer>
