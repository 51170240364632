import {Component, OnInit} from '@angular/core';

import {UserService} from '../../services/user.service';

@Component({
    templateUrl: './landing-desktop.component.html'
})
export class LandingDesktopComponent implements OnInit {
    alerts: Array<any> = [];
    newUser = {
        name: '',
        email: '',
        password: '',
        therapist: {
            ashaNumber: '',
            placeOfEmployment: '',
            facilityType: ''
        }
    };
    registerInProgress;

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
    }

    register(form?): any {
        this.alerts = [];

        return this.userService.registerTherapist(this.newUser).subscribe(() => {
            },
            () => {
                this.registerInProgress = false;

                const msg = {
                    type: 'danger',
                    message: 'This email address has already been registered. Please sign in instead.'
                };
                this.alerts.push(msg);
            });
    };

    closeAlert(index): any {
        this.alerts.splice(index, 1);
    }
}
