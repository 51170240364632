import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

import {UserService} from './services/user.service';
import {StimuliService} from './services/stimuli.service';

import {settings} from './settings';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
    settings = settings;
    zoom = 1;


    constructor(@Inject(DOCUMENT) private doc,
                @Inject(PLATFORM_ID) private platformId: any,
                private titleService: Title,
                private userService: UserService,
                private stimuliService: StimuliService,
                private router: Router) {

        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                settings.isCollapsed = true;

/*
                if (isPlatformBrowser(this.platformId)) {
                    (window as any).ga('set', 'page', event.urlAfterRedirects);
                    (window as any).ga('send', 'pageview');
                }
*/
                this.createLinkForCanonicalURL();
            }
        });
    }

    ngOnInit(): void {
        this.userService.getUserInfo().subscribe();
        this.stimuliService.getTypes().subscribe();
    }

    createLinkForCanonicalURL(): void {
        const link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(link);
        link.setAttribute('href', 'https://myaphasiacoach.com' + this.router.url);
    }

}
