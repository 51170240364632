<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 center p-t-10 bg-teal">
        <h1>What is apraxia?</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 p-15">
        There are different types of apraxia, but all forms have to do with movement. In general, there are problems with voluntary, but not automatic movements. So in the case of oral-verbal apraxia, automatic speech comes out much easier than
        purposeful speech. For example, when you are driving, the person with aphasia may say "look out!" very clearly and easily, but strains when they need to say something during conversation.
        <br/><br/>
        When you have damaged a certain part of your brain, you may get apraxia. There is a part of your brain that helps you plan to do something, a part that holds the motor sequencing to do it (pre-motor cortex), and a part that then tells your
        muscles to carry out that command (motor cortex). Damage to the pre-motor cortex results in problems "remembering" how to make your muscles do what you want them to do, such as say a word.
        <br/><br/>
        Oral apraxia —in oral apraxia, your mouth will not move the way your brain is telling it to move. If I tell you to blow me a kiss, your brain may be trying to make you blow a kiss by puckering your lips. However, your lips may be doing
        something else, such as pulling back to smile. You may or may not be able to feel that you are making the incorrect movement. If I give you a mirror to see what you are actually doing, you may be surprised to see that your mouth is "groping".
        This means that it is making different movements, trying to do what your brain is telling it to do. The connections from your brain to your mouth have been changed. You may start with a smile, then open your jaw, then finally get your lips to
        pucker. Now, if I didn't tell you to do it, but your spouse makes a kiss-face to you, you may make one in return and be able to do it correctly. This is because your brain is doing it automatically; it doesn't have to try to remember how to
        do it.
        <br/><br/>
        Verbal apraxia —same problems as in oral apraxia, but it affects your speech. I may ask you to say "we", and you say "way" or "zoo", or "ah". Your brain is telling you say something, but the connections to make you speak it are changed.
        There are several factors that go in to speaking a word–you need to use your lips, tongue, jaw, teeth, and vocal cords. These must all be coordinated together to produce a sound. For example, to say "ahh", you must take a breath, turn your
        voice on, drop your jaw open, and lay your tongue flat or pull it back in your mouth. You may have more than one type of apraxia at a time, depending on how large an area of your brain was affected by the stroke.
        <br/><br/>

      </div>
    </div>


  </div>
</div>
<app-my-footer></app-my-footer>
