<div class="container-fluid bg-white">
  <div class="container p-t-20">
  <div class="row">
    <div *ngIf="user" class="col-sm-12 p-15">

      <app-trial ng-show="settings.loggedIn === true"></app-trial>

      <div [hidden]="myVideos" class="row" hidden="true">
        <div class="col-sm-12 center"><h3 class="m-5">Please wait...</h3>
        </div>
      </div>

      <div *ngIf="myVideos" class="row">
        <div class="col-sm-12">
          <div (click)="allVideos()" class="m-l-15 pointer btn btn-primary float-right">
            <span class="material-icons middle">movie</span>
            <span class="m-l-15">All videos</span>
          </div>

          <h2>My Videos</h2>
          <hr class="hr-2"/>

          <div *ngFor="let level of myVideos | objectToArray">
            <h1 class="m-t-15 m-b-0">Level {{level.key}}</h1>
            <div *ngFor="let letter of level.value | objectToArray" class="">
              <h4 class="m-t-10 m-b-5">{{letter.key}}</h4>

              <div *ngFor="let video of letter.value | objectToArray" class="p-b-5">
                <span (click)="removeVideo(video.value._id);" class="">
                    <span class="material-icons text-dark-blue pointer middle">star</span>
                  </span>
                <a (click)="open(video.value)" class="pointer p-l-10">{{video.value.name}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
</div>
