<div class="b-2 m-15" style="width:400px;">
  <div class="modal-body center fg-blue" id="modal-body">

    <div class="h1 fg-green">Great Work<span *ngIf="user" [textContent]="' ' + user"></span>!</div>

    <div *ngIf="level">
      <h3 class="fg-blue">You've Reached Level {{level}}</h3>
    </div>

    <img src="/images/promotion.png" width="50%"/><br/>

  </div>
</div>
