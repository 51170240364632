import {debounceTime} from 'rxjs/operators';
import {settings} from '../../settings';
import {User} from '../../models/user';
import {UserService} from '../../services/user.service';
import {VideoService} from '../../services/video.service';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';


import * as _ from 'lodash';


import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-videos',
    templateUrl: './videos.component.html',
    styleUrls: ['./videos.component.css'],
})

export class VideosComponent implements OnInit, OnDestroy {
    settings;
    userInfo;
    levels = [];
    letters = [];
    levelsChecked = [];
    lettersChecked = [];

    user: User | null = null;
    videos;
    myVideos;
    videosFiltered;
    videosUnfiltered;
    selectedLevel;

    show = true;

    filterInput = new UntypedFormControl();
    filterType = new UntypedFormControl();

    payload = {name: ''};
    search = {name: '', searchType: 'startsWith'};

    hide = {};

    private oUser;
    private oVideosUnfiltered;
    private oVideosFiltered;
    private oMyVideos;

    constructor(private userService: UserService,
                private videoService: VideoService,
                private modalService: ModalService,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private router: Router) {
        this.settings = settings;
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): void {
        this.selectedLevel = this.location.path(true).split('/')[2];

        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                this.updateBookmarked();
            }
        });

        this.oVideosUnfiltered = this.videoService.getVideos('Apraxia', this.selectedLevel).subscribe(result => {
            this.videosUnfiltered = result;
            result = _.sortBy(result, (video) => {
                return _.upperFirst(video.name);
            });

            this.videos = _.groupBy(result, (video) => {
                return video.level;
            });

            _.forEach((this.videos as any), (level, iter) => {
                (this.videos as any)[iter] = _.groupBy(level, (video) => {
                    const x = _.upperFirst((video as any).name);
                    return x.substr(0, 1);
                });
            });
        });

        this.filterInput
            .valueChanges.pipe(
            debounceTime(500))
            .subscribe(term => {
                this.search.name = term;
                this.levels = [];
                this.letters = [];
                this.levelsChecked = [];
                this.lettersChecked = [];
                return this.filterVideos();
            });

        this.filterType.valueChanges.subscribe(term => {
            this.search.searchType = term;
            return this.filterVideos();
        });

    }

    ngOnDestroy(): void {
        if (this.oUser) {
            this.oUser.unsubscribe();
        }
        if (this.oVideosUnfiltered) {
            this.oVideosUnfiltered.unsubscribe();
        }
        if (this.oMyVideos) {
            this.oMyVideos.unsubscribe();
        }
    }

    resetSearch(): void {
        this.filterInput.setValue('');
    }

    open(videoUrl, video): Promise<any> {
        if (!this.userService.userInfo.basicSubscriber) {
            return this.router.navigate(['/account']);
        }
        else {
             return this.modalService.video(videoUrl).then(() => {
                 return false;
             }, () => {
                 const el: HTMLElement | null = document.getElementById(video.value._id);
                 if (el && el.scrollIntoView) {
                     return setTimeout(() => el.scrollIntoView({block: 'center'}), 0);
                 }
                 return false;
             });
        }
    }

    noVideos(): boolean {
        if (_.has(this, 'user.videos') && this.user && (this.user as any).videos.length === 0) {
            return true;
        }
        else {
            return false;
        }
    }

    bookmarked(id): boolean {
        if (this.user && this.user.videos && this.user.videos.indexOf(id) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    addVideo(id): Subscription {
        (this.user as any).videos.push(id);
        this.levels = [];
        this.letters = [];
        this.levelsChecked = [];
        this.lettersChecked = [];
        this.updateBookmarked();
        return this.videoService.updateVideos({videos: (this.user as any).videos}).subscribe(() => {
        });
    }

    removeVideo(id): Subscription {
        _.pull((this.user as any).videos, id);
        this.levels = [];
        this.letters = [];
        this.levelsChecked = [];
        this.lettersChecked = [];
        this.updateBookmarked();

        return this.videoService.updateVideos({videos: (this.user as any).videos}).subscribe();
    }

    filterVideos(): Subscription | void {
        this.payload.name = this.search.name;

        if (this.search.searchType === 'startsWith') {
            this.payload.name = '^' + this.search.name;
        }

        if (this.payload.name.length > 2) {
            this.oVideosUnfiltered = this.videoService.searchVideos(this.payload).subscribe((res) => {
                _.forEach(res, (video: any) => {
                    video.name = _.upperFirst((video as any).name);
                });

                this.videosFiltered = _.sortBy(res, ['name', 'level']);
            });
        }
    }

    isPremiumUser(): boolean {
        return this.userService.userInfo.premiumSubscriber;
    }

    isBasicUser(): boolean {
        return this.userService.userInfo.basicSubscriber;
    }

    updateBookmarked(): void {
        this.oMyVideos = this.videoService.getVideosById((this.user as any).videos).subscribe((result2) => {
            this.myVideos = _.groupBy(result2, (video) => {
                return video.level;
            });

            _.forEach((this.myVideos as any), (level, iter) => {
                (this.myVideos as any)[iter] = _.groupBy(level, (video) => {
                    const x = _.upperFirst((video as any).name);
                    return x.substr(0, 1);
                });
            });

            _.forEach((this.myVideos as any), (level, iter) => {
                const ordered = {};
                Object.keys(level).sort().forEach(key => {
                    ordered[key] = level[key];
                });
                (this.myVideos as any)[iter] = ordered;
            });

            this.show = false;
            this.cdr.detectChanges();
            this.show = true;
            this.cdr.detectChanges();
        });
    }

    clickLetter(ix){
        this.hide = {};
        this.hide[ix] = true;
    }
}
