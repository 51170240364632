import * as _ from 'lodash';

import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';

import {AnswerService} from '../../services/answer.service';
import {AudioService} from '../../services/audio.service';
import {BuilderService} from '../../services/builder.service';
import {ModalService} from '../../services/modal.service';
import {ScreenService} from '../../services/screen.service';
import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

import {settings} from '../../settings';
import {takeUntil} from 'rxjs/operators';
import {config} from '../../config';

@Component({
    selector: 'app-sst-writing2',
    templateUrl: './sst-writing2.component.html'
})

export class SstWriting2Component implements OnInit, OnDestroy, AfterViewInit {
    settings = settings;
    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;
    show = true;
    alternatives;
    message;
    moveOn = false;
    input: any = {};
    highlight: any = {};
    answer: Array<any> = [];

    onStep = 0;
    answered = 0;
    stimuliImage: SafeResourceUrl = '';

    @ViewChild('box') box: ElementRef | any;
    @ViewChild('box2') box2: ElementRef | any;

    wrongCount: BehaviorSubject<any> = new BehaviorSubject(0);
    wordsPlaced: BehaviorSubject<any> = new BehaviorSubject(0);

    wrongAnswerCount = 0;
    lastWrong;
    wrongAnswers = '';

    private subscriptions = new Subscription();

    constructor(private router: Router,
                private cdr: ChangeDetectorRef,
                private userService: UserService,
                private answerService: AnswerService,
                private modalService: ModalService,
                private audioService: AudioService,
                private screenService: ScreenService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
        this.highlight = this.audioService.highlight;

    }

    ngOnInit(): any {
        this.subscriptions.add(this.screenService.bsOnStep.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            this.onStep = result;

            if (this.onStep === 0) {
                return this.builderService.getType().subscribe();
            }
            else if (this.onStep === 1) {
                const name = this.stimuliService.bsType.value.name;
                const stats = _.find(this.userService.bsUser.value?.tasks, {task: name});

                setTimeout(() => {
                if (document.getElementById('input_0')) {

                    // @ts-ignore
                    document.getElementById('input_0').focus();
                }
                }, 300);

                if (stats) {
                    return this.audioService.buildAudio();
                }
                else {
                    return of(this.audioService.playSstIntro(1));
                }
            }
            else if (this.onStep === 2) {
                const type = JSON.parse(JSON.stringify(this.stimuliService.bsType.value));
                type.buildType = 'whichWordIs';
                this.stimuliService.bsType.next(type);

                return setTimeout(() => {
                    return this.builderService.findPostSound(null, true).subscribe();
                }, 1000);
            }

            return false;

        }));

        this.subscriptions.add(this.userService.bsUser.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                if (this.userService.userInfo.loggedIn) {
                    if (!this.userService.userInfo.premiumSubscriber && this.userService.userInfo.basicSubscriber) {
                        const route = ['/modules-videos'];
                        return this.router.navigate(route);
                    }
                    else if (!this.userService.userInfo.basicSubscriber) {
                        const route = ['/payment'];
                        return this.router.navigate(route);
                    }
                }
            }
            return;
        }));

        this.subscriptions.add(this.stimuliService.type.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsStimuli.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {

                this.input = {};
                this.answer = [];
                this.answered  = 0;


                this.stimuliImage = this.screenService.getStimuliImage();
                this.show = false;
                this.cdr.detectChanges();

                const tempResult = _.forEach(result.postSounds, sound => {
                    sound.scrambled = _.toArray(sound.word);
                    sound.scrambled = _.shuffle(sound.scrambled);
                    sound.scrambled = _.shuffle(sound.scrambled);
                    sound.scrambled = _.shuffle(sound.scrambled);
                });

                result.postSounds = tempResult;
                this.stimuli = result;
                this.show = true;
                this.cdr.detectChanges();
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        }));
    }

    ngOnDestroy(): void {
        this.screenService.bsOnStep.next(0);
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): any {
        return setTimeout(() => {
            if (this.box?.nativeElement?.offsetHeight > 100) {
                this.box.nativeElement.style.textAlign = 'left';
                this.box2.nativeElement.style.textAlign = 'left';
            }
        }, 500);
    }

    checkScrambling(): any {
        let answer = '';
        const answer2: Array<any> = [];

        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                answer = answer + (child as any).innerText + ' ';
                answer2.push((child as any).innerText);
            });
        });

        answer = answer.trim();

        return of(this.audioService.cleanupAudioObjects().then(() => {
            let correct = false;

            if (answer === this.stimuliService.bsStimuli.value.levels[0].word) {
                correct = true;
            }

            const payload = {
                correct,
                correctDoesNotCount: this.answerService.correctDoesNotCount,
                type: this.stimuliService.bsType.value.name,
                level: this.builderService.onLevel,
                question: this.stimuliService.bsStimuli.value._id,
            };

            this.stimuliService.updateStats(payload).subscribe();

            if (correct) {
                return this.modalService.therapyResponse(true, 1).subscribe(() => {
                    let temp = this.screenService.bsOnStep.value;
                    temp = temp + 1;
                    this.screenService.bsOnStep.next(temp);
                    this.wrongCount.next(null);
                });
            }
            else if (this.wrongCount.value === 3) {
                return this.modalService.therapyResponse(false, 1).subscribe(() => {
                    this.checkScrambling2(answer2);
                    this.wrongCount.next(null);
                });
            }
            else {
                this.wrongCount.next(this.wrongCount.value + 1);
                return this.modalService.therapyResponse(false, 1).subscribe(() => {
                    return this.checkScrambling2(answer2);
                });
            }
        }));
    }

    checkScrambling2(answerArray): void {
        if (this.wrongCount.value > 0) {
            this.show = false;
            this.cdr.detectChanges();
            this.show = true;
            this.cdr.detectChanges();

            const correctAnswer = this.stimuli.levels[0].word.split(' ');

            _.forEach(correctAnswer, (correct, ix: number) => {
                if (answerArray[ix] === correct || this.wrongCount.value >= 3) {
                    _.forEach(this.alternatives, (alt, iter2) => {
                        if (correct === alt) {
                            // @ts-ignore
                            document.getElementById('land' + ix).appendChild(document.getElementById(iter2 + '' + alt));
                            // document.getElementById('land' + ix)
                        }
                    });
                }
            });

        }
    }

    highlight2(ix): string {
        if (ix === 0 && this.highlight['highlight-drop']) {
            return 'highlight-drop';
        }
        else {
            return '';
        }
    }

    checkWriting(): any {
        let correct = true;
        _.forEach(this.stimuli.postSounds, (sound, ix) => {
            // tslint:disable-next-line:triple-equals
            if (sound.word.toLowerCase() != this.input[ix].toLowerCase()) {
                correct = false;
            }
        });


        if (correct) {
            return this.modalService.therapyResponse(true, 1).subscribe(() => {
                this.screenService.bsOnStep.next(2);
                this.moveOn = false;
            });
        }
        else {
            return this.modalService.therapyResponse(false, 1).subscribe(() => {
                if (this.moveOn === true) {
                    this.screenService.bsOnStep.next(2);
                }
                else {
                    this.input = {};
                    this.moveOn = true;
                    setTimeout(() =>{
                    if (document.getElementById('input_0')) {
                        // @ts-ignore
                        document.getElementById('input_0').focus();
                    }
                    }, 300);
                }
            });
        }
    }

    alignment(): string {
        this.cdr.detectChanges();
        if (this.box) {
            return 'left';
        }
        else {
            return 'center';
        }
    }

    dragDropReplacement(id): Observable<any> {
        this.audioService.pauseSound();

        const selected = document.getElementsByClassName('scrambling-selected');
        const clicked: any = document.getElementById(id);
        let setClass = true;

        if (clicked.children.length > 0 && clicked.children[0] === selected[0]) {
            if ((clicked.children[0] as any).classList) {
                clicked.children[0].classList.remove('scrambling-selected');
            }
            setClass = false;
        }

        if (selected.length > 0 && (clicked as any).children.length === 0) {
            clicked.appendChild(selected[0]);
            setClass = false;
        }

        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        _.forEach(document.getElementsByClassName('scrambling-start'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        // Highlight selected
        if (setClass && (clicked.children[0] !== selected[0]) && this.wrongCount.value < 3) {
            // @ts-ignore
            const elem = document.getElementById(id).children;
            let word;

            _.forEach(elem, (child) => {
                (child as any).classList.add('scrambling-selected');
                word = (child as any).innerText;
            });

            if (word) {
                return this.audioService.buildDragAndDropAudio(word);
            }
        }
        return of(false);
    }

    drop(el): void {
        this.audioService.pauseSound();
        
        const id = el.dataTransfer.getData('Text');
        if (!el.target.classList.contains('scrambling-word')) {
            if (document.getElementById(id)) {
                el.target.appendChild(document.getElementById(id));

                const elem = document.getElementById(id);
                let word = elem?.innerText;

                if (word) {
                    this.audioService.buildDragAndDropAudio(word);
                }
            }
        }
        el.stopPropagation();
        // @ts-ignore
        event.preventDefault();
    }

    dragStart(el): boolean {
        if (el && el.target) {
            el.dataTransfer.effectAllowed = 'move';
            el.dataTransfer.setData('Text', el.target.getAttribute('id'));
            el.dataTransfer.setDragImage(el.target, 30, 20);
        }
        return true;
    }

    dragEnter(event): boolean {
        event.preventDefault();
        return true;
    }

    dragOver(event): boolean {
        event.preventDefault();
        return true;
    }

    /*    highlight(ix): boolean {
            // tslint:disable-next-line:triple-equals
            if (ix == this.audioService.soundCounter) {
                return true;
            }
            return false;
        }*/

    wwIs(word: any): any {
        this.answered++;
        const temp = this.audioService.audioObjects[1];
        if (word === temp.word) {
            return this.modalService.therapyResponse(true, 1).subscribe(() => {
                this.alternatives = [];
                this.screenService.bsOnStep.next(0);
            });
        }
        else {
            return this.modalService.therapyResponse(false, 1).subscribe(() => {
                if (this.answered === 2) {
                    this.alternatives = [];
                    this.screenService.bsOnStep.next(0);
                }
                else {
                    this.audioService.playAudioObjects(0).then();
                }
            });
        }
    }

    nextField(i): void {
        if (this.input[i] && this.stimuli.postSounds[i].word.length === this.input[i].length) {
            const newField = i + 1;

            if (document.getElementById('input_' + newField)) {
                // @ts-ignore
                document.getElementById('input_' + newField).focus();
            }
        }
    }

}
