<app-error-message></app-error-message>
<div *ngIf="stimuli && alternatives && type && typeSettings" class="center">
  <h1 class="click m-b-30">
    Which word is
  </h1>

  <div *ngIf="(typeSettings.mainImage && stimuli.image)">
    <img [ngClass]="{'fs-stimuli-image': settings.fullScreen, 'stimuli-image': !settings.fullScreen}" [src]="getStimuliImage()"/>
  </div>
  <br *ngIf="typeSettings.mainImage && stimuli.image" clear="all">

  <div *ngFor="let answer of alternatives" class="inline">
    <div *ngIf="typeSettings.buttons && answer.word" class="outer">
      <button (click)="checkAnswer(answer.word)" class="inner btn btn-primary" id="{{answer.word}}">
        <h2 class="m-0 p-0 text-lowercase">{{answer.word}}</h2>
      </button>
    </div>
  </div>
  <br clear="all"/>
</div>
