<div class="container-fluid bg-white vh-100">
  <div class="container p-t-20">
    <div class="row">
      <div *ngIf="user" class="col-sm-12 p-15">

        <!--
              <app-mobile-warning></app-mobile-warning>
        -->
        <app-trial></app-trial>
        <div *ngIf="isBasicUser()" class="container">

          <div class="h4 m-t-15 m-b-5 text-center">
            Apraxia Videos
          </div>


          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 pointer p-15" routerLink="/videos/1" routerLinkActive="active">
              <div class="p-2 fg-white bg-blue">
                <img src="/images/modules/video-1.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    Apraxia Video 1
                  </h5>
                  1 syllable, 2 sounds<br/><br/>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pointer p-15" routerLink="/videos/2" routerLinkActive="active">
              <div class="p-2 fg-white bg-blue">
                <img src="/images/modules/video-2.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    Apraxia Video 2
                  </h5>
                  1 syllable, 3 sounds<br/><br/>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pointer p-15" routerLink="/videos/3" routerLinkActive="active">
              <div class="p-2 fg-white bg-blue">
                <img src="/images/modules/video-3.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    Apraxia Video 3
                  </h5>
                  1 syllable, blends<br/><br/>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pointer p-15" routerLink="/videos/4" routerLinkActive="active">
              <div class="p-2 fg-white bg-blue">
                <img src="/images/modules/video-4.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    Apraxia Video 4
                  </h5>
                  3 word sentences, 3-5 syllable words<br/>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pointer p-15" routerLink="/videos/5" routerLinkActive="active">
              <div class="p-2 fg-white bg-blue">
                <img src="/images/modules/video-5.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    Apraxia Video 5
                  </h5>
                  3 word sentences, harder sounds<br/>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pointer p-15" routerLink="/videos/6" routerLinkActive="active">
              <div class="p-2 fg-white bg-blue">
                <img src="/images/modules/video-6.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    Apraxia Video 6
                  </h5>
                  4-6 word sentences<br/><br/>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pointer p-15" routerLink="/videos/7" routerLinkActive="active">
              <div class="p-2 fg-white bg-blue">
                <img src="/images/modules/video-7.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    Apraxia Video 7
                  </h5>
                  3+ syllable words, 4-8 word sentences<br/>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pointer p-15" routerLink="/videos/8" routerLinkActive="active">
              <div class="p-2 fg-white bg-blue">
                <img src="/images/modules/video-8.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    Apraxia Video 8
                  </h5>
                  4-6 word sentences, multisyllabic words
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pointer p-15" routerLink="/videos/9" routerLinkActive="active">
              <div class="p-2 fg-white bg-blue">
                <img src="/images/modules/video-9.png" width="100%"/>
                <div class="p-10 text-center">
                  <h5>
                    Apraxia Video 9
                  </h5>
                  Sentences for faster pacing
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-my-footer></app-my-footer>
</div>
