<div class="bg-light-gray">
  <div class="container p-t-30">
    <div class="row">
      <div class="col-sm-12">

        <h1>Speech Therapist Signup</h1>

        Create your FREE Speech Therapist account

        <p *ngFor="let alert of alerts">
          <ngb-alert (close)="closeAlert(alert)" [type]="alert.type">{{ alert.message }}</ngb-alert>
        </p>

        <ngb-alert *ngIf="registerInProgress" [dismissible]="false">
          Registering New User...
        </ngb-alert>

        <div *ngIf="showIEWarning" class="alert alert-danger">
          My Aphasia Coach does not support Internet Explorer. <a [routerLink]="'/upgrade'">How to upgrade</a>.
        </div>

        <form #form="ngForm" (ngSubmit)="register(form)" class="form-horizontal m-t-15">
          <div class="form-group">
            <label>Name</label>
            <input [(ngModel)]="newUser.name" class="form-control" name="name" placeholder="Your name" required type="text">
          </div>
          <div class="form-group">
            <label>Email address</label>
            <input #email="ngModel" [(ngModel)]="newUser.email" [email]="true" class="form-control" name="email" placeholder="Email address" required type="email">
          </div>
          <div class="form-group">
            <label>Password</label>
            <input [(ngModel)]="newUser.password" class="form-control" name="password" placeholder="" required type="password">
          </div>

          <div class="form-group">
            <label>ASHA Number</label>
            <input [(ngModel)]="newUser.therapist.ashaNumber" class="form-control" name="ashaNumber" required type="text">
          </div>

          <div class="form-group">
            <label>Place of Employment</label>
            <input [(ngModel)]="newUser.therapist.placeOfEmployment" class="form-control" name="placeOfEmployment" required type="text">
          </div>

          <div class="form-group">
            <label>Facility Type</label>
            <select class="form-control" name="userType" ng-model="newUser.therapist.facilityType" required>
              <option [ngValue]=null disabled hidden selected></option>
              <option value="Outpatient">Outpatient Clinic</option>
              <option value="Hospital">Hospital</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <button [disabled]="form.invalid" class="btn btn-primary " type="submit">Register</button>
        </form>
        <br/>
        <!--
                <a routerLink="/register" routerLinkActive="active">Register as regular user</a><br/>
        -->
        <a routerLink="/login" routerLinkActive="active">Sign in</a><br/>
        <a routerLink="/lost-password" routerLinkActive="active">Lost password</a><br/>
        <br/>
      </div>
    </div>
  </div>
</div>

<app-my-footer></app-my-footer>
