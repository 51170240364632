import {Component, OnDestroy, OnInit} from '@angular/core';
import {StimuliService} from '../../services/stimuli.service';

@Component({
    selector: 'no-stimuli',
    templateUrl: './no-stimuli.component.html'
})
export class NoStimuliComponent implements OnInit, OnDestroy {
    type;

    constructor(private stimuliService: StimuliService) {
    }

    ngOnInit(): void {
        this.stimuliService.type.subscribe(result => this.type = result);
    }

    ngOnDestroy(): void {
    }
}
