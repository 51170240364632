import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-aphasia',
    templateUrl: './aphasia-primary-progressive.component.html',
})
export class AphasiaPrimaryProgressiveComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
