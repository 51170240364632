<div class="container-fluid bg-white p-t-20">
  <div class="container">
  <div class="row">
    <div *ngIf="user" class="col-sm-12 p-15">

      <app-trial ng-show="settings.loggedIn === true"></app-trial>

      <div class="row">
        <div *ngIf="show" class="col-sm-6">
          <button (click)="copy()" class="btn btn-primary float-right">
            <span  class="material-icons pointer middle ">star</span>
            <span class="m-l-5 middle"> Bookmark All</span>
          </button>

          <h2>My Homework Videos</h2>
          <hr class="hr-2"/>

          <div *ngFor="let video of myVideos">
                  <span (click)="addVideo(video.video);" class="">
                  <span [hidden]="bookmarked(video.video)" class="material-icons text-dark-blue pointer">star</span>
                  </span>
            <span (click)="removeVideo(video.video);" class="">
                    <span [hidden]="!bookmarked(video.video)" class="material-icons text-dark-blue pointer">star</span>
                  </span>
            <a (click)="open(video.url)" class="pointer p-l-10">{{video.name}}</a>

          </div>

          <div *ngIf="!user.homeworkVideos || user.homeworkVideos.length<1">No homework videos assigned</div>
        </div>

        <div class="col-sm-6">
          <h2>My Homework</h2>

          <hr class="hr-2"/>

          <div class="row">

            <div (click)="navigate(type)" *ngFor="let type of homework" class="col-md-12 col-lg-6 col-xl-4 pointer p-10">

              <div [class]="getClass(type)" style="height:100%; display:flex; justify-content:space-between; flex-direction: column">
                <div>
                  <img [src]="getImage(type)" width="100%"/>
                  <div class="p-5 text-center">
                    <h6 class="m-0"> {{type.visibleName || type.name}}</h6>
                    {{type.description}}
                  </div>
                </div>

                <div class="bg-gray p-5 center">

                  <div *ngIf="type.done>=type.todo || moduleCompleted(type)">Completed</div>
                  <div *ngIf="type.done<type.todo && !moduleCompleted(type) ">{{type.todo - type.done}} exercises</div>
                </div>

              </div>
            </div>

            <div *ngIf="!user.homework || user.homework.length<1">No homework assigned</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
</div>
