import * as _ from 'lodash';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ModalService} from '../../services/modal.service';
import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-training-modules',
    templateUrl: './training-modules.component.html',
    styleUrls: ['./training-modules.component.css']
})
export class TrainingModulesComponent implements OnInit, OnDestroy {
    types;
    private oTypes;

    constructor(private router: Router,
                private modalService: ModalService,
                private userService: UserService,
                private stimuliService: StimuliService,) {
    }

    ngOnInit(): void {
        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            let types = _.filter(result, r => {
                if (parseInt(r.menu.level, 10) === 0) {
                    return true;
                }
                else {
                    return false;
                }
            });
            types = _.sortBy(types, ['sortCode']);
            this.types = types;

        });
    }

    ngOnDestroy(): void {
        if (this.oTypes) {
            this.oTypes.unsubscribe();
        }
    }

    open(videoUrl): Promise<any> {
        return this.modalService.tutorial(videoUrl);
    }

    getClass(type): string {
        let returnData = 'p-2 btn-primary ';

        if (type.menu && type.menu.color) {
            returnData = returnData + ' ' + type.menu.color;
        }
        else {
            returnData = returnData + ' fg-default ';
        }
        if (type.menu && type.menu.backgroundColor) {
            returnData = returnData + ' ' + type.menu.backgroundColor;
        }
        else {
            returnData = returnData + ' bg-default ';
        }
        return returnData;
    }

    getImage(type): string {
        if (type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }

    navigate(type): Promise<any> {
        if (!this.userService.userInfo.premiumSubscriber) {
            if (this.userService.userInfo.basicSubscriber) {
                const route = ['/modules-videos'];
                return this.router.navigate(route);
            }
            else {
                const route = ['/account'];
                return this.router.navigate(route);
            }
        }
        else {
            const route = ['/therapy/' + type.templateType + '/' + type.name];
            return this.router.navigate(route);
        }
    }


}


