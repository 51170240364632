import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {catchError, retry, takeUntil, tap, timeout} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

import {MessagingService} from './messaging.service';
import {UserService} from './user.service';

import {config} from '../config';
import {Router} from '@angular/router';

@Injectable()
export class StimuliService {
    unsubscribe: Subject<void> = new Subject<void>();
    bsType = new BehaviorSubject<any>(null);
    bsTypes = new BehaviorSubject<any>(null);
    bsTypeSettings = new BehaviorSubject<any>(null);
    bsAlternatives = new BehaviorSubject<any>(null);
    bsStimuli = new BehaviorSubject<any>(null);
    bsWhichWordIsAnswer = new BehaviorSubject<any>(null);

    stimuli = this.bsStimuli.asObservable();
    type = this.bsType.asObservable();


    constructor(private http: HttpClient,
                private router: Router,
                private messagingService: MessagingService,
                private userService: UserService) {
    }

    getTypes(): Observable<any> {
        return this.http.get(config.server + 'types', {withCredentials: true}).pipe(
            takeUntil(this.unsubscribe),
            retry(3),
            tap((res) => {
                this.bsTypes.next(res);
            }, err => {
                // return this.handleError(err);
            }));
    }

    getType(type): Observable<any> {
        const url = config.server + 'types/' + type;
        return this.http.get(url, {withCredentials: true}).pipe(
            takeUntil(this.unsubscribe),
            retry(3),
            tap((res) => {
                this.bsType.next(res);
            }, err => {
                return this.userService.handleError(err);
            }));
    }

    getTypesList(): Observable<any> {
        return this.http.get(config.server + 'typesList', {withCredentials: true}).pipe(
            takeUntil(this.unsubscribe),
            retry(3),
            tap(() => {
            }, err => {
                return this.userService.handleError(err);
            }));
    }

    getStimuliSet(type, level): Observable<any> {
        return this.http.get(config.server + 'stimuli-set/' + type + '/' + level, {withCredentials: true}).pipe(
            takeUntil(this.unsubscribe),
            retry(3),
            tap(() => {
            }, err => {
                return this.userService.handleError(err);
            }));
    }

    getStimuli(type, level, stimuliId?): Observable<any> {
        if (stimuliId) {
            return this.http.get(config.server + 'stimuli/' + type + '/' + level + '/' + stimuliId, {withCredentials: true}).pipe(
                takeUntil(this.unsubscribe),
                retry(3),
                tap(res => {
                    this.bsStimuli.next(res)
                }, err => {
                    return this.userService.handleError(err);
                }));
        }
        else {
            const url = config.server + 'stimuli/' + type + '/' + level;
            return this.http.get(url, {withCredentials: true}).pipe(
                takeUntil(this.unsubscribe),
                retry(3),
                tap((res) => {
                    this.bsStimuli.next(res);
                }, (err) => {
                    if (parseInt(err.status, 10) === 404) {
                        return this.router.navigate(['/module-completed']);
                    }
                    else {
                        return false;
                    }
                }));
        }
    }

    checkAnswer(payload): Observable<any> {
        return this.http.post(config.server + 'check-answer', payload, {withCredentials: true}).pipe(
            takeUntil(this.unsubscribe),
            retry(3),
            tap(() => {
            }, err => {
                return this.userService.handleError(err);
            }));
    }

    updateStats(payload): Observable<any> {
        const url = config.server + 'update-stats';
        return this.http.post(url, payload, {withCredentials: true}).pipe(
            takeUntil(this.unsubscribe),
            timeout(10000),
            retry(3),
            catchError(this.userService.handleError));
    }

    handleError(error: HttpErrorResponse): Observable<any> | Promise<any> {
        if (error.error && error.error.errors) {
            return this.router.navigate(['/payment']);
        }
        else {
            return throwError(error);
        }
    };

}
