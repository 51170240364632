<div class="container" id="lp-desktop" style="font-size: 1.3rem">
  <a routerLink="/" routerLinkActive="active">
    <img class="p-30" src="/images/logo.svg" width="250"/><br/>
  </a>

  <div style="background-image: linear-gradient(#00848b, #60e4eb); height:10px;">
  </div>
  <div class="" id="lp-d1">
    <div class="container-fluid" id="fp-2">
      <div class="" id="fp-3">
        <h2 class="p-b-15" id="fp-4">Aphasia Therapy Software</h2>
        <h3 class="p-b-15">Free for Speech Pathologists</h3>
        Create your account for free today
      </div>
    </div>
  </div>
  <div style="background-color:#60e4eb; height:5px;"></div>

  <div style="display: flex; flex-direction:row; flex-wrap: wrap; justify-content: flex-start; align-items:center; margin-top:40px;">
    <div class="inline-block">
      <img src="/images/callout-desktop-1.png" style="width:200px;"><br/>
    </div>
    <div style="width:400px; padding-left: 20px; flex-grow: 1; flex-shrink: 1;">
      Wish you had more therapeutic time with your patients?<br/>
      <br/>
      <b>My Aphasia Coach™</b> software targets foundational
      language skills so you can maximize your skilled
      treatment time.<br/>
    </div>
  </div>

  <div class="center h4 m-t-30">
    Join thousands of aphasia therapists for FREE!<br/>
    <br/>
    Sign up for your FREE account today!<br/>
    <br/>
  </div>
</div>
<div class="container">
  <p *ngFor="let alert of alerts">
    <ngb-alert (close)="closeAlert(alert)" [type]="alert.type">{{ alert.message }}</ngb-alert>
  </p>

  <ngb-alert *ngIf="registerInProgress" [dismissible]="false">
    Registering New User...
  </ngb-alert>


  <form #form="ngForm" (ngSubmit)="register(form)" class="form-horizontal m-t-15">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Name</label>
          <input [(ngModel)]="newUser.name" class="form-control" name="name" placeholder="Your name" required type="text">
        </div>
        <div class="form-group">
          <label>Email address</label>
          <input #email="ngModel" [(ngModel)]="newUser.email" [email]="true" class="form-control" name="email" placeholder="Email address" required type="email">
        </div>
        <div class="form-group">
          <label>Password</label>
          <input [(ngModel)]="newUser.password" class="form-control" name="password" placeholder="" required type="password">
        </div>

      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>ASHA Number</label>
          <input [(ngModel)]="newUser.therapist.ashaNumber" class="form-control" name="ashaNumber" required type="text">
        </div>

        <div class="form-group">
          <label>Place of Employment</label>
          <input [(ngModel)]="newUser.therapist.placeOfEmployment" class="form-control" name="placeOfEmployment" required type="text">
        </div>

        <div class="form-group">
          <label>Facility Type</label>
          <select class="form-control" name="userType" ng-model="newUser.therapist.facilityType" required>
            <option [ngValue]=null disabled hidden selected></option>
            <option value="Outpatient">Outpatient Clinic</option>
            <option value="Hospital">Hospital</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
    </div>

    <div class="center">
      <button [disabled]="form.invalid" class="btn btn-primary" type="submit">Register</button>
    </div>

  </form>


</div>

<app-my-footer></app-my-footer>
