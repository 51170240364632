<div class="container-fluid bg-white" id="fp-1">

  <a href="/"><img class="p-t-15 p-l-15" src="/images/logo2.png" width="300"/></a>

  <div class="container" id="fp-2">
    <div class="" id="fp-3">
      <br/>
      <br/>
      <h1 id="fp-4">Software for Faster Aphasia Progress</h1>
      <br/>
      <h3 class="">Expert therapy you can do from home for stroke and brain injury
        rehabilitation</h3>
      <br/>
      <br/>

      <div class="inline-block">
      <a [hidden]="user" [routerLink]="'/register'" class="btn trial p-30 h3">
        Get started for free
      </a>

      <a [hidden]="!user" [routerLink]="'/modules'" class="btn trial p-30 h3">
        Get started for free
      </a>
      <br/><div class="text-small center">* No Credit Card Required</div>
    </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-teal">
  <div class="container p-30 center">
    <h2>Does Aphasia Software Really Work?</h2>
  </div>
</div>

<div class="container-fluid bg-light-gray">
  <div class="container">
    <div class="center">
      <svg class="p-b-30" style="width:100px" viewBox="0 0 42 21">
        <polygon class="triangle" points="0,0 42,0 21,21"/>
      </svg>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-6 p-30">
        <img src="/images/idea.png" width="100%"><br/>
      </div>
      <div class="col-sm-6">
        <h2>Aphasia CAN and DOES improve.</h2>

        <h5>There is no time limit for progress.</h5>

        Some doctors or therapists will tell you that you only have 6 months after your stroke or brain injury to get better. But that’s not true.
        <br/><br/>
        <h5>There is no plateau.</h5>

        How do I know this? I’m Dr. Bartels, and I’ve helped families with aphasia full-time since I founded The Aphasia Center Intensive Treatment Programs 15 years ago. I’ve worked with every type of aphasia, every severity of aphasia, people from 14 to 92 years old, and people who’ve had aphasia for 7 days up to 20 years since their brain injury or stroke.
        <br/><br/>
        If you’re wondering if software can help you get more practice, it can. It’s an easy way to get more therapy from an expert while working at home. Numerous studies and my professional experience have shown that frequent use of aphasia software helps you make progress faster than those who do not use software for practice.
        <br/><br/>

        <div class="center">
          <a [hidden]="user" [routerLink]="'/register'" class="btn trial p-15 h3">
            Get started for free
          </a>

          <a [hidden]="!user" [routerLink]="'/modules'" class="btn trial p-15 h3">
            Get started for free
          </a>
          <br/>
          <div class="text-small center">* No Credit Card Required</div>
          <br/><br/>
        </div>
      </div>

    </div>
  </div>

</div>

<div class="container-fluid bg-white p-0" id="howitworks">
  <div class="container-fluid bg-teal">
    <div class="container p-30 center">
      <h2>How It Works</h2>
    </div>
  </div>

  <div class="container-fluid p-b-30">
    <div class="container">
      <div class="center">
        <svg class="p-b-30" style="width:100px" viewBox="0 0 42 21">
          <polygon class="triangle" points="0,0 42,0 21,21"/>
        </svg>
      </div>

      <div style="padding:56.25% 0 0 0;position:relative;">
        <iframe src="https://player.vimeo.com/video/794645111?h=7714f17b2e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="My Aphasia Coach Intro.mp4"></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  </div>

  <div class="container-fluid bg-light-gray m-t-60 p-b-60">
    <div class="container">
      <div class="row p-t-60 p-b-60">
        <div class="col-sm-6">
          <img src="/images/register-icon.png" width="40"/><br/>
          <h5 class="text-teal">Register</h5>
          <h4>Sign up for free</h4>
          Create an account. Choose between a learner with aphasia, <br/>or a Speech-Language pathologist account.
        </div>
        <div class="col-sm-6 center">
          <img src="/images/register.png" width="80%"/><br/>
        </div>
      </div>


      <div class="row p-t-60 p-b-60">
        <div class="col-sm-6 center">
          <img src="/images/get-started-1.png" width="80%"/>
          <!--
                    <img src="/images/get-started-2.png" width="25%"/>
                    <img src="/images/indenpendently.png" width="25%"/><br/>
          -->
        </div>
        <div class="col-sm-6">
          <img src="/images/get-started-icon.png" width="40"/><br/>
          <h5 class="text-teal">Get Started</h5>
          <h4>3 Ways to Begin Learning</h4>
          Take a placement quiz, follow recommendations by your type of aphasia, or explore on your own.
        </div>
      </div>


      <div class="row p-t-60 p-b-60">
        <div class="col-sm-6">
          <img src="/images/connect-icon.png" width="40"/><br/>
          <h5 class="text-teal">Connect</h5>
          <h4>Connect with your speech therapist</h4>
          Add your Speech Therapist to receive home and track progress in Homework.
        </div>
        <div class="col-sm-6 center">
          <img src="/images/connect-3.png" width="80%"/>
          <!--<img src="/images/connect-2.png" width="40%"/><br/>-->
        </div>
      </div>

      <h2 class="text-teal p-t-30">OR</h2>

      <div class="row p-t-60 p-b-60">
        <div class="col-sm-6">
          <img src="/images/independently-icon.png" width="40"/><br/>
          <h5 class="text-teal">Work Independently</h5>
          <h4>Explore and find your work in My Tasks</h4>
          Find your saved videos and recent exercises in one easy place on the home screen.
        </div>
        <div class="col-sm-6 center">
          <img src="/images/get-started-3.png" width="75%"/><br/>

        </div>
      </div>

      <div class="row p-t-60 p-b-60">
        <div class="col-sm-6 center">
          <img src="/images/progress-1.png" width="40%"/>
          <img src="/images/progress-2.png" width="20%"/>
        </div>
        <div class="col-sm-6">
          <img src="/images/progress-icon.png" width="40"/><br/>
          <h5 class="text-teal">Progress</h5>
          <h4>Keep up the momentum</h4>
          Take a placement quiz, follow recommendations by your type of aphasia, or explore on your own.
        </div>
      </div>

    </div>
  </div>


  <div class="container-fluid bg-teal">
    <div class="container p-30 center">
      <h2>What Do Speech Therapists Think?</h2>
    </div>
  </div>

  <div class="container-fluid">
    <div class="container">
      <div class="center">
        <svg class="p-b-30" style="width:100px" viewBox="0 0 42 21">
          <polygon class="triangle" points="0,0 42,0 21,21"/>
        </svg>
      </div>
    </div>
  </div>

  <div class="container">
    <!--    <div class="center">
          <div class="h4 inline-block bb-3 p-l-10 p-r-10">Testimonials
          </div>
        </div>-->


    <div class="row">
      <div class="col-sm-3 p-5">
        <div class="h100p p-10 border center flexbox">
          <div class="center">
            <img src="/images/quote-circle.png" style="width: 30%"/><br/><br/>
            <b class="text-purple">The best aphasia app</b><br/>
            As a therapist, a lot of my clients ask about apps or other things they can do at home to help their loved one. This is an app I feel confident recommending to all of my clients with Aphasia. It’s user friendly and there are a variety of
            tasks for all levels of Aphasia. Great app!<br/><br/>
          </div>
          <div class="center">
            <div class="text-purple">BeachTherapist</div>
            <img src="/images/5-stars.png" style="width: 80%"/><br/>
          </div>
        </div>
      </div>
      <div class="col-sm-3 p-5">
        <div class="h100p p-10 border flexbox">
          <div class="center">
            <img src="/images/quote-circle.png" style="width: 30%"/><br/><br/>
            <b class="text-purple">Love this app!</b><br/>
            My Aphasia Coach is the perfect app with tasks geared towards everyone with Aphasia! The tasks are challenging enough to keep interest, but not so challenging that they’re frustrating. I would recommend this app to anyone with Aphasia!
            <br/><br/>
          </div>

          <div class="center">
            <div class="text-purple">Karen Wendell</div>
            <img src="/images/5-stars.png" style="width: 80%"/><br/>
          </div>
        </div>
      </div>
      <div class="col-sm-3 p-5">
        <div class="h100p p-10 border center flexbox">
          <div class="center">
            <img src="/images/quote-circle.png" style="width: 30%"/><br/><br/>
            <b class="text-purple">Functional and Motivating!</b><br/>
            My Aphasia Coach has more functional tasks than I’ve seen from other aphasia based apps. The tasks work directly on foundational skills in all areas of speech and language. The app provides enough help for my clients to be successful
            independently, which is so motivating! Highly recommend!<br/><br/>
          </div>
          <div class="center">
            <div class="text-purple">D. Volker</div>
            <img src="/images/5-stars.png" style="width: 80%"/><br/>
          </div>
        </div>
      </div>
      <div class="col-sm-3 p-5">
        <div class="p-10 border center h100p flexbox">
          <div class="">
            <img src="/images/quote-circle.png" style="width: 30%"/><br/><br/>
            <b class="text-purple">The perfect level</b><br/>
            I struggled finding an app that was not too hard and not too easy. My Aphasia Coach finds a perfect level and gives the right amount of help and just the right amount of challenge to keep it interesting.<br/><br/>

          </div>
          <div>
            <div class="text-purple">Martha Prichett</div>
            <img src="/images/5-stars.png" style="width: 80%; float:bottom;"/><br/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="center">
    <h3 class="p-t-30 m-b-30">Get it in the app store</h3>

    <a href="https://itunes.apple.com/us/app/my-aphasia-coach/id1163288893?ls=1&mt=8" rel="noreferrer"
       target="_blank" title="Get My Aphasia Coach in the Apple App Store">
      <img alt="Get MyAphasiaCoach in the Apple App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/appstore.png">
    </a>

    <a href="https://play.google.com/store/apps/details?id=com.myaphasiacoach" rel="noreferrer" target="_blank"
       title="Get My Aphasia Coach in the Google App Store">
      <img alt="Get My Aphasia Coach in the Google App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/googleplay.png">
    </a>
  </div>

  <app-my-footer></app-my-footer>
</div>
