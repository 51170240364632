import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'objectToArray'
})
export class ObjectToArrayPipe implements PipeTransform {
    transform(value?, args?: string[]): any {
        const keys: Array<any> = [];

        for (const key in value) {
            keys.push({key, value: value[key]});
        }
        return keys;
    }
}
