<app-error-message></app-error-message>
<div *ngIf="stimuli && alternatives && type && typeSettings" class="center w-100 h100p p-b-15 p-t-10">

  <h1 *ngIf="typeSettings.question && stimuli.description" class="click m-t-10 m-1-20">
    <span class="p-5" [class.highlight-word]="highlight['highlight-text']">{{stimuli.description}}</span>
  </h1>

  <div *ngIf="stimuli && stimuli.sound && !typeSettings?.question && !typeSettings?.mainImage && showEar">
      <span class="material-icons middle h1" color="#1d3557" [class.highlight-word]="highlight['highlight-ear']">hearing</span>
  </div>

  <div *ngIf="(typeSettings.mainImage && stimuli.image)" [class.highlight-word]="highlight['highlight-image']">
    <img [src]="getStimuliImage()" class="stimuli-image"/>
  </div>

  <div *ngIf="type.levelQuestion && stimuli.levels[0].question" class="bg-light-gray inline-block m-t-15 m-b-30 p-15" [class.highlight-word]="highlight['highlight-question']">
    <h2 *ngIf="(type.levelQuestion && stimuli.levels[0].question)" class="m-t-20 m-b-20">{{stimuli.levels[0].question}}</h2>
  </div>

  <div class="p-l-5 p-r-5">
    <div *ngFor="let answer of alternatives; let index=index; let isOdd=odd; let isEven=even" class="inline">
      <div *ngIf="typeSettings.buttons && answer.word" class="p-5" [class.highlight-word]="highlight['highlight-alternatives']">
        <button (click)="checkAnswer(answer.id)" class="answerbutton btn btn-lg btn-primary" id="{{answer.id}}">
          <div style="white-space: normal">{{answer.word}}</div>
        </button>
      </div>
    </div>

    <div class="center-text" [class.highlight-word]="highlight['highlight-image-alternatives']">
      <div *ngFor="let answer of alternatives; let index=index; let isOdd=odd; let isEven=even" style="width:50%; padding: 5px; " class="inline-block">
        <img (click)="checkAnswer(answer.id)"
             *ngIf="typeSettings.image && answer.image"
             [src]="getAnswerImage(answer.image)"
             [id]="'image_' + answer.id"
             style="width: 100%; aspect-ratio: 4/3"><br/>
      </div>
    </div>

    <app-hint></app-hint>
  </div>

</div>
