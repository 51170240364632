<app-error-message></app-error-message>

<div *ngIf="stimuli && question && type && typeSettings" class="center">

  <div *ngIf="(typeSettings.mainImage && stimuli.image)" class="p-t-15" [class.highlight-word]="highlight['highlight-image']">
    <img [ngClass]="{ 'fs-stimuli-image': settings.fullScreen, 'stimuli-image': !settings.fullScreen}" [src]="getStimuliImage()"/>
  </div>

  <div *ngIf="(typeSettings.question && stimuli.description)" [class.highlight-word]="highlight['highlight-text']">
    <h2 [innerText]="stimuli.description"></h2>
  </div>

  <form name="answerForm clear" [class.highlight-word]="highlight['highlight-input']" *ngIf="question">
    <div *ngFor="let word of question; let i = index" class="inline-block p-10" id="">

      <input (keyup)="highlightCorrect(i)"
             [(ngModel)]="input[i]"
             autocomplete="off"
             autofocus
             class="uppercase writing-input center"
             id="input_{{i}}"
             maxlength="{{word.length}}"
             name="input_{{i}}"
             size="{{word.length +2 }}"
             style="width:initial; display:initial">
      <br/>
      <div *ngFor="let letter of word; let wix = index" class="inline" id="letter_{{i}}_{{wix}}">
        <p class="uppercase scrambling-char">
          {{letter}}
        </p>
      </div>
    </div>

    <app-hint></app-hint>

    <div class="clear">
      <button type="submit" (click)="checkAnswer()" class="btn btn-primary high-z center-block" type="submit"><span class="material-icons middle">done</span> Done</button>
    </div>
  </form>
  <br clear="all"/>
</div>
