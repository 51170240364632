import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StimuliService} from '../../services/stimuli.service';
import {Subscription} from 'rxjs';

@Component({
    templateUrl: './set-complete-modal.component.html',
    styleUrls: ['./set-complete-modal.component.css']
})
export class SetCompleteModalComponent implements OnInit, OnDestroy {
    @Input() correctItems;
    type;
    fillHeight;
    duration;
    private subscriptions = new Subscription();

    constructor(
        private stimuliService: StimuliService,
        public activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.stimuliService.type.subscribe((result) => {
            this.type = result;
            if (this.type) {
                this.fillHeight = (100 - (this.correctItems / this.type.setSize) * 100) + '%';
                this.duration = (this.correctItems / this.type.setSize) + 's';
            }
            else {
                this.fillHeight = '100%';
                this.duration = '1s';
            }

        }));
    }

    ngOnDestroy(): void {
        // this.stimuliService.unsubscribe.next();
        // this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
    }
}
