<div class="container p-t-20 p-b-20">
  <div class="row">
    <div class="col-sm-12 p-15">
      <h1>Homework Notification</h1>

      <p *ngFor="let alert of alerts">
        <ngb-alert (close)="closeAlert(alert)" [type]="alert.type">{{ alert.message }}</ngb-alert>
      </p>

      Do you want to be notified when homework has been assigned on additional email addresses?<br/>
      Type your email address below.&nbsp; Enter one email address at a time.<br/><br/>

      <form class="form-inline">
        <div class="form-group">
          <label class="m-r-10">Your Email</label>
          <input #email="ngModel" [(ngModel)]="payload.email" [email]="true" class="form-control" class="m-r-10" name="email" required type="email">
          <button (click)="add()" [disabled]="email.invalid" class="btn btn-primary float-right m-l-5" type="button"><span class="material-icons middle">email</span> Add</button>
        </div>
      </form>

      <div *ngIf="clientData && clientData.homeworkNotify && clientData.homeworkNotify.length>0" class="m-t-30">
        <h4>Notification addresses</h4>

        <table class="table table-hover m-t-15">
          <tr>
            <th>Email Address</th>
            <th></th>
          </tr>
          <tr *ngFor="let notify of clientData.homeworkNotify">
            <td>
              {{notify.email}}
            </td>
            <td class="p-r-0">
              <button (click)="remove(notify)" class="float-right btn btn-primary btn-sm"><span class="material-icons middle">clear</span>  Remove Email</button>

            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<app-my-footer></app-my-footer>
