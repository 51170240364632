import * as _ from 'lodash';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

@Component({
    templateUrl: './module-completed-modal.component.html'
})
export class ModuleCompletedModalComponent implements OnInit, OnDestroy {
    @Input() correctItems;
    type;
    next;
    name;

    constructor(
        private stimuliService: StimuliService,
        private userService: UserService,
        public activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
        this.type = this.stimuliService.bsType.getValue();
        this.next = _.find(this.stimuliService.bsTypes.getValue(), {name: this.type.promotionModule});

        this.name = this.userService.getName();
    }

    ngOnDestroy(): void {
    }

    worksOn(): string {
        if (this.next?.worksOn?.reading) {
            return 'Reading';
        }
        else if (this.next?.worksOn?.writing) {
            return 'Writing';
        }
        else if (this.next?.worksOn?.comprehension) {
            return 'Listening';
        }
        else {
            return 'Speech';
        }
    }
}
