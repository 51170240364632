<div class="container p-t-20">
  <div class="row">
    <div *ngIf="!user" class="col-sm-12 p-15">
      <h1>Your Transactions</h1>
    </div>
    <div *ngIf="user" class="col-sm-12 p-15">
      <h1>Your Transactions</h1>
      <div *ngIf="userInfo.subscriber.stripePay">
        <b>Product:</b> {{subscriptionType(user.payment.subscriptionType)}}<br/>
        <b>Renews automatically:</b> <span *ngIf="user.payment.autoRenewing">Yes</span> <span
        *ngIf="!user.payment.autoRenewing">No</span><br/>

        <b *ngIf="user.payment.autoRenewing">Renewal Date: </b>
        <b *ngIf="!user.payment.autoRenewing">Subscription end date: </b>
        {{user.payment.subscriptionExpires|date:'medium'}}<br/>

        <b *ngIf="user.payment.delinquent" class="text-red">There is a problem with your card. Please contact
          support.<br/></b>

      </div>

      <div *ngIf="invoices && invoices.stripe" class="alert alert-danger p10 ">{{invoices.stripe}}</div>
      <div *ngIf="invoices && invoices.error && invoices.error.stripe" class="alert alert-danger p10 ">
        {{invoices.error.stripe}}
      </div>

      <div *ngIf="invoices">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Date</th>
            <th>Starting Balance</th>
            <th>Amount Due</th>
            <th>Paid</th>
            <th>Ending Balance</th>
            <th>Tracking ID</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let inv of invoices">
            <td>
              {{inv.date * 1000 | date}}
            </td>
            <td>
              {{inv.starting_balance / 100 | currency}}
            </td>
            <td>{{inv.amount_due / 100 | currency}}
            </td>
            <td>{{inv.total / 100 | currency}}
            </td>
            <td>
              {{inv.ending_balance / 100 | currency}}
            </td>
            <td>{{inv.id}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<app-my-footer></app-my-footer>
