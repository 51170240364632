<app-error-message></app-error-message>
<div *ngIf="stimuli && alternatives && type && typeSettings && show" class="center" id="redrawTarget">
  <h4 class="m-b-10">
    Put the words in the correct order
  </h4>

  <div *ngIf="(typeSettings.mainImage && stimuli.image)" [class.highlight-word]="highlight['highlight-image']">
    <img class="stimuli-image" [src]="getStimuliImage()"/>
  </div>

  <br *ngIf="(typeSettings.mainImage && stimuli.image)" clear="all">


  <div #box id="box">
    <div (click)="dragDropReplacement('land' + index)"
         (dragenter)="dragEnter()"
         (dragover)="dragOver()"
         (drop)="drop($event)"
         *ngFor="let letter of alternatives; let index = index"
         [class.highlight-word]="highlight2(index)"
         class="scrambling-drop"
         draggable="false"
         id="land{{index}}">
    </div>
  </div>
  <hr style="width: 50%; margin: auto;">

  <div #box2>
    <div (click)="dragDropReplacement('start' + index)"
         (dragenter)="dragEnter()"
         (dragover)="dragOver()"
         (drop)="drop($event)"
         *ngFor="let letter of alternatives; let index = index"
         class="scrambling-start"
         draggable="false"
         id="start{{index}}">
      <div *ngIf="index === 0" [class.highlight-word]="highlight['highlight-drag']" (dragstart)="dragStart($event)" [innerHTML]="letter" class="scrambling-word pointer" draggable="true" id="{{index + letter}}"></div>
      <div *ngIf="index !== 0" (dragstart)="dragStart($event)" [innerHTML]="letter" class="scrambling-word pointer" draggable="true" id="{{index + letter}}"></div>
    </div>
  </div>

  <div class="clearfix">
    <br/>
    <button (click)="checkAnswer()" class="btn btn-primary high-z center-block" type="submit"><span class="material-icons middle">done</span> Done</button>
  </div>
  <br/>
</div>
