declare var Vimeo: any;

import {DomSanitizer} from '@angular/platform-browser';
import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './tutorial-modal.component.html'
})

export class TutorialModalComponent implements OnInit {
    @Input() videoUrl;

    innerWidth;

    constructor(public activeModal: NgbActiveModal,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;

        let width;
        if (this.innerWidth < 1024) {
            width = this.innerWidth - 40;
        }
        else {
            width = 1024;
        }

        var vimeoOptions = {
            url: this.videoUrl,
            width: width,
            autoplay: true,
        };

        const vimeoPlayer = new Vimeo.Player('vimeo', vimeoOptions);
    }

}
