<div class="modal-header">
  <h3 class="modal-title" id="modal-title">Homework</h3>
</div>
<div class="modal-body" id="modal-body">
  <h3>Homework Completed</h3>
  You have completed the assigned homework for this module.
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('Close click')" class="btn btn-secondary">Keep Working</button>
  <button (click)="activeModal.dismiss('Close click')" class="btn btn-primary" type="submit"><span class="material-icons middle">arrow_back_ios</span>  Done</button>
</div>
