import {of, Subscription} from 'rxjs';
import * as reSampler from '../../../audio.service';
import * as _ from 'lodash';

import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SafeResourceUrl} from '@angular/platform-browser';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';
import { Diff } from "diff";

import {AnswerService} from '../../services/answer.service';
import {AudioService} from '../../services/audio.service';
import {BuilderService} from '../../services/builder.service';
import {MessagingService} from '../../services/messaging.service';
import {ModalService} from '../../services/modal.service';
import {ScreenService} from '../../services/screen.service';
import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

import {settings} from '../../settings';
import {config} from '../../config';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './speech.component.html'
})

export class SpeechComponent implements OnInit, OnDestroy {
    // Diff = require('diff');
    Diff = Diff;
    highlight;
    processor;
    source;
    ws;
    context;
    recording;
    attempts = 0;
    reConnecting = false;
    message;
    settings;
    config;
    alternatives: Array<any> = [];
    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;
    showIEWarning;
    playingSound = false;
    timer;
    showSpeechAnswer = false;
    showEar = true;

    private subscriptions = new Subscription();

    constructor(private modalService: ModalService,
                private messagingService: MessagingService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private userService: UserService,
                private screenService: ScreenService,
                private audioService: AudioService,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService,
                private zone: NgZone) {
        this.settings = settings;
        this.config = config;
        this.userInfo = this.userService.userInfo;
        this.highlight = this.audioService.highlight;
    }

    ngOnInit(): Subscription {
        if (this.userService.isIE()) {
            this.showIEWarning = true;
        }

        this.subscriptions.add(this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;

                if (this.userService.userInfo.loggedIn) {
                    if (!this.userService.userInfo.premiumSubscriber && this.userService.userInfo.basicSubscriber) {
                        const route = ['/modules-videos'];
                        return this.router.navigate(route);
                    }
                    else if (!this.userService.userInfo.basicSubscriber) {
                        const route = ['/account'];
                        return this.router.navigate(route);
                    }
                }
            }
            return;
        }));

        this.subscriptions.add(this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.stimuli.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.stimuli = result;

                if (this.stimuli.desc) {
                    this.stimuli.words = this.stimuli.description.split(' ');
                }
            }
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
                this.showSpeechAnswer = result.showSpeechAnswer;
            }
        }));

        this.subscriptions.add(this.audioService.bsListen.subscribe(result => {
            this.playingSound = false;
            if (result) {
                return this.start();
            }
            return false;
        }));

        this.subscriptions.add(this.audioService.bsStopListen.subscribe(result => {
            if (result) {
                return this.stop(true);
            }
            return false;
        }));

        this.subscriptions.add(this.audioService.bsShowEar.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            this.showEar = result;
        }));

        return this.builderService.getType().subscribe();
    }

    ngOnDestroy(): Promise<any> {
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
        this.audioService.bsListen.next(false);
        return this.stop(true);
    }

    start(): Promise<any> {
        return this.audioService.startSound().then(() => {
            this.recording = true;

            try {
                this.context = new AudioContext();
                this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');
            } catch (err: any) {
                this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
            }

            this.ws.getDataStream().subscribe((msg: MessageEvent) => {
                return this.receiveMessage(msg);
            });

            return navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
                this.builderService.timer = setTimeout(() => {
                    return this.stop().then();
                }, 8000);


                return this.zone.run(() => {
                    this.source = this.context.createMediaStreamSource(stream);
                    this.processor = this.context.createScriptProcessor(8192, 1, 1);

                    this.source.connect(this.processor);
                    this.processor.connect(this.context.destination);

                    return this.processor.onaudioprocess = (data) => {
                        return this.zone.run(() => {
                            return reSampler(data.inputBuffer, 16000, (event) => {
                                return this.zone.run(() => {
                                    try {
                                        if (this.ws && this.ws.send()) {
                                            return this.ws.send(this.convertFloat32ToInt16(event.getAudioBuffer().getChannelData(0)), undefined, true).subscribe();
                                        }
                                    } catch (err: any) {
                                        console.error(new Error(err));
                                    }
                                });
                            });
                        });
                    };
                });
            }, () => {
                this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
            });
        });
    }

    stop(skip?): Promise<any> {
        this.audioService.bsListen.next(false);
        clearTimeout(this.builderService.timer);

        if (this.ws) {
            this.ws.send('stop');
            this.ws.close();
            this.ws = null;
        }

        if (this.processor) {
            this.processor.disconnect();
            this.processor = null;
        }

        this.source = null;
        this.recording = false;
        this.builderService.timer = null;

        if (!skip) {
            return this.audioService.stopSound();
        }
        else {
            return Promise.resolve(true);
        }
    }

    receiveMessage(msg?): any {
        return this.zone.run(() => {
            let message;
            try {
                message = JSON.parse(msg.data);
            } catch (err: any) {
            }

            if (message) {


                return this.stop().then(() => {
                    let correct = false;

                    if (message && message[0] && message[0].alternatives) {

                        _.forEach(this.stimuli.levels, (answer) => {
                            const result = _.filter(message[0].alternatives, (alternative) => {
                                if (_.toLower(alternative.transcript).includes(_.toLower(answer.word.trim()))) {
                                    return true;
                                }
                                return false;
                            });

                            if (result.length > 0) {
                                correct = true;
                            }
                        });

                        if (!correct) {
                            this.answerService.correctDoesNotCount = true;
                        }
                    }

                    this.attempts = this.attempts + 1;

                    const payload = {
                        correct,
                        correctDoesNotCount: this.answerService.correctDoesNotCount,
                        type: this.stimuliService.bsType.value.name,
                        level: this.builderService.onLevel,
                        question: this.stimuliService.bsStimuli.value._id,
                    };

                    if (!correct) {
                        if (message && message[0] && message[0].alternatives) {
                            const answer = message && message[0] && message[0].alternatives[0].transcript;

                            let span;
                            const diff = this.Diff.diffWords(answer.toLowerCase(), this.stimuli.levels[0].word.trim().toLowerCase());
                            const display = document.getElementById('display');
                            const fragment = document.createDocumentFragment();

                            if (display) {
                                display.innerHTML = '';
                            }

                            /*diff.forEach((part) => {
                                span = document.createElement('span');
                                if (part.removed) {
                                    span.style.color = '#660000';
                                }
                                else if (part.added) {
                                    span.style.display = 'none';
                                }
                                else {
                                    span.style.color = '#000000';
                                }

                                span.appendChild(document.createTextNode(part.value));
                                fragment.appendChild(span);
                            });*/

                            span = document.createElement('span');
                            span.appendChild(document.createTextNode(this.stimuli.levels[0].word));
                            fragment.appendChild(span);
                            if (display) {
                                display.appendChild(fragment);
                            }
                        }

                        // stimuli.levels[0].word|json

                    }

                    return this.stimuliService.updateStats(payload).subscribe(() => {
                        if (correct) {
                            this.attempts = 0;
                            this.stimuli = null;
                            return this.answerService.displayModal(true).subscribe();
                        }
                        else {
                            return this.answerService.displayModal(false, false, true).subscribe(() => {
                                if (this.attempts > 2 ||
                                    (this.attempts > 1 && this.type.name === 'SentenceRepetition4')
                                ) {
                                    this.answerService.correctDoesNotCount = false;
                                    this.attempts = 0;
                                    this.stimuli = null;
                                    return setTimeout(() => {
                                        return this.builderService.getType().subscribe();
                                    }, 1500);
                                }
                                else {
                                    return setTimeout(() => {
                                        if (this.type.buildType === 'repeatSentence' && this.stimuli.preSounds && this.stimuli.preSounds.length > 0) {
                                            return this.audioService.playPreSound();
                                        }
                                        else {
                                            return this.audioService.buildAudio().subscribe();
                                        }
                                    }, 1500);
                                }
                            });
                        }
                    });
                });
            }
            else {
                console.log('no msg');
            }
            return of(false);
        });
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }

    playAlternative(index): Promise<any> {
        return this.audioService.playAlternativeSound(index);
    }

    convertFloat32ToInt16(buffer): any {
        let l = buffer.length;
        const buf = new Int16Array(l);
        while (l--) {
            buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
        }
        return buf.buffer;
    }

}
