// @ts-nocheck
import {Subscription} from 'rxjs';
import * as _ from 'lodash';

import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {UserService} from '../../services/user.service';
import {AnswerService} from '../../services/answer.service';
import {BuilderService} from '../../services/builder.service';
import {StimuliService} from '../../services/stimuli.service';
import {CsatService} from '../../services/csat.service';

import {config} from '../../config';
import {settings} from '../../settings';

@Component({
    templateUrl: './csat-reading.component.html',
    styleUrls: ['./csat-reading.component.css']
})
export class CsatReadingComponent implements OnInit, OnDestroy, AfterViewInit {
    alternatives: Array<any> = [];
    client;
    clientId;
    onLevel = 1;
    settings;
    stimuli;
    type;
    typeId;
    typeSettings: any = {};
    user;
    userInfo;
    ws;
    private subscriptions = new Subscription();
    private config;

    constructor(private csatService: CsatService,
                private userService: UserService,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.config = config;
        this.settings = settings;
        this.clientId = this.location.path(true).split('/')[2];
        this.typeId = decodeURI(this.location.path(true).split('/')[4]);

        this.csatService.outgoing.therapistMessage = true;
        this.csatService.outgoing.click = true;
    }

    ngOnInit(): void {
        this.builderService.bsOnLevel.next(this.onLevel);
        this.ws = this.csatService.ws;

        this.subscriptions.add(this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
                this.csatService.outgoing.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.stimuli.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.stimuli = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.getType(this.typeId).subscribe(() => {
            this.builderService.type = this.stimuliService.bsType.value;
            return this.get();
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
                this.csatService.outgoing.typeSettings = result;
            }
        }));

        this.builderService.skipPlay = true;
    }

    ngOnDestroy(): void {
        this.stimuliService.unsubscribe.next();
this.stimuliService.unsubscribe.complete();
this.subscriptions.unsubscribe();
    }

    ngAfterViewInit() {
    }

    sendAnswer(id) {
        this.csatService.outgoing.selection = id;
        return this.csatService.send();
    }

    sendAlternatives() {
        if (this.stimuli) {
            this.csatService.outgoing.image = this.stimuli.image;
            this.csatService.outgoing.alternatives = (this.alternatives as any);
            return this.csatService.send();
        }
    }

    sendText() {
        this.csatService.outgoing.description = this.stimuli.description;
        return this.csatService.send();
    }

    get() {
        this.builderService.onLevel = this.onLevel;
        return this.builderService.getStimuli().subscribe();
    }

    selected(id) {
        // tslint:disable-next-line:triple-equals
        if (id == this.csatService.incoming.selection) {
            return true;
        }
    }

    correct(id) {
        // tslint:disable-next-line:triple-equals
        if (id == this.csatService.outgoing.selection) {
            return true;
        }
    }

    clear() {
        this.stimuli = null;
        this.csatService.outgoing.image = null;
        this.csatService.outgoing.alternatives = [];
        return this.csatService.send();
    }

    getAnswerImage(image) {
        return this.screenService.getAnswerImage(image);
    }

    getStimuliImage() {
        return this.screenService.getStimuliImage();
    }


}
