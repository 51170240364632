import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    config;
    alerts: Array<any> = [];
    newUser;
    registerInProgress;
    showIEWarning = false;

    constructor(private userService: UserService,
                private location: Location) {
        this.newUser = {
            name: '',
            email: '',
            password: '',
            therapistId: null,
            secret: null
        };
    }

    ngOnInit(): void {
        this.newUser.therapistId = this.location.path(true).split('/')[2];
        this.newUser.secret = this.location.path(true).split('/')[3];

        if (this.userService.isIE()) {
            this.showIEWarning = true;
        }
    }

    register(): Subscription | void {
        if (this.newUser.email && this.newUser.password) {
            this.registerInProgress = true;
            this.alerts = [];
            return this.userService.register(this.newUser).subscribe(() => {
                },
                () => {
                    this.registerInProgress = false;
                    const msg = {
                        type: 'danger',
                        message: 'This email address has already been registered. Please sign in instead.'
                    };
                    this.alerts.push(msg);
                });
        }
    }

    closeAlert(index): void {
        this.alerts.splice(index, 1);
    }
}
