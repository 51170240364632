<div class="container-fluid bg-white" id="atfaq-1">
  <div class="container" id="atfaq-2">
    <div class="center p-10 uppercase" id="afp-3">

    </div>
  </div>


  <div class="container p-t-20">

    <h2>How to Use My Aphasia Coach™️ Features with your Patients</h2>
    <hr class="hr-2"/>


    <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">

      <div class="col-sm-6 col-md-4 col-lg-3 pointer">
        <div (click)="open('https://vimeo.com/509907261/0f57a05cd8')" class="border text-center" style="height:100%;">
          <img class="tutorial-thumb" src="/images/homework.png"/><br/>
          <hr width="80%">
          <div class="p-b-10">
            How to assign homework
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3 pointer">
        <div (click)="open('https://vimeo.com/509905970/67a1a6f1e0')" class="border text-center" style="height:100%;">
          <img class="tutorial-thumb" src="/images/homework-videos.png"/><br/>
          <hr width="80%">
          <div class="p-b-10">
            How to assign videos
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3 pointer">
        <div (click)="open('https://vimeo.com/509907467/d53e32ffc1')" class="border text-center" style="height:100%;">
          <img class="tutorial-thumb" src="/images/connect-client.png"/><br/>
          <hr width="80%">
          <div class="p-b-10">
            How to connect with a patient
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3 pointer">
        <div class="border text-center" style="height:100%;" (click)="open('https://vimeo.com/554814090/9fbdac0f65')">
          <img src="/images/progress.png" class="tutorial-thumb"/><br/>
          <hr width="80%">
          <div class="p-b-10">
            How to check patient progress
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 p-15">

        <h3 class="p-t-30">Frequently Asked Questions for Therapists</h3>

        <div class="row">
          <div class="col-sm-12">

            <ngb-accordion>
              <ngb-panel title="Why should we use this software?">
                <ng-template ngbPanelContent>
                  With such limited time for aphasia treatment, this software can be used independently by your patient to work on core language areas. The more foundational work your patient does on their own, the more time you have to help them
                  with
                  activities that need your expertise.
                </ng-template>
              </ngb-panel>
              <ngb-panel title="What do you mean by 'foundational work'?">
                <ng-template ngbPanelContent>
                  The more severe your patient’s aphasia, the more skills there are to work on. Many ‘core’ or ‘foundational’ skills, such as single-word reading, do not require a speech-language pathologist. Think of these activities as ‘unskilled’,
                  anyone can do them.
                </ng-template>
              </ngb-panel>
              <ngb-panel title="Where should my patient start?">
                <ng-template ngbPanelContent>
                  Exercises are generally categorized by Beginning, Emerging, and Challenging. All exercises use all language modalities for the best learning, which is the ultimate goal. While each exercise begins at level 1, within a few minutes,
                  your patient will be working at their highest level.
                </ng-template>
              </ngb-panel>
              <ngb-panel title="What does it mean that the software is ‘self-leveling’?">
                <ng-template ngbPanelContent>
                  Each language exercise is designed to become more challenging the more the patient answers correctly. For example, a picture-word matching task begins with multiple language modalities and two choices. As the patient answers
                  correctly, the type of cueing (modality) changes and the number and type of choices become more difficult.
                </ng-template>
              </ngb-panel>

              <ngb-panel title="Why is it important to work at the patient’s highest level? Aren’t I doing that now?">
                <ng-template ngbPanelContent>
                  The reason many patients seem to plateau is that they are working at a level that is too high or too low. Each therapy task you do is composed of hundreds of smaller tasks in two different hierarchies. Each exercise in My Aphasia
                  Coach is designed to start at a basic level. Therapy tasks produce better outcomes if they work one step below the patient’s highest level so that their work is challenging but not frustrating. You don’t want them to make mistakes,
                  you want them to learn.
                </ng-template>
              </ngb-panel>
              <ngb-panel title="Why is Internet access needed for My Aphasia Coach?">
                <ng-template ngbPanelContent>
                  My Aphasia Coach™ is a browser-based application and apps for Apple and Android products. This allows for one site/app with access to multiple types of exercises instead of purchasing just one type of exercise app. The software and
                  apps are updated frequently with new exercises and expanding lessons.
                </ng-template>
              </ngb-panel>
              <ngb-panel title="How does my patient purchase My Aphasia Coach after the trial period?">
                <ng-template ngbPanelContent>
                  Every time your patient logs in during their free 14-day trial period, they will see a countdown until their trial expires. To subscribe to My Aphasia Coach, go the Menu tab and select “Payment”. There are 3 options available, with
                  the
                  yearly subscription being the most cost-effective and inclusive.
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

          </div>
        </div>
      </div>
    </div>
  </div>

  <app-my-footer></app-my-footer>
</div>
