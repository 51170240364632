import {Component, OnInit} from '@angular/core';

import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {
    user;

    constructor(private userService: UserService) {
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => this.user = result);
    }

}
