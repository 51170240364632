import * as _ from 'lodash';

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {settings} from '../../settings';
import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {BuilderService} from '../../services/builder.service';
import {AnswerService} from '../../services/answer.service';
import {AudioService} from '../../services/audio.service';
import {config} from '../../config';
import {Subscription} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {User} from '../../models/user';
import {ScreenService} from '../../services/screen.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-writing-numbers',
    templateUrl: './writing-numbers.component.html',
})
export class WritingNumbersComponent implements OnInit, OnDestroy {
    highlight;
    settings = settings;
    typeSettings: any = {};
    user: User | null = null;
    userInfo: any;
    type: any;
    stimuli: any;
    lastWrong;
    question;
    wrongAnswers = '';
    wrongAnswerCount = 0;
    showEar = true;

    input: any = {};
    // input: Array<any> = [];

    answer: Array<any> = [];


    help;
    focus;
    showHelp;

    private subscriptions = new Subscription();

    constructor(private userService: UserService,
                private cdr: ChangeDetectorRef,
                private screenService: ScreenService,
                private answerService: AnswerService,
                private audioService: AudioService,
                private builderService: BuilderService,
                private location: Location,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
        this.highlight = this.audioService.highlight;
    }

    ngOnInit(): Subscription {
        this.wrongAnswerCount = 0;
        this.wrongAnswers = '';

        this.subscriptions.add(this.userService.bsUser.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsType.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsStimuli.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            this.stimuli = null;
            if (!_.isEmpty(result)) {
                this.input = {};
                this.wrongAnswers = '';
                this.wrongAnswerCount = 0;
                this.answerService.help.next(0);
                this.answer = [];
                this.stimuli = result;

                const answer = _.sortBy(this.stimuli.levels, ['sortCode']);
                _.forEach(answer, (i) => {
                    this.answer.push(i.word.split(' '));
                });
                this.answer = _.flatten(this.answer);
                this.showHelp = false;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.question = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        }));

        this.subscriptions.add(this.answerService.help.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (result > 0) {
                this.showHelp = true;
                this.cdr.detectChanges();

                if (result === 1) {
                    let timeout = 3000;
                    if (this.stimuli && this.stimuli.levels && this.stimuli.levels[0] && config.writingTimeout[this.stimuli.levels[0].level]) {
                        timeout = config.writingTimeout[this.stimuli.levels[0].level];
                    }
                    setTimeout(() => {
                        this.showHelp = false;
                        this.cdr.detectChanges();
                        this.setFocus();
                    }, timeout);
                }
            }
        }));

        this.subscriptions.add(this.screenService.bsSetFocus.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (result) {
                this.screenService.bsSetFocus.next(false);
                return this.setFocus();
            }
            else {
                return false;
            }
        }));

        this.subscriptions.add(this.audioService.bsShowEar.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            this.showEar = result;
        }));


        return this.builderService.getType().subscribe();
    }

    ngOnDestroy(): void {
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
        this.builderService.cleanUp().then();
    }

    checkAnswer(): any {
        if (this.input[0] && this.input[0].length > 0) {
            const temp = document.activeElement;
            (temp as any).blur();
            return setTimeout(() => {
                return this.answerService.writing(_.values(this.input)).subscribe(() => {
                    this.input = {};
                    try {
                        // @ts-ignore
                        return document.getElementById('input_0').focus();
                    } catch {
                    }
                });
            }, 100);
        }
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }

    checkField(ix): void {
        if (this.input && this.input[ix] && this.input[ix].slice(0, 1) === ' ') {
            this.input[ix] = this.input[ix].substring(1);
        }

        if (this.input && this.input[ix] && this.answer[ix].length === this.input[ix].length) {
            this.lastWrong = null;
            const newField = ix + 1;
            if (document.getElementById('input_' + newField)) {
                try {
                    // @ts-ignore
                    document.getElementById('input_' + newField).focus();
                } catch {
                }
            }
        }
    }

    makeInput(word): any {

        const width = ((parseInt(word.word.length, 10) * 1.5) - 0.01).toFixed(2) + 'ch';
        return {width};
/*
        if (word && word.word) {
            return {width, background: 'repeating-linear-gradient(90deg, grey 0, grey 1ch, transparent 0, transparent 1.6ch) 0 100%/100% 2px no-repeat'};
        }
*/


    }

    setFocus(): any {
        return setTimeout(() => {
            try {
                // @ts-ignore
                document.getElementById('input_0').focus();
            } catch {
            }
        }, 300);
    }

}
