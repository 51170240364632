<app-error-message></app-error-message>
<div *ngIf="stimuli && alternatives && type && typeSettings && show" class="center" id="redrawTarget">

  <h4 class="m-b-10">
    <span *ngIf="(onStep === 0)">Put the words in the correct order</span>
    <span *ngIf="(onStep === 1)">Write the sentence</span>
    <span *ngIf="(onStep === 2)" [class.highlight-word]="highlight['highlight-text']">Which word is</span>
  </h4>

  <div *ngIf="(typeSettings.mainImage && stimuli.image)" [class.highlight-word]="highlight['highlight-image']">
    <img class="stimuli-image" [src]="stimuliImage"/>
  </div>

  <br *ngIf="(typeSettings.mainImage && stimuli.image)" clear="all">

  <div *ngIf="onStep===0">
    <div #box id="box">
      <div (click)="dragDropReplacement('land' + index)"
           (dragenter)="dragEnter($event)"
           (dragover)="dragOver($event)"
           (drop)="drop($event)"
           *ngFor="let letter of alternatives; let index = index"
           [class.highlight-word]="highlight2(index)"
           class="scrambling-drop"
           draggable="false"
           id="land{{index}}">
      </div>
    </div>

    <hr style="width: 50%; margin:auto;" [hidden]="wrongCount.value >= 3">

    <div #box2 [hidden]="wrongCount.value >= 3">
      <div (click)="dragDropReplacement('start' + index)"
           (dragenter)="dragEnter($event)"
           (dragover)="dragOver($event)"
           (drop)="drop($event)"
           *ngFor="let letter of alternatives; let index = index"
           class="scrambling-start"
           draggable="false"
           id="start{{index}}">
        <div *ngIf="index === 0" [class.highlight-word]="highlight['highlight-drag']" (dragstart)="dragStart($event)" [innerHTML]="letter.toLowerCase()" class="scrambling-word pointer" draggable="true" id="{{index + letter}}"></div>
        <div *ngIf="index !== 0" (dragstart)="dragStart($event)" [innerHTML]="letter.toLowerCase()" class="scrambling-word pointer" draggable="true" id="{{index + letter}}"></div>
      </div>
    </div>

    <div class="clearfix p-b-15">
      <br/>
      <button (click)="checkScrambling()" class="btn btn-primary high-z center-block" type="submit"><span class="material-icons middle">done</span> Done</button>
    </div>
  </div>

  <div *ngIf="(onStep === 1)">
    <div class="center-text p-b-10">

      <div *ngFor="let word of stimuli.postSounds; let i = index" class="inline-block p-10 center" id="">
        <h1 [class.highlight-word]="highlight['highlight-hint']">{{stimuli.postSounds[i].word}}</h1>
        <input [(ngModel)]="input[i]"
               (keyup)="nextField(i)"
               autocomplete="off"
               autofocus
               class="writing-input center"
               [class.highlight-word]="highlight['highlight-input']"
               id="input_{{i}}"
               maxlength="{{word.word.length}}"
               name="input_{{i}}"
               size="{{word.word.length +2 }}"

               style="width:initial; display:initial">
      </div>
      <div class="clearfix p-b-15">
        <br/>
        <button (click)="checkWriting()" class="btn btn-primary high-z center-block" type="submit"><span class="material-icons middle">done</span> Done</button>
      </div>

    </div>
  </div>

  <div *ngIf="(onStep === 2)">
    <div class="center-text p-b-10" *ngIf="alternatives">
      <div *ngFor="let a of alternatives; let index=index; let isOdd=odd; let isEven=even" style="width:50%; padding: 5px;" class="inline-block" [class.highlight-word]="highlight['highlight-alternatives']">
        <button (click)="wwIs(a.word)" class="answerbutton btn btn-lg btn-primary" id="{{a.word}}">
          <div *ngIf="a?.word" style="white-space: normal">{{a.word.toLowerCase()}}</div>
        </button>
      </div>
    </div>
  </div>

</div>
