import {debounceTime} from 'rxjs/operators';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {UserService} from '../../services/user.service';
import {Location} from '@angular/common';
import {VideoService} from '../../services/video.service';

import {UntypedFormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalService} from '../../services/modal.service';
import {Subscription} from 'rxjs';

@Component({
    templateUrl: './assign-homework-videos.component.html',
})
export class AssignHomeworkVideosComponent implements OnInit, OnDestroy {
    clientData;
    videosFiltered: Array<any> = [];
    messageStatus = {
        success: false,
        error: false
    };

    filterInput = new UntypedFormControl();
    filterType = new UntypedFormControl();

    payload = {name: ''};
    search = {name: '', searchType: 'startsWith'};


    private oVideosUnfiltered;
    private oClientData;

    constructor(private cdr: ChangeDetectorRef,
                private videoService: VideoService,
                private router: Router,
                private modalService: ModalService,
                private location: Location,
                private userService: UserService) {
    }

    ngOnInit(): Subscription {
        this.oClientData = this.userService.getClientList().subscribe(result2 => {
            this.clientData = _.find(result2, {_id: this.location.path(true).split('/')[2]});
            if (!this.clientData) {
                console.error('User not found!');
            }
        });

        this.filterInput.valueChanges.pipe(
            debounceTime(500))
            .subscribe(term => {
                this.search.name = term;
                return this.filterVideos();
            });

        return this.filterType.valueChanges.subscribe(term => {
            this.search.searchType = term;
            return this.filterVideos();
        });
    }

    ngOnDestroy(): void {
        this.oClientData.unsubscribe();
    }

    isAssigned(video): any {
        if (this.clientData && this.clientData.homeworkVideos) {
            const has = _.find(this.clientData.homeworkVideos, {video: video._id});
            if (has) {
                return true;
            }
        }
        return false;
    }

    toggleAssigned(video): Subscription {
        if (this.clientData && this.clientData.homeworkVideos) {
            const has = _.find(this.clientData.homeworkVideos, {video: video._id});

            if (has) {
                _.remove(this.clientData.homeworkVideos, (currentObject) => {
                    return (currentObject as any).video === video._id;
                });
            }
            else {
                this.clientData.homeworkVideos.push({video: video._id, name: video.name, created: new Date(), url: video.url, level: video.level});
                this.clientData.homeworkVideos = _.sortBy(this.clientData.homeworkVideos, ['name']);
            }
        }
        else {
            this.clientData.homeworkVideos = [{video: video._id, name: video.name, created: new Date(), url: video.url, level: video.level}];
        }

        return this.userService.saveHomeworkVideos(this.clientData).subscribe();
    }

    removeVideo(video): any {
        if (this.clientData && this.clientData.homeworkVideos) {
            const has = _.find(this.clientData.homeworkVideos, {video: video.video});

            if (has) {
                _.remove(this.clientData.homeworkVideos, (currentObject) => {
                    return (currentObject as any).video === video.video;
                });

                return this.userService.saveHomeworkVideos(this.clientData).subscribe();
            }
        }
    }

    filterVideos(): any {
        this.payload.name = this.search.name;

        if (this.search.searchType === 'startsWith') {
            this.payload.name = '^' + this.search.name;
        }

        if (this.payload.name.length > 2) {
            this.oVideosUnfiltered = this.videoService.searchVideos(this.payload).subscribe((res) => {
                _.forEach(res, (video: any) => {
                    video.name = _.upperFirst((video as any).name);
                });

                this.videosFiltered = _.sortBy(res, ['name', 'level']);
            });
        }
    }

    level(level): Subscription {
        return this.videoService.getVideos('Apraxia', level).subscribe(result => {
            result = _.sortBy(result, (video) => {
                return _.upperFirst(video.name);
            });

            this.videosFiltered = result;
        });
    }

    resetSearch(): void {
        this.filterInput.setValue('');
        this.videosFiltered = [];
    }

    open(videoUrl): Promise<any> {
        if (!this.userService.userInfo.basicSubscriber) {
            return this.router.navigate(['/account']);
        }
        else {
            return this.modalService.video(videoUrl);
        }
    }

    send(): Subscription {
        return this.userService.saveHomeworkNotify(this.clientData).subscribe(res => {
            this.messageStatus.success = res.success;
            this.messageStatus.error = res.error;
        });
    }

    clear(): any {
        if (this.clientData && this.clientData.homeworkVideos) {
            this.clientData.homeworkVideos = [];
            return this.userService.saveHomeworkVideos(this.clientData).subscribe();
        }
    }

    back(): void {
        return this.location.back();
    }

}
