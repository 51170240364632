// @ts-nocheck
import {Subscription} from 'rxjs';
import * as _ from 'lodash';

import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';

import {UserService} from '../../services/user.service';
import {config} from '../../config';
import {settings} from '../../settings';

@Component({
    selector: 'csat-whiteboard',
    templateUrl: './csat-whiteboard.component.html',
    styleUrls: ['./csat-whiteboard.component.css']
})
export class CsatWhiteboardComponent implements OnInit, OnDestroy, AfterViewInit {

    alternatives: Array<any> = [];
    client;
    clientId;
    inter;
    settings;
    user;
    userInfo;
    ws;
    @ViewChild('canvas') public canvas: ElementRef | undefined;
    @ViewChild('paint') public paint: ElementRef | undefined;
    drawing = false;
    ix = 0;
    tool = 'freehand';
    color = '#0000ff';
    line = '3';
    text = null;
    canvasElement: HTMLCanvasElement | null = null;
    ctx: CanvasRenderingContext2D | null = null;
    mouse = {x: 0, y: 0};
    offsetX = 0;
    offsetY = 0;
    rectX = 0;
    rectY = 0;
    outgoing: any = {
        therapistMessage: true,
        messageType: 'csat',
        canvas: true,
        from: '',
        to: '',
        clear: false,
        canvasElements: [],
        typeSettings: null
    };
    incoming: any = {
        from: '',
        text: '',
        client: true,
        canvasElements: [],
    };
    private subscriptions = new Subscription();
    private config;

    constructor(private userService: UserService,
                private cdr: ChangeDetectorRef,
                private location: Location) {
        this.config = config;
        this.settings = settings;
        this.clientId = this.location.path(true).split('/')[2];
    }

    ngOnInit(): void {
        this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');

        this.subscriptions.add(this.userService.getUserInfo().subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                this.outgoing.from = this.user._id;
            }
        }));

        this.subscriptions.add(this.userService.getClientList().subscribe(result => {
            const client = _.find(result, ['_id', this.clientId]);
            this.client = client.local.name + ', (' + client.local.email + ')';
            this.outgoing.to = client._id;
        }));

        this.ws.getDataStream().subscribe(
            (msg: MessageEvent) => {
                return this.receiveMessage(msg);
            },
            (err) => {
                console.error(new Error(err));
            });

    }

    ngOnDestroy(): void {
        this.ws.close();
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        // @ts-nocheck
        if (this.canvas && this.canvas.nativeElement) {
            this.canvasElement = this.canvas.nativeElement;
            this.ctx = this.canvasElement.getContext('2d');

            const computedStyle = getComputedStyle(this.canvasElement);
            this.canvasElement.width = parseInt(computedStyle.getPropertyValue('width'), 10);
            this.canvasElement.height = parseInt(computedStyle.getPropertyValue('height'), 10);

            this.offsetX = this.paint.nativeElement.getBoundingClientRect().left;
            this.offsetY = this.paint.nativeElement.getBoundingClientRect().top;

            this.ctx.lineWidth = 1;
            this.ctx.lineJoin = 'round';
            this.ctx.lineCap = 'round';
            this.ctx.strokeStyle = '#000000';

        }
    }

    receiveMessage(msg?): any {
        try {
            const message = JSON.parse(msg.data);
            this.incoming = message;
            console.log(this.incoming);
            if ((this.incoming as any).client && (this.incoming as any).canvasElements) {
                this.drawCanvas();
            }
        } catch (err: any) {
            console.error(new Error(err));
        }
    }

    clear(): any {
        // @ts-nocheck
        this.text = null;
        this.outgoing.clear = true;
        this.outgoing.canvasElements = [];
        this.ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        return this.send();
    };

    check(): any {
        if (this.user && this.clientId) {
            const msg = {
                check: true,
                from: this.clientId,
                to: this.user._id,
            }
            return this.ws.send(msg).subscribe();
        }
    }

    send(): any {
        return this.ws.send(this.outgoing).subscribe(
            (res) => {
                // console.log(res);
            },
            (err) => {
                // console.error(new Error(err));
                return setTimeout(() => {
                    return this.send();
                }, 500);
            });
    }

    drawCanvas(): any {
        // @ts-nocheck
        console.log(this.incoming);
        if (this.incoming.canvasElements && this.incoming.canvasElements.length > 0) {

            for (this.ix; this.ix < this.incoming.canvasElements.length; this.ix++) {
                const item = this.incoming.canvasElements[this.ix]

                if (item.action === 'moveTo') {
                    this.ctx.beginPath();
                    this.ctx.lineWidth = item.line;
                    this.ctx.strokeStyle = item.color;
                    this.ctx.moveTo(item.x, item.y);
                }
                if (item.action === 'lineTo') {
                    this.ctx.lineWidth = item.line;
                    this.ctx.strokeStyle = item.color;
                    this.ctx.lineTo(item.x, item.y);
                    this.ctx.stroke();
                }
            }

            this.ix = 0;
        }
        else {
            this.incoming.canvasElements = [];
            this.ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        }
    }

    mousedown(): any {
        // @ts-nocheck
        this.outgoing.clear = false;
        this.ctx.lineWidth = parseInt(this.line, 10);
        this.ctx.strokeStyle = this.color;

        if (this.tool === 'freehand') {
            this.drawing = true;
            this.ctx.beginPath();
            this.ctx.moveTo(this.mouse.x - this.offsetX, this.mouse.y - this.offsetY);
            this.outgoing.canvasElements.push({
                action: 'moveTo',
                x: this.mouse.x - this.offsetX,
                y: this.mouse.y - this.offsetY
            });
        }
        else if (this.tool === 'line') {
            this.ctx.beginPath();
            this.ctx.moveTo(this.mouse.x - this.offsetX, this.mouse.y - this.offsetY);
            this.outgoing.canvasElements.push({
                action: 'moveTo',
                x: this.mouse.x - this.offsetX,
                y: this.mouse.y - this.offsetY
            });
        }
        else if (this.tool === 'rectangle') {
            this.rectX = this.mouse.x - this.offsetX;
            this.rectY = this.mouse.y - this.offsetY;
        }
        else if (this.tool === 'text') {
            this.rectX = this.mouse.x - this.offsetX;
            this.rectY = this.mouse.y - this.offsetY;
        }
    }

    mouseup(): any {
        // @ts-nocheck
        if (this.tool === 'freehand') {
            this.drawing = false;
        }
        else if (this.tool === 'line') {
            this.ctx.lineTo(this.mouse.x - this.offsetX, this.mouse.y - this.offsetY);
            this.ctx.stroke();

            this.outgoing.canvasElements.push({
                action: 'lineTo',
                line: this.line,
                color: this.color,
                x: this.mouse.x - this.offsetX,
                y: this.mouse.y - this.offsetY
            });

        }
        else if (this.tool === 'rectangle') {
            const width = this.mouse.x - this.offsetX - this.rectX;
            const height = this.mouse.y - this.offsetY - this.rectY;
            this.ctx.rect(this.rectX, this.rectY, width, height);
            this.ctx.stroke();

            this.outgoing.canvasElements.push({
                action: 'rect',
                line: this.line,
                color: this.color,
                x: this.mouse.x - this.offsetX,
                y: this.mouse.y - this.offsetY,
                width: width,
                height: height
            });

        }
        else if (this.tool === 'text') {
            let width = this.mouse.x - this.rectX;
            if (width < 300) {
                width = 300;
            }

            let y = this.rectY;

            let text: any = 'All the world\'s a stage, and all the men and women merely players. They have their exits and their entrances; ';
            if (this.text) {
                text = this.text;
            }

            const lineHeight = 36;
            this.ctx.font = '24pt Arial, Helvetica, sans-serif';
            this.ctx.fillStyle = this.color;

            const words = text.split(' ');
            let line = '';

            words.forEach((word, n) => {
                const testLine = line + words[n] + ' ';
                const metrics = this.ctx.measureText(testLine);

                if (metrics.width > width && n > 0) {
                    this.ctx.fillText(line, this.rectX, y);
                    line = words[n] + ' ';
                    y += lineHeight;
                }
                else {
                    line = testLine;
                }
            });

            this.ctx.fillText(line, this.rectX, y);

            this.outgoing.canvasElements.push({
                action: 'fillText',
                text: text,
                font: '24pt Arial, Helvetica, sans-serif',
                fillStyle: this.color,
                x: this.rectX,
                y: this.rectY,
                x2: this.mouse.x - this.offsetX,
                y2: this.mouse.y - this.offsetY,
            });

        }

        this.send();
    }

    mousemove(e): any {
        this.mouse.x = e.pageX;
        this.mouse.y = e.pageY;

        if (this.tool === 'freehand' && this.drawing) {
            this.ctx.lineTo(this.mouse.x - this.offsetX, this.mouse.y - this.offsetY);
            this.ctx.stroke();

            this.outgoing.canvasElements.push({
                action: 'lineTo',
                line: this.line,
                color: this.color,
                x: this.mouse.x - this.offsetX,
                y: this.mouse.y - this.offsetY
            });
        }
    }

    mouseleave(): any {
        if (this.tool === 'freehand') {
            this.drawing = false;
        }

        this.send();
    }

    back(): void {
        this.outgoing.canvas = false;
        this.clear();
        return this.location.back();
    }


}
