import * as _ from 'lodash';

import {Subscription} from 'rxjs';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {StimuliService} from '../../services/stimuli.service';

import {UserService} from '../../services/user.service';
import {ModalService} from '../../services/modal.service';


@Component({
    selector: 'app-client-list',
    templateUrl: './client-list.component.html'
})
export class ClientListComponent implements OnInit, OnDestroy {
    user;
    clientData;
    private subscriptions = new Subscription();

    constructor(private stimuliService: StimuliService,
                private modalService: ModalService,
                private userService: UserService) {
    }

    ngOnInit(): void {

        this.subscriptions.add(this.userService.getUserInfo().subscribe(result => this.user = result));

        this.subscriptions.add(this.userService.getClientList().subscribe(result => {
            this.clientData = _.sortBy(result, ['local.name']);
        }));
    }

    ngOnDestroy(): void {
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
    }

    removeClient(id): Promise<void> {
        return this.modalService.revokeAccess().then(res => {
            if (res === 'revoke') {
                this.subscriptions.add(this.userService.removeClient(id).subscribe(() => {
                    _.remove(this.clientData, ['_id', id]);
                }, (err) => {
                }));
            }
        });
    }

}
