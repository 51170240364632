<div class="container-fluid bg-white" style="padding-top: 100px;">
</div>
<div class="container-fluid bg-dark-gray" style="">
  <div class="container" style="padding-top:40px;">
    <div class="row">
      <div class="col-sm-3">
        <a routerLink="/about-us" class="text-white h5">About Us</a><br/>
        <a routerLink="/contact" class="text-white h5">Contact Us</a><br/>
        <a routerLink="/faq" class="text-white h5">FAQ</a><br/>

        <br/>



      </div>
      <div class="col-sm-6 center">
        <a [hidden]="user" [routerLink]="'/register'" class="btn trial p-15 h3 m-b-0">
          Get started for free
        </a>
        <br/>
        <div [hidden]="user" class="text-small center">* No Credit Card Required</div>
        <br/>
        <br/>
          <a routerLink="/" routerLinkActive="active"><img src="/images/logo-white.png" width="200"/></a>
        <br/>
        <br/>
        <h3 class="text-white">Software for Faster Aphasia Progress</h3>
      </div>
      <div class="col-sm-3">
        <a routerLink="/how-it-works" class="text-white h5">How it works</a><br/>
        <a routerLink="/pricing" class="text-white h5">Pricing</a><br/>
        <a routerLink="/where-do-i-start" class="text-white h5">Placement Quiz</a><br/>
        <a routerLink="/aphasia" class="text-white h5">What is my aphasia type?</a><br/>
        <br/>


      </div>

    </div>
    <div class="row">
      <div class="col-sm-3">
        <div style="font-size: 13px;">
          Phasic Software, LLC<br/>
          6830 Central Ave, STE A<br/>
          St. Petersburg, FL 33707<br/>
          USA
        </div>
      </div>
      <div class="col-sm-6">
      </div>
      <div class="col-sm-3">
        <div style="font-size: 13px;">
          <span class="material-icons middle">drafts</span> <a class="text-white" href="mailto:info@myaphasiacoach.com"> info@myaphasiacoach.com</a><br/>
          <!-- <span class="material-icons middle">phone</span> +1 813 421 5538<br/> -->
        </div>

      </div>
    </div>


  </div>
</div>
<div class="container-fluid bg-teal p-15" style="">
  <div class="text-center text-small">
    <a class="text-white" routerLink="/">MyAphasiaCoach.com</a> © 2014-{{copyrightYear}} Phasic Software, LLC ·
    <a class="text-white" routerLink="/privacy">Privacy Policy</a> ·
    <a class="text-white" routerLink="/terms">Terms of Service</a>
  </div>
</div>
<div class="container-fluid bg-white p-15" style="min-height: 75vh">
</div>
