<div class="container" id="lp-desktop" style="font-size: 1.3rem">
  <a routerLink="/" routerLinkActive="active">
    <img class="p-30" src="/images/logo.svg" width="250"/><br/>
  </a>

  <div style="background-color: #11a4ab; height:20px;">
  </div>
  <div class="" id="lp-d2">
    <div class="container-fluid" id="fp-2">
      <div class="p-30" id="fp-3">
        <h2 class="p-b-15" id="fp-4">Aphasia Therapy Software</h2>
        <h3 class="p-b-15">Free for Speech Pathologists</h3>
        <h4>Sign up today!</h4>
      </div>
    </div>
  </div>
  <div class="m-t-15 m-b-10" style="background-color:#11a4ab; height:10px;"></div>

  <div class="p-30 h3 font-italic" style="background-color:#11a4ab; color: #ffffff">
    <div class="center h1 font-weight-normal">
      Give your patients the advantage of intensive aphasia
      therapy exercises at home!
    </div>
    <br/>
    Exclusive Benefits:<br/>
    <ul>
      <li>Assign and Check Homework</li>
      <li>Monitor Progress</li>
      <li>All Language Modalities in the Same Place for One Low Price</li>
      <li>Exercises Adapt Automatically to Improve Learning</li>
      <li>1000s of Videos at 8 Different Levels for Critical Apraxia Practice</li>
    </ul>

  </div>
</div>
<div class="container">
  <p *ngFor="let alert of alerts">
    <ngb-alert (close)="closeAlert(alert)" [type]="alert.type">{{ alert.message }}</ngb-alert>
  </p>

  <ngb-alert *ngIf="registerInProgress" [dismissible]="false">
    Registering New User...
  </ngb-alert>


  <div class="p-t-30 p-b-30 h2">
    Join thousands of aphasia therapists for FREE!
  </div>
  <form #form="ngForm" (ngSubmit)="register(form)" class="form-horizontal m-t-15">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Name</label>
          <input [(ngModel)]="newUser.name" class="form-control" name="name" placeholder="Your name" required type="text">
        </div>
        <div class="form-group">
          <label>Email address</label>
          <input #email="ngModel" [(ngModel)]="newUser.email" [email]="true" class="form-control" name="email" placeholder="Email address" required type="email">
        </div>
        <div class="form-group">
          <label>Password</label>
          <input [(ngModel)]="newUser.password" class="form-control" name="password" placeholder="" required type="password">
        </div>

      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>ASHA Number</label>
          <input [(ngModel)]="newUser.therapist.ashaNumber" class="form-control" name="ashaNumber" required type="text">
        </div>

        <div class="form-group">
          <label>Place of Employment</label>
          <input [(ngModel)]="newUser.therapist.placeOfEmployment" class="form-control" name="placeOfEmployment" required type="text">
        </div>

        <div class="form-group">
          <label>Facility Type</label>
          <select class="form-control" name="userType" ng-model="newUser.therapist.facilityType" required>
            <option [ngValue]=null disabled hidden selected></option>
            <option value="Outpatient">Outpatient Clinic</option>
            <option value="Hospital">Hospital</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
    </div>

    <div class="center">
      <button [disabled]="form.invalid" class="btn btn-primary" type="submit">Register</button>
    </div>

  </form>


  <div class="center h2 m-t-30">
    <div class="bg-black p-10 inline-block m-b-20">Always FREE for Speech Pathologists</div>
  </div>
</div>

<app-my-footer></app-my-footer>
