import {BehaviorSubject} from 'rxjs';

import {Injectable} from '@angular/core';

@Injectable()
export class MessagingService {
    message: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor() {
    }

    setMessage(message: string | null, success?: boolean): boolean {
        let msg;

        if (message === null) {
            msg = null;
        }
        else {
            msg = {
                message,
                success
            };
        }
        this.message.next(msg);

        return true;
    }
}
