import * as _ from 'lodash';

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {StimuliService} from '../../services/stimuli.service';

import {config} from '../../config';
import {settings} from '../../settings';

@Component({
    selector: 'therapy-response-modal',
    templateUrl: './therapy-response-modal.component.html',
    styleUrls: ['./therapy-response-modal.component.css']
})
export class TherapyResponseModalComponent implements OnInit, OnDestroy {
    @Input() correctAnswer;
    @Input() suppressPostSounds;
    @Input() onLevel;

    config = config;
    settings = settings;

    audioObjects: Array<any> = [];
    stimuli;
    type;
    playPromise;
    postSounds = false;
    random = 0;

    constructor(public activeModal: NgbActiveModal,
                private stimuliService: StimuliService) {
    }

    ngOnInit(): void {
        this.type = this.stimuliService.bsType.getValue();
        this.stimuli = this.stimuliService.bsStimuli.getValue();

        let typeSettings;

        if (this.onLevel && this.type) {
            typeSettings = _.find(this.type.levels, {level: this.onLevel});
        }

        if (this.correctAnswer && !this.suppressPostSounds && typeSettings && (typeSettings as any).postSound && this.stimuli.postSounds && this.stimuli.postSounds.length > 0) {
            this.postSounds = true;
            return this.playPostSounds();
        }
        else if (this.settings.muteStimuliResponse) {
            setTimeout(() => {
                return this.activeModal.close();
            }, config.audioWait);
        }
        else {
            this.random = Math.floor(Math.random() * Math.floor(2));
            return this.playResponse();
        }
    }

    ngOnDestroy(): void {
        if (this.playPromise) {
            this.playPromise.pause();
            this.playPromise.currentTime = 0;
        }
    }

    playPostSounds(): void {
        this.audioObjects = [];

        _.forEach(this.stimuli.postSounds, (sound: any) => {
            if (sound.sound) {
                const audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/' + sound.sound + '.mp3';
                this.audioObjects.push(audio);
            }
        });
        return this.playAudio(0);
    }

    playAudio(index): any {
        if (document.getElementById('word' + (index))) {
            // @ts-ignore
            document.getElementById('word' + (index)).classList.add('highlight-word');
        }

        this.playPromise = this.audioObjects[index];
        this.playPromise.addEventListener('ended', () => {
            if (index < this.audioObjects.length - 1) {
                index++;

                return setTimeout(() => {
                    if (document.getElementById('word' + (index - 1))) {
                        // @ts-ignore
                        document.getElementById('word' + (index - 1)).classList.remove('highlight-word');
                    }

                    return this.playAudio(index);
                }, config.audioWait);
            }
            else {
                return setTimeout(() => {
                    return this.activeModal.close();
                }, config.audioWait);
            }
        });

        if (this.playPromise && this.playPromise.play()) {
            return this.playPromise.play().then(() => {
            }, () => {
                return setTimeout(() => {
                    return this.noSound(index);
                }, config.audioWait);
            });
        }
    }

    noSound(index): any {
        if (index < this.audioObjects.length - 1) {
            index++;
            return setTimeout(() => {
                // @ts-ignore
                document.getElementById('word' + (index - 1)).classList.remove('highlight-word');
                return this.playAudio(index);
            }, config.audioWait);
        }
        else {
            return this.activeModal.close();
        }
    }

    playResponse(): void {
        const audio = new Audio();
        let sound;

        if (this.correctAnswer) {
            sound = '5f7df46a3c5714002d36e15d';

            /*
                        if (this.random === 0) {
                            sound = '580bc6c6364ebc21006564d7';
                        }
                        else {
                            sound = '579ba853d307973900f24093';
                        }
            */
        }
        else {
            sound = '5f7df46b18f232001e108084';


            /*
                        if (this.random === 0) {
                            sound = '578b92e1af56f12f0074929a';
                        }
                        else {
                            sound = '57a3c1b5cd99811a00ba9191';
                        }
            */
        }

        audio.src = config.mediaServer + 'blob.mp3/' + sound + '.mp3';
        audio.play().then(() => {
            return setTimeout(() => {
                return this.activeModal.close();
            }, config.audioWait);
        });
    }
}


