import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchFilter',
    pure: true // Pure: stack values. Impure for heap values
})

@Injectable()
export class SearchFilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: string): any[] {
        if (!items) {
            return [];
        }
        else {
            return items.filter(it => {
                    return it.value[field].toLowerCase().startsWith(value.toLowerCase());
                }
            );
        }
    }
}
