import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-contact',
    templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {

    formData: any = {
        name: null,
        email: null,
        message: null,
        contact: null,
        browser: null,
    };

    emailSent;
    errorMessage;

    constructor(private userService: UserService) {
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            if (result !== null) {
                this.formData.name = this.userService.getName();
                this.formData.email = this.userService.getEmail();
            }
        });
    }


    processContact(): any {
        if (this.formData.message !== null) {
            this.formData.browser = window.navigator.userAgent;
            return this.userService.getHelp(this.formData).subscribe(res => {
                this.emailSent = res.success;
                this.errorMessage = res.error;
            });
        }
    }

}
