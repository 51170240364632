import * as _ from 'lodash';

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {config} from '../../config';
import {settings} from '../../settings';
import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {AudioService} from '../../services/audio.service';
import {BuilderService} from '../../services/builder.service';
import {AnswerService} from '../../services/answer.service';
import {of, Subscription} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {User} from '../../models/user';
import {ScreenService} from '../../services/screen.service';

@Component({
    templateUrl: './writing3.component.html',
})

export class Writing3Component implements OnInit, OnDestroy {
    highlight;
    settings = settings;

    typeSettings: any = {};
    user: User | null = null;
    userInfo: any;
    type: any;
    stimuli: any;

    wrongAnswerCount = 0;
    answer: Array<any> = [];
    input: Array<any> = [];

    question;
    help;
    showHelp = false;
    showInput = true;
    focus;

    private oUser;
    private oHelp;
    private oType;
    private oStimuli;
    private oTypeSettings;
    private oAlternatives;
    private oFocus;

    constructor(
        private answerService: AnswerService,
        private audioService: AudioService,
        private builderService: BuilderService,
        private cdr: ChangeDetectorRef,
        private location: Location,
        private screenService: ScreenService,
        private stimuliService: StimuliService,
        private userService: UserService,
    ) {
        this.userInfo = this.userService.userInfo;
        this.highlight = this.audioService.highlight;
    }


    ngOnInit(): Subscription {
        this.wrongAnswerCount = 0;

        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        });

        this.oType = this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        });

        this.oStimuli = this.stimuliService.stimuli.subscribe(result => {
            this.stimuli = null;
            if (!_.isEmpty(result)) {
                this.stimuli = result;

                this.showInput = true;
                this.showHelp = false;
                this.wrongAnswerCount = 0;
                this.answerService.help.next(0);

                this.makeInputField();
            }
        });

        this.oAlternatives = this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.question = result;
            }
        });

        this.oTypeSettings = this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        });

        this.oHelp = this.answerService.help.subscribe(result => {
            this.help = result;

            if (result > 0) {
                if (result === 1) {
                    this.showHelp = true;
                    this.showInput = false;
                    this.cdr.detectChanges();
                }

                this.makeInputField();

                return setTimeout(() => {
                    this.showHelp = false;
                    this.showInput = true;
                    this.cdr.detectChanges();
                    this.setFocus();
                }, config.writingTimeout[this.stimuli.levels[0].level]);
            }
            else {
                return of(false);
            }
        });

        this.oFocus = this.screenService.bsSetFocus.subscribe(result => {
            if (result) {
                this.screenService.bsSetFocus.next(false);
                this.setFocus();
            }
        });

        return this.builderService.getType().subscribe();

    }

    ngOnDestroy(): void {
        this.oHelp.unsubscribe();
        this.oUser.unsubscribe();
        this.oType.unsubscribe();
        this.oStimuli.unsubscribe();
        this.oAlternatives.unsubscribe();
        this.oTypeSettings.unsubscribe();
    }

    makeInputField(): void {
        this.answer = [];
        if (this.stimuli && this.stimuli.levels && this.stimuli.levels[0].word) {
            const word = this.stimuli.levels[0].word.split(' ');

            _.forEach(word, w => {
                const obj = {
                    output: w,
                    input: '',
                    isOutput: false,
                    isInput: true
                };
                this.answer.push(JSON.parse(JSON.stringify(obj)));
            });

            this.setFocus();
        }

    }

    checkAnswer(): Subscription | null {
        if (this.answer[0].input && this.answer[0].input.length > 0) {
            const temp = document.activeElement;
            (temp as any).blur();

            this.input = [];

            _.forEach(this.answer, answer => {
                this.input.push(answer.input);
            });

            this.showInput = false;

            return this.answerService.writing(this.input).subscribe();
        }
        else {
            return null;
        }
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }

    makeInput(word): any {
        if (word) {
            const width = ((parseInt(word.length, 10) * 1.60) - 0.01).toFixed(2) + 'ch';
            return {width, background: 'repeating-linear-gradient(90deg, grey 0, grey 1ch, transparent 0, transparent 1.6ch) 0 100%/100% 2px no-repeat'};
        }
    }


    nextField(i): void {
        if (this.answer[i].input.slice(0, 1) === ' ') {
            this.answer[i].input = this.answer[i].input.substring(1);
        }

        if (this.answer[i] && (this.answer[i].input.length === this.answer[i].output.length)) {
            const newField = i + 1;

            if (document.getElementById('input_' + newField)) {
                // @ts-ignore
                document.getElementById('input_' + newField).focus();
            }
        }
    }

    setFocus(): any {
        return setTimeout(() => {
            try {
                // @ts-ignore
                document.getElementById('input_0').focus();
            } catch {
            }
        }, 300);
    }
}
