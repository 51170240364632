import {Component, OnInit} from '@angular/core';

import {ModalService} from '../../services/modal.service';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-tutorials',
    templateUrl: './tutorials.component.html',
    styleUrls: ['./tutorials.component.css']
})
export class TutorialsComponent implements OnInit {

    constructor(private modalService: ModalService,
                private userService: UserService) {
    }

    ngOnInit(): void {
    }

    open(videoUrl): Promise<any> {
        return this.modalService.tutorial(videoUrl);
    }

}


