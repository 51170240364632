<div class="modal-header" style="border-bottom: 0px; background-color: #efefef;">
  <h4 class="modal-title" id="myModalLabel" style="color: #000000; margin: auto;">My Aphasia Coach™ Tutorial</h4>

  <div (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" style="margin:0">
    <span aria-hidden="true">&times;</span>
  </div>

</div>
<div class="modal-body p-0 m-0" style="">
  <div id="vimeo"></div>
</div>
