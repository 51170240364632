import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {BuilderService} from '../../services/builder.service';
import {AnswerService} from '../../services/answer.service';

import {settings} from '../../settings';
import {Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SafeResourceUrl} from '@angular/platform-browser';
import {AudioService} from '../../services/audio.service';
import {ScreenService} from '../../services/screen.service';

@Component({
    selector: 'app-click',
    templateUrl: './click.component.html',
    styleUrls: ['./click.component.css']
})

export class ClickComponent implements OnInit, OnDestroy {
    message;
    settings = settings;
    alternatives: Array<any> = [];
    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;
    highlight;
    showEar = true;
    foo = false;

    private subscriptions = new Subscription();

    constructor(private router: Router,
                private audioService: AudioService,
                private userService: UserService,
                private screenService: ScreenService,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
        this.highlight = this.audioService.highlight;
    }

    ngOnInit(): any {
        this.subscriptions.add(this.userService.bsUser.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!(_.isEmpty(result))) {
                this.user = result;

                if (this.userService.userInfo.loggedIn) {
                    if (!this.userService.userInfo.premiumSubscriber && this.userService.userInfo.basicSubscriber) {
                        const route = ['/modules-videos'];
                        return this.router.navigate(route);
                    }
                    else if (!this.userService.userInfo.basicSubscriber) {
                        const route = ['/account'];
                        return this.router.navigate(route);
                    }
                }
            }
            return false;
        }));

        this.subscriptions.add(this.stimuliService.type.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.stimuli.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.stimuli = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        }));

        this.subscriptions.add(this.audioService.bsShowEar.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            this.showEar = result;
        }));

        return this.builderService.getType().pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe();
    }

    ngOnDestroy(): Promise<any> {
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
        return this.builderService.cleanUp();
    }

    checkAnswer(id): void {
        return this.subscriptions.add(this.answerService.click(id).pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe());
    }

    getAnswerImage(image): SafeResourceUrl {
        return this.screenService.getAnswerImage(image);
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }
}
