// User's settings
export let settings = {
    fullScreen: false,
    isCollapsed: true,
    menuLevel: undefined,
    muteStimuliResponse: false,
    highlightLevel: null as null|number,
    highlightType: null as null|string,
    highlightModule: null as null|string,
};
