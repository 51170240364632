<div class="bg-light-gray">
  <div class="container p-t-30">
    <div class="row">
      <div class="col-sm-12">
        <h1>Create your account</h1>
        <p *ngFor="let alert of alerts">
          <ngb-alert (close)="closeAlert(alert)" [type]="alert.type">{{ alert.message }}</ngb-alert>
        </p>
        <ngb-alert *ngIf="registerInProgress" [dismissible]="false">
          Registering New User...
        </ngb-alert>

        <div *ngIf="showIEWarning" class="alert alert-danger">
          My Aphasia Coach only partially supports Internet Explorer. <a routerLink="/upgrade">How and why you should upgrade</a>.
        </div>

        <form #myForm="ngForm" class="form-horizontal clear m-t-15 col-sm-6 p-0">
          <div class="form-group">
            <!--<label>Name*</label>-->
            <div class="input-group">
              <input #name="ngModel" [(ngModel)]="newUser.name" class="form-control" name="name" placeholder="Your name" required type="text">
              <div class="input-group-append">
                <span *ngIf="name.invalid" class="input-group-text"><span class="material-icons middle">clear</span> </span>
                <span *ngIf="name.valid" class="input-group-text"><span class="material-icons middle">done</span> </span>
              </div>
            </div>
          </div>
          <div class="form-group">

            <!--<label>Email*</label>-->
            <div class="input-group">
              <input #newEmail="ngModel" [(ngModel)]="newUser.email" [email]="true" class="form-control" name="email" placeholder="Enter email" required type="email">
              <div class="input-group-append">
                <span *ngIf="newEmail.invalid" class="input-group-text"><span class="material-icons middle">clear</span> </span>
                <span *ngIf="newEmail.valid" class="input-group-text"><span class="material-icons middle">done</span> </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <!--
                        <label>Password*</label>
            -->
            <div class="input-group">
              <input #password="ngModel" [(ngModel)]="newUser.password" class="form-control" name="password" placeholder="Password" required type="password">
              <div class="input-group-append">
                <span *ngIf="password.invalid" class="input-group-text"><span class="material-icons middle">clear</span> </span>
                <span *ngIf="password.valid" class="input-group-text"><span class="material-icons middle">done</span> </span>
              </div>
            </div>
          </div>


          <button (click)="register()" [disabled]="myForm.invalid" class="btn btn-primary" type="submit">
            GET MY FREE TRIAL
          </button>
        </form>

        <div class="clear m-t-30 h6">
          Already <b>have an account</b>? <a routerLink="/login">Sign in</a>.<br/>
          <br/>
          <!--
                    Are you a <b>speech therapist</b>? <a routerLink="/register-therapist">Click here</a> to register.<br/><br/>
                    <br/><br/>
          -->
          By creating an account, you are agreeing to the <a routerLink="/terms">terms of service</a>.
          <br/><br/>
        </div>


      </div>
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
