<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div *ngIf="user" class="col-sm-12 p-15">
        <button class="btn btn-primary float-right" routerLink="/invite-client">Invite Patient</button>
        <h1>My Patients</h1>

        <div *ngIf="!clientData || !clientData.length">
          You are currently not connected to any patients. Invite them to try My Aphasia Coach, and connect!
        </div>

        <div *ngIf="clientData && clientData.length">
          You are connected to {{clientData.length}} patient<span *ngIf="clientData.length > 1">s</span>.
          <br/>
          <br/>


          <div *ngIf="clientData" class="row" style="display:flex; justify-content:flex-start;  flex-flow: row wrap;">

            <div *ngFor="let user of clientData" class="client-wrapper col-sm-3 ">
              <div class="client">
                <b>{{user.local.name || user.local.email}}</b><br/>

                <a [routerLink]="'/client-progress/' + user._id" class="inline-block">
                  <span class="material-icons middle">insights</span><br/>
                  Progress
                </a>

                <a [routerLink]="'/client-homework/' + user._id" class="m-l-5 inline-block">
                  <span class="material-icons middle">home_work</span><br/>
                  Homework</a>


<!--
                <a [routerLink]="'/csat/' + user._id" class="m-l-5 inline-block">
                  <span class="material-icons middle">people</span><br/>
                  Online</a>
-->

                <a (click)="removeClient(user._id)" class="m-l-5 text-blue inline-block">
                  <span class="material-icons middle">clear</span><br/>
                  Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-my-footer></app-my-footer>
</div>
