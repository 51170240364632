import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-landing-mobile2',
    templateUrl: './landing-mobile2.component.html'
})
export class LandingMobile2Component implements OnInit {


    constructor() {
    }

    ngOnInit(): void {
    }

}
