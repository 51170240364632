import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

@Component({
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit, OnDestroy, AfterViewInit {
    correct = 0;
    inter;

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.inter = setInterval(() => {
                this.correct = this.correct + 1;
                this.cdr.detectChanges();
                if (this.correct === 25) {
                    clearInterval(this.inter);
                }
            }, 60);
        }, 1000);
    }

}
