<div class="b-2 m-15" style="width:400px;">
  <div class="modal-body center fg-blue" id="modal-body">
    <h2 class="text-teal">You did it<br/>
      {{name}}!</h2>
    <h2 class="fg-blue">The End</h2>
    <img src="/images/cat.png" style="width:150px; max-width:100%;"><br/>
<!--
    <h4>You have been promoted to
      <b>{{next.visibleName || next.name}}</b>
    </h4>
    This module can be found under Level {{next.menu.level}} {{worksOn()}}.
-->

  </div>
  <div class="p-b-20 p-r-10">
    <button (click)="activeModal.close('next')" class="btn btn-primary float-right">Next Exercise <span class="material-icons middle">arrow_forward_ios</span> </button>
  </div>
  <br/>
</div>
