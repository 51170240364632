<div class="bg-light-gray">
  <div class="container p-t-30">
    <div class="row" style="justify-content: center">
      <div class="col-sm-6 col-md-6 col-lg-4 p-15" routerLink="/register-client">
        <div class="center border bg-white p-30 h100p">
          <h3>I Have Aphasia</h3>
          <br/><img src="/images/register-client.png" width="150"/><br/>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 p-15" routerLink="/register-therapist">
        <div class="center border bg-white p-30 h100p">
          <h3>I'm a Speech Pathologist</h3>
          <br/>
          <img src="/images/register-therapist.png" width="150"/><br/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 p-30 center">
        Already <b>have an account</b>? <a routerLink="/login">Sign in</a>.<br/>
      </div>
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
