<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 center p-t-15 p-b-10 bg-teal">
        <h1>What is Anomic's aphasia?</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 p-15 center">
        <img src="/images/anomic.png" style="height: 350px;"/><br/>
      </div>
      <div class="col-sm-12 p-15">
        <b>Anomic aphasia</b>is typically considered the “mildest” type of aphasia. Anomia means the person has difficulty finding the right words while speaking or writing. Like all types of aphasia, anomic aphasia can be mild, moderate, severe, or
        very severe. Anomic aphasia may be the first diagnosis, or the person’s aphasia severity resolved over time to become anomic aphasia.
        <br/><br/>
        <b>Anomic aphasia affects the ability to speak and write quickly and effectively, read at a paragraph or higher level, and understand rapid, more complex information</b>. These language skills can be affected to different degrees, but the gap
        between them is less pronounced than in more severe types of aphasia.
        <br/><br/>
        While reading, writing, repetition and comprehension skills have advanced beyond single words or sentences, these skills are still weak when compared to pre-stroke functioning. Treatment for anomic aphasia is often the longest and most
        difficult aspect of aphasia recovery for persons wishing to return to work.
        <br/><br/>
        <b>Speech:</b> Anomic aphasia can present a few different ways depending on its severity. Someone with anomic aphasia may:

        <ul>
          <li>Pause a lot while searching for words (“She, uh….came over to, uh…..”)</li>
          <li>Substitute a specific word with a related word (the ‘forest’ instead of the ‘yard’)</li>
          <li>Begin a sentence and then trail off without finishing it (“We went to …”)</li>
          <li>Substitute a generic word instead of a specific word (‘stuff’ instead of ‘groceries’)</li>
        </ul>

        <b>Writing:</b> Since speaking and writing are both expressive forms of communication, writing issues will be very similar to those outlined in the Speech section. They may write in complete sentences, but will have problems organizing their
        thoughts sequentially. Simple email or other written exchanges will be easier than addressing important points in an exchange with more complex information.
        <br/><br/>
        <b>Reading:</b> Typically between sentence and multi-paragraph length reading, but may read slowly and need to reread a section or page several times. The person has difficulty summarizing the material they read and understanding inferential
        or nuanced vocabulary.
        <br/><br/>
        <b>Understanding:</b> Can appear to be unaffected, but the person has less stamina and a harder time in a large group with multiple speakers and background noise. More complex information and directions will need support.
        How Can My Aphasia Coach Software Help Anomic Aphasia?
        <br/><br/>
        Learners with <b>Anomic aphasia</b> can improve within each language system–reading and retaining paragraphs, understanding and repeating longer and faster sentences, writing longer words and sentences, naming actions and objects more
        quickly, and mor!.
        <br/><br/>
        These exercises are designed to target specific processes that begin at their current skill level, building on existing strengths. <b>To get the fastest results, we recommend that learners with aphasia work independently after they get
        started.</b>
        <br/><br/>


        <b>Where Should I Start My Aphasia Coach?</b>
        <br/>
        Take the <a [routerLink]="'/where-do-i-start'">placement quiz</a> to get recommendations.
        <br/><br/>
        You can also try these exercises:


        <br/><br/>
        <h5>Reading</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/Reading 5">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/reading-5.png">
              <div class="p-10 text-center"><h5>Reading 5</h5>
                Choose words in paragraphs
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/WhyQuestionsReading">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/why-questions-reading.png">
              <div class="p-10 text-center"><h5>Why Questions-Reading</h5>
                Answer "why" questions
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/whMixedReading">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/when.png">
              <div class="p-10 text-center"><h5>Mixed Wh Questions</h5>
                Answer "Wh" questions
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/SentenceConstruction3">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/sentence-construction-3.png">
              <div class="p-10 text-center"><h5>Sentence Construction 3</h5>
                Move the words to form a sentence
              </div>
            </div>
          </div>
        </div>
        <br/><br/>

        <h5>Writing</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing3/grammar3writing">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/grammar3-writing.png">
              <div class="p-10 text-center"><h5>Grammar 3</h5>
                Type the word
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing3/SightWordWriting3">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/sight-word-writing-3.png">
              <div class="p-10 text-center"><h5>Sight Word Writing 3</h5>
                Type the word that you hear
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/DefinitionsWriting3">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/definitions-writing3.png">
              <div class="p-10 text-center"><h5>What’s the Word 3</h5>
                Write the defined word
              </div>
            </div>
          </div>
        </div>

        <br/><br/>
        <h5>Speech</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/speech/NumberReading3">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/number-reading3.png">
              <div class="p-10 text-center"><h5>>Number Reading 3</h5>
                Say the number you see
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/9">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/video-9.png">
              <div class="p-10 text-center"><h5>Apraxia videos 9</h5>
                Sentences for faster pacing
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/speech/SightPhrasesOralReading">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/sight-phrases-oral-reading.png">
              <div class="p-10 text-center"><h5>Sight Phrases Oral Reading</h5>
                Say the words you see
              </div>
            </div>
          </div>
        </div>

        <br/><br/>
        <h5>Listening</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/whMixedListening">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/wh-mixed-listening.png">
              <div class="p-10 text-center"><h5>>Mixed WH Questions</h5>
                Answer "Wh" questions
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/WhyQuestionsListening">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/why-questions-listening.png">
              <div class="p-10 text-center"><h5>Why Questions Listening</h5>
                Answer "why" questions
              </div>
            </div>
          </div>
        </div>
        <br/><br/>

        <h5>Numbers</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing-numbers/NumberWriting4">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/number-writing-4.png">
              <div class="p-10 text-center"><h5>>Number Writing 4</h5>
                Type the number that you hear
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/NumberMatch4">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/number-match-4.png">
              <div class="p-10 text-center"><h5>Number Match 4</h5>
                Choose the number that you hear
              </div>
            </div>
          </div>
        </div>

        <br/><br/>
        Anomic aphasia can improve, even years after a stroke or brain injury. <b>There is no plateau or 6-month time limit–you can always make progress.</b> The best results occur when you practice My Aphasia Coach <b>for at least 30 minutes a
        day</b>.


      </div>

    </div>


  </div>
</div>
<app-my-footer></app-my-footer>
