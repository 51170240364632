import {Component, OnDestroy, OnInit} from '@angular/core';
import {StimuliService} from '../../services/stimuli.service';
import {settings} from '../../settings';
import {Subscription} from 'rxjs';

@Component({
    selector: 'programs',
    templateUrl: './programs.component.html',
    styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit, OnDestroy {
    settings = settings;
    types;
    filter;

    constructor(private stimuliService: StimuliService) {
    }

    ngOnInit(): Subscription {
        return this.stimuliService.getTypesList().subscribe(result => {
            this.types = result;
        });
    }

    ngOnDestroy(): void {
    }

    setLevel(lev): void {
        this.settings.menuLevel = lev;
        this.filter = null;
    }

    selectedLevel(lev): string {
        if (this.settings.menuLevel === lev) {
            return 'level-disabled';
        }
        else {
            return '';
        }
    }

    selectedType(type): string {
        if (this.filter === type) {
            return 'level-disabled';
        }
        else {
            return '';
        }
    }

    showProgram(type, filter): boolean {
        return (type.visibility.web && type.worksOn[filter] && settings.menuLevel && type.menu.level.includes(settings.menuLevel));
    }

    getClass(type): string {
        let returnData = 'p-2 ';

        if (type.menu && type.menu.color) {
            returnData = returnData + ' ' + type.menu.color;
        }
        else {
            returnData = returnData + ' fg-default ';
        }
        if (type.menu && type.menu.backgroundColor) {
            returnData = returnData + ' ' + type.menu.backgroundColor;
        }
        else {
            returnData = returnData + ' bg-default ';
        }
        return returnData;
    }

    getImage(type): string {
        if (type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }
}
