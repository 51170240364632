<div class="container-fluid bg-white">
  <div class="container p-t-10">
    <h3>Your messages</h3>
    <hr/>

    <div [hidden]="user?.messages && user?.messages?.length>0">You have no messages.</div>

    <div *ngIf="user?.messages && user?.messages?.length>0" class="" style="clear: both;">
      <div *ngFor="let message of user.messages" class="m-b-15">
        <div class="" style=" border-bottom: 2px solid black">
          <span class="m-r-10">{{message?.messageDate|date}}</span>
          <span [hidden]="!message.completedMessage">Promotion</span>
          <span class="bold" *ngIf="showConnect(message)">Connection</span>
          <span *ngIf="message.quizMessage || message.clientQuizMessage" class="bold">Quiz results</span>
        </div>

        <div class="float-right" style="margin-top: 5px;">
          <div (click)="deleteMessage(message)" class="m-l-5 pointer btn btn-danger btn-sm float-right">
            Delete
          </div>
          <!--
                  <button (click)="markMessageRead(message)" *ngIf="showMarkRead(message)" class="m-l-5 pointer btn btn-primary float-right btn-sm">
                    Done
                  </button>
          -->
          <button (click)="rejectInvite(message)" *ngIf="showConnect(message)" class="m-l-5 pointer btn btn-secondary float-right btn-sm">
            Reject
          </button>
          <button (click)="acceptInvite(message)" *ngIf="showConnect(message)" class="m-l-5 pointer btn btn-primary float-right btn-sm">
            Accept
          </button>
        </div>
        <div class="p-t-5">

          <div *ngIf="message.message && !message.completedMessage" [class.bold]="showMarkRead(message)">{{message.message}}</div>

          <div *ngIf="message.message && message.completedMessage" [class.bold]="showMarkRead(message)">
            {{message.message}}
            <div (click)="navigatePromotionLink(message)" class="inline text-blue pointer">{{getPromotionName(message)}}.</div>
          </div>
          <div *ngIf="message.acceptInvite">You have accepted the invitation.</div>
          <div *ngIf="message.rejectInvite">You have rejected the invitation.</div>
          <div *ngIf="message.homeworkMessage" [class.h6]="showMarkRead(message)" (click)="navigateHomework(message)" class="pointer">
            You have <span class="text-blue">homework</span>.
          </div>
          <div *ngIf="message.quizMessage">Start with these exercises.</div>
          <div *ngIf="message.clientQuizMessage">Your patient <a [routerLink]="'/client-progress/' + message.connectId"> {{message.name}}</a> has suggested modules.</div>
          <div class="container-fluid">
            <div *ngIf="message.quizModules" style="display:flex; justify-content:left;  flex-flow:row wrap; clear: both" class="row">
              <div *ngFor="let task of message.quizModules" (click)="navigateSuggestion(task, $event)" (auxclick)="navigateSuggestion(task, $event)" class="col-xl-2 col-lg-3 col-md-4 col-sm-6 pointer p-10">
                <div class="p-2 btn-primary" style="height:100%;">
                  <img [src]="getImage(task)" width="100%"/>
                  <div class="p-5 text-center">
                    <h5 class="m-0">
                      {{getName(task)}}
                    </h5>
                    {{getDescription(task)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-my-footer></app-my-footer>
</div>
