import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';


@Component({
    templateUrl: './connect-therapist.component.html',
    styleUrls: ['./connect-therapist.component.css']
})
export class ConnectTherapistComponent implements OnInit, OnDestroy {
    status;
    params = {
        userId: undefined,
        secret: undefined
    };

    constructor(private userService: UserService,
                private activatedRoute: ActivatedRoute) {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.params.userId = params.id;
            this.params.secret = params.value;
        });
    }

    ngOnInit(): Subscription {
        return this.userService.connectTherapist(this.params).subscribe(result => {
            this.status = result;
            this.userService.getUserInfo().subscribe();
        }, result => {
            this.status = result;
            this.userService.getUserInfo().subscribe();
        });
    }

    ngOnDestroy(): void {
    }
}
