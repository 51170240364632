import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {BuilderService} from '../../services/builder.service';

import {settings} from '../../settings';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ScreenService} from '../../services/screen.service';

@Component({
    selector: 'app-repetition',
    templateUrl: './repetition.component.html',
    styleUrls: ['./repetition.component.css']
})

export class RepetitionComponent implements OnInit, OnDestroy {
    settings = settings;
    alternatives = [];
    typeSettings: object = {};
    user;
    userInfo;
    type;
    stimuli;

    private oUser;
    private oType;
    private oStimuli;
    private oAlternatives;
    private oTypeSettings;

    constructor(private router: Router,
                private screenService: ScreenService,
                private userService: UserService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
    }

    ngOnInit(): void {
        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;

                if (this.userService.userInfo.loggedIn) {
                    if (!this.userService.userInfo.premiumSubscriber && this.userService.userInfo.basicSubscriber) {
                        const route = ['/modules-videos'];
                        return this.router.navigate(route);
                    }
                    else if (!this.userService.userInfo.basicSubscriber) {
                        const route = ['/account'];
                        return this.router.navigate(route);
                    }
                }
            }
            return false;
        });

        this.oType = this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        });

        this.oStimuli = this.stimuliService.stimuli.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.stimuli = result;
                return this.builderService.repetition();
            }
            return false;
        });

        this.oAlternatives = this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        });

        this.oTypeSettings = this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        });
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
        this.oType.unsubscribe();
        this.oStimuli.unsubscribe();
        this.oAlternatives.unsubscribe();
        this.oTypeSettings.unsubscribe();
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }
}
