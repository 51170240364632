import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessagingService} from '../../services/messaging.service';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit, OnDestroy {
    message;

    oMessages;

    constructor(private messagingService: MessagingService) {
    }

    ngOnInit(): void {
        this.oMessages = this.messagingService.message.subscribe(result => this.message = result);
    }

    ngOnDestroy(): void {
        this.oMessages.unsubscribe();
    }
}
