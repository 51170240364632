<div class="container m-t-30" style="clear: both">
  <div class="row">
    <div class="col-sm-12">
      <form>
        <textarea (keyup)="keyup()" [(ngModel)]="text" class="h1 border bg-light-gray" id="message" name="message" style="width: 100%; height:400px;"></textarea>
      </form>
    </div>
  </div>

  <div class="row p-t-30">
    <div class="col-sm-12">
      <button (click)="back()" class="btn stop " type="button"><span class="material-icons middle">arrow_back_ios</span> BACK</button>
      <button (click)="clear()" class="btn stop m-l-15" type="button"><span class="material-icons middle">clear</span> CLEAR</button>
    </div>
  </div>
</div>
