import {settings} from '../../settings';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {User} from '../../models/user';
import {BuilderService} from '../../services/builder.service';
import {StimuliService} from '../../services/stimuli.service';
import {ModalService} from '../../services/modal.service';
import {Subscription} from 'rxjs';
import {AudioService} from '../../services/audio.service';
import {ScreenService} from '../../services/screen.service';
import {Location} from '@angular/common';

@Component({
    selector: 'app-therapy',
    templateUrl: './therapy.component.html',
    styleUrls: ['./therapy.component.css']
})
export class TherapyComponent implements OnInit, OnDestroy {
    settings = settings;

    user: User | null = null;
    userInfo;

    type;
    typeSettings;
    stimuli;
    zoom = 1;

    private oTypesSettings;
    private oType;
    private oStimuli;

    urlType;
    urlName;

    constructor(private modalService: ModalService,
                private router: Router,
                private location: Location,
                private screenService: ScreenService,
                private audioService: AudioService,
                private stimuliService: StimuliService,
                private builderService: BuilderService) {
    }

    ngOnInit(): void {
        this.oType = this.stimuliService.bsType.subscribe(result => this.type = result);
        this.oTypesSettings = this.stimuliService.bsTypeSettings.subscribe(result => this.typeSettings = result);
        this.oStimuli = this.stimuliService.stimuli.subscribe(result => this.stimuli = result);
        this.urlType = decodeURI(this.location.path(true).split('/')[2]);
        this.urlName = decodeURI(this.location.path(true).split('/')[3]);

    }

    ngOnDestroy(): Promise<any> {
        this.oStimuli.unsubscribe();
        this.oTypesSettings.unsubscribe();
        this.oType.unsubscribe();
        this.builderService.cleanUp();

        return this.audioService.cleanupAudioObjects();
    }

    playSound(): Subscription {
        return this.audioService.buildAudio(false).subscribe(() => {
            this.screenService.bsSetFocus.next(true);
        });
    }

    isMac(): boolean {
        return navigator.userAgent.indexOf('Mac OS X') > 0;
    }

    exit(): Promise<any> {
        this.audioService.pauseSound();
        this.audioService.audioObjects = [];
        this.audioService.skipListen = true;
        this.audioService.bsStopListen.next(true);

        return this.modalService.progressReport().then(() => this.go(), () => this.go());
    }

    swapListenAgain(): boolean {
        if (this.screenService.bsOnStep.value === 2 && (this.stimuliService.bsType.value?.templateType === 'sst-writing2' || this.stimuliService.bsType.value?.templateType === 'sst-speech2')) {
            return true;
        }
        return false;
    }

    showMainSoundListen(): boolean {
        return this.audioService.showMainSoundListen();
    }

    showMainSoundListenAgain(): boolean {
        return this.audioService.showMainSoundListenAgain();
    }

    showPreSoundListen(): boolean {
        return this.audioService.showPreSoundListen();
    }

    showPreSoundListenAgain(): boolean {
        return this.audioService.showPreSoundListenAgain();
    }

    showPostSoundListen(): boolean {
        return this.audioService.showPostSoundListen();
    }

    showPostSoundListenAgain(): boolean {
        return this.audioService.showPostSoundListenAgain();
    }

    showAnswerSoundListen(): boolean {
        return this.audioService.showAnswerSoundListen();
    }

    showAnswerSoundListenAgain(): boolean {
        return this.audioService.showAnswerSoundListenAgain();
    }

    showAudioObjectsListenAgain(): boolean {
        return this.audioService.showAudioObjectsListenAgain();
    }

    playMainSound(): Promise<any> {
        this.audioService.bsStopListen.next(true);
        return this.audioService.playMainSound();
    }

    playWhichWordIs(): any {
        return this.audioService.buildAudio().subscribe();
    }

    playAnswerSound(): Promise<any> {
        return this.audioService.playAnswerSound();
    }

    playPreSound(): Promise<any> {
        return this.audioService.playPreSound();
    }

    playPostSound(): Promise<any> {
        return this.audioService.playPostSound();
    }

    playAudioObjects(): Promise<any> {
        return this.audioService.playAudioObjects(0);
    }

    showTutorial(): boolean {
        if (this.builderService.type.templateType === 'numbers') {
            return false;
        }
        else if (this.type && this.type.intro && this.type.intro.length > 0) {
            return true;
        }
        return false;
    }

    playTutorial(): any {
        return this.audioService.playIntro(0).then(() => {
        });
    }

    playSstTutorial(): any {
        this.audioService.bsStopListen.next(true);
        return this.audioService.playSstIntro(0);
    }

    showSstTutorial(): any {
        if (this.urlType === 'sst-writing2' || this.urlType === 'sst-speech2') {
            return true;
        }
    }

    showNumbersTutorial(): any {
        if (this.urlType === 'numbers') {
            return true;
        }
    }

    playNumbersTutorial(): any {
        this.audioService.bsStopListen.next(true);
        return this.audioService.playNumbersTutorial(0);
    }

    playNumbers(): any {
        this.audioService.sListenAgain.next(true);
    }


    more(): void {
        this.zoom = this.zoom * 1.1;
    }

    less(): void {
        this.zoom = this.zoom / 1.1;
    }

    private go(): Promise<any> {
        this.builderService.cleanUp();
        return this.router.navigate(['/modules']);
    }

}
