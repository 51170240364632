<div class="container-fluid bg-white p-b-30" id="payment-1">

  <a href="/"><img class="p-t-15 p-l-15" src="/images/logo2.png" width="300"/></a>

  <div class="container" id="fp-2">
    <div class="" id="fp-3">
      <br/>
      <br/>
      <h1 id="fp-4">Software for Faster Aphasia Progress</h1>
      <br/>
      <h3>Expert therapy you can do from home for stroke and brain injury
rehabilitation</h3>
      <br/>
      <br/>


      <a [hidden]="user" [routerLink]="'/register'" class="btn trial p-30 h3">
        Get started for free
      </a>

      <a [hidden]="!user" [routerLink]="'/modules'" class="btn trial p-30 h3">
        Get started for free
      </a>
    </div>
  </div>
</div>

<div class="container-fluid bg-teal">
  <div class="container p-30 center">
  </div>
</div>

<div class="container-fluid bg-white">
  <div class="container">
    <div class="center">
      <svg class="p-b-30" style="width:100px" viewBox="0 0 42 21">
        <polygon class="triangle" points="0,0 42,0 21,21"/>
      </svg>
    </div>
  </div>
</div>




<div class="container-fluid bg-white">
<div class="container bg-white p-t-20">
  <div *ngIf="!user" class="row">
    <div class="col-sm-12">
      <h1>Please log in or register</h1>
      <button (click)="login()" class="btn btn-primary m-r-10" type="button">LOG IN</button>
      <button (click)="register()" class="btn btn-primary" type="button">REGISTER</button>
    </div>
  </div>

  <div *ngIf="user" class="row">
    <div class="col-sm-12">
      <h1>Payments</h1>
      Your credit card information is managed through <a href="https://www.stripe.com/about" target="_blank">Stripe</a>.
      My Aphasia Coach does not have access to your credit card information.
      Your card will be charged according to subscription plan you choose.
      <br/><br/>

      <div *ngIf="user?.payment?.autoRenewing">
        Your subscription type is {{subscriptionType(user?.payment?.subscriptionType)}}
        <span *ngIf="user.payment && user.payment.subscriptionStarted">, and it was started on {{user.payment.subscriptionStarted|date:'medium'}}</span>
        <br/><br/>
        It renews on {{user?.payment?.subscriptionExpires|date:'medium'}}.
        <br/><br/>
        To change subscription type, select the new type and enter your credit card number. Your existing subscription will be prorated.
      </div>

      <div *ngIf="user?.googlePay?.autoRenewing">
        Your subscription type is {{subscriptionType(user?.googlePay?.productIdentifier)}}
        <span *ngIf="user.googlePay && user.googlePay.transactionDate">, and it was started on {{user.googlePay.transactionDate|date:'medium'}}</span>
        <br/><br/>
        It renews on {{user?.googlePay?.subscriptionExpires|date:'medium'}}.
        <br/><br/>
        To change subscription type, select the new type and enter your credit card number. Your existing subscription will be prorated.

      </div>
      <div *ngIf="user?.applePay?.autoRenewing">
        Your subscription type is {{subscriptionType(user?.applePay?.productIdentifier)}}
        <span *ngIf="user.applePay && user?.applePay?.transactionDate">, and it was started on {{user?.applePay?.transactionDate|date:'medium'}}</span>
        <br/><br/>
        It renews on {{user?.applePay?.subscriptionExpires|date:'medium'}}.
        <br/><br/>
        To change subscription type, select the new type and enter your credit card number. Your existing subscription will be prorated.

      </div>


      <div *ngIf="!user?.payment?.autoRenewing && user?.payment?.subscriptionType">
        Your subscription type is {{subscriptionType(user?.payment?.subscriptionType)}}, and it
        <span *ngIf="isExpired(user?.payment?.subscriptionExpires)"> expired </span>
        <span *ngIf="!isExpired(user?.payment?.subscriptionExpires)"> expires </span>
        on {{user?.payment?.subscriptionExpires|date:'medium'}}.
      </div>
      <div *ngIf="!user?.googlePay?.autoRenewing && user?.googlePay?.productIdentifier">
        Your subscription type is {{subscriptionType(user?.googlePay?.productIdentifier)}}, and it
        <span *ngIf="isExpired(user?.googlePay?.subscriptionExpires)"> expired </span>
        <span *ngIf="!isExpired(user?.googlePay?.subscriptionExpires)"> expires </span>
        on {{user?.googlePay?.subscriptionExpires|date:'medium'}}.
      </div>
      <div *ngIf="!user?.applePay?.autoRenewing && user?.applePay?.productIdentifier">
        Your subscription type is {{subscriptionType(user?.applePay?.productIdentifier)}}, and it
        <span *ngIf="isExpired(user?.applePay?.subscriptionExpires)"> expired </span>
        <span *ngIf="!isExpired(user?.applePay?.subscriptionExpires)"> expires </span>
        on {{user?.applePay?.subscriptionExpires|date:'medium'}}.
      </div>

      <div *ngIf="user?.payment?.delinquent" class="">
        <div class="p-10 bg-red bold inline-block m-b-15">
          There is a problem with your card.
        </div>

        <div class="m-b-15">
          <a class="btn btn-primary m-r-15" routerLink="/change-card/">Change Credit Card</a>
          <a class="btn btn-secondary" routerLink="/contact/">Contact Support</a>
        </div>
      </div>
    </div>
  </div>

  <form #subscriptionForm="ngForm" class="form-horizontal m-t-15 container-fluid" id="payment-form" role="form">
    <div class="row">
      <div class="col-sm-4 p-5">
        <div (click)="!(!isExpired(user?.payment?.subscriptionExpires || user?.googlePay?.subscriptionExpires || user?.applePay?.subscriptionExpires) && (user?.payment?.subscriptionType === 'vid' || user?.googlePay?.productIdentifier === 'gbasic' || user?.applePay?.productIdentifier === 'iBasic' ))  && formData.subscriptionType = 'vid'" [ngClass]="{ 'glow' : formData.subscriptionType === 'vid' }" class="pricing-1 pointer">
          <div class="pricing-1">
            <div  class="text-center p-15 pricing-4 bg-teal" [ngClass]="{ 'bg-disable' : (!isExpired(user?.payment?.subscriptionExpires || user?.googlePay?.subscriptionExpires || user?.applePay?.subscriptionExpires) && (user?.payment?.subscriptionType === 'vid' || user?.googlePay?.productIdentifier === 'gbasic' || user?.applePay?.productIdentifier === 'iBasic' )) }" >
              <h4 class="p-t-10 p-b-10">Video Subscription</h4>

              <h1>$9.99
                <wbr>
                /Month
              </h1>
            </div>
            <div class="uppercase p-10">
              <table>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">1700+ videos to speak in words and sentences</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Messages</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Connect with your speech therapist</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Save your videos to my videos</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-gray">close</span></td>
                  <td class="text-gray p-5">50,000+ exercises for reading, writing, and understanding</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-gray">close</span></td>
                  <td class="text-gray p-5">Promotion to new levels and exercises</td>
                </tr>
              </table>
            </div>
            <button 
            *ngIf="!isExpired(user?.payment?.subscriptionExpires || user?.googlePay?.subscriptionExpires || user?.applePay?.subscriptionExpires) && (user?.payment?.subscriptionType === 'vid' || user?.googlePay?.productIdentifier === 'gbasic' || user?.applePay?.productIdentifier === 'iBasic' ); else activeButton" 
            class="btn-disabled" 
            >
            Active
          </button>
          
          <ng-template #activeButton>
            <button 
              class="trial2" 
              routerLinkActive="active">
              Select
            </button>
          </ng-template>
          
          </div>
        </div>
      </div>



      <div class="col-sm-4 p-5">
        <div (click)="!(!isExpired(user?.payment?.subscriptionExpires || user?.googlePay?.subscriptionExpires || user?.applePay?.subscriptionExpires) && (user?.payment?.subscriptionType === 'sft' || user?.googlePay?.productIdentifier === 'gpremium' || user?.applePay?.productIdentifier === 'iPremium' )) && formData.subscriptionType = 'sft'" [ngClass]="{ 'glow' : formData.subscriptionType === 'sft' }" class="pricing-1  pointer">
          <div class="pricing-1">
            <div class="text-center p-15 pricing-4 bg-teal" [ngClass]="{'bg-disable' : (!isExpired(user?.payment?.subscriptionExpires || user?.googlePay?.subscriptionExpires || user?.applePay?.subscriptionExpires) && (user?.payment?.subscriptionType === 'sft' || user?.googlePay?.productIdentifier === 'gpremium' || user?.applePay?.productIdentifier === 'iPremium' )) }" >
              <h4 class="p-t-10 p-b-10">Monthly Subscription</h4>
              <h1>
                $19.99
                <wbr>
                /Month
              </h1>
            </div>
            <div>
              <div class="uppercase p-10">
                <table>
                  <tr>
                    <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                    <td class="p-5">1700+ videos to speak in words and sentences</td>
                  </tr>
                  <tr>
                    <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                    <td class="p-5">Messages</td>
                  </tr>
                  <tr>
                    <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                    <td class="p-5">Connect with your speech therapist</td>
                  </tr>
                  <tr>
                    <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                    <td class="p-5">Save your videos to my videos</td>
                  </tr>
                  <tr>
                    <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                    <td class="p-5">50,000+ exercises for reading, writing, and understanding</td>
                  </tr>
                  <tr>
                    <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                    <td class="p-5">Promotion to new levels and exercises</td>
                  </tr>
                </table>
              </div>
            </div>
            <button 
            *ngIf="!isExpired(user?.payment?.subscriptionExpires || user?.googlePay?.subscriptionExpires || user?.applePay?.subscriptionExpires) && (user?.payment?.subscriptionType === 'sft' || user?.googlePay?.productIdentifier === 'gpremium' || user?.applePay?.productIdentifier === 'iPremium' ); else activeButton" 
            class="btn-disabled" 
        >
        Active
          </button>
          
          <ng-template #activeButton>
            <button
              class="trial2" 
              routerLinkActive="active">
              Select
            </button>
          </ng-template>
          </div>
        </div>
      </div>

      <div class="col-sm-4 p-5">
        <div (click)="!(!isExpired(user?.payment?.subscriptionExpires || user?.googlePay?.subscriptionExpires || user?.applePay?.subscriptionExpires) && (user?.payment?.subscriptionType === 'sftvidyearly' || user?.googlePay?.productIdentifier === 'gyearly' || user?.applePay?.productIdentifier === 'iYearly' ))  && formData.subscriptionType = 'sftvidyearly'" [ngClass]="{ 'glow' : formData.subscriptionType === 'sftvidyearly' }" class="pricing-1  pointer">
          <div class="pricing-1">
            <div class="text-center p-15 bg-teal" [ngClass]="{'bg-disable' : (!isExpired(user?.payment?.subscriptionExpires || user?.googlePay?.subscriptionExpires || user?.applePay?.subscriptionExpires) && (user?.payment?.subscriptionType === 'sftvidyearly' || user?.googlePay?.productIdentifier === 'gyearly' || user?.applePay?.productIdentifier === 'iYearly' )) }">
              <h4 class="p-t-10 p-b-10">Yearly Subscription</h4>
              <h1>$199
                <wbr>
                /Year
              </h1>
            </div>
            <div class="uppercase p-10">
              <table>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">1700+ videos to speak in words and sentences</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Messages</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Connect with your speech therapist</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Save your videos to my videos</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">50,000+ exercises for reading, writing, and understanding</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Promotion to new levels and exercises</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5"><b>2 months free</b></td>
                </tr>
              </table>
            </div>
            <button 
            *ngIf="!isExpired(user?.payment?.subscriptionExpires || user?.googlePay?.subscriptionExpires || user?.applePay?.subscriptionExpires) && (user?.payment?.subscriptionType === 'sftvidyearly' || user?.googlePay?.productIdentifier === 'gyearly' || user?.applePay?.productIdentifier === 'iYearly' ); else activeButton" 
            class="btn-disabled" 
            routerLinkActive="active">
            Active
          </button>
          
          <ng-template #activeButton>
            <button 
              class="trial2" >
              Select
            </button>
          </ng-template>
          </div>
        </div>
      </div>
      <span *ngIf="user && user?.payment && user?.payment?.autoRenewing" class="p-t-10 p-l-5">
          <input [(ngModel)]="formData.subscriptionType" id="subscriptionCancel" name="subscriptionCancel" required type="radio" value="cancel">
            <label class="m-l-5" for="subscriptionCancel"> Cancel subscription</label>
          <br/>
      </span>
    </div>


    <div class="row p-t-15">
      <div class="col-sm-9 p-15 p-l-5">
        <div class="border p-15">
          <h4>
            <span class="material-icons middle">lock</span>
            Payment
          </h4>
          <div [hidden]="formData.subscriptionType === 'cancel'">
            Cardholder's Name<br/>
            <input [(ngModel)]="formData.nameOnCard" autofocus class="form-control" data-stripe="number" name="name" placeholder="Name on Card" required required type="text"/>
            <br/>
            Card Number<br/>
            <div class="border p-15 m-b-15">
              <div id="card-element"></div>
            </div>

            <div [hidden]="formData.subscriptionType === 'cancel'" class="">
              <button (click)="getCardToken()" class="btn btn-teal" type="button">Pay Now</button>
            </div>
          </div>

          <div [hidden]="formData.subscriptionType !== 'cancel'" class="">
            Your subscription will be cancelled.<br/><br/>
            <button (click)="getCardToken()" class="btn btn-teal" type="button">Cancel</button>
          </div>

        </div>
      </div>
      <div class="col-sm-3 p-15 p-r-5">
        <div class="border p-15 h100p">
          Order Summary
          <hr>
          <div *ngIf="formData.subscriptionType === 'vid'">Video Package/Monthly<br/>
            $9.99
          </div>
          <div *ngIf="formData.subscriptionType === 'sft'">Premium Package/Monthly<br/>
            $19.99
          </div>
          <div *ngIf="formData.subscriptionType === 'sftvidyearly'">Premium Package/Yearly<br/>
            $199
          </div>
          <br/>
          Your subscription will start immediately.&nbsp; It will renew automatically unless you cancel your subscription.
        </div>
      </div>
    </div>

    <div class="row" style="display:none;">
      <p class="payment-errors"></p>
    </div>

  </form>

  <div *ngIf="status.message" [ngClass]="{'alert-danger':status.error}" class="m-t-15 alert alert-success" id="messages">
    {{ status.message}}
  </div>

  <div *ngIf="status.error" class="m-t-15 alert alert-danger" id="messages">
    {{ status.error}}
  </div>

  <div *ngIf="waitMessage" class="m-t-15 alert alert-info" id="waitmessage">{{ waitMessage }}</div>

</div>
<app-my-footer></app-my-footer>
</div>
