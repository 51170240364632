<div class="container m-t-30" style="clear: both">

  <div class="row">
    <div class="col-sm-12" style="display:flex; justify-content:left;  flex-flow: row wrap;">

      <div *ngFor="let card of cards, let index = index" class="outer" draggable="true">
        <div class="inner border">
          <input (click)="select(card)" [(ngModel)]="card.cardValue" [class.correct]="correct(card)" [class.selected]="selected(card)" class="inputBox" type="text">
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-sm-12">
      <button (click)="addCard()" class="btn btn-primary m-l-15" type="button">ADD CARD</button>
      <button (click)="clearCards()" class="btn btn-primary m-l-15" type="button">CLEAR CARDS</button>
      <button (click)="sendAlternatives()" class="btn btn-primary m-l-15" type="button">SEND</button>
      <button (click)="back()" class="btn stop m-l-15" type="button">BACK</button>
    </div>
  </div>
</div>
