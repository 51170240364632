import {Subscription} from 'rxjs';
import * as _ from 'lodash';

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {UserService} from '../../services/user.service';
import {AnswerService} from '../../services/answer.service';
import {BuilderService} from '../../services/builder.service';
import {StimuliService} from '../../services/stimuli.service';
import {CsatService} from '../../services/csat.service';
import {SafeResourceUrl} from '@angular/platform-browser';
import {AudioService} from '../../services/audio.service';
import {ScreenService} from '../../services/screen.service';

@Component({
    templateUrl: './csat-writing.component.html',
    styleUrls: ['./csat-writing.component.css']
})
export class CsatWritingComponent implements OnInit, OnDestroy {
    alternatives: Array<any> = [];
    client;
    onLevel = 1;
    settings;
    stimuli;
    type;
    typeId;
    typeSettings: any = {};
    user;
    userInfo;
    private subscriptions = new Subscription();

    constructor(private csatService: CsatService,
                private userService: UserService,
                private cdr: ChangeDetectorRef,
                private audioService: AudioService,
                private screenService: ScreenService,
                private location: Location,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.typeId = decodeURI(this.location.path(true).split('/')[4]);
    }

    ngOnInit(): void {
        this.csatService.outgoing.writing = true;
        this.builderService.bsOnLevel.next(this.onLevel);

        this.subscriptions.add(this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
                this.csatService.outgoing.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.stimuli.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.stimuli = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.getType(this.typeId).subscribe(() => {
            this.builderService.type = this.stimuliService.bsType.value;
            return this.get();
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
                this.csatService.outgoing.typeSettings = result;
            }
        }));

        this.audioService.skipPlay = true;
    }

    ngOnDestroy(): void {
        this.stimuliService.unsubscribe.next();
this.stimuliService.unsubscribe.complete();
this.subscriptions.unsubscribe();
    }

    sendAnswer(id): any {
        this.csatService.outgoing.selection = id;
        return this.csatService.send();
    }

    send(): any {
        if (this.stimuli) {
            this.csatService.outgoing.image = this.stimuli.image;
            this.csatService.outgoing.alternatives = (this.alternatives as any);
            this.csatService.outgoing.description = this.stimuli.description;
            return this.csatService.send();
        }
    }

    sendText(): any {
        if (this.stimuli) {
            this.csatService.outgoing.correctAnswer = this.stimuli.levels[0].word;
            return this.csatService.send();
        }
    }

    sendImage(): any {
        if (this.stimuli) {
            this.csatService.outgoing.image = this.stimuli.image;
            return this.csatService.send();
        }
    }

    get(): any {
        this.csatService.outgoing.clear = false;
        this.builderService.onLevel = this.onLevel;
        return this.builderService.getStimuli().subscribe();
    }

    getStimuli(): Promise<Subscription> {
        return this.clear().then(() => {
            this.csatService.outgoing.clear = false;
            this.builderService.onLevel = this.onLevel;
            return this.builderService.getStimuli().subscribe();
        });
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }

    text(): any {
        return this.csatService.incoming.answer;
    }

    clear(): Promise<any> {
        this.stimuli = null;
        this.csatService.outgoing.correctAnswer = null;
        this.csatService.outgoing.clear = true;
        return this.csatService.send();
    }

    back(): Promise<any> {
        this.csatService.outgoing.image = null;
        this.csatService.outgoing.alternatives = [];

        return this.csatService.send().then(() => {
            return this.location.back();
        });

    }

}
