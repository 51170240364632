import * as _ from 'lodash';

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit, OnDestroy {
    types = [];
    dateSort = true;

    @Input() tasks;
    @Input() user;

    private oTypes;

    constructor(private stimuliService: StimuliService,
                private userService: UserService,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            this.types = result;

            _.forEach(this.user.tasks, task => {
                task.name = this.getVisibleName(task.task);
            });

            this.user.tasks = _.reverse(_.sortBy(this.user.tasks, ['modified']));
        });
    }

    ngOnDestroy(): void {
        this.oTypes.unsubscribe();
    }

    getVisibleName(name): string {
        const match = _.find(this.types, {name});
        if (match && (match as any).visibleName) {
            return (match as any).visibleName;
        }
        else {
            return name;
        }
    }

    getLinkByName(name): string | void {
        const type: any = _.find(this.types, {name});
        if (type) {
            return '/therapy/' + (type as any).templateType + '/' + type.name;
        }
    }

    statsWidth(lev): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle((lev.answeredCorrectly / lev.answered * 100).toFixed(2));
    }

    toggleSort(): boolean {
        this.dateSort = !this.dateSort;

        if (!this.dateSort) {
            this.user.tasks = _.sortBy(this.user.tasks, ['name']);
        }
        else {
            this.user.tasks = _.reverse(_.sortBy(this.user.tasks, ['modified']));
        }
        return this.dateSort;
    }

    stroke(task): string {
        const type: any = _.find(this.types, {name: task.task});
        if (type) {
            if (type.worksOn.reading) {
                return '#22FF00';
            }
            else if (type.worksOn.writing) {
                return '#ce4b99';
            }
            else if (type.worksOn.comprehension) {
                return '#4444FF';
            }
            else {
                return '#EEEE22';
            }
        }
        else {
            return '#f0f0f0';
        }
    }

    noStatsRecorded(task): boolean {
        let current;
        if (task.onLevel) {
            current = _.find(task.levels, ['level', task.onLevel]);
        }

        if (!current) {
            return true;
        }
        else {
            return false;
        }
    }

}
