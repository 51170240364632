// @ts-nocheck
import * as _ from 'lodash';

import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';

import {UserService} from '../../services/user.service';
import {BuilderService} from '../../services/builder.service';

import {config} from '../../config';
import {CsatService, IMessage} from '../../services/csat.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-csat-client',
    templateUrl: './csat-client.component.html',
    styleUrls: ['./csat-client.component.css']
})
export class CsatClientComponent implements OnInit, OnDestroy, AfterViewInit {
    config;
    ws: WebSocket;
    therapistId;
    therapist;
    incoming: IMessage;
    writingInput;
    private subscriptions: Subscription = new Subscription();

    constructor(private csatService: CsatService,
                private router: Router,
                private userService: UserService,
                private cdr: ChangeDetectorRef,
                private builderService: BuilderService,
                private location: Location,
                private zone: NgZone) {
        this.config = config;
        this.therapistId = this.location.path(true).split('/')[2];
        this.incoming = this.csatService.incoming;
        this.csatService.outgoing.clientMessage = true;

    }

    ngOnInit(): void {
        this.csatService.outgoing.clientMessage = true;

        this.csatService.ws = new $WebSocket(this.config.webSocket, null, {reconnectIfNotNormalClose: true, initialTimeout: 300, maxTimeout: 10000}, 'arraybuffer');

        this.csatService.ws.getDataStream().subscribe((msg: MessageEvent) => {
                return this.csatService.receiveMessage(msg).then(() => {
                    this.incoming = this.csatService.incoming;

                    if (this.incoming.clear) {
                        this.writingInput = '';
                        this.incoming.typeSettings = null;
                        this.incoming.image = null;
                        this.incoming.alternatives = null;
                        this.incoming.correctAnswer = null;
                    }
                });
            },
            (err) => {
                console.error(new Error(err));
            });

        this.csatService.ws.onOpen(() => {
            return this.csatService.announce();
        });

        this.subscriptions.add(this.userService.accessList().subscribe(result2 => {
            const therapist = _.find(result2, ['_id', this.therapistId]);
            this.therapist = therapist.local.name + ', (' + therapist.local.email + ')';
            this.csatService.outgoing.to = this.therapistId;

        }));
    }

    ngOnDestroy(): void {
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
        this.csatService.ws.close();
    }

    ngAfterViewInit() {
    }

    sendAnswer(answer) {
        this.csatService.outgoing.answer = answer;
        return this.csatService.send();
    }

    clickAnswer(id) {
        if (this.csatService.outgoing.selection !== id) {
            this.csatService.outgoing.selection = id;
        }
        else {
            this.csatService.outgoing.selection = null;
        }
        return this.csatService.send();
    }

    selected(id) {
        if (this.csatService.outgoing.selection && this.csatService.outgoing.selection == id) {
            return true;
        }
    }

    correct(id) {
        if (this.csatService.incoming.selection && this.csatService.incoming.selection == id) {
            return true;
        }
    }

    getAnswerImage(image) {
        return this.screenService.getAnswerImage(image);
    }

    getStimuliImage() {
        return this.screenService.getStimuliImage();
    }

    back() {
        return this.location.back();
    }


    drop(el) {
        const id = el.dataTransfer.getData('Text');
        if (!el.target.classList.contains('scrambling-word')) {
            if (document.getElementById(id)) {
                el.target.appendChild(document.getElementById(id));
            }
        }

        this.csatService.send();
        el.stopPropagation();
        event.preventDefault();
    }

    dragStart(el) {
        if (el && el.target) {
            el.dataTransfer.effectAllowed = 'move';
            el.dataTransfer.setData('Text', el.target.getAttribute('id'));
            el.dataTransfer.setDragImage(el.target, 30, 20);
        }
        return true;
    }

    dragEnter() {
        event.preventDefault();
        return true;
    }

    dragOver() {
        return false;
    }

    dragDropReplacement(id) {
        const selected = document.getElementsByClassName('scrambling-selected');
        const clicked = document.getElementById(id);
        let setClass = true;

        if (clicked.children.length > 0 && clicked.children[0] === selected[0]) {
            if ((clicked.children[0] as any).classList) {
                clicked.children[0].classList.remove('scrambling-selected');
            }
            setClass = false;
        }

        if (selected.length > 0 && (clicked as any).children.length === 0) {
            clicked.appendChild(selected[0]);
            setClass = false;
        }

        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        _.forEach(document.getElementsByClassName('scrambling-start'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        // Highlight selected
        if (setClass && (clicked.children[0] !== selected[0])) {
            const elem = document.getElementById(id).children;
            let word;

            _.forEach(elem, (child) => {
                (child as any).classList.add('scrambling-selected');
                word = (child as any).innerText;
            });
        }


        return this.csatService.send();

    }

}
