import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

import {StimuliService} from '../../services/stimuli.service';

@Component({
    selector: 'module-completed',
    templateUrl: './module-completed.component.html',
    styleUrls: ['./module-completed.component.css']
})
export class ModuleCompletedComponent implements OnInit, OnDestroy {
    type;
    urlName;

    constructor(private router: Router,
                private location: Location,
                private stimuliService: StimuliService) {
    }

    ngOnInit(): void {
        this.urlName = this.location.path(true).split('/')[2];
        this.type = _.find(this.stimuliService.bsTypes.value, ['name', this.urlName]);
    }

    ngOnDestroy(): void {
    }

}
