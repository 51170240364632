import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-mobile-warning',
    templateUrl: './mobile-warning.component.html'
})
export class MobileWarningComponent implements OnInit {
    deviceIsIOS;
    deviceIsAndroid;

    constructor() {
    }

    ngOnInit(): void {
        this.deviceIsAndroid = navigator.userAgent.indexOf('Android') > 0;
        this.deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent);
    }
}
