<div class="container-fluid bg-white p-t-20">
<div class="container">
  <div class="row">
    <div *ngIf="user" class="col-sm-12 p-15">
      <div (click)="myHomework()" *ngIf="hasHomework()" class="inline-block pointer p-10" style="width:50%; min-width:120px;">
        <div [class]="'p-10 h100p pointer btn-primary'">
          <h3 class="text-center">
            <span class="material-icons h1">assignment</span><br/>
            Home<wbr>work
          </h3>
        </div>
      </div>
      <div [routerLink]="'/my-videos'" *ngIf="hasVideos()" class="inline-block pointer p-10" style="width:50%; min-width:120px;">
        <div [class]="'p-10 h100p pointer btn-primary'">
          <h3 class="text-center">
            <span class="material-icons h1">video_library</span><br/>
            My Videos</h3>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="tasks && tasks.length>0" class="container">
    <h2 class="center">My Tasks</h2>
    <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
      <div (click)="navigate(type, $event)" (auxclick)="navigate(type, $event)" *ngFor="let type of tasks" class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
        <div [class]="getClass(type)" style="height:100%;" [class.pulse4]="highlightModule(type.name)">
          <img [src]="getImage(type)" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              {{type.visibleName || type.name}}
            </h5>
            {{type.description}}
            <span *ngIf="isAlpha(type)" class="text-danger bg-white">Alpha</span>
            <span *ngIf="isBeta(type) && !isAlpha(type)" class="text-danger bg-white">Beta</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<app-my-footer></app-my-footer>
</div>
