import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {config} from '../../config';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'progress-modal',
    templateUrl: './promotion-modal.component.html'
})
export class PromotionModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() level;
    user = '';

    constructor(public activeModal: NgbActiveModal,
                public userService: UserService) {
    }

    ngOnInit(): void {
        this.user = (this.userService.bsUser.getValue() as any).local.name;
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): any {
        return setTimeout(() => {
            return this.activeModal.close();
        }, config.audioWait * 3);
    }

}
