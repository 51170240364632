<div class="container-fluid bg-white" id="about-us">
  <a href="/"><img class="p-t-15 p-l-15" src="/images/logo2.png" width="300"/></a>

  <div class="container" id="fp-2">
    <div class="" id="fp-3">
      <br/>
      <br/>
      <h1 id="fp-4">Software for Faster Aphasia Progress</h1>
      <br/>
      <h3>Expert therapy you can do from home for stroke and brain injury
rehabilitation</h3>
      <br/>
      <br/>


      <div class="inline-block">
      <a [hidden]="user" [routerLink]="'/register'" class="btn trial p-30 h3">
        Get started for free
      </a>

      <a [hidden]="!user" [routerLink]="'/modules'" class="btn trial p-30 h3">
        Get started for free
      </a>
      <br/><div class="text-small center">* No Credit Card Required</div>
    </div>
    </div>
  </div>
</div>


<div class="container-fluid bg-teal">
  <div class="row">
    <div class="col-sm-12 center p-t-10">
      <h1>About Us</h1>
    </div>
  </div>
</div>


<div class="container-fluid bg-white">
  <div class="center">
    <svg class="p-b-30" style="width:100px" viewBox="0 0 42 21">
      <polygon class="triangle" points="0,0 42,0 21,21"/>
    </svg>
  </div>

  <div class="container p-t-20" style="display: flex; flex-direction: row;">
    <iframe style="float:left;" class="p-r-25" width="640" height="360" src="https://www.youtube.com/embed/ALlNR3MqunI" title="Meet Dr. Bartels and My Aphasia Coach" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen description="Meet Dr. Bartels and My Aphasia Coach"></iframe>
    <div style="width: 50%" class="p-t-50">
      <p style=" text-align: justify!important;">
        Hi, I'm Dr. Bartels, and I created the My Aphasia Coach app and software as homework to help my intensive program clients. I realized that the more my clients worked on their own for homework, the faster we could tackle other skills in-person to make better progress. We use this software every day at The Aphasia Center, and I'm excited to now make it available for you to use at home. You get the exact same therapy activities as our in-house clients.
      </p>
    </div>
  </div>
  <div class="container p-t-20">
    <br clear="both"/>
    <br/>
    <b>Why create my own software?</b><br/>
    <p class="m-0" style=" text-align: justify!important;">
      When we started this project in 2017, there weren't many options for apps or software for aphasia. We used a few here and there at the Center, but no apps did quite what I needed them to do. There were a lot of exercises that didn't actually contribute to a client's progress and were essentially busy-work. Those apps also needed a lot of therapist intervention to adjust exercises for individual clients, which slowed the progress down for everyone. That's why I made our software do all of the work automatically--each exercise begins at an introductory level and automatically adjusts to your personalized level so that you're always learning and never just pushing buttons. <b>Each exercise is also available in each Aphasia Level, so you can work on a skill and advance to a higher level within the same software.</b>
      <br/>
      <br/>
      Additionally, it was very important to me to make sure our software and app would always be free for Speech Therapists. I was frustrated when I wasn't sure if an app was right for a client and had to buy it first to try it out. My Aphasia Coach also contains all language skills areas and levels, so you don't have to buy several different apps to work on different skills &mdash;you can use it will all of your patients
    </p>

    <!--    <div class="row">
          <div class="col-sm-12 center p-t-10 bg-teal">
            <h1>What is aphasia?</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 p-15">
            Aphasia is a language disorder.

          </div>
        </div>-->


  </div>
</div>
<app-my-footer></app-my-footer>
