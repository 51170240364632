import * as _ from 'lodash';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'invoices',
    templateUrl: './invoices.component.html'
})
export class InvoicesComponent implements OnInit, OnDestroy {
    userInfo;
    user;
    invoices;

    constructor(private userService: UserService) {
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe((result) => {
            if (!_.isEmpty(result)) {
                this.user = result;

                if (this.user && this.user.payment && this.user.payment.subscriptionType) {
                    return this.userService.invoices().subscribe(result2 => {
                        if (result2.invoices && result2.invoices.data) {
                            this.invoices = result2.invoices.data;
                        }
                    });
                }
            }
            return false;
        });
    }

    ngOnDestroy(): void {
    }

    logout(): void {
        this.userService.logout().subscribe();
    }

    subscriptionType(type): string {
        return this.userService.productNames[type];
    }

}

