import {settings} from '../../settings';
import {User} from '../../models/user';
import {UserService} from '../../services/user.service';
import {VideoService} from '../../services/video.service';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';


import * as _ from 'lodash';


import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {StimuliService} from '../../services/stimuli.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'my-videos',
    templateUrl: './my-videos.component.html'
})

export class MyVideosComponent implements OnInit, OnDestroy {
    settings;
    userInfo;

    levels: Array<any> = [];
    letters: Array<any> = [];
    levelsChecked: Array<any> = [];
    lettersChecked: Array<any> = [];

    user: User | null = null;
    videos;
    myVideos;
    types;
    homework: Array<any> = [];

    show = true;

    private oUser;
    private oTypes;
    private oVideosUnfiltered;
    private oMyVideos;


    constructor(private stimuliService: StimuliService,
                private userService: UserService,
                private videoService: VideoService,
                private modalService: ModalService,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private router: Router) {
        this.settings = settings;
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): void {
        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                this.updateBookmarked();
            }
        });

        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            if (result) {
                result = _.sortBy(result, 'visibleName');
                this.types = result;
            }
        });

    }

    ngOnDestroy(): void {
        if (this.oUser) {
            this.oUser.unsubscribe();
        }
        if (this.oVideosUnfiltered) {
            this.oVideosUnfiltered.unsubscribe();
        }
    }

    open(videoUrl): Promise<any> {
        if (!this.userService.userInfo.basicSubscriber) {
            return this.router.navigate(['/account']);
        }
        else {
            return this.modalService.video(videoUrl);
        }
    }

    bookmarked(id): boolean {
        if (this.user && this.user.videos && this.user.videos.indexOf(id) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    removeVideo(id): Subscription {
        if (this.user) {
            // FIXME Wat?
            // @ts-ignore
            _.pull(this.user.videos, id);
        }

        this.levels = [];
        this.letters = [];
        this.levelsChecked = [];
        this.lettersChecked = [];
        this.updateBookmarked();

        // @ts-ignore
        return this.videoService.updateVideos({videos: this.user.videos});
    }

    updateBookmarked(): void {
        // @ts-ignore
        this.oMyVideos = this.videoService.getVideosById(this.user.videos).subscribe((result2) => {
            this.myVideos = _.groupBy(result2, (video) => {
                return video.level;
            });

            _.forEach((this.myVideos as any), (level, iter) => {
                (this.myVideos as any)[iter] = _.groupBy(level, (video) => {
                    const x = _.upperFirst((video as any).name);
                    return x.substr(0, 1);
                });
            });

            _.forEach((this.myVideos as any), (level, iter) => {
                const ordered = {};
                Object.keys(level).sort().forEach(function (key) {
                    ordered[key] = level[key];
                });
                (this.myVideos as any)[iter] = ordered;
            });

            this.show = false;
            this.cdr.detectChanges();
            this.show = true;
            this.cdr.detectChanges();
        });
    }


    getClass(type): string {
        let returnData = 'p-2 ';

        if (type.menu && type.menu.color) {
            returnData = returnData + ' ' + type.menu.color;
        }
        else {
            returnData = returnData + ' fg-default ';
        }
        if (type.menu && type.menu.backgroundColor) {
            returnData = returnData + ' ' + type.menu.backgroundColor;
        }
        else {
            returnData = returnData + ' bg-default ';
        }
        return returnData;
    }

    getImage(type): string {
        if (type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }

    name(): string {
        if (this.user && this.user.local && this.user.local.name) {
            return this.user.local.name;
        }
        else if (this.user && this.user.google && this.user.google.name) {
            return this.user.google.name;
        }
        else if (this.user && this.user.facebook && this.user.facebook.name) {
            return this.user.facebook.name;
        }
        else if (this.user && this.user.twitter && this.user.twitter.displayName) {
            return this.user.twitter.displayName;
        }
        else {
            return 'No name';
        }
    }

    navigate(type): Promise<any> {
        if (!this.userService.userInfo.premiumSubscriber) {
            if (this.userService.userInfo.basicSubscriber) {
                const route = ['/modules-videos'];
                return this.router.navigate(route);
            }
            else {
                const route = ['/account'];
                return this.router.navigate(route);
            }
        }
        else {
            const route = ['/therapy/' + type.templateType + '/' + type.name];
            return this.router.navigate(route);
        }
    }

    allVideos(): Promise<any> {
        return this.router.navigate(['/modules-videos']);
    }

}
