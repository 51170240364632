<div class="container m-t-30" style="clear: both">
  <div class="row">
    <div class="col-sm-12">
      <form *ngIf="type" class="form-inline">
        <div class="form-group col-sm-12">
          Level:
          <select *ngIf="type" [(ngModel)]="onLevel" class="form-control inline-block" name="level" style="width: 60px;">
            <option *ngFor="let level of type.levels" [value]="level.level">
              {{level.level}}
            </option>
          </select>


          <button (click)="get()" class="btn btn-primary m-l-15" type="button">GET STIMULI</button>
          <button (click)="clear()" class="btn btn-primary m-l-15">CLEAR</button>
          <button (click)="sendText()" class="btn btn-primary m-l-15">SEND TEXT</button>
          <button (click)="sendAlternatives()" class="btn btn-primary m-l-15" type="button">SEND ALTERNATIVES</button>
        </div>
      </form>
    </div>
  </div>
  <br/><br/>

  <div class="row">
    <div class="col-sm-12">


      <div *ngIf="stimuli && alternatives && type && typeSettings" class="center w-100 p-b-15 p-t-10">

        <h1 *ngIf="typeSettings.question && stimuli.description" class="click m-t-20 m-b-20">
          {{stimuli.description}}
        </h1>

        <div *ngIf="(typeSettings.mainImage && stimuli.image)" class="p-15">
          <img [src]="getStimuliImage()"/>
        </div>

        <div (click)="sendAnswer(answer.id)" *ngFor="let answer of alternatives; let index=index; let isOdd=odd; let isEven=even" class="inline" style="width: 50%">

          <div *ngIf="answer.image && typeSettings.image" class="picture-container" style="border:4px solid white">
            <div class="picture-dummy"></div>
            <div [class.correct]="correct(answer.id)" [class.picture-even]="isEven" [class.picture-odd]="isOdd" [class.selected]="selected(answer.id)" class="picture-element">
              <img [src]="getAnswerImage(answer.image)" class="picture" id="image_{{answer.id}}" width="100%"/>
            </div>
          </div>

          <button (click)="sendAnswer(answer.id)" *ngIf="typeSettings.buttons && answer.word" [class.correct]="correct(answer.id)" [class.selected]="selected(answer.id)" class="answerbutton btn btn-lg btn-primary" id="{{answer.id}}">
            {{answer.word}}
          </button>
        </div>
      </div>


    </div>
  </div>

</div>
