<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 center p-t-15 p-b-10 bg-teal">
        <h1>What is Wernicke's aphasia?</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 p-15 center">
        <img src="/images/wernickes.png" style="height: 350px;"/><br/>
      </div>
      <div class="col-sm-12 p-15">
        <b>Wernicke’s aphasia</b> is a type of fluent aphasia that can occur when a more posterior part of the left hemisphere is damaged from a stroke or brain injury. It is often thought to be harder to treat than other types of aphasia, but this
        isn’t true. The hallmark of this aphasia is poor understanding, but fluent speech that consists of nonsense words or words with mixed-up sounds. For example, saying “She wersh complirest over againer, right?”
        <br/><br/>
        <b>Wernicke’s aphasia affects the person’s ability to speak or write in real words; they have little to no understanding that they are not making sense and cannot monitor their own speech.</b> All language skills are affected to different
        degrees, but disordered speech is the most noticeable to listeners. Like all types of aphasia, Wernicke’s aphasia can be mild, moderate, or severe.
        <br/><br/>
        <b>Speech:</b> The person speaks in sentences that have varying emphasis and intonation, but their words may be all nonsense or mixed up sounds of real words. There may be ‘islands’ of real words or speech may be almost all nonsense words and
        sounds. Repeating words and sounds is severely affected. <b>It is NOT apraxia</b>.
        <br/><br/>
        <b>Writing:</b> Since speaking and writing are both expressive forms of communication, writing issues will be very similar to those outlined in the Speech section. Copying words is difficult as the person forms the same letters over and over
        or writes in shapes instead of letters. They often do not recognize their errors.
        <br/><br/>
        <b>Reading:</b> Typically very poor or at a limited single word/phrase level at first. Reading is also limited to largely nouns and verbs. Ordering of words into a sentence relies intermittently on reading and physical characteristics of the
        words.
        <br/><br/>
        <b>Understanding:</b> While understanding is poor, social and contextual conversation is usually understood. Once they understand how to do a task, it’s difficult to change that task–structured activities should be replaced with indirect
        therapy activities.
        <br/><br/>
        <b>How Can My Aphasia Coach Software Help Wernicke's Aphasia?</b>
        <br/><br/>
        Learners with <b>Wernicke’s aphasia</b> can improve within each language system–recognizing words and sentences, copying words, repeating words, understanding spoken and written information, and more.

        <br/><br/>
        These exercises are designed to target specific processes that begin at their current skill level, building on existing strengths. <b>To get the fastest results, we recommend that learners with aphasia work independently after they get
        started.</b>
        <br/><br/>

        <b>Where Should I Start My Aphasia Coach?</b>
        <br/>
        Take the <a [routerLink]="'/where-do-i-start'">placement quiz</a> to get recommendations.
        <br/><br/>
        You can also try these exercises:
        <br/><br/>
        <h5>Reading</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/Naming">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/naming.png">
              <div class="p-10 text-center"><h5>Noun Choice 1</h5>
                Choose the defined word
              </div>
            </div>
          </div>
        </div>

        Any Level 1 exercises
        <br/><br/>
        <h5>Writing</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing/PracticeNounPictures">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/practice-noun-pictures.png">
              <div class="p-10 text-center"><h5>Practice Noun Pictures</h5>
                Type picture names

              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing1/Naming Writing'">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/word-writing-nouns.png">
              <div class="p-10 text-center"><h5>Noun Pictures 1</h5>
                Type picture names
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing1/VerbNamingWriting">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/verb-naming-writing.png">
              <div class="p-10 text-center"><h5>Verb Pictures 1</h5>
                Type action names
              </div>
            </div>
          </div>
        </div>


        <br/><br/>
        <h5>Speech</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/speech/Naming Speech">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2"><img width="100%" src="/images/modules/naming-speech.png">
              <div class="p-10 text-center"><h5> Repeating Nouns </h5> Say the word you hear</div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/1" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-1.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 1
                </h5>
                1 syllable, 2 sounds
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/2" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-2.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 2
                </h5>
                1 syllable, 3 sounds
              </div>
            </div>
          </div>

        </div>

        May not be appropriate, depending on the person’s repetition ability. Start with Repeating Nouns or Apraxia Videos (while they don’t have apraxia, the word/sound        presentation        may be helpful)
        <br/><br/>
        <h5>Listening</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/PracticePictureMatchNoun">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/training-picture-match-nouns.png">
              <div class="p-10 text-center"><h5>Match the photo with the word</h5>

              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/AudioPicSent">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/auditory-picture-sentence-match.png">
              <div class="p-10 text-center"><h5>Verb Sentence Match 1</h5>
                Match spoken sentences to photos
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/AudioPictureResponse">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/auditory-picture-match-noun.png">
              <div class="p-10 text-center"><h5>Picture Matching</h5>
                Match the word to the picture
              </div>
            </div>
          </div>

        </div>

        <br/><br/>
        <h5>Numbers</h5>
        Not recommended.
        <br/><br/>
        Wernicke's aphasia can improve, even years after a stroke or brain injury. <b>There is no plateau or 6-month time limit–you can always make progress.</b> The best results occur when you practice My Aphasia Coach <b>for at least 30 minutes a
        day</b>.


      </div>
    </div>
  </div>
</div>

<app-my-footer></app-my-footer>

