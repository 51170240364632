<div class="container-fluid bg-white p-t-40">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <img src="/images/register-icon.png" width="40"/><br/>
        <h5 class="text-teal">Register</h5>
        <h4>Sign up for free</h4>
        Create an account. Choose between a learner with aphasia, <br/>or a Speech-Language pathologist account.
      </div>
      <div class="col-sm-6">
        <img src="/images/register.png" width="100%"/><br/>
      </div>
    </div>


    <div class="row p-t-60">
      <div class="col-sm-8">
        <img src="/images/get-started.png" width="25%"/>
        <img src="/images/get-started-2.png" width="37.5%"/>
        <img src="/images/get-started-3.png" width="37.5%"/><br/>
      </div>
      <div class="col-sm-4">
        <img src="/images/get-started-icon.png" width="40"/><br/>
        <h5 class="text-teal">Get Started</h5>
        <h4>3 Ways to Begin Learning</h4>
        Take a placement quiz, follow recommendations by your type of aphasia, or explore on your own.
      </div>
    </div>


    <div class="row p-t-60">
      <div class="col-sm-6">
        <img src="/images/connect-icon.png" width="40"/><br/>
        <h5 class="text-teal">Connect</h5>
        <h4>Connect with your speech therapist</h4>
        Add your Speech Therapist to receive home and track progress in Homework.
      </div>
      <div class="col-sm-6">
        <img src="/images/connect-1.png" width="50%"/>
        <img src="/images/connect-2.png" width="50%"/><br/>
      </div>
    </div>

    <h2 class="text-teal p-t-60">OR</h2>

    <div class="row p-t-60">
      <div class="col-sm-6">
        <img src="/images/independently-icon.png" width="40"/><br/>
        <h5 class="text-teal">Work Independently</h5>
        <h4>Explore and find your work in My Tasks</h4>
        Find your saved videos and recent exercises in one easy place on the home screen.
      </div>
      <div class="col-sm-6">
        <img src="/images/indenpendently.png" width="100%"/><br/>
      </div>
    </div>

    <div class="row p-t-60">
      <div class="col-sm-6">
        <img src="/images/progress-1.png" width="50%"/>
        <img src="/images/progress-2.png" width="25%"/>
      </div>
      <div class="col-sm-6">
        <img src="/images/progress-icon.png" width="40"/><br/>
        <h5 class="text-teal">Progress</h5>
        <h4>Keep up the momentum</h4>
        Take a placement quiz, follow recommendations by your type of aphasia, or explore on your own.
      </div>
    </div>

  </div>
  <app-my-footer></app-my-footer>
</div>
