import {Router} from '@angular/router';
import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {HttpClient} from '@angular/common/http';

import {config} from '../../config';
import {of, Subscription} from 'rxjs';

declare var Stripe: any;

@Component({
    selector: 'payment',
    templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit, OnDestroy {
    formData = {
        auth: true,
        // subscriptionType: 'price_1NH68i2geJctkXBk24C9XNRp',
        subscriptionType: '',
        nameOnCard: '',
        number: '',
        month: '',
        year: '',
        cvc: ''
    };
    submitted = false;
    user;
    userInfo;
    isFormDisabled = false;
    waitMessage: null | string = null;
    error;
    status = {message: '', error: false};
    private cardToken: any;
    private stripe: any;
    private elements: any;
    private card: any;
    private oUser;

    constructor(private userService: UserService,
                private router: Router,
                private http: HttpClient,
                private cdr: ChangeDetectorRef,
                private zone: NgZone) {
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): void {
        this.oUser = this.userService.bsUser.subscribe(result => {
            if (result !== null) {
                this.user = result;
                this.setUpCard();
            }
        });
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
    }

    setUpCard(): any {
        return setTimeout(() => {
            this.stripe = Stripe(config.stripeKey);
            this.elements = this.stripe.elements();
            this.card = this.elements.create('card');
            if (this.card && this.card.mount) {
                this.card.mount('#card-element');
            }
        }, 100);
    }

    getCardToken(): Subscription | false {
        this.submitted = true;
        this.status = {message: '', error: false};

        if (this.formData.auth && !this.isFormDisabled) {
            this.isFormDisabled = true;

            if (this.formData.subscriptionType === 'cancel') {

                const urlSearchParams = new URLSearchParams();
                urlSearchParams.append('name', this.formData.nameOnCard);
                const payload = urlSearchParams.toString();

                this.waitMessage = 'Please wait...';
                this.cdr.detectChanges();

                return this.http.post(config.server + 'formhandler/subscriptionCancel', payload, {withCredentials: true})
                    .subscribe(res => {
                        this.status.message = (res as any).message;
                        this.status.error = (res as any).error;
                        this.waitMessage = null;
                        this.isFormDisabled = false;
                        this.formData.subscriptionType='';
                        this.status.error = (res as any).error;
                        this.status.message = (res as any).message;
                        return this.userService.getUserInfo().subscribe();
                    });
                    

            }
           else if(this.formData.subscriptionType === ''){           
                this.submitted = false;
                this.isFormDisabled = false;
                this.status.message = 'You can not proceed without select plan.';
                this.status.error = false;
                this.waitMessage = null;

                return false;
            }
            else {
               
                return this.stripe.createToken(this.card).then((status) => {
                    if (status.token) {
                        this.cardToken = status.token.id;
                        return this.subscriptionCreate();
                    }
                    else {
                        this.submitted = false;
                        this.isFormDisabled = false;
                        this.status.message = '';
                        this.status.error = status.error.message;
                        this.waitMessage = null;

                        return of(false);
                    }
                }, (err) => {
                    this.submitted = false;
                    this.isFormDisabled = false;
                    this.status.message = '';
                    this.status.error = err.error.message;
                    this.waitMessage = null;

                    return of(false);

                });
            }
        }
        else {
            return false;
        }
    }

    subscriptionCreate(): Subscription {
        this.waitMessage = 'Please wait...';
        this.cdr.detectChanges();

        const payload = {
            token: this.cardToken,
            subscriptionType: this.formData.subscriptionType
        };

        return this.http.post(config.server + 'formhandler/subscriptionCreate', payload, {withCredentials: true})
            .subscribe(res => {
                console.info("subscriptionCreate", res);
                console.log("subscriptionCreate", res);
                return this.zone.run(() => {
                    this.isFormDisabled = false;
                    this.submitted = false;
                    this.waitMessage = null;
                    this.status.error = (res as any).error;
                    this.status.message = (res as any).message;

                    if (!(res as any).error) {
                        // setTimeout(() => {
                        //     // this.router.navigate(['/account']);
                        //     if (typeof window !== "undefined") {
                        //         window.location.href = '/account'; // assure refresh of data
                        //     }
                        // }, 2000);
                    }
                    this.formData.subscriptionType='';
                    return this.userService.getUserInfo().subscribe();
                });
            }, err => {
                this.submitted = false;
                this.isFormDisabled = false;
            });
    }

    authClass(): string {
        if (this.submitted && !this.formData.auth) {
            return 'col-9 p-5 highlight';
        }
        else {
            return 'col-9 p-5';
        }
    }

    isExpired(date): boolean {
        const currentDate = new Date();
        const expireDate = new Date(date);
        if(!date){
            return false;
        }
        return currentDate >= expireDate;
    }

    subscriptionType(type): string {
        return this.userService.productNames[type];
    }

    login(): Promise<any> {
        const route = ['/login'];
        return this.router.navigate(route);
    }

    register(): Promise<any> {
        const route = ['/register'];
        return this.router.navigate(route);
    }


}
