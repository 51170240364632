import {of, Subscription} from 'rxjs';
import * as reSampler from '../../../audio.service';
import * as _ from 'lodash';

import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {BuilderService} from '../../services/builder.service';
import {AnswerService} from '../../services/answer.service';
import {MessagingService} from '../../services/messaging.service';
import {ModalService} from '../../services/modal.service';

import {settings} from '../../settings';
import {config} from '../../config';
import {SafeResourceUrl} from '@angular/platform-browser';
import {AudioService} from '../../services/audio.service';
import {ScreenService} from '../../services/screen.service';

@Component({
    selector: 'app-speech',
    templateUrl: './speech-debug.component.html'
})

export class SpeechDebugComponent implements OnInit, OnDestroy {
    result;
    processor;
    source;
    ws;
    context;
    recording;
    attempts = 0;
    reConnecting = false;
    message;
    settings;
    config;
    alternatives: Array<any> = [];
    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;
    showIEWarning;
    playingSound = false;
    timer;
    private subscriptions = new Subscription();

    constructor(private modalService: ModalService,
                private messagingService: MessagingService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private userService: UserService,
                private screenService: ScreenService,
                private audioService: AudioService,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService,
                private zone: NgZone) {
        this.settings = settings;
        this.config = config;
        this.userInfo = this.userService.userInfo;
    }

    ngOnInit(): void {
        this.subscriptions.add(this.audioService.bsListen.subscribe(result => {
            this.playingSound = false;
            if (result) {
                return this.start();
            }
            return false;
        }));

        return this.subscriptions.add(this.audioService.bsStopListen.subscribe(result => {
            if (result) {
                return this.stop(true);
            }
            return false;
        }));

    }

    ngOnDestroy(): Promise<any> {
        this.stimuliService.unsubscribe.next();
this.stimuliService.unsubscribe.complete();
this.subscriptions.unsubscribe();
        this.audioService.bsListen.next(false);
        return this.stop();
    }

    start(): Promise<any> {
        return this.audioService.startSound().then(() => {
            this.recording = true;

            try {
                this.context = new AudioContext();
                this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');
            } catch (err: any) {
                this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
            }

            this.ws.getDataStream().subscribe((msg: MessageEvent) => {
                return this.receiveMessage(msg);
            });

            return navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
                return this.zone.run(() => {
                    this.source = this.context.createMediaStreamSource(stream);
                    this.processor = this.context.createScriptProcessor(8192, 1, 1);

                    this.source.connect(this.processor);
                    this.processor.connect(this.context.destination);

                    return this.processor.onaudioprocess = (data) => {
                        return this.zone.run(() => {
                            return reSampler(data.inputBuffer, 16000, (event) => {
                                return this.zone.run(() => {
                                    try {
                                        if (this.ws && this.ws.send()) {
                                            return this.ws.send(this.convertFloat32ToInt16(event.getAudioBuffer().getChannelData(0)), undefined, true).subscribe();
                                        }
                                    } catch (err: any) {
                                        console.error(new Error(err));
                                    }
                                });
                            });
                        });
                    };
                });
            }, () => {
                this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
            });
        });
    }

    stop(skip?): Promise<any> {
        this.audioService.bsListen.next(false);
        clearTimeout(this.builderService.timer);

        if (this.ws) {
            this.ws.send('stop');
            this.ws.close();
            this.ws = null;
        }

        if (this.processor) {
            this.processor.disconnect();
            this.processor = null;
        }

        this.source = null;
        this.recording = false;

        this.builderService.timer = null;

        if (!skip) {
            return this.audioService.stopSound();
        }
        else {
            return Promise.resolve(true);
        }
    }

    receiveMessage(msg?): any {
        return this.zone.run(() => {
            let message;
            try {
                message = JSON.parse(msg.data);
            } catch (err: any) {
            }

            if (message && message[0]) {
                this.result = message[0].alternatives;
                this.cdr.detectChanges();
            }
            else {
                console.log('no msg');
            }
            return of(false);
        });
    }


    convertFloat32ToInt16(buffer): any {
        let l = buffer.length;
        const buf = new Int16Array(l);
        while (l--) {
            buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
        }
        return buf.buffer;
    }

}
