import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-register-therapist',
    templateUrl: './register-therapist.component.html',
    styleUrls: []
})
export class RegisterTherapistComponent implements OnInit {
    alerts: Array<any> = [];
    newUser;
    showIEWarning = false;
    registerInProgress;

    constructor(private userService: UserService,
                private location: Location) {
        this.newUser = {
            name: '',
            email: '',
            password: '',
            clientId: null,
            secret: null,
            therapist: {
                ashaNumber: '',
                placeOfEmployment: '',
                facilityType: ''
            }
        };
    }

    ngOnInit(): void {
        if (this.userService.isIE()) {
            this.showIEWarning = true;
        }

        this.newUser.clientId = this.location.path(true).split('/')[2];
        this.newUser.secret = this.location.path(true).split('/')[3];
    }

    register(form?): Subscription {
        this.alerts = [];

        return this.userService.registerTherapist(this.newUser).subscribe(() => {
            },
            () => {
                this.registerInProgress = false;

                const msg = {
                    type: 'danger',
                    message: 'This email address has already been registered. Please sign in instead.'
                };
                this.alerts.push(msg);
            });
    }

    closeAlert(index): void {
        this.alerts.splice(index, 1);
    }

}
