<div class="container-fluid bg-white vh-100">
  <div class="container bg-white p-t-20" id="assign-homework">
    <h1 class="center">
      Assign modules
    </h1>
    <div class="h4 m-t-20 m-b-5 text-center">
      Choose a language focus
    </div>

    <div class="row" style="display:flex; justify-content:center; flex-flow: row wrap;">
      <div (click)="filter='reading'" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-primary p-10 ' + selectedType('reading')">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">menu_book</span><br/>
            Reading
          </h3>
        </div>
      </div>
      <div (click)="filter='writing'" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-primary p-10 ' + selectedType('writing')">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">edit</span><br/>
            Writing
          </h3>
        </div>
      </div>
      <div (click)="filter='comprehension'" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-primary p-10 ' + selectedType('comprehension')">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">headphones</span><br/>
            Listening</h3>
        </div>
      </div>
      <div (click)="filter='speech'" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-primary p-10 ' + selectedType('videos')">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">message</span><br/>
            Speech</h3>
        </div>
      </div>
    </div>


    <div *ngIf="filter" class="h4 m-t-15 m-b-5 text-center">
      Choose level
    </div>

    <div *ngIf="filter" class="row text-center" style="display: flex; flex-wrap: wrap; justify-content: center; align-items: stretch;">
      <div (click)="setLevel(0)" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 p-10">
        <div [class]="'p-10 h100p pointer bg-menu ' + selectedLevel(0)">
          <h4>Practice<br/>(Introductory)</h4>
          <h5>Simplified exercises</h5>
        </div>
      </div>
      <div (click)="setLevel(1)" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 p-10">
        <div [class]="'p-10 h100p pointer bg-menu ' + selectedLevel(1)">
          <h4>Level 1<br/>(Beginning)</h4>
          <h5>Words</h5>
        </div>
      </div>

      <div (click)="setLevel(2)" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 p-10">
        <div [class]="'p-10 h100p pointer bg-menu ' + selectedLevel(2)">
          <h4>Level 2<br/>(Emerging)</h4>
          <h5>Words and short sentences</h5>
        </div>
      </div>

      <div (click)="setLevel(3)" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 p-10">
        <div [class]="'p-10 h100p pointer bg-menu ' + selectedLevel(3)">
          <h4>Level 3<br/>(Challenging)</h4>
          <h5>Sentences and paragraphs</h5>
        </div>
      </div>
    </div>

    <div *ngIf="filter && types && types.length>0" class="h4 m-t-20 m-b-5 text-center">
      Choose an exercise
    </div>

    <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
      <div *ngFor="let type of types"
           [hidden]="!showProgram(type, filter)"
           class="col-lg-3 col-md-4 col-sm-6 pointer p-10">

        <div [class]="getClass(type)" [class.is-assigned]="isAssigned(type)" [class.is-completed]="!canAssign(type)" style="height:100%;">
          <img (click)="toggleAssigned(type)" [src]="getImage(type)" width="100%"/>
          <div (click)="toggleAssigned(type)" class="p-5 text-center">
            <h5 class="m-0">
              {{type.visibleName || type.name}}
              <span [hidden]="!type.highlight" class="text-warning"><span class="material-icons middle">star</span>  <strong>New!</strong></span>
            </h5>
            <div *ngIf="!isAssigned(type)">
              <div *ngIf="!canAssign(type)">
                Module completed
              </div>
              <div *ngIf="canAssign(type)">
                {{type.description}}
              </div>
            </div>
          </div>

          <div *ngIf="isAssigned(type)" class="center p-b-5">
            <span class="text-white">Assigned</span> <input #myTemplateVar="ngModel" (change)="updateHomework(type, myTemplateVar.value)" *ngIf="isAssigned(type)" [ngModel]="getCount(type)" class="m-l-5 middle" size="2" type="text">
          </div>

        </div>
      </div>
    </div>


    <div class="m-t-50 float-right">
      <button (click)="send()" class="btn btn-primary m-l-10"> Notify Patient</button>
      <button (click)="back()" class="btn stop m-l-10" type="button"><span class="material-icons middle">arrow_back_ios</span> Back</button>
    </div>

    <div [hidden]="!messageStatus.success" class="alert alert-success">Your patient has been notified.</div>
    <div [hidden]="!messageStatus.error" class="alert alert-danger">Something went wrong. Please contact support</div>
  </div>
  <app-my-footer></app-my-footer>
</div>
