<div class="bg-white">
  <div class="container p-t-30">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="">Sign In</h1>

        <p *ngFor="let alert of alerts">
          <ngb-alert (close)="closeAlert(alert)" [type]="alert.type">{{ alert.message }}</ngb-alert>
        </p>

        <ngb-alert *ngIf="loginInProgress" [dismissible]="false">
          Logging in...
        </ngb-alert>

        <div *ngIf="showIEWarning" class="alert alert-danger">
          My Aphasia Coach has only partial support for Internet Explorer. <a routerLink="/upgrade">How and why you should upgrade</a>.
        </div>


      </div>
    </div>

    <form #form class="clearfix p-b-5 form-horizontal">
      <div class="row">
        <div class="col-sm-8">

          <div class="form-group">
            <label>Email</label>
            <div class="input-group">
              <input #email="ngModel" (keyup.enter)="next('password')" [(ngModel)]="loginData.email" [email]="true" class="form-control" name="email" placeholder="Enter email" required type="email">
            </div>

          </div>
          <div class="form-group">
            <label>Password</label>
            <input (keyup.enter)="next()" [(ngModel)]="loginData.password" class="form-control" id="password" name="password" placeholder="Password" required type="password">
          </div>


        </div>
        <div class="col-sm-4">
          <div style="display: flex;    align-items: center;    justify-content: flex-start;    height: 100%;">
            OR <a class="m-l-15 btn btn-primary " routerLink="/register" routerLinkActive="active" type="button"><h4 class="p-0 m-0">Register Now</h4>
            For a Free Trial
          </a>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-sm-12">
          <button (click)="login()" class="btn btn-primary" type="button">LOG IN</button>
          <br/><br/>
          <a class="" routerLink="/lost-password" routerLinkActive="active" type="button">Forgot password</a><br/>
          <br/><br/>

        </div>
      </div>
    </form>
  </div>
</div>
<app-my-footer></app-my-footer>
