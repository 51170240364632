import {Component, OnDestroy, OnInit} from '@angular/core';

import {ModalService} from '../../services/modal.service';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    templateUrl: './invite-client.component.html',
    styleUrls: ['./invite-client.component.css']
})
export class InviteClientComponent implements OnInit, OnDestroy {
    alerts;
    form = {email: ''};
    user;
    emailSent = false;

    constructor(private modalService: ModalService,
                private userService: UserService) {
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            this.user = result;
        });
    }

    ngOnDestroy(): void {
    }

    inviteClient(): Subscription {
        return this.userService.inviteClient(this.form).subscribe((res) => {
            this.form.email = '';
            this.emailSent = true;
        });
    }

    closeAlert(index): void {
        this.alerts.splice(index, 1);
    }

}
