<div class="container-fluid bg-white">
  <div class="center p-10">
    <app-error-message></app-error-message>

    <h3 class="p-b-15">
      Speech Debug
    </h3>

    <div class="p-b-10 p-t-30">
      <button (click)="start()" *ngIf="!recording" class="btn btn-primary">
        <span class="material-icons middle">mic</span>
        Start
      </button>

      <button (click)="stop()" *ngIf="recording" class="btn btn-danger">
        <span class="material-icons middle">mic_off</span>
        Pause
      </button>
    </div>

    <div *ngIf="result" class="p-t-10">
      <table class="center text-left">
        <tr *ngFor="let res of result;">
          <td class="p-5">{{res.confidence | percent}}</td>
          <td class="p-5">{{res.transcript}}</td>
        </tr>
      </table>
    </div>
  </div>
  <app-my-footer></app-my-footer>
</div>
