<div class="container-fluid bg-white vh-100">

  <div class="container p-t-20">
    <div class="row">
      <div *ngIf="user" class="col-sm-12 p-15">
        <div class="clearfix m-b-15">
          <form #searchForm="ngForm" class="m-l-15 float-right form-inline">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><span class="material-icons middle">search</span> </span>
              </div>
              <select [formControl]="filterType" [value]="'startsWith'" class="form-control">
                <option selected value="startsWith">Starts with</option>
                <option value="contains">Contains</option>
              </select>

              <input [formControl]="filterInput" class="form-control" name="search" placeholder="Search" type="text">
              <div (click)="resetSearch()" class="input-group-append">
                <span class="input-group-text"><span class="material-icons middle">clear</span> </span>
              </div>
            </div>
          </form>
          <button *ngIf="isBasicUser() && !isPremiumUser()" class="float-right btn btn-primary" routerLink="/modules-videos">
            <span class="material-icons middle">arrow_circle_left</span>
            Choose Level
          </button>
          <button *ngIf="isPremiumUser()" class="float-right btn btn-primary" routerLink="/modules-videos">
            <span class="material-icons middle">arrow_circle_left</span> Choose Level
          </button>

          <p class="form-control-plaintext"> Unsure what to do? Please see our
            <a routerLink="/faq"> Frequently Asked Questions </a> page.
        </div>
        <app-trial ng-show="settings.loggedIn === true"></app-trial>

        <div [hidden]="videos" class="row" hidden="true">
          <div class="col-sm-12 center"><h3 class="m-5">Please wait...</h3>
          </div>
        </div>

        <div *ngIf="videos" class="row">
          <div class="col-sm-6">
            <h2>My Videos</h2>
            <hr class="hr-2"/>

            <div [hidden]="!noVideos()" class="btn-primary p-5">
              Click on <span class="material-icons">star</span> to save your videos.
            </div>
            <div *ngFor="let level of myVideos | objectToArray">
              <h3 class="m-t-15 m-b-0 bb-1">Level {{level.key}}</h3>
              <div *ngFor="let letter of level.value | objectToArray" class="">
                <h4 class="m-t-10 m-b-5">{{letter.key}}</h4>

                <div *ngFor="let video of letter.value | objectToArray" class="p-b-5">
                  <span (click)="addVideo(video.value._id);" class="">
                  <span [hidden]="bookmarked(video.value._id)" class="material-icons text-dark-blue pointer">star</span>
                  </span>
                  <span (click)="removeVideo(video.value._id);" class="">
                    <span [hidden]="!bookmarked(video.value._id)" class="material-icons text-dark-blue pointer">star</span>
                  </span>
                  <a (click)="open(video.value, video)" [id]="video.value._id" class="pointer p-l-10">{{video.value.name}}</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div *ngIf="!search.name && show" class="clearfix">
              <h2>Level {{selectedLevel}} Videos</h2>
              <hr class="hr-2"/>
              Words and sentences beginning with:
              <div *ngFor="let level of videos | objectToArray">
                <div *ngFor="let letter of level.value | objectToArray; let ix = index" class="p-15 inline">
                  <h4 class="m-b-5 text-blue inline" (click)="clickLetter(ix)">{{letter.key}}
                  </h4>
                </div>
              </div>

              <div *ngFor="let level of videos | objectToArray">
                <div *ngFor="let letter of level.value | objectToArray; let ix = index" class="m-b-15">

                  <div *ngFor="let video of letter.value | objectToArray" class="p-b-5" [id]="ix" [hidden]="!hide[ix]">
                  <span (click)="addVideo(video.value._id);" class="">
                  <span [hidden]="bookmarked(video.value._id)" class="material-icons text-dark-blue pointer">star_outline</span>
                  </span>
                  <span (click)="removeVideo(video.value._id);" class="">
                  <span [hidden]="!bookmarked(video.value._id)" class="material-icons text-dark-blue pointer">star</span>
                  </span>
                    <a (click)="open(video.value, video)" [id]="video.value._id" class="pointer p-l-10">{{video.value.name}}</a>
                  </div>
                </div>
              </div>


            </div>

            <div *ngIf="search.name && show" class="clearfix">
              <h2>Search</h2>
              <hr class="hr-2"/>
              <div *ngFor="let video of videosFiltered" class="m-b-5">
              <span (click)="addVideo(video._id);" class="">
              <span [hidden]="bookmarked(video._id)" class="material-icons text-dark-blue pointer">star</span>
              </span>
                <span (click)="removeVideo(video._id);" class="">
              <span [hidden]="!bookmarked(video._id)" class="material-icons text-dark-blue pointer">star</span>
              </span>
                <a (click)="open(video.url, video)" [id]="video.value._id" class="pointer p-l-10">{{video.name}}</a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <app-my-footer></app-my-footer>
  </div>
