<div class="container-fluid bg-white vh-100">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 p-15">
        <h1>Privacy Policy</h1>
        Last updated: January 16, 2020.<br/><br/>

        This privacy policy discloses the privacy practices for <a routerLink="https://myaphasiacoach.com">myaphasiacoach.com</a>. This privacy policy applies solely to information collected by this web
        site.<br/>
        <br/>

        It will notify you of the following:
        <ul>
          <li>What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.</li>
          <li>What choices are available to you regarding the use of your data.</li>
          <li>The security procedures in place to protect the misuse of your information.</li>
          <li>How you can correct any inaccuracies in the information.</li>
          <li>Information Collection, Use, and Sharing</li>
        </ul>

        We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as
        necessary
        to fulfill your request, e.g. to set up payment for your subscription.<br/>
        <br/>
        Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.<br/>
        <br/>

        <h2>Registration</h2>
        In order to use this website, a user must first complete the registration form. During registration a user is required to give certain information (such as name and email address). This
        information can be used to contact you about the products/services on our site in which you have expressed interest.<br/>
        <br/>

        <h2>Your Access to and Control Over Information</h2>
        You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:
        <ul>
          <li>See what data we have about you, if any.</li>
          <li>Change/correct any data we have about you.</li>
          <li>Have us delete any data we have about you.</li>
          <li>Express any concern you have about our use of your data.</li>
        </ul>
        <br/>
        <h2>Subscriptions</h2>
        We request information from you on our sign up form. To become a subscriber, you must provide contact information (like name
        and email address) and financial information (like credit card number, expiration date). This information is used for billing purposes and to fill your orders. If we have trouble processing an
        order, we'll use this information to contact you.<br/>
        <br/>
        <h2>Security</h2>
        We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.<br/>
        <br/>
        Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock icon
        in
        the address bar of your web browser, or looking for "https" at the beginning of the address of the web page.<br/>
        <br/>
        While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for
        example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure
        environment.<br/>
        <br/>
        Your credit card number is only stored with our credit card processing service for subscription billing. We do not have access to it, and we can only change the expiration date.
        <br/>
        <br/>
        <h2>Cookies</h2>
        We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to our site and identify repeat visitors to our site. For
        instance,
        when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests
        of
        our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.
        <br/><br/>
        <h2>Updates</h2>
        Our Privacy Policy may change from time to time and all updates will be posted on this page.<br/>
        <br/>
        If you feel that we are not abiding by this privacy policy, you should contact us immediately via <a href="mailto:help@myaphasiacoach.com">email</a>.<br/>
      </div>
    </div>
  </div>
  <app-my-footer></app-my-footer>
</div>
