import {Component, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {UserService} from '../../services/user.service';
import {User} from '../../models/user';

@Component({
    selector: 'app-messaging',
    templateUrl: './messaging.component.html',
    styleUrls: ['./messaging.component.css']
})

export class MessagingComponent implements OnInit, OnDestroy {
    user: User | null = null;
    filteredMessages;

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
        this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                if (this.user && this.user.messages) {
                    this.filteredMessages = _.filter(this.user.messages, {ack: false});
                }
            }
        });
    }

    ngOnDestroy(): void {
    }

    showMessages(): boolean {
        if (this.user) {
            const temp = _.filter(this.user.messages, {ack: false});

            return temp.length > 0;
        }
        else {
            return false;
        }
    }


/*
    hideMessage(message): void {
        if (this.user && this.user.messages) {
            _.remove(this.user.messages, ['_id', message._id]);
            message.ack = true;
            message.ackDate = new Date();

            this.user.messages.push(message);
            const payload = {messages: this.user.messages};

            this.userService.updateMessages(payload).subscribe();
            this.filteredMessages = _.filter(this.user.messages, {ack: false});
        }
    }
*/

}
