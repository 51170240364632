import * as _ from 'lodash';

import {Observable, of, Subscription} from 'rxjs';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {BuilderService} from '../../services/builder.service';
import {AnswerService} from '../../services/answer.service';
import {ModalService} from '../../services/modal.service';

import {settings} from '../../settings';
import {SafeResourceUrl} from '@angular/platform-browser';
import {AudioService} from '../../services/audio.service';
import {ScreenService} from '../../services/screen.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './find-post-sound.component.html',
    styleUrls: ['./find-post-sound.component.css']
})
export class FindPostSoundComponent implements OnInit, OnDestroy {
    settings = settings;
    alternatives: Array<any> = [];
    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;
    message;
    correctDoesNotCount = false;
    private subscriptions = new Subscription();

    private wordsHeard = 0;

    constructor(private answerService: AnswerService,
                private userService: UserService,
                private location: Location,
                private audioService: AudioService,
                private modalService: ModalService,
                private screenService: ScreenService,
                private builderService: BuilderService,
                private router: Router,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
    }

    ngOnInit(): Subscription {
        this.subscriptions.add(this.userService.bsUser.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;

                if (this.userService.userInfo.loggedIn) {
                    if (!this.userService.userInfo.premiumSubscriber && this.userService.userInfo.basicSubscriber) {
                        const route = ['/modules-videos'];
                        return this.router.navigate(route);
                    }
                    else if (!this.userService.userInfo.basicSubscriber) {
                        const route = ['/account'];
                        return this.router.navigate(route);
                    }
                }
            }
            return false;
        }));

        this.subscriptions.add(this.userService.bsUser.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsStimuli.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.stimuli = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        }));


        if (this.location.path(true).split('/')[3] !== 'Scrambling2') {
            return this.builderService.getType().subscribe();
        }
        else {
            return this.builderService.findPostSound().subscribe();
        }
    }

    ngOnDestroy(): void {
        this.stimuliService.unsubscribe.next();
this.stimuliService.unsubscribe.complete();
this.subscriptions.unsubscribe();
    }

    checkAnswer(answer): Subscription {
        if (document.getElementById(answer)) {
            (document.getElementById(answer) as any).disabled = true;
        }

        let correct = false;
        if (answer === this.audioService.audioObjects[1].word) {
            correct = true;
        }
        else {
            this.correctDoesNotCount = true;
        }

        const payload = {
            correctDoesNotCount: this.correctDoesNotCount,
            correct,
            type: this.stimuliService.bsType.value.name,
            level: this.builderService.onLevel,
        };

        return this.stimuliService.updateStats(payload).subscribe(() => {
            if (correct) {
                return this.modalService.therapyResponseNoPostSounds(true, this.builderService.onLevel).subscribe(() => {
                    return this.checkAnswer2(answer).subscribe();
                }, () => {
                    return this.checkAnswer2(answer).subscribe();
                });
            }
            else {
                this.answerService.correctDoesNotCount = true;
                return this.modalService.therapyResponseNoPostSounds(false, this.builderService.onLevel);
            }

        });
    }

    checkAnswer2(answer): Observable<any> {
        this.correctDoesNotCount = false;
        this.wordsHeard++;

        if (this.wordsHeard > 1) {
            const link = ['/therapy/sentenceWriting/' + this.type.name];
            return of(this.router.navigate(link));
        }
        else {
            return this.builderService.findPostSound(answer);
        }
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }
}
