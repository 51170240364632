<div class="bg-light-gray">
  <div class="container p-t-30 p-b-30">
    <div class="row">
      <div class="col-sm-12">
        <h1>Lost Password</h1>
        We will email you a link to reset your password.<br/>
        Check your email.<br/><br/>
        <div [hidden]="!message" class="alert alert-success p10 " id="messages">{{ message }}</div>
        <div [hidden]="!errorEmail" class="alert alert-danger p10 ">{{ errorEmail }}</div>
        <!-- FORM -->

        <form class="p-b-30">
          <div class="form-group" id="email-group" ng-class="{ 'has-error' : errorEmail }">
            <label>Email address</label>
            <input [(ngModel)]="formData.email" class="form-control" name="email" placeholder="Enter email" type="email">
            <span class="bg-warning p-5 help-block" ng-cloak ng-show="errorEmail">{{ errorEmail }}</span>
          </div>

          <button (click)="processLostPass()" class="btn btn-primary" type="submit"><span class="material-icons middle">drafts</span>  Send</button>
        </form>

        <a class="" routerLink="/login" routerLinkActive="active">Sign in</a><br/>
        <a class="" routerLink="/register" routerLinkActive="active">Register</a><br/>
      </div>
    </div>
  </div>
</div>

<app-my-footer></app-my-footer>
