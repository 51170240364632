import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {UserService} from '../../services/user.service';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';

@Component({
    selector: 'my-therapist',
    templateUrl: './notification-addresses.component.html',
    styleUrls: ['./notification-addresses.component.css']
})
export class NotificationAddressesComponent implements OnInit, OnDestroy {
    client;
    alerts;
    payload = {
        email: '',
        user: null,
    };
    user;
    emailSent = false;
    clientData;
    private subscriptions = new Subscription();

    constructor(private modalService: ModalService,
                private userService: UserService,
                private location: Location) {
        this.client = this.location.path(true).split('/')[2];
        this.payload.user = this.client;
    }

    ngOnInit(): void {
        this.subscriptions.add(this.userService.bsUser.subscribe(result => {
            this.user = result;

            this.subscriptions.add(this.userService.getClientList().subscribe(clients => {
                this.clientData = _.find(clients, {'_id': this.client});

                if (!this.clientData) {
                    console.error('User not found!');
                }
            }));


        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    add(): Subscription {
        return this.userService.addHomeworkNotify(this.payload).subscribe((res) => {
            if (res.success) {
                if (!this.clientData.homeworkNotify) {
                    this.clientData.homeworkNotify = [];
                }

                this.clientData.homeworkNotify.push(JSON.parse(JSON.stringify(this.payload)));
                this.clientData.homeworkNotify = _.uniqBy(this.clientData.homeworkNotify, 'email');
                this.payload.email = '';

            }
        });
    };

    remove(address): Promise<any> {
        return this.modalService.revokeAccess().then(() => {
            this.payload.email = address.email;

            return this.userService.removeHomeworkNotify(this.payload).subscribe(() => {
                _.remove(this.clientData.homeworkNotify, ['email', address.email]);
                this.payload.email = '';
            }, () => {

            });

        }, () => {

        });
    }

    closeAlert(index): void {
        this.alerts.splice(index, 1);
    }

}
