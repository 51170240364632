<div class="container-fluid bg-white vh-100">

  <div *ngIf="user" class="container p-t-30">

    <table style="width: 100%; height:150px; padding-bottom:10px; background-color: #ffffff;">
      <tr>
        <td colspan="3">
          <h2 style="margin:5px; font-weight: normal; font-size: 28px; line-height:90%; text-align: center; color: #000000; padding:10px;">How to use My Aphasia Coach™ with your patients</h2>
        </td>
      </tr>
      <tr>
        <td style=" vertical-align: top; width: 33%; text-align: center">
          <img style="width:170px; " src="/images/card1.png"/><br/>
        </td>
        <td style=" vertical-align: top; width: 34%; text-align: center">
          <img style="width:170px; " src="/images/card2.png"/><br/>
        </td>
        <td style=" vertical-align: top; width: 33%; text-align: center">
          <img style="width:170px; " src="/images/card3.png"/><br/>
        </td>
      </tr>
      <tr>
        <td style="vertical-align: top; padding: 10px; text-align: center">
          <h4 style="color:#1e707d;">Create a User Account</h4>
          Invite your patients to connect in the software. It's free for 14 days
        </td>
        <td style="vertical-align: top; padding: 10px; text-align: center">
          <h4 style="color:#1e707d;">Take the Quiz</h4>
          Find the right exercises to begin
        </td>
        <td style="vertical-align: top; padding: 10px; text-align: center">
          <h4 style="color:#1e707d;">Exercises</h4>
          Trial recommended exercises
        </td>
      </tr>
      <tr>
        <td style=" vertical-align: top; width: 33%; text-align: center">
          <img style="width:170px; " src="/images/card4.png"/><br/>
        </td>
        <td style=" vertical-align: top; width: 34%; text-align: center">
          <img style="width:170px; " src="/images/card5.png"/><br/>
        </td>
        <td style=" vertical-align: top; width: 33%; text-align: center">
          <img style="width:170px; " src="/images/card6.png"/><br/>
        </td>
      </tr>
      <tr>
        <td style="vertical-align: top; padding: 10px; text-align: center">
          <h4 style="color:#1e707d;">Assign Homework</h4>
          Guide your patients' recovery
        </td>
        <td style="vertical-align: top; padding: 10px; text-align: center">
          <h4 style="color:#1e707d;">Progress</h4>
          Monitor progress
        </td>
        <td style="vertical-align: top; padding: 10px; text-align: center">
          <h4 style="color:#1e707d;">Promotion</h4>
          Follow the recommendations for promotion to new exercises
        </td>
      </tr>

    </table>


    <table style="width: 100%; height:150px; padding-bottom:10px; background-color: #ffffff;">
      <tr>
        <td style="width: 10%"></td>
        <td style="text-align: center;">
          <h3>Have Questions?</h3>
          <h3>See the <a href="https://myaphasiacoach.com/faq-therapist">Therapists' FAQ</a> or <a href="https://myaphasiacoach.com/tutorials">Tutorials</a></h3>

          <div style="text-align: center; display: inline-block">
            <a href="https://itunes.apple.com/us/app/my-aphasia-coach/id1163288893?ls=1&mt=8">
              <img alt="Get MyAphasiaCoach in the Apple App Store" border="0" style="width: 200px; margin: 10px" src="/images/appstore.png">
            </a>

            <a href="https://play.google.com/store/apps/details?id=com.myaphasiacoach">
              <img alt="Get My Aphasia Coach in the Google App Store" border="0" style="width: 200px; margin: 10px" src="/images/googleplay.png">
            </a>
          </div>

        </td>
        <td style="width: 10%"></td>
      </tr>
    </table>

  </div>
  <app-my-footer></app-my-footer>
</div>
