import {from, Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

import {TutorialModalComponent} from '../components/tutorial-modal/tutorial-modal.component';
import {VideoModalComponent} from '../components/video-modal/video-modal.component';
import {TherapyResponseModalComponent} from '../components/therapy-response-modal/therapy-response-modal.component';
import {ProgressModalComponent} from '../components/progress-modal/progress-modal.component';
import {PromotionModalComponent} from '../components/promotion-modal/promotion-modal.component';
import {HomeworkModalComponent} from '../components/homework-modal/homework-modal.component';
import {RevokeAccessModalComponent} from '../components/revoke-access-modal/revoke-access-modal.component';
import {SetCompleteModalComponent} from '../components/set-complete-modal/set-complete-modal.component';
import {ModuleCompletedModalComponent} from '../components/module-completed-modal/module-completed-modal.component';
import {DeleteHomeworkModalComponent} from '../components/delete-homework-modal/delete-homework-modal.component';
import {CloseAccountModalComponent} from '../components/close-account-modal/close-account-modal.component';

@Injectable()
export class ModalService {
    defaultOptions: NgbModalOptions = {
        centered: true
    };

    constructor(private modalService: NgbModal) {
    }

    video(param): Promise<any> {
        const options: NgbModalOptions = {
            size: 'lg',
            centered: true
        };

        const modalRef = this.modalService.open(VideoModalComponent, options);
        modalRef.componentInstance.videoUrl = param;
        return modalRef.result;
        // This works instead of returning modalRef.result
        // modalRef.result.then(result => doStuff(result));
        // If you dismiss the modal, there must be a dismiss handler in addition to close handler.
    }

    tutorial(param): Promise<any> {
        const modalRef = this.modalService.open(TutorialModalComponent, this.defaultOptions);
        modalRef.componentInstance.videoUrl = param;
        return modalRef.result;
    }

    therapyResponseNoPostSounds(correctAnswer, onLevel): Observable<any> {
        const options: NgbModalOptions = {
            windowClass: 'therapyResponse',
            centered: true
        };
        const modalRef = this.modalService.open(TherapyResponseModalComponent, options);
        modalRef.componentInstance.correctAnswer = correctAnswer;
        modalRef.componentInstance.onLevel = onLevel;
        modalRef.componentInstance.suppressPostSounds = true;
        return from(modalRef.result);
    }

    therapyResponse(correctAnswer, onLevel): Observable<any> {
        const options: NgbModalOptions = {
            windowClass: 'therapyResponse',
            centered: true
        };

        const modalRef = this.modalService.open(TherapyResponseModalComponent, options);
        modalRef.componentInstance.correctAnswer = correctAnswer;
        modalRef.componentInstance.onLevel = onLevel;
        return from(modalRef.result);
    }

    revokeAccess(): Promise<any> {
        const modalRef = this.modalService.open(RevokeAccessModalComponent, this.defaultOptions);
        return modalRef.result;
    }
    deleteHomework(): Promise<any> {
        const modalRef = this.modalService.open(DeleteHomeworkModalComponent, this.defaultOptions);
        return modalRef.result;
    }

    setComplete(correctItems): Promise<any> {
        const modalRef = this.modalService.open(SetCompleteModalComponent, this.defaultOptions);
        modalRef.componentInstance.correctItems = correctItems;
        return modalRef.result;
    }

    moduleComplete(): Promise<any> {
        const modalRef = this.modalService.open(ModuleCompletedModalComponent, this.defaultOptions);
        return modalRef.result;
    }

    homework(): Promise<any>  {
        const modalRef = this.modalService.open(HomeworkModalComponent, this.defaultOptions);
        return modalRef.result;
    }

    progressReport(): Promise<any>  {
        const modalRef = this.modalService.open(ProgressModalComponent, this.defaultOptions);
        return modalRef.result;
    }

    promotion(level): Promise<any>  {
        const modalRef = this.modalService.open(PromotionModalComponent, this.defaultOptions);
        modalRef.componentInstance.level = level;
        return modalRef.result;
    }

    closeAccount(): Promise<any>  {
        const modalRef = this.modalService.open(CloseAccountModalComponent, this.defaultOptions);
        return modalRef.result;
    }
}
