import * as _ from 'lodash';

import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {StimuliService} from './stimuli.service';

@Injectable()
export class NumbersService {
    constructor(private stimuliService: StimuliService,
    ) {
    }

    public generateNumbers(): Observable<boolean> {
        let numbers = [
            {
                word: '0',
                sound: '5b46b85d47507f001fef2032',
                alternateAnswer: 'zero'
            },
            {
                word: '2',
                sound: '5b46b85d2a7fd40037ad5284',
                alternateAnswer: 'two'
            },
            {
                word: '6',
                sound: '5b46b85f47507f001fef2033',
                alternateAnswer: 'six'
            },
            {
                word: '8',
                sound: '5b46b85e94926d0024717e92',
                alternateAnswer: 'eight'
            },
            {
                word: '4',
                sound: '5b46b85e61ad33003f345199',
                alternateAnswer: 'four'
            }, {
                word: '7',
                sound: '5b46b85dcd157300367033d7',
                alternateAnswer: 'seven'
            }, {
                word: '9',
                sound: '5b46b85efc2200002cc42841',
                alternateAnswer: 'nine'
            },
            {
                word: '1',
                sound: '57bd001d5356b82f00f799eb',
                alternateAnswer: 'one'
            },
            {
                word: '3',
                sound: '5b46b85e926e1f001abcda6f',
                alternateAnswer: 'three'
            }, {
                word: '5',
                sound: '5b46b85eec8c630026a70973',
                alternateAnswer: 'five'
            }];
        numbers = _.shuffle(numbers);
        numbers = _.shuffle(numbers);
        numbers = _.shuffle(numbers);
        numbers = _.shuffle(numbers);
        this.stimuliService.bsAlternatives.next(numbers);
        return of(true);
    }

}
