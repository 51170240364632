<div class="container-fluid bg-white">
  <div class="p-10">

    <h2 class="center">Audio & speech recognition</h2>
    This page is used for checking that you microphone and speakers are set up correctly.
    <br/>
    <br/>

    <h3>1. Speaker test</h3>
    Press the button to play a test sound.<br/>
    <button (click)="ding()" *ngIf="!recording" class="btn btn-primary">
      <span class="material-icons middle">play_circle_outline</span> Play sound.
    </button>
    <br/>
    <br/>
    <div [hidden]="!status.soundPlayed">
      Did you hear a sound?
      <br/>

      <button (click)="updateStatus({soundHeard:true,soundResponse:true})" class="btn btn-primary">
        Yes
      </button>
      <button (click)="updateStatus({soundHeard:false,soundResponse:true})" class="m-l-10 btn btn-secondary">
        No
      </button>
      <br/>

      <div *ngIf="status.soundHeard === false" class="p-t-10">
        Please check your volume, mute, and output device. Then try again.
      </div>
      <br/>

      <div *ngIf="status.soundResponse">
        <h3>2. Microphone test</h3>
        Click the Start speech recognition button, then say the phrase<br/>

        <div class="bold m-t-10 m-b-10 p-10 bg-gray inline-block">This is a test</div>

        <div class="p-b-10">
          <button (click)="start()" *ngIf="!recording" class="btn btn-primary">
            <span class="material-icons middle">mic</span>
            Start speech recognition
          </button>

          <button (click)="stop()" *ngIf="recording" class="btn btn-danger">
            <span class="material-icons middle">mic_off</span>
            Stop speech recognition
          </button>
        </div>


        Volume:
        <div class="bg-light-gray inline-block" style="width:300px; margin-top: 10px; border: 1px solid #444444;">
          <div [style.background-color]="vuColor" [style.width]="vuFill" style="height:20px; border-right: 1px solid #222222;"></div>
        </div>
        <br/>
        Highest volume: {{highestVolume}}<br/>

        <div *ngIf="status.micAllowed && status.highestVolume === null" class="p-10 m-10 bg-stop">No input detected</div>

        <div *ngIf="message" class="p-t-10">

          <div class="bold">
            <div class="inline-block p-r-10" style="width: 100px; text-align: right">Confidence</div>
            Transcript
          </div>
          <div *ngFor="let alternatives of message[0].alternatives" class="">
            <div class="inline-block p-r-10" style="width: 100px; text-align: right;">{{alternatives.confidence * 100 | number:'1.0-0'}}%</div>
            <span class=""> {{alternatives.transcript}}</span>
          </div>
        </div>


        <br/>
        <br/>

        <h3>3. Status Report</h3>
        Copy the text in the gray area and email it to us when requested.
        <br/>

        <pre class="p-10 bg-light-gray">{{status|json}}</pre>

      </div>
    </div>


  </div>
  <app-my-footer></app-my-footer>
</div>
