<div class="container m-t-30" style="clear: both">

  <div class="row">
    <div class="col-sm-12">
      <b>{{client}} selected</b><br/>


      <h2 class="m-t-30">Word Selection</h2>

      <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/click' + '/Naming'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/naming.png" width="100%">
            <div class="p-10 text-center">
              <h5>Noun Choice</h5>
              Choose the picture name
            </div>
          </div>
        </div>

        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/click' + '/VerbNaming'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/verb-naming.png" width="100%">
            <div class="p-10 text-center">
              <h5>Verb Choice</h5>
              Choose the picture name
            </div>
          </div>
        </div>
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/click' + '/Definitions'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/definitions.png" width="100%">
            <div class="p-10 text-center">
              <h5>Definition Choice</h5>
              Choose the defined word
            </div>
          </div>
        </div>


      </div>

      <h2 class="m-t-30">Reading</h2>

      <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/click' + '/Reading 1'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/reading-1.png" width="100%">
            <div class="p-10 text-center">
              <h5>Reading 1</h5>
              Read short sentences
            </div>
          </div>
        </div>
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/click' + '/Reading 2'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/reading-2.png" width="100%">
            <div class="p-10 text-center">
              <h5>Reading 2</h5>
              Read sentences
            </div>
          </div>
        </div>
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/click' + '/Reading 3'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/reading-3.png" width="100%">
            <div class="p-10 text-center">
              <h5>Reading 3</h5>
              Read longer sentences
            </div>
          </div>
        </div>
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/click' + '/Reading 4'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/reading-4.png" width="100%">
            <div class="p-10 text-center">
              <h5>Reading 4</h5>
              Read short paragraphs
            </div>
          </div>
        </div>
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/click' + '/Reading 5'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/reading-5.png" width="100%">
            <div class="p-10 text-center">
              <h5>Reading 5</h5>
              Choose words in paragraphs
            </div>
          </div>
        </div>

      </div>


      <h2 class="m-t-30">Image Selection</h2>

      <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/click' + '/4pics1sentence'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/reading-sentence-picture-match.png" width="100%">
            <div class="p-10 text-center">
              <h5>Sentence Picture Match 1</h5>
              Choose photos for written sentences
            </div>
          </div>
        </div>
      </div>


      <h2 class="m-t-30">Writing</h2>
      <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/writing' + '/Naming%20Writing'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/word-writing-nouns.png" width="100%">
            <div class="p-10 text-center">
              <h5>Noun Pictures 1</h5>
              Type picture names
            </div>
          </div>
        </div>
      </div>

      <h2 class="m-t-30">Scrambling</h2>
      <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/scramble/Scrambling'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/sentence-construction.png" width="100%">
            <div class="p-10 text-center">
              <h5>Sentence Construction</h5>
              Order words
            </div>
          </div>
        </div>
      </div>

      <h2 class="m-t-30">Free Form</h2>
      <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/cards'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/cards.png" width="100%">
            <div class="p-10 text-center">
              <h5>Cards</h5>
              Word cards
            </div>
          </div>
        </div>
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/whiteboard'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/whiteboard.png" width="100%">
            <div class="p-10 text-center">
              <h5>Whiteboard</h5>
              Shared whiteboard
            </div>
          </div>
        </div>
        <div class="col-sm-3 pointer p-10">
          <div [routerLink]="'/csat/' + clientId + '/text'" class="p-2 btn-primary fg-white bg-blue" style="height:100%;">
            <img src="/images/modules/whiteboard.png" width="100%">
            <div class="p-10 text-center">
              <h5>Words</h5>
              Send text
            </div>
          </div>
        </div>
      </div>


      <!--
                  <h2 class="m-t-30">Scramble</h2>

                  <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
                      <div class="col-sm-3 pointer p-10">
                          <div style="height:100%;" class="p-2 btn-primary fg-white bg-blue" [routerLink]="'/csat/scramble/' + clientId + '/Scrambling'">
                              <img width="100%" src="/images/modules/sentence-construction.png">
                              <div class="p-10 text-center">
                                  <h5>Sentence Construction</h5>
                                  Move the words to form a sentence
                              </div>
                          </div>
                      </div>

                  </div>
      -->


    </div>
  </div>

</div>
