import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {AnswerService} from '../../services/answer.service';
import {AudioService} from '../../services/audio.service';
import {BuilderService} from '../../services/builder.service';
import {ScreenService} from '../../services/screen.service';
import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

import {User} from '../../models/user';
import {settings} from '../../settings';

@Component({
    templateUrl: './writing1.component.html',
})
export class Writing1Component implements OnInit, OnDestroy {
    highlight;
    settings = settings;
    typeSettings: any = {};
    user: User | null = null;
    userInfo: any;
    type;
    stimuli: any;

    lastWrong;
    question;
    wrongAnswers = '';
    wrongAnswerCount = 0;
    answer: Array<any> = [];
    input: any = {};
    focus;

    private oUser;
    private oType;
    private oStimuli;
    private oTypeSettings;
    private oAlternatives;
    private oFocus;

    constructor(private userService: UserService,
                private answerService: AnswerService,
                private audioService: AudioService,
                private screenService: ScreenService,
                private builderService: BuilderService,
                private location: Location,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
        this.highlight = this.audioService.highlight;
    }

    ngOnInit(): Subscription {
        this.wrongAnswerCount = 0;
        this.wrongAnswers = '';

        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        });

        this.oType = this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        });

        this.oStimuli = this.stimuliService.stimuli.subscribe(result => {
            this.stimuli = null;

            if (!_.isEmpty(result)) {
                this.input = {};
                this.wrongAnswers = '';
                this.wrongAnswerCount = 0;
                this.answer = [];
                this.stimuli = result;

                const answer = _.sortBy(this.stimuli.levels, ['sortCode']);
                _.forEach(answer, (i) => {
                    this.answer.push(i.word.split(' '));
                });
                this.answer = _.flatten(this.answer);

                this.setFocus();
            }
        });

        this.oAlternatives = this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.question = result;
            }
        });

        this.oTypeSettings = this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        });

        this.oFocus = this.screenService.bsSetFocus.subscribe(result => {
            if (result) {
                this.screenService.bsSetFocus.next(false);
                this.setFocus();
            }
        });

        return this.builderService.getType().subscribe();

    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
        this.oType.unsubscribe();
        this.oStimuli.unsubscribe();
        this.oAlternatives.unsubscribe();
        this.oTypeSettings.unsubscribe();
        this.oFocus.unsubscribe();

    }

    checkAnswer(): any {
        if (this.input[0] && this.input[0].length > 0) {
            const temp = document.activeElement;
            (temp as any).blur();

            setTimeout(() => {
                return this.answerService.writing(this.input).subscribe((res) => {
                    this.input = {};
                    this.setFocus();
                }, err => {
                    console.error(err);
                });
            }, 100);
        }
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }

    setFocus(): any {
        return setTimeout(() => {
            try {
                // @ts-ignore
                document.getElementById('input_0').focus();
            } catch {
            }
        }, 300);
    }
}

