<div class="container m-t-30" style="clear: both">
  <div class="row">
    <div class="col-sm-12">
      <div #paint>
        <canvas #canvas (mousedown)="mousedown()" (mouseleave)="mouseleave()" (mousemove)="mousemove($event)" (mouseup)="mouseup()"
                style="width:100%; height: 400px; border: 1px solid #e0e0e0; background-color:  #f5f5f5; margin:0; padding: 0"></canvas>
      </div>
    </div>
  </div>


  <div class="row p-t-30">
    <div class="form-inline">
      <select [(ngModel)]="line" class="form-control m-l-15" name="line">
        <option value="1">─────</option>
        <option value="3">━━━━━━</option>
      </select>
      <select [(ngModel)]="color" [style.color]="color" class="form-control m-l-15" name="color">
        <option style="color: #000000;" value="#000000">▇</option>
        <option style="color: #ff0000;" value="#ff0000">▇</option>
        <option style="color: #00ff00;" value="#00ff00">▇</option>
        <option style="color: #0000ff;" value="#0000ff">▇</option>
      </select>
      <select [(ngModel)]="tool" class="form-control m-l-15" name="tool">
        <option value="freehand">Freehand</option>
        <option value="line">Line</option>
        <option value="rectangle">Rectangle</option>
        <option value="text">Text</option>
      </select>

      <button (click)="clear()" class="btn btn-primary m-l-15" type="button">CLEAR</button>
    </div>
  </div>

  <div class="row p-t-30">
    <div class="col-sm-12">
      <textarea [(ngModel)]="text" class="form-control" name="text" placeholder="Type text here and then draw a rectangle using the text tool to add it to the whiteboard."></textarea>
    </div>

  </div>

  <div class="row p-t-30">
    <div class="col-sm-12">
      <button (click)="back()" class="btn stop" type="button"><span class="material-icons middle">arrow_back_ios</span>  Back</button>
    </div>
  </div>
</div>
