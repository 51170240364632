<div class="container-fluid bg-white vh-100">
  <div class="container bg-white p-t-20">
    <div class="row">
      <div class="col-sm-12 p-15">
        <h1>Update Credit Card</h1>
        Is your card expiring? Update the information here.<br/>

        <div *ngIf="status.message" [ngClass]="{'alert-danger':status.error}" class="m-t-15 alert alert-success" id="messages">
          {{ status.message}}
        </div>

        <div *ngIf="!user" class="m-t-30">
          <h3>Please sign in to change your card.</h3>
        </div>

        <form #subscriptionForm="ngForm" *ngIf="user" class="form-horizontal m-t-30" id="payment-form" role="form">

          <div class="form-group row">
            <label class="col-3 col-form-label text-right">New card</label>
            <div class="col-6 p-t-10">
              <div id="card-element"></div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-3 col-form-label text-right"></label>
            <div class="col-9">
              <div [class]="authClass()">
                <input [(ngModel)]="formData.auth" name="auth" required type="checkbox"> I authorize this change<br/>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-3 col-form-label text-right"></label>
            <div class="col-9">
              <button (click)="getCardToken()" class="btn btn-primary" type="button">Update card</button>
            </div>
          </div>

          <div class="row" style="display:none;">
            <div class="col-xs-12">
              <p class="payment-errors"></p>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>

  <app-my-footer></app-my-footer>
</div>
