<div *ngIf="user" class="container-fluid bg-white p-t-20 ">
  <div class="row bg-white">
    <div class="col-sm-12 p-15">
      <app-version></app-version>
      <app-trial></app-trial>
    </div>
  </div>

  <div class="container">
    <h3>Search</h3>
    Use the drop down menus to only see modules that matches the criteria<br/>
    <br/>
  </div>
</div>

<div class="bg-white">

  <div class="bg-lighter-gray">
    <div class="flexbox container">
      <div class="p-10 m-t-5">
        <select [(ngModel)]="search" (change)="makeList()" class="search-dd">
        <option value="" disabled>Skill</option>
        <option value="reading">Reading</option>
        <option value="writing">Writing</option>
        <option value="speech">Speech</option>
        <option value="listening">Listening</option>
        <option value="multi">Multi Modal</option>
      </select>
      </div>
      <div class="p-10 m-t-5" [hidden]="!search">
        <select [(ngModel)]="search2" (change)="makeList()" class="search-dd">
        <option value="" disabled>Type</option>
        <option value="numbers">Numbers</option>
        <option value="oral" *ngIf="showType('oral')">Oral Reading</option>
        <option value="pictures" *ngIf="showType('pictures')">Pictures</option>
        <option value="sentences">Sentences</option>
        <option value="words">Words</option>
      </select>
      </div>
      <div class="p-10 m-t-5" [hidden]="hideFormatMenu()">
        <select [(ngModel)]="search3" (change)="makeList()" class="search-dd">
        <option value="" disabled>Format</option>
        <option *ngIf="showFormat('copying')" value="copying">Copying</option>
        <option *ngIf="showFormat('dictation')" value="dictation">Dictation</option>
        <option *ngIf="showFormat('fill')" value="fill">Fill in the blank</option>
        <option *ngIf="showFormat('multiple')" value="multiple">Multiple Sentences</option>
        <option *ngIf="showFormat('naming')" value="naming">Naming</option>
        <option *ngIf="showFormat('oral')" value="oral">Oral</option>
        <option *ngIf="showFormat('questions')" value="questions">Questions</option>
        <option *ngIf="showFormat('repetition')" value="repetition">Repetition</option>
        <option *ngIf="showFormat('scrambled')" value="scrambled">Scrambled</option>
        <option *ngIf="showFormat('videos')" value="videos">Videos</option>
      </select>
      </div>
      <div class="p-10 m-t-5">
        <button class="btn btn-primary m-b-10 p-0" (click)="resetForm()">Reset</button>
      </div>
    </div>
  </div>

  <div>
  <div class="" style="display:flex; justify-content:center;  flex-wrap: wrap;">
    <div *ngIf="search && tasks?.length && showModules" class="container">
      <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
        <div (click)="navigate(type, $event)" (auxclick)="navigate(type, $event)" *ngFor="let type of tasks" class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div [class]="getClass(type)" style="height:100%;">
            <img [src]="getImage(type)" width="100%"/>
            <div class="p-10 text-center">
              <h5>
                {{type.visibleName || type.name}}
              </h5>
              {{type.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>

<app-my-footer></app-my-footer>
