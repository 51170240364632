import * as _ from 'lodash';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
    showIEWarning = false;
    user;
    loginInProgress = false;
    alerts: Array<any> = [];
    loginData = {
        email: '',
        password: ''
    };
    private oUser;

    constructor(private userService: UserService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.oUser = this.userService.getUserInfo().subscribe(result => {
            if (!_.isEmpty(result)) {
                const route = ['/modules'];
                return this.router.navigate(route);
            }
            return false;
        });

        if (this.userService.isIE()) {
            this.showIEWarning = true;
        }
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
    }

    login(): Subscription {
        this.loginInProgress = true;
        this.alerts = [];

        return this.userService.login(this.loginData).subscribe(() => {
                const route = ['/modules'];
                return this.router.navigate(route);
            },
            (err) => {
                const msg = {
                    type: 'danger',
                    message: 'Something went wrong. Please reload the page'
                };

                if (err.status === 0) {
                    msg.message = 'Could not connect to the server. Please check your Internet connection';
                }

                if (err.status === 401) {
                    msg.message = 'Invalid username or password';
                }


                this.loginInProgress = false;
                this.alerts.push(msg);
            });
    }

    next(field?): Subscription | void {
        if (field) {
            if (document.getElementById(field)) {
                // @ts-ignore
                document.getElementById(field).focus();
            }
        }
        else {
            return this.login();
        }
    }

    closeAlert(index): void {
        this.alerts.splice(index, 1);
    }


}
