import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';

import {UserService} from '../../services/user.service';

@Component({
    selector: 'lost-password-reset',
    templateUrl: './lost-password-reset.component.html',
    styleUrls: ['./lost-password-reset.component.css']
})
export class LostPasswordResetComponent implements OnInit, OnDestroy {
    errorPassword;
    errorEmail;
    successMessage;

    formData = {
        password: '',
        id: '',
        lostPass: ''
    };

    constructor(private userService: UserService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params: Params) => {
            this.formData.id = params['id'];
            this.formData.lostPass = params['lostPass'];
        });
    }

    ngOnDestroy(): void {
    }

    processLostPassReset() {
        return this.userService.lostPassReset(this.formData).subscribe(response => {
            if (response.success === false) {
                // if not successful, bind errors to error variables
                this.errorPassword = response.errors.password;
                this.errorEmail = response.errors.email;
            }
            else {
                // if successful, bind success message to message
                this.successMessage = response.message;
                this.errorPassword = '';
            }
        });
    };

}



