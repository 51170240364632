import * as reSampler from '../../../audio.service';

import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {BuilderService} from '../../services/builder.service';
import {AnswerService} from '../../services/answer.service';
import {MessagingService} from '../../services/messaging.service';
import {ModalService} from '../../services/modal.service';
import {AudioService} from '../../services/audio.service';

import {settings} from '../../settings';
import {config} from '../../config';

@Component({
    selector: 'audio-test',
    templateUrl: './audio-test.component.html'
})

export class AudioTestComponent implements OnInit, OnDestroy {
    processor;
    source;
    ws;
    context;
    recording;
    attempts = 0;
    reConnecting = false;

    message;
    settings;
    config;
    alternatives: Array<any> = [];
    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;
    showIEWarning;

    clipLevel = 0.98;
    lastClip = 0;
    volume = 0;
    averaging = 0.95;
    vuFill = '0%';
    vuColor = '#00ff00';
    highestVolume = 0;

    status: any = {
        userAgent: window.navigator.userAgent,
        soundPlayed: false,
        soundResponse: false,
        soundHeard: null,
        micDetected: null,
        micAllowed: null,
        highestVolume: null,
        wsIn: null,
        wsOut: null,
        speechRecognition: {},
    };

    private oListen;

    constructor(private modalService: ModalService,
                private messagingService: MessagingService,
                private router: Router,
                private audioService: AudioService,
                private cdr: ChangeDetectorRef,
                private userService: UserService,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService,
                private zone: NgZone) {
        this.settings = settings;
        this.config = config;
        this.userInfo = this.userService.userInfo;
    }

    ngOnInit(): void {
        try {
            this.context = new AudioContext();
            this.status.micDetected = true;

        } catch (err: any) {
            this.status.micDetected = (err.name + ': ' + err.message);
        }

        this.oListen = this.audioService.bsListen.subscribe(result => {
            if (result) {
                return this.start();
            }
            return false;
        });


    }

    ngOnDestroy(): void {
        if (this.ws) {
            this.ws.send('stop');
            this.ws.close();
            this.ws = null;
        }

        if (this.processor) {
            this.processor.disconnect();
            this.processor = null;
        }

        this.source = null;
        this.recording = false;


        this.oListen.unsubscribe();
        this.audioService.bsListen.next(false);
    }

    start(): Promise<any> {
        this.audioService.startSound().then();

        this.recording = true;

        this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');
        this.ws.getDataStream().subscribe(
            (msg: MessageEvent) => {
                return this.receiveMessage(msg);
            });

        return navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
            this.status.micAllowed = true;
            return this.initializeRecorder(stream);
        }, (err) => {
            this.status.micAllowed = (err.name + ': ' + err.message);
        });
    }

    stop(skip?): Promise<any> {
        if (this.ws) {
            this.ws.send('stop');
            this.ws.close();
            this.ws = null;
        }

        if (this.processor) {
            this.processor.disconnect();
            this.processor = null;
        }

        this.source = null;
        this.recording = false;

        if (!skip) {
            return this.audioService.stopSound().then();
        }
        else {
            return Promise.resolve();
        }
    }

    initializeRecorder(stream): any {
        this.source = this.context.createMediaStreamSource(stream);
        this.processor = this.context.createScriptProcessor(8192, 1, 1);

        this.processor.clipping = false;
        this.processor.lastClip = 0;
        this.processor.volume = 0;
        this.processor.clipLevel = this.clipLevel || 0.98;
        this.processor.averaging = this.averaging || 0.95;

        this.processor.shutdown = function (): void {
            this.disconnect();
            this.onaudioprocess = null;
        };


        this.source.connect(this.processor);
        this.processor.connect(this.context.destination);


        return this.processor.onaudioprocess = (data) => {
            const buf = data.inputBuffer.getChannelData(0);
            const bufLength = buf.length;
            let sum = 0;
            let x;

            for (let i = 0; i < bufLength; i++) {
                x = buf[i];
                sum += x * x;
            }
            const rms = Math.sqrt(sum / bufLength);

            const math1 = Math.round(Math.max(rms, this.volume * this.averaging) * 100);

            if (math1 > 97) {
                this.vuColor = '#ff0000';
            }
            else {
                this.vuColor = '#00ff00';
            }

            if (math1 > this.highestVolume) {
                this.highestVolume = math1;
                this.status.highestVolume = math1;
            }

            this.vuFill = math1 + '%';
            this.cdr.detectChanges();

            return reSampler(data.inputBuffer, 16000, (event) => {
                try {
                    this.status.wsOut = true;
                    return this.ws.send(this.convertFloat32ToInt16(event.getAudioBuffer().getChannelData(0)), undefined, true).subscribe();
                } catch (err: any) {
                    this.status.wsOut = false;
                    // console.error(err);
                }
            });
        };
    }

    convertFloat32ToInt16(buffer): any {
        let l = buffer.length;
        const buf = new Int16Array(l);
        while (l--) {
            buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
        }
        return buf.buffer;
    }

    receiveMessage(msg?): any {
        this.status.wsIn = true;

        let message: any;
        try {
            message = JSON.parse(msg.data);
        } catch (err: any) {
        }
        if (message) {
            return this.stop().then(() => {
                this.message = message;
                this.status.speechRecognition = message;
            });
        }
    }

    ding(): Promise<any> {
        const audio = new Audio();
        let sound;
        sound = '5f7df46a3c5714002d36e15d';
        audio.src = config.mediaServer + 'blob.mp3/' + sound + '.mp3';
        return audio.play().then(() => {
            this.status.soundPlayed = true;
        });
    }

    updateStatus(stat): void {
        /*
                console.log(Object.keys(stat)[0]);
                console.log(stat[Object.keys(stat)[0]]);
        */
        this.status[Object.keys(stat)[0]] = stat[Object.keys(stat)[0]];
        this.status[Object.keys(stat)[1]] = stat[Object.keys(stat)[1]];
    }


}
