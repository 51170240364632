<div class="container p-t-20">
  <div class="row">
    <div class="col-sm-12 p-15">

      <div *ngFor="let type of types">
        '/therapy/{{type.templateType}}/{{type.name}}',
      </div>
    </div>

  </div>
</div>
<app-my-footer></app-my-footer>
