import {Component, OnDestroy, OnInit} from '@angular/core';

import {UserService} from '../../services/user.service';
import {config} from '../../config';


@Component({
    selector: 'app-version',
    templateUrl: './version.component.html'
})
export class VersionComponent implements OnInit, OnDestroy {
    userInfo;
    version;
    isMac = /Macintosh/i.test(window.navigator.userAgent);

    constructor(private userService: UserService) {
        this.userInfo = userService.userInfo;
        this.version = config.version;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

}
