<div class="container" id="lp-desktop" style="font-size: 1.3rem">

  <a routerLink="/" routerLinkActive="active">
    <img class="p-30" src="/images/logo.svg" width="250"/><br/>
  </a>

  <div style="background-color: #11a4ab; height:20px;">
  </div>
  <div class="" id="lp-d2">
    <div class="container-fluid" id="fp-2">
      <div class="p-30 p-b-10" id="fp-3">
        <h2 class="p-b-15" id="fp-4">Aphasia Therapy Software</h2>
        <h3 class="">Free for Speech Pathologists</h3>
        Download from the app store for FREE today!

        <div class="center p-t-15">
          <a href="https://itunes.apple.com/us/app/my-aphasia-coach/id1163288893?ls=1&mt=8" rel="noreferrer"
             target="_blank" title="Get My Aphasia Coach in the Apple App Store">
            <img alt="Get MyAphasiaCoach in the Apple App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/appstore.png" width="150">
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.myaphasiacoach" rel="noreferrer" target="_blank"
             title="Get My Aphasia Coach in the Google App Store">
            <img alt="Get My Aphasia Coach in the Google App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/googleplay.png" width="150">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="m-t-15 m-b-10" style="background-color:#11a4ab; height:10px;"></div>

  <div class="p-30 h3 font-italic" style="background-color:#11a4ab; color: #ffffff">
    <div class="center h1 font-weight-normal">
      Give your patients the advantage of intensive aphasia
      therapy exercises at home!
    </div>
    <br/>
    Exclusive Benefits:<br/>
    <ul>
      <li>Assign and Check Homework</li>
      <li>Monitor Progress</li>
      <li>All Language Modalities in the Same Place for One Low Price</li>
      <li>Exercises Adapt Automatically to Improve Learning</li>
      <li>1000s of Videos at 8 Different Levels for Critical Apraxia Practice</li>
    </ul>

  </div>

  <div class="center p-t-30">
    <a href="https://itunes.apple.com/us/app/my-aphasia-coach/id1163288893?ls=1&mt=8" rel="noreferrer"
       target="_blank" title="Get My Aphasia Coach in the Apple App Store">
      <img alt="Get MyAphasiaCoach in the Apple App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/appstore.png" width="250">
    </a>

    <a href="https://play.google.com/store/apps/details?id=com.myaphasiacoach" rel="noreferrer" target="_blank"
       title="Get My Aphasia Coach in the Google App Store">
      <img alt="Get My Aphasia Coach in the Google App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/googleplay.png" width="250">
    </a>
  </div>

  <div class="center h2 m-t-30">
    <div class="bg-black p-10 inline-block m-b-20">Always FREE for Speech Pathologists</div>
  </div>

</div>

<app-my-footer></app-my-footer>
