import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';


@Component({
    selector: 'get-access',
    templateUrl: './get-access.component.html',
    styleUrls: ['./get-access.component.css']
})
export class GetAccessComponent implements OnInit, OnDestroy {
    status;
    params = {
        userId: undefined,
        secret: undefined
    };

    constructor(private userService: UserService,
                private activatedRoute: ActivatedRoute) {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.params.userId = params['id'];
            this.params.secret = params['value'];
        });
    }

    ngOnInit(): Subscription {
        return this.userService.getAccess(this.params).subscribe(result => {
            this.status = result;
            this.userService.getUserInfo().subscribe();
        }, result => {
            this.status = result;
            this.userService.getUserInfo().subscribe();
        });
    }

    ngOnDestroy(): void {
    }
}
