<div *ngIf="userInfo.loggedIn">
  <div *ngIf="(userInfo.delinquent.googlePay || userInfo.delinquent.applePay || userInfo.delinquent.applePay) && !userInfo.basicSubscriber">
    <div class="alert alert-danger">
      There is a problem with your subscription.<br/>
      Please see the <a class="bold" routerLink="/account"><span class="text-dark-blue">account page</span></a>.
    </div>
  </div>

  <div *ngIf="(userInfo.expired.googlePay || userInfo.expired.applePay || userInfo.expired.applePay) && !userInfo.basicSubscriber">
    <div class="alert alert-danger">
      Your subscription has expired.
      <a class="bold" routerLink="/account"><span class="text-dark-blue">Subscribe here</span></a>.
    </div>
  </div>


  <div *ngIf="userInfo.showTrial && !userInfo.validTrial && !userInfo.basicSubscriber">
    <div class="alert alert-danger">
      Your free trial has ended. If you want to continue using My Aphasia Coach, you must become a subscriber.<br/><br/>
      <a class="bold" routerLink="/payment"><span class="text-dark-blue">Subscribe here</span></a>.
    </div>
  </div>

  <div *ngIf="userInfo.showTrial && userInfo.validTrial">
    <div *ngIf="dayDiff() > 1" class="alert alert-primary">Your free trial ends in {{dayDiff()}} days.
      <a class="bold" routerLink="/payment"><span class="text-dark-blue">Subscribe here</span></a>.
    </div>
    <div *ngIf="dayDiff() === 1" class="alert alert-info">Your free trial ends in 1 day.
      <a class="bold" routerLink="/payment"><span class="text-dark-blue">Subscribe here</span></a>.
    </div>
    <div *ngIf="dayDiff() === 0" class="alert alert-warning">Your free trial ends today.
      <a class="bold" routerLink="/payment"><span class="text-dark-blue">Subscribe here</span></a>.
    </div>
  </div>
</div>
