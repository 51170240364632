import * as _ from 'lodash';
import {of, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as reSampler from '../../../audio.service';
import {$WebSocket} from 'angular2-websocket';

import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SafeResourceUrl} from '@angular/platform-browser';

import {AudioService} from '../../services/audio.service';
import {BuilderService} from '../../services/builder.service';
import {ModalService} from '../../services/modal.service';
import {NumbersService} from '../../services/numbers.service';
import {ScreenService} from '../../services/screen.service';
import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

import {config} from '../../config';

@Component({
    templateUrl: './numbers.component.html'
})

export class NumbersComponent implements OnInit, OnDestroy {
    // highlight;
    config;
    typeSettings: any = {};
    user;
    userInfo;
    type;
    alternatives;
    onNumber = -1;
    numbers;
    correctAnswer;
    onStep = 0;
    recognition;
    wrongCount = 0;

    playingSound = false;
    processor;
    source;
    ws;
    context;
    screen = {recording: false, highlightNumber: 0};
    showEar = false;
    highlight;
    private subscriptions = new Subscription();

    constructor(private router: Router,
                private audioService: AudioService,
                private userService: UserService,
                private modalService: ModalService,
                private zone: NgZone,
                private cdr: ChangeDetectorRef,
                private screenService: ScreenService,
                private builderService: BuilderService,
                private numbersService: NumbersService,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
        this.config = config;
        this.highlight = this.audioService.highlight;
    }

    ngOnInit(): any {
        this.subscriptions.add(this.userService.bsUser.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.type.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        }));

        this.subscriptions.add(this.stimuliService.bsAlternatives.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
                if (this.screenService.bsOnStep.value === 0) {
                    this.makeStep0();
                }
            }
        }));

        this.subscriptions.add(this.stimuliService.bsTypeSettings.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        }));

        this.subscriptions.add(this.audioService.bsStopListen.subscribe(result => {
            if (result && this.screen.recording) {
                return this.stop(true);
            }
            return false;
        }));

        this.subscriptions.add(this.audioService.bsListen.subscribe(result => {
            this.playingSound = false;
            if (result) {
                return this.start();
            }
            return false;
        }));

        this.subscriptions.add(this.audioService.sListenAgain.subscribe(result => {
            this.audioService.audioObjects = [];
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[0].sound;
            this.audioService.audioObjects.push(audio);
            return this.audioService.playAudioObjects(0);
        }));

        this.subscriptions.add(this.screenService.bsOnStep.subscribe(result => {
            if (result) {
                this.onStep = result;
                if (result === 2) {
                    return this.makeStep2();
                }
            }
        }));

        this.subscriptions.add(this.audioService.bsShowEar.pipe(takeUntil(this.stimuliService.unsubscribe)).subscribe(result => {
            this.showEar = result;
        }));

        return this.builderService.getType().subscribe(() => {

        });

    }

    ngOnDestroy(): void {
        this.audioService.pauseSound();
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
        this.builderService.cleanUp().then();
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }

    makeStep0(): any {
        this.onNumber = 2;
        this.alternatives = _.shuffle(this.alternatives);
        this.alternatives = _.shuffle(this.alternatives);
        this.numbers = this.alternatives.slice(0, 3);

        let temp = JSON.parse(JSON.stringify(this.numbers));

        temp = _.shuffle(temp);
        temp = _.shuffle(temp);
        temp = _.shuffle(temp);

        this.correctAnswer = temp.slice(0, 1);


        const stats = _.find(this.userService.bsUser.value?.tasks, {task: name});

        /*if (stats) {
            this.audioService.audioObjects = [];
            // FIXME Static audio;
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[0].sound;
            this.audioService.audioObjects.push(audio);
            return this.audioService.playAudioObjects(0);
        }
        else {
            return of(this.audioService.playIntro(0));
        }*/
    }

    makeStep0a(): any {
        _.remove(this.numbers, {word: this.correctAnswer[0].word});

        this.onNumber = this.onNumber + 1;
        if (this.onNumber === 12) {
            this.onStep = 1;
            this.screenService.bsOnStep.next(1);
            this.onNumber = -1;
            this.makeStep1();
        }
        else {
            if (typeof this.alternatives[this.onNumber] === 'object') {
                this.numbers.push(this.alternatives[this.onNumber]);
            }
            else {
                this.numbers.push(this.alternatives[this.onNumber - 10]);
            }

            this.numbers = _.shuffle(this.numbers);
            this.numbers = _.shuffle(this.numbers);
            this.correctAnswer = JSON.parse(JSON.stringify(this.numbers.slice(0, 1)));
            this.numbers = _.shuffle(this.numbers);
            this.numbers = _.shuffle(this.numbers);

            this.audioService.audioObjects = [];
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[0].sound;
            this.audioService.audioObjects.push(audio);
            this.audioService.playAudioObjects(0);
        }
    }

    checkStep0(answer): any {
        if (answer.word === this.correctAnswer[0].word) {
            return this.modalService.therapyResponse(true, 1).subscribe({
                next: () => this.makeStep0a(),
                error: () => this.makeStep0a()
            });
        }
        else {
            return this.modalService.therapyResponse(false, 1).subscribe({
                next: () => this.playStep0Audio(),
                error: () => this.playStep0Audio()
            });
        }
    }

    playStep0Audio(): any {
        this.audioService.audioObjects = [];
        const audio = new Audio();
        audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[0].sound;
        this.audioService.audioObjects.push(audio);
        this.audioService.playAudioObjects(0).then();
    }

    makeStep1(): any {
        this.onNumber = this.onNumber + 1;

        let temp = JSON.parse(JSON.stringify(this.alternatives));
        temp = _.shuffle(temp);
        temp = _.shuffle(temp);
        temp = _.shuffle(temp);
        this.correctAnswer = temp;

        this.audioService.audioObjects = [];

        let audio = new Audio();
        audio.src = config.mediaServer + 'blob.mp3/602beef9ca047d0022d3b7a8';
        this.audioService.audioObjects.push(audio);

        audio = new Audio();
        audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[this.onNumber].sound;
        this.audioService.audioObjects.push(audio);

        audio = new Audio();
        audio.src = config.mediaServer + 'blob.mp3/5b70ad97def28500359340a0';
        this.audioService.audioObjects.push(audio);

        return this.audioService.playAudioObjects(0);
    }

    makeStep1a(): any {
        this.onNumber = this.onNumber + 1;
        this.audioService.audioObjects = [];

        if (this.correctAnswer[this.onNumber]) {
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[this.onNumber].sound;
            this.audioService.audioObjects.push(audio);

            return this.audioService.playAudioObjects(0);
        }
        else {
            this.onStep = 2;
            this.onNumber = 0;
            this.screenService.bsOnStep.next(2);
        }
    }

    playStep1Audio(): any {
        this.wrongCount++;
        if (this.wrongCount > 1) {
            this.wrongCount = 0;
            this.onNumber++;
        }

        if (this.correctAnswer[this.onNumber]) {
            this.audioService.audioObjects = [];
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[this.onNumber].sound;
            this.audioService.audioObjects.push(audio);
            return this.audioService.playAudioObjects(0);
        }
        else {
            // console.log('next');
            this.onStep = 2;
            this.onNumber = 0;
            this.screenService.bsOnStep.next(2);
        }
    }

    makeStep2(): any {
        this.audioService.bsShowEar.next(true);
        this.onNumber = 0;

        this.numbers = _.reject(this.alternatives, {word: '0'});
        this.numbers = _.shuffle(this.numbers);
        this.numbers = _.shuffle(this.numbers);

        this.correctAnswer = JSON.parse(JSON.stringify(this.numbers));

        this.numbers = _.shuffle(this.numbers);
        this.numbers = _.shuffle(this.numbers);

        const stats = _.find(this.userService.bsUser.value?.tasks, {task: name});
        if (stats) {
            this.audioService.audioObjects = [];
            let audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/5f64b6210e2dc200440a211b';
            this.audioService.audioObjects.push(audio);

            audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[this.onNumber].sound;
            this.audioService.audioObjects.push(audio);

            return this.audioService.playAudioObjects(0);
        }
        else {
            return of(this.audioService.playIntro(0));
        }


    }

    makeStep2a(): any {
        this.onNumber = this.onNumber + 1;
        if (this.correctAnswer[this.onNumber]) {
            this.audioService.audioObjects = [];
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[this.onNumber].sound;
            this.audioService.audioObjects.push(audio);

            return this.audioService.playAudioObjects(0);
        }
        else {
            this.onStep = 0;
            this.onNumber = -1;
            return this.makeStep0();
        }
    }

    checkStep2(answer): any {
        if (answer.word === this.correctAnswer[this.onNumber].word) {
            answer.disabled = true;
            return this.modalService.therapyResponse(true, 1).subscribe({
                next: () => this.makeStep2a(),
                error: () => this.makeStep2a()
            });
        }
        else {
            return this.modalService.therapyResponse(false, 1).subscribe({
                next: () => this.playStep2Audio(answer),
                error: () => this.playStep2Audio(answer)
            });
        }
    }

    playStep2Audio(answer): any {
        this.screen.highlightNumber = parseInt(this.correctAnswer[this.onNumber].word, 10);
        this.correctAnswer.push(this.correctAnswer[this.onNumber]);

        this.audioService.audioObjects = [];
        let audio = new Audio();
        audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[this.onNumber].sound;
        this.audioService.audioObjects.push(audio);

        this.onNumber = this.onNumber + 1;

        audio = new Audio();
        audio.src = config.mediaServer + 'blob.mp3/5f64b6210e2dc200440a211b';
        this.audioService.audioObjects.push(audio);

        audio = new Audio();
        audio.src = config.mediaServer + 'blob.mp3/' + this.correctAnswer[this.onNumber].sound;
        this.audioService.audioObjects.push(audio);


        setTimeout(() => {
            this.screen.highlightNumber = -1;
        }, 1000);

        return this.audioService.playAudioObjects(0);
    }

    start(): Promise<any> {
        this.screen.recording = true;

        return this.audioService.startSound().then(() => {
            // @ts-ignore
            const isChrome = !!(window.SpeechRecognition || webkitSpeechRecognition);

            if (isChrome) {
                // @ts-ignore
                const SpeechRecognition = window.SpeechRecognition || webkitSpeechRecognition;
                this.recognition = new SpeechRecognition();
                this.recognition.onspeechend = (res) => {
                    return this.zone.run(() => {
                        this.screen.recording = false;
                        this.recognition.stop();
                        this.audioService.stopSound().then();
                    });
                };

                this.recognition.onresult = (event) => {
                    return this.zone.run(() => {
                        let correct = false;
                        if (event.results && event.results[0]) {
                            _.forEach(event.results[0], alt => {
                                if (_.toLower(alt.transcript).includes(_.toLower(this.correctAnswer[this.onNumber].word.trim())) ||
                                    _.toLower(alt.transcript).includes(_.toLower(this.correctAnswer[this.onNumber].alternateAnswer.trim()))) {
                                    correct = true;
                                }
                            });
                        }

                        if (correct) {
                            return this.modalService.therapyResponse(true, 1).subscribe({
                                next: () => this.makeStep1a(),
                                error: () => this.makeStep1a()
                            });
                        }
                        else {
                            return this.modalService.therapyResponse(false, 1).subscribe({
                                next: () => this.playStep1Audio(),
                                error: () => this.playStep1Audio()
                            });
                        }
                    });
                };

                return this.recognition.start();
            }
            else {
                try {
                    this.context = new AudioContext();
                    this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');
                } catch (err: any) {
                    // this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
                }

                this.ws.getDataStream().subscribe((msg: MessageEvent) => {
                    return this.receiveMessage(msg);
                });

                return navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
                    return this.zone.run(() => {
                        this.source = this.context.createMediaStreamSource(stream);
                        this.processor = this.context.createScriptProcessor(8192, 1, 1);

                        this.source.connect(this.processor);
                        this.processor.connect(this.context.destination);

                        return this.processor.onaudioprocess = (data) => {
                            return this.zone.run(() => {
                                return reSampler(data.inputBuffer, 16000, (event) => {
                                    return this.zone.run(() => {
                                        try {
                                            if (this.ws && this.ws.send()) {
                                                return this.ws.send(this.convertFloat32ToInt16(event.getAudioBuffer().getChannelData(0)), undefined, true).subscribe();
                                            }
                                        } catch (err: any) {
                                            console.error(new Error(err));
                                        }
                                    });
                                });
                            });
                        };
                    });
                }, () => {
                    // this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
                });
            }
        });
    }

    stop(skip?): Promise<any> {
        this.audioService.bsListen.next(false);

        if (this.builderService.timer) {
            clearTimeout(this.builderService.timer);
        }

        if (this.ws) {
            this.ws.send('stop');
            this.ws.close();
            this.ws = null;
        }

        if (this.processor) {
            this.processor.disconnect();
            this.processor = null;
        }

        this.source = null;
        this.builderService.timer = null;
        this.screen.recording = false;

        if (!skip) {
            return this.audioService.stopSound();
        }
        else {
            return Promise.resolve(true);
        }
    }

    stopButton(): any {
        this.screen.recording = false;
        if (this.recognition) {
            this.recognition.stop();
        }
        return this.audioService.stopSound();
    }

    receiveMessage(msg?): any {
        console.log(msg);
        return this.zone.run(() => {
            let message;
            try {
                message = JSON.parse(msg.data);
            } catch (err: any) {
                console.error(new Error(err));
            }

            if (message) {
                return this.stop().then(() => {
                    let correct = false;


                    if (message && message[0] && message[0].alternatives) {
                        _.forEach(message[0].alternatives, alt => {
                            if (_.toLower(alt.transcript).includes(_.toLower(this.correctAnswer[0].word.trim())) ||
                                _.toLower(alt.transcript).includes(_.toLower(this.correctAnswer[0].alternateAnswer.trim()))) {
                                correct = true;
                            }
                        });
                    }
                    if (correct) {
                        return this.modalService.therapyResponse(true, 1).subscribe({
                            next: () => this.makeStep1a(),
                            error: () => this.makeStep1a()
                        });
                    }
                    else {
                        return this.modalService.therapyResponse(false, 1).subscribe({
                            next: () => this.playStep1Audio(),
                            error: () => this.playStep1Audio()
                        });
                    }
                });
            }
            else {
                console.log('no msg');
            }
            return of(false);
        });
    }

    convertFloat32ToInt16(buffer): any {
        let l = buffer.length;
        const buf = new Int16Array(l);
        while (l--) {
            buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
        }
        return buf.buffer;
    }

    highlightAnswer(answer): boolean {
        if (parseInt(answer.word, 10) === this.screen.highlightNumber) {
            return true;
        }
        return false;
    }

    isDisabled(answer): boolean {
        return answer.disabled;
    }

}
