<div class="container p-t-20 bg-white">
  <div class="row">
    <div class="col-sm-12 p-15">
      <h1>We have lost contact with the Internet</h1>
      Please check your Internet connection, then select therapy from the menu.
    </div>
  </div>
</div>

<app-my-footer></app-my-footer>
