// @ts-nocheck
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import * as ot from 'ot';
import DiffMatchPatch from 'diff-match-patch';

import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';

import {UserService} from '../../services/user.service';
import {CsatService} from '../../services/csat.service';
import {config} from '../../config';
import {settings} from '../../settings';

@Component({
    selector: 'csat-text',
    templateUrl: './csat-text.component.html',
    styleUrls: ['./csat-text.component.css']
})
export class CsatTextComponent implements OnInit, OnDestroy, AfterViewInit {

    alternatives = [];
    client;
    clientId;
    inter;
    settings;
    user;
    userInfo;
    ws;
    text = '';
    textBackup = '';
    otClient;
    outgoing = {
        document: 'abc123',
        messageType: 'csat',
        revision: null,
        doc: true,
        reset: false,
        content: null,
        from: '',
        to: '',
        unique: Date.now()
    };
    incoming = {
        revision: null,
        from: '',
        reset: false,
        content: null,
        unique: null,
        doc: true,
        init: false,
        document: 'abc123'
    };
    private subscriptions = new Subscription();
    private config;
    private timer;

    constructor(private csatService: CsatService,
                private userService: UserService,
                private cdr: ChangeDetectorRef,
                private location: Location) {
        this.config = config;
        this.settings = settings;
        this.clientId = this.location.path(true).split('/')[2];
    }

    ngOnInit(): void {
        this.ws = new $WebSocket(this.config.webSocket, null, {'reconnectIfNotNormalClose': true}, 'arraybuffer');

        this.subscriptions.add(this.userService.getUserInfo().subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                this.outgoing.from = this.user._id;

                const msg = {
                    doc: true,
                    document: 'abc123',
                    init: true,
                    from: this.user._id,
                    to: this.clientId,
                    unique: Date.now()
                }

                this.subscriptions.add(this.ws.send(msg).subscribe((res) => {
                }, (err) => {
                    // console.error(new Error(err));
                }));

            }
        }));

        this.subscriptions.add(this.userService.getClientList().subscribe(result => {
            const client = _.find(result, ['_id', this.clientId]);
            this.client = client.local.name + ', (' + client.local.email + ')';
            this.outgoing.to = client._id;
        }));

        this.subscriptions.add(this.ws.getDataStream().subscribe(
            (msg: MessageEvent) => {
                return this.receiveMessage(msg);
            },
            (err) => {
                console.error(new Error(err));
            }));

        this.ws.onOpen(() => {
            return setTimeout(() => {
                return this.refresh();
            }, 1000);
        });

        this.initOT();
    }

    ngOnDestroy(): void {
        clearTimeout(this.timer);
        this.ws.close();
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit() {
    }

    keyup() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            const matcher = new DiffMatchPatch();
            const changes = matcher.diff_main(this.textBackup, this.text);
            matcher.diff_cleanupSemantic(changes);

            const operation = new ot.TextOperation();

            if (changes.length === 1) {
                if (changes[0][0] < 0) {
                    operation.delete(changes[0][1]).retain(0);
                }
                else if (changes[0][0] === 0) {
                    // FIXME Cursor movement and selection
                }
                if (changes[0][0] > 0) {
                    operation.retain(0).insert(changes[0][1]);
                }
            }
            else if (changes.length === 2) {
                if (changes[0][0] < 0) {
                    operation.insert(changes[0][1]).retain(changes[1][1].length);
                }
                else if (changes[0][0] === 0 && changes[1][0] === 1) {
                    operation.retain(changes[0][1].length).insert(changes[1][1]);
                }
                else if (changes[0][0] === 0 && changes[1][0] === -1) {
                    operation.retain(changes[0][1].length).delete(changes[1][1]);
                }
                else if (changes[0][0] > 0) {
                    operation.insert(changes[0][1]).retain(changes[1][1].length);
                }
            }
            else if (changes.length === 3) {
                if (changes[0][0] === 0 && changes[1][0] > 0) {
                    operation.retain(changes[0][1].length).insert(changes[1][1]).retain(changes[2][1].length);
                }
                else if (changes[0][0] === 0 && changes[1][0] < 0) {
                    operation.retain(changes[0][1].length).delete(changes[1][1]).retain(changes[2][1].length);
                }

            }
            else if (changes.length === 4) {
                operation.retain(changes[0][1].length).delete(changes[1][1]).insert(changes[2][1]).retain(changes[3][1].length);
            }
            else if (changes.length > 4) {
                console.error(new Error(changes));
            }

            if (operation.ops && operation.ops.length > 0) {
                this.outgoing.content = JSON.stringify(operation);
                this.textBackup = JSON.parse(JSON.stringify(this.text));

                return this.otClient.applyClient(operation);
            }
        }, 500);
    }

    initOT() {
        this.otClient = new ot.Client(0);

        this.otClient.applyOperation = (operation) => {
            try {
                this.text = operation.apply(this.text);
                this.textBackup = JSON.parse(JSON.stringify(this.text));
            } catch (err: any) {
                console.error(new Error(err));
                return this.initOT();
            }
        };

        this.otClient.sendOperation = (revision, operation) => {
            if (localStorage && localStorage.setItem) {
                localStorage.setItem('abc123', JSON.stringify(this.text));
            }

            this.outgoing.revision = revision;
            return this.sendMessage();
        };
    }

    sendMessage() {
        return this.ws.send(this.outgoing).subscribe(
            (res) => {
            },
            (err) => {
                return setTimeout(() => {
                    return this.sendMessage();
                }, 500);
            });
    }

    receiveMessage(msg?) {
        try {
            const message = JSON.parse(msg.data);
            this.incoming = message;
        } catch (err: any) {
            console.error(new Error(err));
        }

        if (this.incoming.init) {
            this.text = '';
            this.textBackup = '';
            this.outgoing.revision = 0;
            this.initOT();
        }
        else if (this.incoming.unique === this.outgoing.unique) {
            this.otClient.serverAck();
        }
        else {
            const operation = ot.TextOperation.fromJSON(JSON.parse(this.incoming.content));
            this.otClient.applyServer(operation);
        }
    }

    back() {
        return this.location.back();
    }

    clear() {
        this.text = '';
        if (localStorage && localStorage.setItem) {
            localStorage.setItem('abc123', '');
        }
        this.keyup();
    }

    refresh(): void {
        if (localStorage && localStorage.getItem) {
            const ls = localStorage.getItem('abc123');
            if (ls) {
                this.text = JSON.parse(ls);
                this.keyup();
            }
        }
    }

}
