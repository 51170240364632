import * as _ from 'lodash';

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-stats-clients',
    templateUrl: './stats-clients.component.html',
    styleUrls: ['./stats-clients.component.css']
})
export class StatsClientsComponent implements OnInit, OnDestroy {
    types = [];
    dateSort = true;
    __: any;

    @Input() user;
    tempUser;
    clientData;
    typesSorted = {
        reading: {
            0: [],
            1: [],
            2: [],
            3: [],
        },
        writing: {
            0: [],
            1: [],
            2: [],
            3: [],
        },
        comprehension: {
            0: [],
            1: [],
            2: [],
            3: [],
        },
        speech: {
            0: [],
            1: [],
            2: [],
            3: [],
        }
    };

    private oTypes;

    constructor(private stimuliService: StimuliService,
                private cdr: ChangeDetectorRef,
                private userService: UserService) {
        this.__ = _;
    }

    ngOnInit(): void {
        this.clientData = this.user;
        this.types = this.stimuliService.bsTypes.value;

        if (this.types) {
            this.tempUser = JSON.parse(JSON.stringify(this.user));
            this.tempUser.tasks = _.reverse(_.sortBy(this.user.tasks, ['modified']));
            console.log(this.tempUser.tasks);
            _.forEach(this.tempUser.tasks, task => {
                task.name = this.getVisibleName(task.task);
                [task.worksOn, task.level] = this.getLevelAndType(task);
            });
            // console.log(this.tempUser.tasks)

            // console.log(this.user.tasks);

            _.forEach(this.tempUser.tasks, (type: any) => {
                if (type.worksOn.reading && type.level) {
                    this.typesSorted.reading[parseInt(type.level, 10)].push(type);
                }
                if (type.worksOn.writing && type.level) {
                    this.typesSorted.writing[parseInt(type.level, 10)].push(type);
                }
                if (type.worksOn.comprehension && type.level) {
                    this.typesSorted.comprehension[parseInt(type.level, 10)].push(type);
                }
                if (type.worksOn.speech && type.level) {
                    this.typesSorted.speech[parseInt(type.level, 10)].push(type);
                }
            });
            console.log(this.typesSorted);
        }
        else {

            this.oTypes = this.stimuliService.getTypes().subscribe(result => {
                this.types = result;
                _.forEach(this.user.tasks, task => {
                    task.name = this.getVisibleName(task.task);
                });
                this.user.tasks = _.reverse(_.sortBy(this.user.tasks, ['modified']));
            });
        }
    }

    ngOnDestroy(): void {
        if (this.oTypes) {
            this.oTypes.unsubscribe();
        }
    }

    getVisibleName(name): string {
        const match = _.find(this.types, {name});
        if (match && (match as any).visibleName) {
            return (match as any).visibleName;
        }
        else {
            return name;
        }
    }

    getLevelAndType(task): any {
        const match: any = _.find(this.types, {name: task.task});
        if (match) {
            return [match.worksOn, match.menu.level];
        }
        else {
            return [{}, 1];
        }
    }

    getLinkByName(name): string | void {
        const type: any = _.find(this.types, {name});
        if (type) {
            return '/therapy/' + (type as any).templateType + '/' + type.name;
        }
    }

    converter(item) {
        return Object.entries(item);
    }

    toggleSort(): boolean {
        this.dateSort = !this.dateSort;

        if (!this.dateSort) {
            this.user.tasks = _.sortBy(this.user.tasks, ['name']);
        }
        else {
            this.user.tasks = _.reverse(_.sortBy(this.user.tasks, ['modified']));
        }
        return this.dateSort;
    }

    toggleAssigned(type): Subscription {
        if (this.user && this.user.homework) {
            const has = _.find(this.user.homework, {module: type.task});

            if (has) {
                _.remove(this.user.homework, currentObject => {
                    return (currentObject as any).module === type.task;
                });
            }
            else {
                this.user.homework.push({module: type.task, created: new Date(), done: null, todo: 0});
            }

        }
        else {
            this.user.homework = [{module: type.task, created: new Date(), done: null, todo: 0}];
        }

        this.user.homework = _.sortBy(this.user.homework, 'module');
        return this.userService.saveHomework(this.user).subscribe();
    }

    isAssigned(type): boolean {
        if (this.user && this.user.homework) {
            const has = _.find(this.user.homework, {module: type.task});
            if (has) {
                return true;
            }
        }
        return false;
    }

    canAssign(type): boolean {
        if (this.user && this.user.tasks) {
            const t2 = _.find(this.user.tasks, {task: type.task});
            const t3 = _.find(this.types, {name: type.task}) as any;
            if (t2 && t3 && t2.onLevel > t3.maxLevel) {
                return false;
            }
        }
        if (this.user && this.user.homework) {
            const has = _.find(this.user.homework, {module: type.task});
            if (has) {
                return false;
            }
        }
        return true;
    }

    updateHomework(name, count): Subscription {
        const hw = _.find(this.user.homework, {module: name});
        // @ts-ignore
        hw.todo = count;
        return this.userService.saveHomework(this.user).subscribe();
    }

    getCount(task): any {
        const hw = _.find(this.user.homework, {module: task.task});
        if (hw) {
            // @ts-ignore
            return hw.todo;
        }
    }

    isHomeworkDone(task): boolean {
        const hw = _.find(this.user.homework, {module: task.task});
        if (hw) {
            // @ts-ignore
            if (hw.done && hw.todo <= hw.done) {
                return true;
            }
        }

        if (this.user && this.user.tasks) {
            const t2 = _.find(this.user.tasks, {task: task.task});
            const t3 = _.find(this.types, {name: task.task}) as any;
            if (t2 && t3 && t2.onLevel > t3.maxLevel) {
                return true;
            }
        }

        return false;
    }

    showReset(task): boolean {
        if (this.user && this.user.tasks) {
            const t2 = _.find(this.user.tasks, {task: task.task});
            const t3 = _.find(this.types, {name: task.task}) as any;
            if (t2 && t3 && t2.onLevel > t3.maxLevel) {
                return false;
            }
        }
        const hw = _.find(this.user.homework, {module: task.task});
        if (hw) {
            // @ts-ignore
            if (hw.done && hw.todo <= hw.done) {
                return true;
            }
        }
        return false;
    }

    reset(task): Subscription {
        const newTask = JSON.parse(JSON.stringify(task));

        if (this.clientData && this.clientData.homework) {
            const has = _.find(this.clientData.homework, {module: task.task});

            if (has) {
                _.remove(this.clientData.homework, currentObject => {
                    return (currentObject as any).module === task.task;
                });
            }
            this.clientData.homework.push({module: newTask.module, created: new Date(), done: null, todo: task.todo});
            this.clientData.homework = _.sortBy(this.clientData.homework, 'module');
        }

        return this.userService.saveHomework(this.clientData).subscribe();
    }

}
