import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css'],
    providers: [NgbCarouselConfig],
    encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit, OnDestroy {
    settings;
    name;
    formData: any = {
        name: null,
        email: null,
        message: null
    };

    emailSent;
    errorMessage;

    user;
    userInfo;

    constructor(private userService: UserService,
                config: NgbCarouselConfig) {
        this.userInfo = userService.userInfo;

        config.interval = 10000;
        config.showNavigationIndicators = false;
        config.keyboard = false;
        config.pauseOnHover = false;
        config.showNavigationArrows = false;
        config.showNavigationIndicators = false;

    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            this.user = result;
            this.name = this.userService.getName();
            this.formData.name = this.userService.getName();
            this.formData.email = this.userService.getEmail();
        });

    }

    ngOnDestroy(): void {
    }

    processContact(): any {
        if (this.formData.message !== null) {
            return this.userService.getHelp(this.formData).subscribe(res => {
                this.emailSent = res.success;
                this.errorMessage = res.error;
            });
        }
    }
}
