import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {config} from '../config';
import {StimuliService} from './stimuli.service';

@Injectable()
export class ScreenService {
    bsSetFocus = new BehaviorSubject<boolean>(false);
    bsNewLevel = new BehaviorSubject<boolean>(false);
    bsOnStep = new BehaviorSubject<number>(0);

    constructor(private sanitizer: DomSanitizer,
                private stimuliService: StimuliService) {
    }

    getAnswerImage(image): SafeResourceUrl {
        const url = config.mediaServer + 'blob.600/' + image + '.jpg';
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    getStimuliImage(): SafeResourceUrl {
        const url = config.mediaServer + 'blob.500/' + this.stimuliService.bsStimuli.value.image + '.jpg';
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    hasClass(el, className): boolean {
        if (el.classList) {
            return el.classList.contains(className);
        }
        else {
            return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
        }
    }

    addClass(el, className): void {
        if (el.classList) {
            el.classList.add(className);
        }
        else if (!this.hasClass(el, className)) {
            el.className += ' ' + className;
        }
    }

    removeClass(el, className): void {
        if (el.classList) {
            el.classList.remove(className);
        }
        else if (this.hasClass(el, className)) {
            const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
            el.className = el.className.replace(reg, ' ');
        }
    }

}
