import {settings} from '../../settings';
import {User} from '../../models/user';
import {UserService} from '../../services/user.service';
import {VideoService} from '../../services/video.service';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';


import * as _ from 'lodash';


import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {StimuliService} from '../../services/stimuli.service';
import {Subscription, zip} from 'rxjs';

@Component({
    selector: 'app-my-tasks',
    templateUrl: './my-tasks.component.html'
})

export class MyTasksComponent implements OnInit, OnDestroy {
    settings;
    userInfo;

    user: User | null = null;
    tasks: Array<any> = [];
    videos;
    types;
    homework: Array<any> = [];

    show = true;

    private oUser;
    private oTypes;
    private oVideosUnfiltered;
    private oMyVideos;


    constructor(private stimuliService: StimuliService,
                private userService: UserService,
                private videoService: VideoService,
                private modalService: ModalService,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private router: Router) {
        this.settings = settings;
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): void {
        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                // @ts-ignore
                this.myVideos = this.user.homeworkVideos;
            }
        });

        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            if (result) {
                result = _.sortBy(result, 'visibleName');
                this.types = result;

                _.forEach(this.types, type => {
                    if (this.userService.isAdmin() || (type.visible && type.visibility.web) || (type.visible && this.user?.beta)) {
                        if (this.isMyTask(type)) {
                            this.tasks.push(type);
                        }
                    }
                });

            }
        });

    }

    ngOnDestroy(): void {
        if (this.oUser) {
            this.oUser.unsubscribe();
        }
        if (this.oVideosUnfiltered) {
            this.oVideosUnfiltered.unsubscribe();
        }
    }

    isAlpha(type): boolean {
        if (!type.visible) {
            return true;
        }

        return false;
    }

    isMyTask(type): boolean {
        const recent = _.find(this.userService.bsUser.value?.tasks, {task: type.name});
        if (recent?.modified) {
            const date = new Date();
            date.setDate(date.getDate() - 7);
            const modified = new Date(recent.modified);

            if (modified > date) {
                return true;
            }
        }
        return false;
    }

    isBeta(type): boolean {
        if (type.active && !type.visible) {
            return true;
        }
        else if (type.active && type.visible && !type.visibility.web) {
            return true;
        }
        return false;
    }

    hasHomework(): boolean {
        if (this.user && ((this.user.homework && this.user.homework.length > 0) || (this.user.homeworkVideos && this.user.homeworkVideos.length > 0))) {
            return true;
        }
        return false;
    }

    hasVideos(): boolean {
        if (this.user && this.user.videos && this.user.videos.length > 0) {
            return true;
        }
        return false;
    }

    myHomework(): Promise<any> {
        const route = ['/my-homework'];
        return this.router.navigate(route);
    }

    navigate(type, event): Promise<any> {
        if (!this.userService.userInfo.premiumSubscriber) {
            if (this.userService.userInfo.basicSubscriber) {
                const route = ['/modules-videos'];
                return this.router.navigate(route);
            }
            else {
                const route = ['/account'];
                return this.router.navigate(route);
            }
        }
        else {
            const route = ['/therapy/' + type.templateType + '/' + type.name];
            if (event.button === 1) {
                window.open(route[0], '_blank');
                return Promise.resolve();
            }
            else {
                return this.router.navigate(route);
            }
        }
    }

    getClass(type): string {
        let returnData = 'p-2 btn-primary ';

        if (type.menu && type.menu.color) {
            returnData = returnData + ' ' + type.menu.color;
        }
        else {
            returnData = returnData + ' fg-default ';
        }
        if (type.menu && type.menu.backgroundColor) {
            returnData = returnData + ' ' + type.menu.backgroundColor;
        }
        else {
            returnData = returnData + ' bg-default ';
        }
        return returnData;
    }

    getImage(type): string {
        const task = _.find(this.user?.tasks, {task: type.name});
        if (task && task.onLevel > type.maxLevel) {
            return '/images/modules/finished.png';
        }
        else if (type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }

    highlightModule(module): boolean {
        if (module === this.settings.highlightModule) {
            return true;
        }
        else {
            return false;
        }
    }

}
