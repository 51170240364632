import {BehaviorSubject, from, Observable, of} from 'rxjs';

import * as _ from 'lodash';

import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';

import {AudioService} from './audio.service';
import {MessagingService} from './messaging.service';
import {ModalService} from './modal.service';
import {ScreenService} from './screen.service';
import {NumbersService} from './numbers.service';
import {StimuliService} from './stimuli.service';
import {UserService} from './user.service';

import {config} from '../config';
import {settings} from '../settings';

@Injectable()
export class BuilderService {
    config;
    type: any = {};
    stimuli;
    levels: Array<any> = [];
    currentNumber = 0;
    currentStimuli = 0;
    set: Array<any> = [];
    previousSet: Array<any> = [];
    onSetItem = 0;
    correctSetItems = 0;
    fetchId;
    onLevel = 1;
    timer;
    skipListen = false;

    bsOnLevel = new BehaviorSubject<number>(this.onLevel);

    urlType;
    urlName;
    urlLevel;
    urlId;

    constructor(private sanitizer: DomSanitizer,
                private messagingService: MessagingService,
                private stimuliService: StimuliService,
                private audioService: AudioService,
                private numbersService: NumbersService,
                private screenService: ScreenService,
                private userService: UserService,
                private modalService: ModalService,
                private router: Router,
                private location: Location) {
        this.config = config;
    }

    init(): void {
        this.skipListen = false;
        this.messagingService.setMessage(null);
        this.urlType = this.location.path(true).split('/')[2];
        this.urlName = this.location.path(true).split('/')[3];
        this.urlLevel = this.location.path(true).split('/')[4];
        this.urlId = this.location.path(true).split('/')[5];
        this.fetchId = null;
    }

    getType(typeName?): Observable<any> {
        this.init();

        if (typeName) {
            this.urlName = typeName;
        }

        return of(this.stimuliService.getType(this.urlName).subscribe(() => {
            return this.getUserInfo().subscribe();
        }));
    }

    getUserInfo(): Observable<any> {
        return of(this.userService.getUserInfo().subscribe(() => {
            if (this.stimuliService.bsType.value) {
                // this.modulePromotion();

                // @ts-ignore
                const task = _.find(this.userService.bsUser.getValue().tasks, {task: this.stimuliService.bsType.getValue().name});

                if (task && _.has(task, 'onLevel')) {
                    const newLevel = parseInt((task as any).onLevel, 10);


                    if ((task as any).alertLevel === true) {
                        this.bsOnLevel.next(newLevel);
                        this.screenService.bsNewLevel.next(true);
                    }

                    if (parseInt((task as any).onLevel, 10) < 1) {
                        this.onLevel = 1;
                    }
                    else {
                        this.onLevel = parseInt((task as any).onLevel, 10);
                    }
                }
                else {
                    this.onLevel = 1;
                }

                if (this.urlLevel) {
                    this.onLevel = parseInt(this.urlLevel, 10);
                }

                // @ts-ignore
                const homework = _.find(this.userService.bsUser.getValue().homework, ['module', decodeURIComponent(this.urlName)]);
                if (homework && homework.done === homework.todo) {
                    this.audioService.skipPlay = true;
                }

                if (this.urlType === 'speech-pacing') {
                    return of(true);
                }
                else if (this.urlType === 'numbers') {
                    return this.getNumbers().subscribe();
                }
                else if (this.stimuliService.bsType.getValue().buildType === 'whMixed') {
                    if (this.onLevel > this.stimuliService.bsType.value.maxLevel) {
                        return this.modulePromotion();
                    }
                    else {
                        return this.getWhMixed().subscribe();
                    }
                }
                else {
                    if (this.onLevel > this.stimuliService.bsType.value.maxLevel) {
                        return this.modulePromotion();
                    }
                    else {
                        return this.getStimuliSelect().subscribe();
                    }
                }
            }
            else {
                return false;
            }
        }));
    }

    modulePromotion(): Promise<any> {
        this.type = this.stimuliService.bsType.getValue();
        const next = _.find(this.stimuliService.bsTypes.getValue(), {name: this.type.promotionModule});
        let link;
        let message;
        if (next) {
            link = '/therapy/' + next.templateType + '/' + next.name;
            settings.highlightLevel = next.menu.level;
            settings.highlightModule = next.name;
            message = {
                messageDate: new Date(),
                message: 'You have been promoted from ' + this.type.visibleName + ' to ' + next.visibleName,
                completedMessage: true,
                completedModule: this.type.promotionModule,
            };
        }
        else {
            link = '/modules';
            message = {
                messageDate: new Date(),
                message: 'You have been completed ' + this.type.visibleName,
                completedMessage: true,
                completedModule: this.type.promotionModule,
            };
        }
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };


        if (next?.worksOn?.reading) {
            settings.highlightType = 'reading';
        }
        else if (next?.worksOn?.writing) {
            settings.highlightType = 'writing';
        }
        else if (next?.worksOn?.comprehension) {
            settings.highlightType = 'comprehension';
        }
        else if (next?.worksOn?.speech) {
            settings.highlightType = 'speech';
        }


        let messages = this.userService.bsUser.getValue()?.messages as any;
        if (!messages) {
            messages = [];
        }
        messages.push(message);

        this.userService.updateMessages({messages}).subscribe();

        return this.modalService.moduleComplete().then(() => {
            return from(this.router.navigateByUrl(link));
        }, () => {
            return from(this.router.navigateByUrl(link));
        });
    }

    getStimuliSelect(): Observable<any> {
        if (this.urlId) {
            this.fetchId = this.urlId;
            return this.getStimuli();
        }
        else if (this.stimuliService.bsType.value?.setSize && this.stimuliService.bsType.value?.setSize > 0) {
            if (this.set.length === 0) {
                let dataSource;
                if (this.stimuliService.bsType.value?.dataSource) {
                    dataSource = this.stimuliService.bsType.value?.dataSource;
                }
                else {
                    dataSource = this.stimuliService.bsType.value.name;
                }

                return of(this.stimuliService.getStimuliSet(dataSource, this.onLevel).subscribe((set) => {
                        this.onSetItem = 0;
                        this.correctSetItems = 0;

                        if (this.previousSet.length > 0) {
                            // @ts-ignore
                            _.remove(set, n => {
                                // tslint:disable-next-line:prefer-for-of
                                for (let i = 0; i < this.previousSet.length; i++) {
                                    if ((n as any)._id.toString() === this.previousSet[i].toString()) {
                                        return true;
                                    }
                                }
                            });
                        }

                        set = _.shuffle(set);
                        set = _.shuffle(set);
                        set = _.shuffle(set);
                        this.set = set.slice(0, this.stimuliService.bsType.value.setSize);

                        return this.getStimuliSelect();
                    }, (err => {
                        if (err.status === 404) {
                            const link = ['/no-stimuli'];
                            return from(this.router.navigate(link));
                        }
                        else {
                            return false;
                        }

                    })
                ));
            }
            else {
                if (this.onSetItem === this.set.length) {
                    return of(this.modalService.setComplete(this.correctSetItems).then((res) => {
                        this.onSetItem = 0;
                        this.correctSetItems = 0;

                        if (res === 'new') {
                            this.previousSet = JSON.parse(JSON.stringify(this.set));
                            this.set = [];
                            return this.getStimuliSelect().subscribe();
                        }
                        else {
                            return of(this.cleanUp().then(() => {
                                return of(this.router.navigate(['/modules']));
                            }));
                        }
                    }, () => {
                        // repeat
                        this.onSetItem = 0;
                        this.correctSetItems = 0;
                        return this.getStimuliSelect().subscribe();
                    }));

                }
                else {
                    this.fetchId = JSON.parse(JSON.stringify(this.set[this.onSetItem]._id));
                    this.onSetItem = this.onSetItem + 1;
                    return this.getStimuli();
                }
            }
        }
        else {
            return this.getStimuli();
        }
    }

    getStimuli(): Observable<any> {
        let dataSource;
        if (this.stimuliService.bsType.value?.dataSource) {
            dataSource = this.stimuliService.bsType.value.dataSource;
        }
        else {
            dataSource = this.stimuliService.bsType.value.name;
        }

        if (!this.onLevel) {
            this.onLevel = 1;
        }

        return of(this.stimuliService.getStimuli(dataSource, this.onLevel, this.fetchId).subscribe((stimuli) => {
                this.stimuli = stimuli;
                return this.builderSelector();
            }, (err => {
                if (err.status === 404) {
                    const link = ['/no-stimuli'];
                    return from(this.router.navigate(link));
                }
                else {
                    return false;
                }
            })
        ));

    }

    getWhMixed(): Observable<any> {
        const types = ['WhatQuestions', 'WhenQuestions', 'WhereQuestions', 'WhoQuestions'];
        const dataSource = _.sample(types);

        return of(this.stimuliService.getStimuli(dataSource, this.onLevel).subscribe((stimuli) => {
                this.stimuli = stimuli;
                return this.builderSelector();
            }, (err => {
                if (err.status === 404) {
                    const link = ['/no-stimuli'];
                    return from(this.router.navigate(link));
                }
                else {
                    return false;
                }
            })
        ));
    }

    getNumbers(): Observable<any> {
        if (this.urlName === 'Numbers') {
            if (this.currentStimuli === 0) {
                this.stimuliService.bsStimuli.next(
                    {
                        levels: [{
                            alternatives: [],
                            level: '1',
                            word: '1',
                            sound: '57bd001d5356b82f00f799eb',
                            alternateAnswer: 'one'
                        }, {
                            alternatives: [],
                            level: '1',
                            word: '3',
                            sound: '5b46b85e926e1f001abcda6f',
                            alternateAnswer: 'three'
                        }, {
                            alternatives: [],
                            level: '1',
                            word: '5',
                            sound: '5b46b85eec8c630026a70973',
                            alternateAnswer: 'five'
                        }],
                        type: 'Numbers',
                        name: '1,3,5'
                    }
                );
            }
            else if (this.currentStimuli === 1) {
                this.stimuliService.bsStimuli.next({
                        levels: [{
                            alternatives: [],
                            level: '1',
                            word: '4',
                            sound: '5b46b85e61ad33003f345199',
                            alternateAnswer: 'four'
                        }, {
                            alternatives: [],
                            level: '1',
                            word: '7',
                            sound: '5b46b85dcd157300367033d7',
                            alternateAnswer: 'seven'
                        }, {
                            alternatives: [],
                            level: '1',
                            word: '9',
                            sound: '5b46b85efc2200002cc42841',
                            alternateAnswer: 'nine'
                        }],
                        type: 'Numbers',
                        name: '4,7,9'
                    }
                );
            }
            else if (this.currentStimuli === 2) {
                this.stimuliService.bsStimuli.next({
                        levels: [
                            {
                                alternatives: [],
                                level: '1',
                                word: '0',
                                sound: '5b46b85d47507f001fef2032',
                                alternateAnswer: 'zero'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '2',
                                sound: '5b46b85d2a7fd40037ad5284',
                                alternateAnswer: 'two'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '6',
                                sound: '5b46b85f47507f001fef2033',
                                alternateAnswer: 'six'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '8',
                                sound: '5b46b85e94926d0024717e92',
                                alternateAnswer: 'eight'
                            }
                        ],
                        type: 'Numbers',
                        name: '0,2,6,8',
                    }
                );
            }
            else {
                const stimuli = {
                    levels:
                        [
                            {
                                alternatives: [],
                                level: '1',
                                word: '1',
                                sound: '57bd001d5356b82f00f799eb',
                                alternateAnswer: 'one'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '3',
                                sound: '5b46b85e926e1f001abcda6f',
                                alternateAnswer: 'three'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '5',
                                sound: '5b46b85eec8c630026a70973',
                                alternateAnswer: 'five'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '4',
                                sound: '5b46b85e61ad33003f345199',
                                alternateAnswer: 'four'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '7',
                                sound: '5b46b85dcd157300367033d7',
                                alternateAnswer: 'seven'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '9',
                                sound: '5b46b85efc2200002cc42841',
                                alternateAnswer: 'nine'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '0',
                                sound: '5b46b85d47507f001fef2032',
                                alternateAnswer: 'zero'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '2',
                                sound: '5b46b85d2a7fd40037ad5284',
                                alternateAnswer: 'two'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '6',
                                sound: '5b46b85f47507f001fef2033',
                                alternateAnswer: 'six'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '8',
                                sound: '5b46b85e94926d0024717e92',
                                alternateAnswer: 'eight'
                            }
                        ],
                    type: 'Numbers',
                };

                stimuli.levels = this.shuffle(stimuli.levels);
                stimuli.levels = this.shuffle(stimuli.levels);
                stimuli.levels = this.shuffle(stimuli.levels);

                if (this.currentStimuli > 10) {
                    stimuli.levels = stimuli.levels.slice(0, 3);
                }
                else {
                    stimuli.levels = stimuli.levels.slice(0, 4);
                }

                this.stimuliService.bsStimuli.next(stimuli);
            }
        }
        else if (this.urlName === 'Numbers2') {
            if (this.currentStimuli === 0) {
                this.stimuliService.bsStimuli.next(
                    {
                        levels: [
                            {
                                alternatives: [],
                                level: '1',
                                word: '11',
                                sound: '5bd7a0c5c26eae0032271b90',
                                alternateAnswer: 'eleven'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '19',
                                sound: '5bd7a0c6275047001cf4f6a0',
                                alternateAnswer: 'nineteen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '15',
                                sound: '5bd7a0c5eeb01e002dba54c0',
                                alternateAnswer: 'fifteen'
                            },
                        ],
                        type: 'Numbers',
                        name: '1,3,5'
                    }
                );
            }
            else if (this.currentStimuli === 1) {
                this.stimuliService.bsStimuli.next({
                        levels: [
                            {
                                alternatives: [],
                                level: '1',
                                word: '12',
                                sound: '5bd7a0c5275047001cf4f69f',
                                alternateAnswer: 'twelve'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '18',
                                sound: '5bd7a0c61c7e9d002833fc66',
                                alternateAnswer: 'eighteen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '16',
                                sound: '5bd7a0c59ce453001b642820',
                                alternateAnswer: 'sixteen'
                            }
                        ],
                        type: 'Numbers',
                        name: '4,7,9'
                    }
                );
            }
            else if (this.currentStimuli === 2) {
                this.stimuliService.bsStimuli.next({
                        levels: [
                            {
                                alternatives: [],
                                level: '1',
                                word: '13',
                                sound: '5bd7a0c53f1f890034b097d8',
                                alternateAnswer: 'thirteen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '17',
                                sound: '5bd7a0c6ebc03e001d79e7c9',
                                alternateAnswer: 'seventeen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '20',
                                sound: '5bd7a0c63f1f890034b097d9',
                                alternateAnswer: 'twenty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '14',
                                sound: '5bd7a0c6613bce00200b2989',
                                alternateAnswer: 'fourteen'
                            }
                        ],
                        type: 'Numbers',
                        name: '0,2,6,8',
                    }
                );
            }
            else {
                const stimuli = {
                    levels:
                        [
                            {
                                alternatives: [],
                                level: '1',
                                word: '11',
                                sound: '5bd7a0c5c26eae0032271b90',
                                alternateAnswer: 'eleven'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '19',
                                sound: '5bd7a0c6275047001cf4f6a0',
                                alternateAnswer: 'nineteen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '15',
                                sound: '5bd7a0c5eeb01e002dba54c0',
                                alternateAnswer: 'fifteen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '12',
                                sound: '5bd7a0c5275047001cf4f69f',
                                alternateAnswer: 'twelve'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '18',
                                sound: '5bd7a0c61c7e9d002833fc66',
                                alternateAnswer: 'eighteen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '16',
                                sound: '5bd7a0c59ce453001b642820',
                                alternateAnswer: 'sixteen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '13',
                                sound: '5bd7a0c53f1f890034b097d8',
                                alternateAnswer: 'thirteen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '17',
                                sound: '5bd7a0c6ebc03e001d79e7c9',
                                alternateAnswer: 'seventeen'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '20',
                                sound: '5bd7a0c63f1f890034b097d9',
                                alternateAnswer: 'twenty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '14',
                                sound: '5bd7a0c6613bce00200b2989',
                                alternateAnswer: 'fourteen'
                            }
                        ],
                    type: 'Numbers',
                };

                stimuli.levels = this.shuffle(stimuli.levels);
                stimuli.levels = this.shuffle(stimuli.levels);
                stimuli.levels = this.shuffle(stimuli.levels);

                if (this.currentStimuli > 10) {
                    stimuli.levels = stimuli.levels.slice(0, 3);
                }
                else {
                    stimuli.levels = stimuli.levels.slice(0, 4);
                }

                this.stimuliService.bsStimuli.next(stimuli);
            }
        }
        else if (this.urlName === 'Numbers3') {
            if (this.currentStimuli === 0) {
                this.stimuliService.bsStimuli.next(
                    {
                        levels: [
                            {
                                alternatives: [],
                                level: '1',
                                word: '100',
                                sound: '5c88597f0038d6001e089eb9',
                                alternateAnswer: 'one hundred'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '40',
                                sound: '5c66c374f2f74800311dcab6',
                                alternateAnswer: 'forty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '60',
                                sound: '5c66c374eef1530022a1c19c',
                                alternateAnswer: 'sixty'
                            },
                        ],
                        type: 'Numbers',
                        name: '1,3,5'
                    }
                );
            }
            else if (this.currentStimuli === 1) {
                this.stimuliService.bsStimuli.next({
                        levels: [
                            {
                                alternatives: [],
                                level: '1',
                                word: '20',
                                sound: '5bd7a0c63f1f890034b097d9',
                                alternateAnswer: 'twenty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '80',
                                sound: '5c66c375614a2b002c2fba79',
                                alternateAnswer: 'eighty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '50',
                                sound: '5c66c37425d9290025b02ed4',
                                alternateAnswer: 'fifty'
                            }
                        ],
                        type: 'Numbers',
                        name: '4,7,9'
                    }
                );
            }
            else if (this.currentStimuli === 2) {
                this.stimuliService.bsStimuli.next({
                        levels: [
                            {
                                alternatives: [],
                                level: '1',
                                word: '30',
                                sound: '5c66c374194b670026f191dd',
                                alternateAnswer: 'thirty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '90',
                                sound: '5c66c37537876600434c6085',
                                alternateAnswer: 'ninety'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '70',
                                sound: '5c66c3759f8db90036518310',
                                alternateAnswer: 'seventy'
                            },
                        ],
                        type: 'Numbers',
                        name: '0,2,6,8',
                    }
                );
            }
            else {
                const stimuli = {
                    levels:
                        [
                            {
                                alternatives: [],
                                level: '1',
                                word: '100',
                                sound: '5c88597f0038d6001e089eb9',
                                alternateAnswer: 'one hundred'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '40',
                                sound: '5c66c374f2f74800311dcab6',
                                alternateAnswer: 'forty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '60',
                                sound: '5c66c374eef1530022a1c19c',
                                alternateAnswer: 'sixty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '30',
                                sound: '5c66c374194b670026f191dd',
                                alternateAnswer: 'thirty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '70',
                                sound: '5c66c3759f8db90036518310',
                                alternateAnswer: 'seventy'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '90',
                                sound: '5c66c37537876600434c6085',
                                alternateAnswer: 'ninety'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '20',
                                sound: '5bd7a0c63f1f890034b097d9',
                                alternateAnswer: 'twenty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '80',
                                sound: '5c66c375614a2b002c2fba79',
                                alternateAnswer: 'eighty'
                            },
                            {
                                alternatives: [],
                                level: '1',
                                word: '50',
                                sound: '5c66c37425d9290025b02ed4',
                                alternateAnswer: 'fifty'
                            }

                        ],
                    type: 'Numbers',
                };

                stimuli.levels = this.shuffle(stimuli.levels);
                stimuli.levels = this.shuffle(stimuli.levels);
                stimuli.levels = this.shuffle(stimuli.levels);

                if (this.currentStimuli > 10) {
                    stimuli.levels = stimuli.levels.slice(0, 3);
                }
                else {
                    stimuli.levels = stimuli.levels.slice(0, 4);
                }

                this.stimuliService.bsStimuli.next(stimuli);
            }

        }

        this.currentNumber = 0;
        this.currentStimuli++;

        return of(this.builderSelector());
    }

    builderSelector(): Observable<any> | Promise<any> {
        if (this.stimuliService.bsType.value) {
            this.stimuliService.bsTypeSettings.next(_.find(this.stimuliService.bsType.getValue().levels, ['level', this.onLevel]));
            this.init();

            if (this.urlType === 'repetition') {
                return this.repetition();
            }
            else if (this.urlType === 'numbers') {
                return this.numbersService.generateNumbers();
            }
            else if (this.urlType === 'numbers2') {
                return this.numbers2();
            }
            else if (this.urlType === 'numbers3') {
                return this.numbers3();
            }
            else if (this.urlType === 'numbers4') {
                return this.numbers4();
            }
            else if (this.urlType === 'numbers5') {
                return this.numbers5();
            }
            else if (this.urlType === 'numbers6') {
                return this.numbers6();
            }
            else if (this.urlType === 'numbers7') {
                return this.numbers7();
            }
            else if (this.urlType === 'numbers8') {
                return this.numbers8();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'alternatives') {
                return this.click();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'speech') {
                return this.click();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'numbersWriting') {
                return this.numbersWriting();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'thisWordIs') {
                return this.click();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'thisNumberIs') {
                return this.click();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'whichWordIs') {
                return this.click();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'whichNumberIs') {
                return this.click();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'levelScramble') {
                return this.levelScramble();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'wordScramble') {
                return this.sentenceWordScramble();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'sentenceScramble') {
                return this.sentenceScramble();
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'whMixed') {
                return this.click();
            }
            else {
                return this.click();
            }
        }
        else {
            const route = ['/modules'];
            return of(this.router.navigate(route));
        }
    }

    numbers(): Observable<any> {
        this.init();
        this.audioService.audioObjects = [];
        this.audioService.currentNumber = 0;

        this.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        this.levels = _.shuffle(this.levels);
        this.stimuliService.bsAlternatives.next(this.levels);

        const audio = new Audio();
        audio.src = this.config.mediaServer + 'blob.mp3/5b70ad9650ebf70041c9afd1.mp3';
        this.audioService.audioObjects.push(audio);

        _.forEach(this.levels, (level) => {
            const audio2 = new Audio();

            if (level.sound) {
                audio2.src = this.config.mediaServer + 'blob.mp3/' + level.sound;
            }
            else {
                audio2.src = '';
            }

            this.audioService.audioObjects.push(audio2);
            return of(null);
        });

        if (this.audioService.audioObjects.length > 0) {
            return of(setTimeout(() => {
                return of(this.audioService.playAudioObjects(0));
            }));
        }
        else {
            const route = ['/modules'];
            return of(this.router.navigate(route));
        }
    }

    numbers2(): Observable<any> {
        this.init();
        this.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        this.levels = _.shuffle(this.levels);
        this.levels = _.shuffle(this.levels);
        this.stimuliService.bsAlternatives.next(this.levels);
        this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);

        if (_.isEqual(this.levels, this.stimuliService.bsStimuli.value.levels)) {
            return this.numbers2();
        }
        else {

            this.audioService.audioObjects = [];
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/5b46b85d926e1f001abcda6e.mp3';
            this.audioService.audioObjects.push(audio);

            return of(this.audioService.playAudioObjects(0));
        }
    }

    numbers3(): Observable<any> {
        this.init();
        this.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        this.levels = _.shuffle(this.levels);
        this.levels = _.shuffle(this.levels);
        this.stimuliService.bsAlternatives.next(this.levels);
        this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);

        if (_.isEqual(this.levels, this.stimuliService.bsStimuli.value.levels)) {
            return this.numbers3();
        }
        else {
            this.audioService.audioObjects = [];
            const audio = new Audio();
            audio.src = this.config.mediaServer + 'blob.mp3/5b70ad97899bc9001f99d66f.mp3';
            this.audioService.audioObjects.push(audio);

            return of(this.audioService.playAudioObjects(0));
        }
    }

    numbers4(shiftNumber?): Observable<any> {
        this.init();

        if (shiftNumber) {
            this.audioService.currentNumber++;
        }
        else {
            this.audioService.currentNumber = 0;
            this.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
            this.levels = _.shuffle(this.levels);
            this.levels = _.shuffle(this.levels);
            this.stimuliService.bsAlternatives.next(this.levels);
        }

        if (this.audioService.currentNumber <= this.levels.length) {
            return of(this.audioService.numbers4Audio(this.audioService.currentNumber));
        }
        else {
            const route = ['/modules'];
            return of(this.router.navigate(route));
        }
    }

    numbers5(shiftNumber?): Observable<any> {
        this.init();

        if (shiftNumber) {
            this.audioService.currentNumber++;
        }
        else {
            this.audioService.currentNumber = 0;
            this.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
            this.levels = _.shuffle(this.levels);
            this.stimuliService.bsAlternatives.next(this.levels);

            this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
            this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
            this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        }

        if (this.audioService.currentNumber <= this.levels.length) {
            return of(this.audioService.numbers5Audio(this.audioService.currentNumber));
        }
        else {
            const route = ['/modules'];
            return of(this.router.navigate(route));
        }
    }

    numbers6(): Observable<any> {
        this.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        this.levels = _.shuffle(this.levels);
        this.stimuliService.bsAlternatives.next(this.levels);

        this.audioService.audioObjects = [];
        const audio = new Audio();
        audio.src = this.config.mediaServer + 'blob.mp3/5b46b85bfc2200002cc42840';
        this.audioService.audioObjects.push(audio);

        return of(this.audioService.playAudioObjects(0));
    }

    numbers6Listen(index): Observable<any> {
        this.audioService.audioObjects = [];
        const audio2 = new Audio();
        audio2.src = this.config.mediaServer + 'blob.mp3/' + this.levels[index].sound;
        this.audioService.audioObjects.push(audio2);

        return of(this.audioService.playAudioObjects(0));
    }

    numbers7(shiftNumber?): Observable<any> {
        this.init();

        if (shiftNumber) {
            this.audioService.currentNumber++;
        }
        else {
            this.audioService.currentNumber = 0;
            this.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
            this.levels = _.shuffle(this.levels);
            this.stimuliService.bsAlternatives.next(this.levels);

            this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
            this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
            this.stimuliService.bsStimuli.value.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
        }

        if (this.audioService.currentNumber <= this.levels.length && this.stimuliService.bsStimuli.value.levels[this.audioService.currentNumber].sound) {
            this.audioService.audioObjects = [];
            let audio;

            if (this.audioService.currentNumber === 0) {
                audio = new Audio();
                audio.src = this.config.mediaServer + 'blob.mp3/5b46b85947507f001fef2031';
                this.audioService.audioObjects.push(audio);
            }

            audio = new Audio();
            audio.src = this.config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value.levels[this.audioService.currentNumber].sound;
            this.audioService.audioObjects.push(audio);

            return of(setTimeout(() => {
                return of(this.audioService.playAudioObjects(0));
            }));

        }
        else {
            const route = ['/modules'];
            return of(this.router.navigate(route));
        }
    }

    numbers8(shiftNumber?): Observable<any> {
        this.init();

        if (shiftNumber) {
            this.audioService.currentNumber++;
        }
        else {
            this.audioService.currentNumber = 0;
            // this.levels = _.shuffle(this.stimuliService.bsStimuli.value.levels);
            // this.levels = _.shuffle(this.levels);
            // this.stimuliService.bsAlternatives.next(this.levels);
        }

        if (this.audioService.currentNumber <= this.levels.length && this.stimuliService.bsStimuli.value.levels[this.audioService.currentNumber].sound) {
            this.audioService.audioObjects = [];
            let audio;

            if (this.audioService.currentNumber === 0) {
                audio = new Audio();
                audio.src = this.config.mediaServer + 'blob.mp3/5b46b85a2a7fd40037ad5283';
                this.audioService.audioObjects.push(audio);
            }

            audio = new Audio();
            audio.src = this.config.mediaServer + 'blob.mp3/' + this.levels[this.audioService.currentNumber].sound;
            this.audioService.audioObjects.push(audio);

            return of(setTimeout(() => {
                return of(this.audioService.playAudioObjects(0));
            }, 100));
        }
        else {
            const route = ['/modules'];
            return of(this.router.navigate(route));
        }
    }

    click(): Observable<any> {
        this.init();
        this.stimuliService.bsStimuli.value.levels = _.sortBy(this.stimuliService.bsStimuli.value.levels, ['sortCode']);

        const foils: number = _.get(this.stimuliService.bsTypeSettings.getValue(), 'foils', 0);
        const alternativeCount: number = _.get(this.stimuliService.bsTypeSettings.getValue(), 'alternatives', 3);

        const answersArray: Array<any> = [];
        const tempArray: Array<any> = [];

        answersArray.push({
            word: this.stimuliService.bsStimuli.value.levels[0].word,
            sound: this.stimuliService.bsStimuli.value.levels[0].sound,
            image: this.stimuliService.bsStimuli.value.levels[0].image,
            id: this.stimuliService.bsStimuli.value.levels[0]._id
        });

        _.forEach(this.stimuliService.bsStimuli.value.levels[0].alternatives, (alternative: any, iter: number) => {
            if (iter < foils) {
                answersArray.push({
                    word: alternative.word,
                    sound: alternative.sound,
                    image: alternative.image,
                    id: alternative._id
                });
            }
            else {
                tempArray.push({
                    word: alternative.word,
                    sound: alternative.sound,
                    image: alternative.image,
                    id: alternative._id
                });
            }
        });

        if (tempArray.length > 0) {
            this.shuffle(tempArray);
            this.shuffle(tempArray);
            this.shuffle(tempArray);
            this.shuffle(tempArray);

            let otherAlternatives = alternativeCount - foils;
            if (tempArray.length < otherAlternatives) {
                otherAlternatives = tempArray.length;
            }

            if (otherAlternatives > 0) {
                for (let index = 0; index < otherAlternatives; ++index) {

                    answersArray.push({
                        word: tempArray[index].word,
                        sound: tempArray[index].sound,
                        image: tempArray[index].image,
                        id: tempArray[index].id
                    });
                }
            }
        }

        this.shuffle(answersArray);
        this.shuffle(answersArray);
        this.shuffle(answersArray);
        this.shuffle(answersArray);
        this.shuffle(answersArray);
        this.stimuliService.bsAlternatives.next(answersArray);

        return this.checkIntro();
    }

    checkIntro(): Observable<any> {
        const name = this.stimuliService.bsType.value.name;
        const stats = _.find(this.userService.bsUser.value?.tasks, {task: name});
        if (stats) {
            return this.audioService.buildAudio();
        }
        else {
            return of(this.audioService.playIntro(0));
        }
    }

    numbersWriting(): Observable<any> {
        this.init();
        const alt = [this.stimuliService.bsStimuli.value.levels[0].word.split('')];
        this.stimuliService.bsAlternatives.next(alt);
        return this.checkIntro();
    }

    // Scrambles the word order
    sentenceScramble(): Observable<any> {
        this.init();
        if (!this.stimuliService.bsStimuli.value.levels[0].word) {
            this.messagingService.setMessage('No correct answer defined', false);
            return of(false);
        }
        else {

            let temp = this.stimuliService.bsStimuli.value.levels[0].word.split(' ');

            if (temp.length === 1) {
                this.messagingService.setMessage('Stimuli is not a sentence', false);
            }

            temp = this.shuffle(temp);
            temp = this.shuffle(temp);
            temp = this.shuffle(temp);
            temp = this.shuffle(temp);
            temp = this.shuffle(temp);
            this.stimuliService.bsAlternatives.next(temp);

            if (_.isEqual(temp, this.stimuliService.bsStimuli.value.levels[0].word.split(' '))) {
                return this.sentenceScramble();
            }
            else {
                return this.checkIntro();
            }
        }
    }

    // Scrambles the letters in each word in a sentence
    sentenceWordScramble(): Observable<any> {
        if (!this.stimuli) {
            const link = ['/modules/'];
            return of(this.router.navigate(link));
        }

        this.init();
        if (this.stimuli && !this.stimuliService.bsStimuli.value.levels[0].word) {
            this.messagingService.setMessage('No correct answer defined', false);
            return of(false);
        }
        else {
            const sentence: Array<any> = [];
            const temp = this.stimuliService.bsStimuli.value.levels[0].word.split(' ');

            _.forEach(temp, (word) => {
                let temp2 = _.toArray(word);
                temp2 = this.shuffle(temp2);
                temp2 = this.shuffle(temp2);
                temp2 = this.shuffle(temp2);
                temp2 = this.shuffle(temp2);
                temp2 = this.shuffle(temp2);
                sentence.push(temp2);
            });

            this.stimuliService.bsAlternatives.next(sentence);

            if (_.isEqual(temp, sentence)) {
                return this.sentenceWordScramble();
            }
            else {
                return this.checkIntro();
            }
        }
    }

    // Scrambles letters in words for multiple stimuli per level.
    levelScramble(): Observable<any> {
        if (!this.stimuli) {
            const link = ['/modules/'];
            return of(this.router.navigate(link));
        }

        this.init();

        if (!this.stimuliService.bsStimuli.value.levels[0].word) {
            this.messagingService.setMessage('No correct answer defined', false);
            return of(true);
        }
        else {
            const orgSentence: Array<any> = [];
            const sentence: Array<any> = [];
            const stimuli = _.sortBy(this.stimuliService.bsStimuli.value.levels, ['sortCode']);

            _.forEach(stimuli, (stim) => {
                const temp = stim.word.split(' ');

                _.forEach(temp, (word) => {
                    let temp2 = _.toArray(word);
                    orgSentence.push(JSON.parse(JSON.stringify(temp2)));

                    temp2 = this.shuffle(temp2);
                    temp2 = this.shuffle(temp2);
                    temp2 = this.shuffle(temp2);
                    temp2 = this.shuffle(temp2);
                    temp2 = this.shuffle(temp2);
                    sentence.push(temp2);
                });
            });

            this.stimuliService.bsAlternatives.next(sentence);

            if (_.isEqual(orgSentence, sentence)) {
                return this.levelScramble();
            }
            else {
                return this.checkIntro();
            }
        }
    }

    repetition(): Promise<any> {
        this.init();
        this.audioService.audioObjects = [];

        const audio = new Audio();
        audio.src = this.config.mediaServer + 'blob.mp3/61e9c497669dfe0041a16824.mp3';
        this.audioService.audioObjects.push(audio);


        _.forEach(this.stimuliService.bsStimuli.value.postSounds, (sound: any) => {
            if (sound.sound) {
                const temp = new Audio();
                temp.src = this.config.mediaServer + 'blob.mp3/' + sound.sound;
                (temp as any).word = sound.word;
                this.audioService.audioObjects.push(temp);
            }
        });

        if (this.audioService.audioObjects.length > 0) {
            return this.audioService.playAudioObjects(0);
        }

        return Promise.resolve();
    }

    findPostSound(answer = null, playSnippets = false): Observable<any> {
        this.init();
        this.audioService.audioObjects = [];

        let audio = new Audio();
        audio.src = this.config.mediaServer + 'blob.mp3/63e51f247fe84b00148ed9a8';
        this.audioService.audioObjects.push(audio);

        if (this.stimuli && this.stimuliService.bsStimuli.value.postSounds && this.stimuliService.bsStimuli.value.postSounds.length > 0) {
            let alternatives: Array<any> = [];

            _.forEach(this.stimuliService.bsStimuli.value.postSounds, (sound) => {
                alternatives.push({word: sound.word});
            });

            alternatives = _.shuffle(alternatives);
            alternatives = _.compact(alternatives);
            alternatives = _.shuffle(alternatives);
            alternatives = _.compact(alternatives);

            this.stimuliService.bsAlternatives.next(alternatives);

            let postSounds: Array<any> = [];
            _.forEach(this.stimuliService.bsStimuli.value.postSounds, (sound) => {

                if (sound.sound && this.config && this.config.whichWordExclude && this.config.whichWordExclude?.indexOf(sound.word.toLowerCase()) === -1) {
                    audio = new Audio();
                    (audio as any).word = sound.word;
                    audio.src = this.config.mediaServer + 'blob.mp3/' + sound.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id;
                    postSounds.push(audio);
                }
            });

            postSounds = _.shuffle(postSounds);
            postSounds = _.compact(postSounds);
            postSounds = _.shuffle(postSounds);
            postSounds = _.compact(postSounds);
            this.audioService.audioObjects.push(postSounds[0]);
            console.info("chcek bsWHichWOrdIsAnswer", postSounds[0]);
            this.stimuliService.bsWhichWordIsAnswer.next(postSounds[0]);

            alternatives = _.uniqBy(alternatives, 'word');
            this.stimuliService.bsAlternatives.next(alternatives);

            if (answer && answer === postSounds[0].word) {
                return this.findPostSound(answer);
            }
            else if (this.audioService.audioObjects.length > 0) {
                const name = this.stimuliService.bsType.value.name;
                const stats = _.find(this.userService.bsUser.value?.tasks, {task: name});
                // TODO: has no stats at start?
                console.info("stats", stats);
                if (stats || playSnippets) {
                    return of(this.audioService.playAudioObjects(0));
                }
                else {
                    return of(this.audioService.playSstIntro(2));
                }
            }
        }
        else {
            const route = ['/modules'];
            return of(this.router.navigate(route));
        }

        return of(true);
    }

    cleanUp(): Promise<any> {
        return this.audioService.cleanupAudioObjects().then(() => {
            this.stimuliService.getTypes().subscribe();
            this.audioService.audioObjects = [];
            this.audioService.bsShowEar.next(true);
            this.audioService.bsListen.next(false);
            this.screenService.bsNewLevel.next(false);
            this.stimuliService.bsStimuli.next(null);
            this.stimuliService.bsType.next(null);
            this.stimuliService.bsAlternatives.next(null);
            this.stimuliService.bsTypeSettings.next(null);
            this.messagingService.message.next(null);

            this.fetchId = null;
            this.onLevel = 0;
            this.set = [];
            this.previousSet = [];
            this.onSetItem = 0;
            this.correctSetItems = 0;
            this.currentStimuli = 0;
            this.audioService.soundCounter = 0;
        });
    }

    private shuffle(array): Array<any> {
        let currentIndex = array.length;
        let temporaryValue;
        let randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

}
