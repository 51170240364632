<app-error-message></app-error-message>

<div *ngIf="stimuli && question && type && typeSettings" class="center">

  <div *ngIf="(typeSettings.mainImage && stimuli.image)" class="p-t-15"  [class.highlight-word]="highlight['highlight-image']">
    <img [ngClass]="{ 'fs-stimuli-image': settings.fullScreen, 'stimuli-image': !settings.fullScreen}" [src]="getStimuliImage()"/>
  </div>

  <div *ngIf="(typeSettings.question && stimuli.description)">
    <h2> <span class="p-5" [class.highlight-word]="highlight['highlight-text']">{{stimuli.description}}</span></h2>
  </div>

  <form name="answerForm clear">
    <div *ngFor="let word of question; let i = index" class="inline-block p-10" id="">
       <p [innerText]="answer[i]" class="uppercase writing2-copy center"></p>
      <br/>
      <input [(ngModel)]="input[i]"
             autocomplete="off"
             autofocus
             class="uppercase writing-input center"
             id="input_{{i}}"
             maxlength="{{word.length}}"
             name="input_{{i}}"
             size="{{word.length +2 }}"
             style="width:initial; display:initial;"
             [class.highlight-word]="highlight['highlight-input']">
    </div>

    <app-hint></app-hint>

    <div class="clear m-t-15">
      <button (click)="checkAnswer()" class="btn btn-primary high-z center-block" type="submit"><span class="material-icons middle">done</span> Done</button>
    </div>
  </form>
  <br clear="all"/>
</div>
