import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import * as _ from 'lodash';

import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

import {ScreenService} from './screen.service';
import {StimuliService} from './stimuli.service';
import {MessagingService} from './messaging.service';

import {config} from '../config';
import {mainXi18n} from '@angular/compiler-cli/src/extract_i18n';

@Injectable()
export class AudioService {
    skipPlay;
    skipListen;
    playPromise;
    audioObjects;
    soundListener;
    soundCounter = 0;
    currentNumber = 0;
    introIndex = 0;
    audioIndex = 0;

    urlType;
    urlName;

    highlight = {
        'highlight-text': false,
        'highlight-image': false,
        'highlight-alternatives': false,
        'highlight-image-alternatives': false,
        'highlight-input': false,
        'highlight-input-hint': false,
        'highlight-input-text': false,
        'highlight-ear': false,
        'highlight-pre-sounds': false,
        'highlight-question': false,
        'highlight-speech': false,
        'highlight-drag': false,
        'highlight-drop': false,
    };


    bsShowEar: BehaviorSubject<any> = new BehaviorSubject(true);
    bsListen: BehaviorSubject<any> = new BehaviorSubject(false);
    bsStopListen: BehaviorSubject<any> = new BehaviorSubject(false);
    sAudioPlayed: Subject<boolean> = new Subject<boolean>();
    sListenAgain: Subject<boolean> = new Subject<boolean>();

    constructor(
        private stimuliService: StimuliService,
        private screenService: ScreenService,
        private messagingService: MessagingService,
        private location: Location,
        private router: Router) {
    }

    init(): void {
        this.urlType = decodeURI(this.location.path(true).split('/')[2]);
        this.urlName = decodeURI(this.location.path(true).split('/')[3]);
    }

    showMainSoundListen(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2mainSoundListen && this.stimuliService.bsStimuli.value?.sound) {
            return true;
        }
        return false;
    }

    showMainSoundListenAgain(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2mainSoundListenAgain && this.stimuliService.bsStimuli.value?.sound) {
            return true;
        }
        return false;
    }

    showMainSoundHint(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2mainSoundHint && this.stimuliService.bsStimuli.value?.sound) {
            return true;
        }
        return false;
    }

    showPreSoundListen(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2preSoundListen && this.stimuliService.bsStimuli.value.preSounds && this.stimuliService.bsStimuli.value.preSounds.length > 0) {
            return true;
        }
        return false;
    }

    showPreSoundListenAgain(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2preSoundListenAgain && this.stimuliService.bsStimuli.value.preSounds && this.stimuliService.bsStimuli.value.preSounds.length > 0) {
            return true;
        }
        return false;
    }

    showPreSoundHint(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2preSoundHint && this.stimuliService.bsStimuli.value.preSounds && this.stimuliService.bsStimuli.value.preSounds.length > 0) {
            return true;
        }
        return false;
    }

    showPostSoundListen(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2postSoundListen && this.stimuliService.bsStimuli.value.postSounds && this.stimuliService.bsStimuli.value.postSounds.length > 0) {
            return true;
        }
        return false;
    }

    showPostSoundListenAgain(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2postSoundListenAgain && this.stimuliService.bsStimuli.value.postSounds && this.stimuliService.bsStimuli.value.postSounds.length > 0) {
            return true;
        }
        return false;
    }

    showPostSoundHint(): boolean {

        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2postSoundHint && this.stimuliService.bsStimuli.value.postSounds && this.stimuliService.bsStimuli.value.postSounds.length > 0) {
            return true;
        }
        return false;
    }

    showAnswerSoundListen(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2answerSoundListen && this.stimuliService.bsStimuli.value.levels && this.stimuliService.bsStimuli.value.levels[0] && this.stimuliService.bsStimuli.value.levels[0].sound) {
            return true;
        }
        return false;
    }

    showAnswerSoundListenAgain(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2answerSoundListenAgain && this.stimuliService.bsStimuli.value.levels && this.stimuliService.bsStimuli.value.levels[0] && this.stimuliService.bsStimuli.value.levels[0].sound) {
            return true;
        }
        return false;
    }

    showAudioObjectsListenAgain(): boolean {
        if (this.urlType === 'numbers4' || this.urlType === 'numbers5' || this.urlType === 'numbers7' || this.urlType === 'numbers8') {
            return true;
        }
        return false;
    }

    showAnswerSoundHint(): boolean {
        if (this.stimuliService.bsTypeSettings.value && this.stimuliService.bsTypeSettings.value.v2answerSoundHint && this.stimuliService.bsStimuli.value.levels && this.stimuliService.bsStimuli.value.levels[0] && this.stimuliService.bsStimuli.value.levels[0].sound) {
            return true;
        }
        return false;
    }

    playMainSound(): Promise<any> {
        return this.cleanupAudioObjects().then(() => {
            if (this.stimuliService.bsStimuli.value && this.stimuliService.bsStimuli.value?.sound) {
                const audio = document.createElement('audio');
                audio.setAttribute('src', config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id);

                if (this.stimuliService.bsType.value.buildType === 'numbersWritingSlow') {
                    audio.playbackRate = 0.75;
                }

                this.audioObjects.push(audio);

                return this.playAudioObjects();
            }
            else {
                return Promise.reject();
            }
        });
    }

    playPreSound(): Promise<any> {
        return this.cleanupAudioObjects().then(() => {
            if (this.stimuliService.bsStimuli.value.preSounds && this.stimuliService.bsStimuli.value.preSounds.length > 0) {
                let index = 0;
                if (this.stimuliService.bsType.value.buildType === 'repeatSentence') {
                    index = this.stimuliService.bsStimuli.value.preSounds.length - 1;
                }

                _.forEach(this.stimuliService.bsStimuli.value.preSounds, (sound: any) => {
                    if (sound.sound) {
                        const audio = new Audio();
                        audio.src = config.mediaServer + 'blob.mp3/' + sound.sound + '.mp3';
                        this.audioObjects.push(audio);
                    }
                });

                return this.playAudioObjects(index);
            }
            else {
                return Promise.reject();
            }
        });
    }

    playPostSound(): Promise<any> {
        return this.cleanupAudioObjects().then(() => {
            if (this.stimuliService.bsStimuli.value.postSounds && this.stimuliService.bsStimuli.value.postSounds.length > 0) {
                _.forEach(this.stimuliService.bsStimuli.value.postSounds, (sound: any) => {
                    if (sound.sound) {
                        const audio = new Audio();
                        audio.src = config.mediaServer + 'blob.mp3/' + sound.sound + '.mp3';
                        this.audioObjects.push(audio);
                    }
                });

                return this.playAudioObjects(0);
            }
            else {
                return Promise.reject();
            }
        });
    }

    playAnswerSound(index: number = 0): Promise<any> {
        return this.cleanupAudioObjects().then(() => {
            if (this.stimuliService.bsStimuli.value.levels[index].sound) {
                let audio;
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value.levels[index].sound + '.mp3';
                this.audioObjects.push(audio);
            }

            if (this.audioObjects.length > 0) {
                return this.playAudioObjects(index);
            }
            else {
                return Promise.reject();
            }
        });
    }

    playAlternativeSound(index: number = 0): Promise<any> {
        return this.cleanupAudioObjects().then(() => {
            if (this.stimuliService.bsAlternatives.value.lenght > 0 && this.stimuliService.bsAlternatives.value[index].sound) {
                let audio;
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsAlternatives.value[index].sound + '.mp3';
                this.audioObjects.push(audio);
            }

            if (this.audioObjects.length > 0) {
                return this.playAudioObjects(index);
            }
            else {
                return Promise.reject();
            }
        });
    }

    playIntro(index?): Promise<any> {

        if (typeof index === 'number') {
            this.introIndex = index;
        }
        return this.cleanupAudioObjects().then(() => {

            if (this.stimuliService.bsType.value.intro && this.stimuliService.bsType.value.intro[this.introIndex] && this.stimuliService.bsType.value.intro[this.introIndex].sound) {
                const audio = new Audio();
                if (this.stimuliService.bsType.value.webIntro && this.stimuliService.bsType.value.webIntro[this.introIndex]) {
                    audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsType.value.webIntro[this.introIndex].sound;
                }
                else {
                    audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsType.value.intro[this.introIndex].sound;
                }
                this.playPromise = audio;

                audio.addEventListener('ended', (e) => {
                    this.highlight[this.stimuliService.bsType.value.intro[this.introIndex].highlight] = false;

                    if (this.stimuliService.bsType.value.intro && (this.stimuliService.bsType.value.intro.length > (this.introIndex + 1))) {
                        this.introIndex++;
                        return setTimeout(() => {
                            return this.playIntro();
                        }, 500);

                    }
                    else {
                        return setTimeout(() => {
                            this.bsShowEar.next(false);
                            return this.buildAudio().subscribe();
                        }, 500);
                    }
                });

                this.highlight[this.stimuliService.bsType.value.intro[this.introIndex].highlight] = true;

                return audio.play().then(res => {
                }, (err): any => {
                    console.error(new Error(err));
                    return Promise.resolve();
                });
            }
            else {
                return setTimeout(() => {
                    this.bsShowEar.next(false);
                    return this.buildAudio().subscribe();
                }, 500);
            }
        });
    }

    playSstIntro(index?: number): Promise<any> {
        if (typeof index === 'number') {
            this.introIndex = index;
        }

        const step = this.screenService.bsOnStep.value;
        if (step === 0) {
            const temp = {sound: '6351ae2990320f001e11eaee', highlight: 'highlight-drag'};
            const temp2 = {sound: '643816fa8dd51100328391a7', highlight: 'highlight-drop'};
            const type = JSON.parse(JSON.stringify(this.stimuliService.bsType.value));
            type.intro = [temp, temp2];
            this.stimuliService.bsType.next(type);
        }
        else if (step === 1) {
            if (this.urlType === 'sst-writing2') {
                const temp = {sound: '639214faaf86340016bb1dcf', highlight: 'highlight-input'};
                const temp2 = {sound: '6349a11023ffe9002b3cdb02', highlight: 'highlight-hint'};
                const type = JSON.parse(JSON.stringify(this.stimuliService.bsType.value));
                type.intro = [temp, temp2];
                this.stimuliService.bsType.next(type);

            }
            else if (this.urlType === 'sst-speech2') {
                const temp = {sound: '61eec944247952002309c438', highlight: 'highlight-speech'};
                const temp2 = {sound: '6349a11023ffe9002b3cdb02', highlight: 'highlight-text'};
                const type = JSON.parse(JSON.stringify(this.stimuliService.bsType.value));
                type.intro = [temp, temp2];
                this.stimuliService.bsType.next(type);
            }
        }
        else if (step === 2) {
            const temp = {sound: '61e5b637ad5532004491e0c5', highlight: 'highlight-alternatives'};
            const temp2 = {sound: '61e9c4989b17ba0036dfff11', highlight: 'highlight-text'};
            const type = JSON.parse(JSON.stringify(this.stimuliService.bsType.value));
            type.intro = [temp, temp2];
            this.stimuliService.bsType.next(type);
        }

        return this.playIntro(0);
    }

    playNumbers(): any {
        return this.playAudioObjects(0);
    }

    playNumbersTutorial(index?: number): Promise<any> {
        if (typeof index === 'number') {
            this.introIndex = index;
        }

        const step = this.screenService.bsOnStep.value;
        if (step === 0) {
            const temp = {sound: '61e9c4969feb2d00472dda41', highlight: 'highlight-alternatives'};
            const temp2 = {sound: '61e9c498e4271e0023f0dc25', highlight: 'highlight-ear'};
            const type = JSON.parse(JSON.stringify(this.stimuliService.bsType.value));
            type.intro = [temp, temp2];
            this.stimuliService.bsType.next(type);
        }
        else if (step === 1) {
            const temp = {sound: '630f76551561280033417130', highlight: 'highlight-speech'};
            const temp2 = {sound: '6349a11023ffe9002b3cdb02', highlight: 'highlight-text'};
            const type = JSON.parse(JSON.stringify(this.stimuliService.bsType.value));
            type.intro = [temp, temp2];
            this.stimuliService.bsType.next(type);
        }
        else if (step === 2) {
            const temp = {sound: '61e5b637ad5532004491e0c5', highlight: 'highlight-numbers'};
            const temp2 = {sound: '61e9c498e4271e0023f0dc25', highlight: 'highlight-ear'};
            const type = JSON.parse(JSON.stringify(this.stimuliService.bsType.value));
            type.intro = [temp, temp2];
            this.stimuliService.bsType.next(type);
        }

        return this.playIntro(0);
    }

    cleanupAudioObjects(): Promise<any> {
        this.bsListen.next(false);
        this.urlType = this.location.path(true).split('/')[2];
        this.urlName = this.location.path(true).split('/')[3];
        this.screenService.bsSetFocus.next(true);
        this.audioObjects = [];
        this.skipListen = false;
        this.messagingService.setMessage(null);

        if (this.playPromise && this.playPromise.pause()) {
            return this.playPromise.pause().then(() => {
                return this.playPromise.removeEventListener('ended', this.soundListener);
            });
        }
        else if (this.playPromise) {
            this.playPromise.removeEventListener('ended', this.soundListener);
        }

        return Promise.resolve();
    }

    playAudioObjects(ix?: number): Promise<any> {
        let index = 0;

        if (typeof (ix) === 'number') {
            index = ix;
        }

        this.addHighlight(index);

        this.playPromise = this.audioObjects[index];

        let valid = null;
        if (this.playPromise) {
            valid = this.playPromise.src.match(/blob.mp3/);
        }

        if (valid !== null && this.playPromise && typeof this.playPromise.play === 'function') {
            this.soundListener = () => {
                return this.afterSound(index);
            };

            this.playPromise.addEventListener('ended', this.soundListener);

            return this.playPromise.play().catch(() => {
                this.messagingService.setMessage('Audio can not be played without user interaction. Please select Therapy from the menu and try again.', false);
                return this.noSound(index);
            });

        }
        else {
            return this.noSound(index);
        }
    }

    startSound(): Promise<any> {
        return this.cleanupAudioObjects().then(() => {
            const audio = new Audio();
            audio.src = '/images/start.mp3';
            return audio.play();
        });
    }

    stopSound(): Promise<any> {
        if (this.urlType === 'numbers4' || this.urlType === 'numbers5') {
            const arr: HTMLCollectionOf<Element> = document.getElementsByClassName('highlight-number');
            _.forEach(arr, elem => {
                elem.classList.remove('highlight-number');
            });
        }

        const audio = new Audio();
        audio.src = '/images/stop.mp3';
        return audio.play();
    }

    numbers5Audio(index?): Promise<any> {
        return this.cleanupAudioObjects().then(() => {
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/5b70ad97def28500359340a0';
            this.audioObjects.push(audio);
            return this.playAudioObjects(index);
        });
    }

    numbers4Audio(currentNumber: number = 0): Promise<any> {
        return this.cleanupAudioObjects().then(() => {

            this.audioObjects = [];
            let audio;

            if (currentNumber === 0) {
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/5b46b85acd157300367033d6';
                this.audioObjects.push(audio);
            }

            if (this.stimuliService.bsAlternatives.value[currentNumber] && this.stimuliService.bsAlternatives.value[currentNumber].sound) {
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsAlternatives.value[currentNumber].sound;
                this.audioObjects.push(audio);
            }

            return this.playAudioObjects(0);
        });
    }

    addHighlight(index): void {
        this.init();

        if (_.get(this.stimuliService.bsTypeSettings.value, 'preSound', false)) {
            if (document.getElementById('word' + (index))) {
                // @ts-ignore
                document.getElementById('word' + (index)).classList.add('highlight-word');
            }
        }
        else if (this.urlType === 'numbers') {
            if (document.getElementById('listen' + (this.currentNumber - 1))) {
                // @ts-ignore
                document.getElementById('listen' + (this.currentNumber - 1)).classList.add('highlight-word');
            }
        }
        else if ((index > 0 || this.audioObjects.length === 1) && (this.urlType === 'numbers4' || this.urlType === 'numbers5')) {
            if (document.getElementById('number' + this.currentNumber)) {
                // @ts-ignore
                document.getElementById('number' + this.currentNumber).classList.add('highlight-number');
            }
        }
        else if (this.urlType === 'repetition' || this.urlType === 'sst') {
            if (document.getElementById('word' + (index - 1))) {
                // @ts-ignore
                document.getElementById('word' + (index - 1)).classList.add('highlight-word');
            }
        }
    }

    removeHighlight(): void {
        const arr: HTMLCollectionOf<Element> = document.getElementsByClassName('highlight-word');

        if (arr && arr.length > 0) {
            _.forEach(arr, elem => {
                try {
                    elem.classList.remove('highlight-word');
                } catch (err: any) {
                }
            });
        }

        const arr2: HTMLCollectionOf<Element> = document.getElementsByClassName('highlight-number');
        if (arr2 && arr.length > 0) {
            _.forEach(arr2, elem => {
                try {
                    elem.classList.remove('highlight-number');
                } catch (err: any) {
                }
            });
        }
    }

    pauseSound(): void {
        if (this.playPromise && this.playPromise.pause()) {
            this.playPromise.pause();
        }
    }

    buildAudio(skipLeadAudio?): Observable<any> {
        return of(this.cleanupAudioObjects().then(() => {
            this.init();
            let playLead = true;
            if (skipLeadAudio) {
                playLead = false;
            }

            this.audioObjects = [];

            let audio;
            if (this.stimuliService.bsType.value.templateType === 'numbers') {
                this.sListenAgain.next(true);
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'whichNumberIs') {
                // if (this.soundCounter < 4) {
                this.soundCounter++;
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/5f64b6210e2dc200440a211b.mp3';
                this.audioObjects.push(audio);
                // }

                if (this.stimuliService.bsStimuli.value?.sound) {
                    audio = new Audio();
                    audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id;
                    this.audioObjects.push(audio);
                }
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'repeatNumber') {
                if (this.soundCounter < 3) {
                    this.soundCounter++;
                    audio = new Audio();
                    audio.src = config.mediaServer + 'blob.mp3/5b46b85acd157300367033d6.mp3';
                    this.audioObjects.push(audio);
                }

                if (this.stimuliService.bsTypeSettings.getValue() && (this.stimuliService.bsTypeSettings.getValue() as any).sound && this.stimuliService.bsStimuli.value?.sound) {
                    audio = new Audio();
                    audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id;
                    this.audioObjects.push(audio);
                }
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'thisNumberIs') {
                // if (this.soundCounter < 3) {
                this.soundCounter++;
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/602beef9ca047d0022d3b7a8.mp3';
                this.audioObjects.push(audio);
                // }

                if (this.stimuliService.bsTypeSettings.getValue() && (this.stimuliService.bsTypeSettings.getValue() as any).sound && this.stimuliService.bsStimuli.value?.sound) {
                    audio = new Audio();
                    audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id;
                    this.audioObjects.push(audio);
                }
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'whichWordIs') {
                this.soundCounter++;
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/63e51f247fe84b00148ed9a8.mp3';
                this.audioObjects.push(audio);

                if (this.stimuliService.bsStimuli.value?.sound) {
                    audio = new Audio();
                    audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value.sound + '.mp3';
                    this.audioObjects.push(audio);
                    // this.audioObjects.push(this.stimuliService.bsWhichWordIsAnswer.value);
                }
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'thisWordIs') {
                audio = new Audio();
                if (!skipLeadAudio) {
                    audio.src = config.mediaServer + 'blob.mp3/5fe0da94f75a9a001ea4db3b.mp3';
                }

                audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id;
                this.audioObjects.push(audio);
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'numbersWriting') {
                if (this.soundCounter < 3 && playLead) {
                    this.soundCounter++;
                    audio = new Audio();
                    audio.src = config.mediaServer + 'blob.mp3/5b46b85a2a7fd40037ad5283.mp3';
                    this.audioObjects.push(audio);
                }
                if (this.stimuliService.bsStimuli.value?.sound) {
                    audio = new Audio();
                    audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id;
                    this.audioObjects.push(audio);
                }
            }
            else if (this.stimuliService.bsType.getValue().buildType === 'thisIsA') {
                const thisIs = ['yogurt', 'wood', 'wine', 'water', 'toothpaste', 'toilet paper', 'tea', 'syrup', 'sugar', 'soup', 'snow', 'soap', 'salt', 'rocks', 'ribs', 'potato chips', 'popcorn', 'pepper', 'perfume', 'oatmeal', 'mustard', 'nail' +
                ' polish', 'mayo', 'meat', 'medicine', 'milk', 'money', 'mail', 'makeup', 'lettuce', 'jelly', 'juice', 'ketchup', 'iced' +
                ' tea', 'ice cream', 'garlic', 'garbage', 'grass', 'fruit', 'rice', 'hair', 'bacon', 'broccoli', 'butter', 'candy', 'cereal', 'champagne', 'chips', 'chocolate', 'coffee', 'corn'];
                const theseAre = ['wires', 'vegetables', 'waffles', 'tools', 'tracks', 'trees', 'tacos', 'swings', 'sunglasses', 'stairs', 'socks', 'slippers', 'potatoes', 'pretzels', 'plants', 'photos', 'pears', 'pencils', 'oranges', 'ornaments', 'nectarines', 'nails', 'mussels', 'marshmallows', 'matches', 'lights', 'limes', 'leaves', 'lemons', 'keys', 'jeans', 'hands', 'glasses', 'gloves', 'green beans', 'drums', 'eggs', 'eyes', 'feet', 'teeth', 'bananas', 'batteries', 'blueberries', 'cookies', 'movies', 'strawberries', 'booths', 'cards', 'carrots', 'clouds'];
                const vowels = 'aeiou';

                audio = new Audio();

                if (thisIs.indexOf(this.stimuliService.bsStimuli.value.levels[0].word) >= 0) {
                    audio.src = config.mediaServer + 'blob.mp3/6064cd21ef9c38002c4474af.mp3';
                }
                else if (theseAre.indexOf(this.stimuliService.bsStimuli.value.levels[0].word) >= 0) {
                    audio.src = config.mediaServer + 'blob.mp3/6064cd215c4d0b0047bce44b.mp3';
                }
                else if (vowels.indexOf(this.stimuliService.bsStimuli.value.levels[0].word[0]) >= 0) {
                    audio.src = config.mediaServer + 'blob.mp3/60622b2ea6d935002fe0b0c0.mp3';
                }
                else {
                    audio.src = config.mediaServer + 'blob.mp3/60622b2ecf77cb001e03002f.mp3';
                }
                this.audioObjects.push(audio);
            }
            else if (this.urlType === 'findWord') {
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/63e51f247fe84b00148ed9a8.mp3';
                this.audioObjects.push(audio);

                if (this.stimuliService.bsStimuli.value?.sound) {
                    audio = new Audio();
                    audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id;
                    this.audioObjects.push(audio);
                }
            }
            else if (this.urlType === 'sentenceWriting') {
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/58a9ce863a1a651e00c10003.mp3';
                this.audioObjects.push(audio);

                if (this.stimuliService.bsStimuli.value?.sound) {
                    audio = new Audio();
                    audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id;
                    this.audioObjects.push(audio);
                }
            }
            else if (this.urlType === 'numbers7') {
                audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value.levels[this.currentNumber].sound;
                this.audioObjects.push(audio);
            }
            else if (this.stimuliService.bsTypeSettings.getValue() && (this.stimuliService.bsTypeSettings.getValue() as any).preSound === true) {
                _.forEach(this.stimuliService.bsStimuli.value.preSounds, (sound: any) => {
                    if (sound.sound) {
                        audio = new Audio();
                        audio.src = config.mediaServer + 'blob.mp3/' + sound.sound + '.mp3';
                        this.audioObjects.push(audio);
                    }
                });
            }
            else if (this.stimuliService.bsTypeSettings.getValue() && (this.stimuliService.bsTypeSettings.getValue() as any).sound && this.stimuliService.bsStimuli.value?.sound) {
                /*
                                audio = new Audio();
                                audio.src = config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id;
                                this.audioObjects.push(audio);
                */

                audio = document.createElement('audio');
                audio.setAttribute('src', config.mediaServer + 'blob.mp3/' + this.stimuliService.bsStimuli.value?.sound + '.mp3/' + this.stimuliService.bsStimuli.value._id);
                if (this.stimuliService.bsType.value.buildType === 'numbersWritingSlow') {
                    audio.playbackRate = 0.75;
                }
                this.audioObjects.push(audio);

            }

            if (this.audioObjects.length > 0 && !(this.skipPlay || this.screenService.bsNewLevel.value)) {
                this.skipPlay = false;
                return of(this.playAudioObjects(0));
            }
            else {
                if (this.urlType === 'speech' && !(this.skipListen || this.screenService.bsNewLevel.value)) {
                    this.bsListen.next(true);
                }

                return of(false);
            }
        }));
    }

    buildDragAndDropAudio(word): Observable<any> {
        this.audioObjects = [];

        const temp = _.find(this.stimuliService.bsStimuli.getValue().postSounds, (item) => {
            if (_.toLower((item as any).word) === _.toLower(word)) {
                return item.sound;
            }
        });

        if (temp && (temp as any).sound) {
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + (temp as any).sound + '.mp3/' + this.stimuliService.bsStimuli.getValue()._id;
            this.audioObjects.push(audio);
            return of(this.playAudioObjects(0));
        }

        return of(true);
    }

    buildDragAndDropAudioNumbers(word): Observable<any> {
        this.audioObjects = [];

        const temp = _.find(this.stimuliService.bsStimuli.value.levels, (item) => {
            if (_.toLower((item as any).alternateAnswer) === _.toLower(word)) {
                return (item as any).sound;
            }
            else if (_.toLower((item as any).word) === _.toLower(word)) {
                return (item as any).sound;
            }
        });

        if (temp && (temp as any).sound) {
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + (temp as any).sound + '.mp3/' + this.stimuliService.bsStimuli.getValue()._id;
            this.audioObjects.push(audio);
            return of(this.playAudioObjects(0));
        }

        return of(true);
    }

    private noSound(index): Promise<any> {
        return this.afterSound(index);
    }

    private afterSound(index): any {
        this.init();
        if (this.playPromise) {
            this.playPromise.removeEventListener('ended', this.soundListener);
        }

        this.removeHighlight();

        index++;
        if (this.urlType === 'numbers') {
            this.currentNumber++;
        }

        if (index < this.audioObjects.length) {
            return this.playAudioObjects(index);
        }
        else {
            if (this.urlType === 'repetition') {
                const link = ['/therapy/findPostSound/' + this.stimuliService.bsType.getValue().name];
                return this.router.navigate(link);
            }
            else if (this.urlType === 'sst' && this.screenService.bsOnStep.value === 0) {
                setTimeout(() => {
                    this.screenService.bsOnStep.next(this.screenService.bsOnStep.value + 1);
                }, 1000);

            }
            else if (this.urlType === 'numbers' && this.screenService.bsOnStep.value === 1) {
                this.bsListen.next(true);
            }
            else if (this.urlType === 'speech') {
                if (!this.skipListen || this.screenService.bsNewLevel.value === true) {
                    this.bsListen.next(true);
                }
                this.skipListen = false;
            }
        }

        return Promise.resolve();
    }
}
