import {catchError, retry} from 'rxjs/operators';

import {config} from '../config';
import {UserService} from './user.service';

import {HttpClient} from '@angular/common/http';

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class VideoService {

    constructor(private http: HttpClient,
                private userService: UserService) {
    }

    getVideos(videoType, level?): Observable<any> {
        if (level) {
            return this.http.get(config.server + 'videos/' + videoType + '/' + level, {withCredentials: true}).pipe(
                retry(3),
                catchError(this.userService.handleError));

        }
        else {
            return this.http.get(config.server + 'videos/' + videoType, {withCredentials: true}).pipe(
                retry(3),
                catchError(this.userService.handleError));
        }
    }

    updateVideos(videos): Observable<any> {
        return this.http.post(config.server + 'user-details/videos', videos, {withCredentials: true, responseType: 'text'}).pipe(
            retry(3),
            catchError(this.userService.handleError));
    }

    getVideosById(videos): Observable<any> {
        return this.http.post(config.server + 'videos-by-id', videos, {withCredentials: true}).pipe(
            retry(3),
            catchError(this.userService.handleError));

    }

    searchVideos(term): Observable<any> {
        return this.http.post(config.server + 'videos', term, {withCredentials: true}).pipe(
            retry(3),
            catchError(this.userService.handleError));
    }

}
