import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Component} from '@angular/core';

@Component({
    selector: 'close-account-modal',
    templateUrl: './close-account-modal.component.html'
})
export class CloseAccountModalComponent {

    constructor(public activeModal: NgbActiveModal) {
    }
}
