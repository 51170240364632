import * as _ from 'lodash';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

import {BuilderService} from '../../services/builder.service';
import {ModalService} from '../../services/modal.service';
import {UserService} from '../../services/user.service';
import {ScreenService} from '../../services/screen.service';
import {AudioService} from '../../services/audio.service';

@Component({
    selector: 'promotion',
    templateUrl: './promotion.component.html'
})
export class PromotionComponent implements OnInit, OnDestroy {
    level;
    showHomework = true;
    show;
    user;

    private oNewLevel;
    private oUser;

    constructor(private router: Router,
                private userService: UserService,
                private modalService: ModalService,
                private audioService: AudioService,
                private screenService: ScreenService,
                private builderService: BuilderService,
                private location: Location) {
    }

    ngOnInit(): void {
        this.builderService.bsOnLevel.subscribe(result => {
            this.level = result;
        });

        this.oNewLevel = this.screenService.bsNewLevel.subscribe(result => {
            this.show = result;
            if (this.show === true) {
                return this.modalService.promotion(this.builderService.bsOnLevel.value).then(() => {
                    this.screenService.bsNewLevel.next(false);
                    this.screenService.bsSetFocus.next(true);
                    setTimeout(() => {
                        return this.audioService.playAudioObjects(0);
                    }, 300);
                }, () => {
                    setTimeout(() => {
                        return this.audioService.playAudioObjects(0);
                    }, 300);
                });
            }
            return false;
        });

        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;

                if (this.showHomework && this.user.homework && this.user.homework.length > 0) {
                    const loc = decodeURI(this.location.path(true).split('/')[3]);

                    if (loc) {
                        const homework = _.find(this.user.homework, ['module', loc]);

                        if (homework && homework.done === homework.todo && !homework.alerted) {
                            this.showHomework = false;

                            homework.alerted = true;
                            return this.userService.saveHomeworkCompleted(homework).subscribe(() => {
                                return this.modalService.homework().then(() => {
                                    this.audioService.playAudioObjects(0);
                                }, () => {
                                    setTimeout(() => {
                                        this.builderService.cleanUp();
                                        this.router.navigate(['/my-homework']);

                                    }, 500);
                                });

                            });

                        }
                    }
                }
            }
            return false;
        });
    }

    ngOnDestroy(): void {
        this.oNewLevel.unsubscribe();
        this.oUser.unsubscribe();
    }
}
