<button (click)="toggleSort()" class="btn btn-secondary btn-sm float-right m-b-15" style="margin-top:-15px;">
  <span *ngIf="!dateSort">Sorted by Name</span>
  <span *ngIf="dateSort">Sorted by Most Recent</span>
</button>

<div [hidden]="user.tasks && user.tasks.length>0">You have no stats yet.</div>

<div *ngIf="user.tasks && user.tasks.length>0" class="container-fluid" style="clear: both">
  <div class="row">
    <div *ngFor="let task of user.tasks" class="col-sm-6 col-md-4 col-lg-3 p-b-30 p-t-10 center" style="display:flex; flex-direction:column; justify-content: space-between">

      <a class="fg-black text-decoration-none" routerLink="{{getLinkByName(task.task)}}">
        <h4 class="m-0 center">
          {{getVisibleName(task.task)}}
        </h4>
      </a>

      <div *ngIf="noStatsRecorded(task)" class="p-l-25 p-r-25">
        <a class="fg-black text-decoration-none" routerLink="{{getLinkByName(task.task)}}">
          <svg viewBox="0 0 42 42">
            <circle cx="21" cy="21" fill="#f0f0f0" r="15.91549430918954"></circle>
            <circle cx="21" cy="21" fill="transparent" r="15.91549430918954" stroke="#d2d3d4" stroke-width="3"></circle>
            <text class="svg-text" dominant-baseline="middle" text-anchor="middle" x="50%" y="50%">0%</text>
          </svg>

          <b>On Level {{task.onLevel}}</b>
        </a>
      </div>

      <div *ngFor="let lev of task.levels">

        <a class="fg-black text-decoration-none" routerLink="{{getLinkByName(task.task)}}">
          <div *ngIf="lev.level === task.onLevel" class="p-l-25 p-r-25">
            <svg viewBox="0 0 42 42">
              <circle cx="21" cy="21" fill="#f0f0f0" r="15.91549430918954"></circle>
              <circle cx="21" cy="21" fill="transparent" r="15.91549430918954" stroke="#d2d3d4" stroke-width="3"></circle>
              <circle attr.stroke="{{stroke(task)}}" attr.stroke-dasharray="{{lev.answeredCorrectly / lev.answered * 100 | number:'1.0-0'}} {{100 - (lev.answeredCorrectly / lev.answered * 100) | number:'1.0-0'}}" class="chart" cx="21" cy="21"
                      fill="transparent"
                      r="15.91549430918954"
                      stroke-dashoffset="75"
                      stroke-width="6">
                <animate attributeName="stroke-dasharray" begin="0.0s" dur="0.4s" from="0 100" repeatCount="once" to="67 33"/>
                <animate attributeName="stroke-dasharray" begin="0.4s" dur="0.6s" from="67 33" repeatCount="once" to="0 100"/>
                <animate attributeName="stroke-dasharray" begin="1.0s" dur="0.2s" from="0 100" repeatCount="once" to="0 100"/>
                <animate attr.dur="{{lev.answeredCorrectly / lev.answered | number:'0.2-2'}}s" attr.to="
                        {{lev.answeredCorrectly / lev.answered * 100 | number:'1.0-0'}}
                        {{100 - (lev.answeredCorrectly / lev.answered * 100) | number:'1.0-0'}}" attributeName="stroke-dasharray" begin="1.2s" from="0.1 99.9"
                         repeatCount="once"/>
              </circle>
              <text class="svg-text" dominant-baseline="middle" text-anchor="middle" x="50%"
                    y="50%">{{lev.answeredCorrectly / lev.answered * 100 | number:'1.0-0'}}%
              </text>
            </svg>

            <b>On Level
              <span *ngIf="lev.level>1">{{lev.level}}</span>
              <span *ngIf="lev.level<=1">1</span>
            </b>
            <br/>
            <small>Last used {{task.modified|date}}</small>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
