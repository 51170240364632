import * as _ from 'lodash';
import {UserService} from '../../services/user.service';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {StimuliService} from '../../services/stimuli.service';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';

@Component({
    templateUrl: './client-progress.component.html'
})
export class ClientProgressComponent implements OnInit, OnDestroy {
    user;
    clientData;
    clientId;

    messageStatus = {
        success: false,
        error: false
    };


    private oUser;
    private oClientData;

    constructor(private stimuliService: StimuliService,
                private userService: UserService,
                private location: Location) {
        this.clientId = this.location.path(true).split('/')[2];
    }

    ngOnInit(): void {
        this.oUser = this.userService.bsUser.subscribe(result => this.user = result);

        this.oClientData = this.userService.getClientList().subscribe(result => {
            this.clientData = _.sortBy(result, ['local.name']);
        });
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
        this.oClientData.unsubscribe();
    }

    send(): Subscription {
        return this.userService.saveHomeworkNotify(this.clientData).subscribe(res => {
            this.messageStatus.success = res.success;
            this.messageStatus.error = res.error;
        });
    }

    back(): void {
        return this.location.back();
    }

}
