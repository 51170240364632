import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {User} from '../../models/user';

import {settings} from '../../settings';
import {BuilderService} from '../../services/builder.service';
import {config} from '../../config';
import {ModalService} from '../../services/modal.service';

@Component({
    selector: 'app-therapy',
    templateUrl: './modules.component.html',
    styleUrls: ['./modules.component.css']
})
export class ModulesComponent implements OnInit, OnDestroy {
    user: User | null = null;
    types;
    tasks: Array<any> = [];
    filter;
    more = false;
    settings;
    version;
    showMyTasks = false;

    private oUser;
    private oTypes;

    constructor(private router: Router,
                private modalService: ModalService,
                private builderService: BuilderService,
                private userService: UserService,
                private stimuliService: StimuliService) {
        this.settings = settings;
        this.version = config.version;
    }

    ngOnInit(): void {
        this.oUser = this.userService.getUserInfo().subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        }, err => {
            if (err.status === 401) {
                const route = ['/login'];
                return this.router.navigate(route);
            }
            return false;
        });

        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            this.types = _.sortBy(result, ['sortCode']);
        });
    }

    ngOnDestroy(): void {
        if (this.oUser) {
            this.oUser.unsubscribe();
        }
        if (this.oTypes) {
            this.oTypes.unsubscribe();
        }
    }

    isPremiumUser(): boolean {
        return this.userService.userInfo.premiumSubscriber;
    }

    isAdmin(): boolean {
        return this.userService.isAdmin();
    }

    setLevel(lev): void {
        this.settings.menuLevel = lev;
        if (lev === null) {
            this.filter = null;
        }

        this.tasks = [];

        _.forEach(this.types, type => {
            if (this.userService.isAdmin() || (type.visible && type.visibility.web) || (type.visible && this.user?.beta)) {
                if (type.worksOn[this.filter] && type.menu.level.includes(this.settings.menuLevel)) {
                    this.tasks.push(type);
                }
            }
        });
    }

    selectedLevel(lev): any {
        if (this.settings.menuLevel === lev) {
            return 'level-disabled';
        }
    }

    setSelectedType(type): void {
        if (type) {
            this.showMyTasks = false;
            this.filter = type;
        }

        this.settings.menuLevel = null;
        this.tasks = [];
    }

    selectedType(type): any {
        if (this.filter === type) {
            return 'level-disabled';
        }
    }

    highlightLevel(level): boolean {
        // tslint:disable-next-line:triple-equals
        if (level == settings.highlightLevel && !(this.settings.menuLevel == this.settings.highlightLevel)) {
            return true;
        }
        else {
            return false;
        }
    }

    highlightType(type): boolean {
        // tslint:disable-next-line:triple-equals
        if (type == settings.highlightType && this.settings.menuLevel == this.settings.highlightLevel && !this.filter) {
            return true;
        }
        else {
            return false;
        }
    }

    highlightModule(module): boolean {
        if (module === this.settings.highlightModule) {
            return true;
        }
        else {
            return false;
        }
    }

    name(): string {
        if (this.user && this.user.local && this.user.local.name) {
            return this.user.local.name;
        }
        else if (this.user && this.user.google && this.user.google.name) {
            return this.user.google.name;
        }
        else if (this.user && this.user.facebook && this.user.facebook.name) {
            return this.user.facebook.name;
        }
        else if (this.user && this.user.twitter && this.user.twitter.displayName) {
            return this.user.twitter.displayName;
        }
        else {
            return 'No name';
        }
    }

    navigate(type, event): Promise<any> {
        if (!this.userService.userInfo.premiumSubscriber) {
            if (this.userService.userInfo.basicSubscriber) {
                const route = ['/modules-videos'];
                return this.router.navigate(route);
            }
            else {
                const route = ['/account'];
                return this.router.navigate(route);
            }
        }
        else {
           const route = ['/therapy/' + type.templateType + '/' + type.name];
            if (event.button === 1) {
                window.open(route[0], '_blank');
                return Promise.resolve();
            }
            else {
                return this.router.navigate(route);
            }
        }
    }

    myVideos(): Promise<any> {
        const route = ['/my-videos'];
        return this.router.navigate(route);
    }

    myClients(): Promise<any> {
        const route = ['/client-list'];
        return this.router.navigate(route);
    }

    showProgram(type, filter): boolean {
        if (this.user && (this.user.userType === 'Admin' || (type.visible && type.visibility.web) || (type.visible && this.user.beta))) {
            if (this.settings.menuLevel === 0 && filter === null && type.menu.level.includes(this.settings.menuLevel)) {
                return true;
            }
            else if (type.worksOn[filter] && settings.menuLevel && type.menu.level.includes(this.settings.menuLevel)) {
                return true;
            }
        }
        return false;
    }

    isTherapist(): boolean {
        return this.userService.isTherapist();
    }

    isAlpha(type): boolean {
        if (!type.visible) {
            return true;
        }

        return false;
    }

    isMyTask(type): boolean {
        const recent = _.find(this.userService.bsUser.value?.tasks, {task: type.name});
        if (recent?.modified) {
            const date = new Date();
            date.setDate(date.getDate() - 7);
            const modified = new Date(recent.modified);

            if (modified > date) {
                return true;
            }
        }
        return false;
    }

    isBeta(type): boolean {
        if (type.active && !type.visible) {
            return true;
        }
        else if (type.active && type.visible && !type.visibility.web) {
            return true;
        }
        return false;
    }

    hasStats(): boolean {
        if (this.user?.tasks && this.user?.tasks.length > 0) {
            return true;
        }
        return false;
    }

    hasHomework(): boolean {
        if (this.user && ((this.user.homework && this.user.homework.length > 0) || (this.user.homeworkVideos && this.user.homeworkVideos.length > 0))) {
            return true;
        }
        return false;
    }

    hasVideos(): boolean {
        if (this.user && this.user.videos && this.user.videos.length > 0) {
            return true;
        }
        return false;
    }

    myHomework(): Promise<any> {
        const route = ['/my-homework'];
        return this.router.navigate(route);
    }

    setShowMyTasks(): void {
        this.showMyTasks = true;
        this.tasks = [];
        this.setSelectedType(null);
        this.setLevel(null);

        _.forEach(this.types, type => {
            if (this.userService.isAdmin() || (type.visible && type.visibility.web) || (type.visible && this.user?.beta)) {
                if (this.isMyTask(type)) {
                    this.tasks.push(type);
                }
            }
        });

    }

    getClass(type): string {
        let returnData = 'rounded-bottom p-1';

        if (type.menu && type.menu.color) {
            returnData = returnData + ' ' + type.menu.color;
        }
        else {
            returnData = returnData + ' fg-default ';
        }
        if (type.menu && type.menu.backgroundColor) {
            returnData = returnData + ' ' + type.menu.backgroundColor;
        }
        else {
            returnData = returnData + ' bg-default ';
        }
        return returnData;
    }

    getImage(type): string {
        const task = _.find(this.user?.tasks, {task: type.name});
        if (task && task.onLevel > type.maxLevel) {
            return '/images/modules/finished.png';
        }
        else if (type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }


}
