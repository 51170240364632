import * as _ from 'lodash';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'lost-password-reset',
    templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
    errorPassword;
    errorEmail;
    successMessage;

    formData: any = {
        password: '',
    };

    user;

    constructor(private userService: UserService) {
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        });
    }

    ngOnDestroy(): void {
    }

    processLostPassReset(): void {
        this.userService.changePassword(this.formData).subscribe(response => {
            if (response.success === false) {
                // if not successful, bind errors to error variables
                this.errorPassword = response.errors.password;
                this.errorEmail = response.errors.email;
            }
            else {
                // if successful, bind success message to message
                this.successMessage = response.message;
                this.errorPassword = '';
            }
        });
    };

}



