<div class="container-fluid bg-white" id="pricing-1">

  <a href="/"><img class="p-t-15 p-l-15" src="/images/logo2.png" width="300"/></a>

  <div class="container" id="fp-2">
    <div class="" id="fp-3">
      <br/>
      <br/>
      <h1 id="fp-4">Software for Faster Aphasia Progress</h1>
      <br/>
      <h3>Expert Therapy You Can Do From Home for Stroke and Brain Injury
        Rehabilitation</h3>
      <br/>
      <br/>

      <div class="inline-block">
      <a [routerLink]="'/register'" class="btn trial p-30 h3">
        Get started for free
      </a>
      <br/><div class="text-small center">* No Credit Card Required</div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-teal">
  <div class="container p-30 center">
    <h2>Included In Your Subscription</h2>
  </div>
</div>

<div class="container-fluid bg-white">
  <div class="container">
    <div class="center">
      <svg class="p-b-30" style="width:100px" viewBox="0 0 42 21">
        <polygon class="triangle" points="0,0 42,0 21,21"/>
      </svg>
    </div>
  </div>
</div>


<div class="container-fluid p-t-10 p-b-30 bg-white">
  <div class="container p-30">
    <div class="row p-30 m-l-30 m-r-30">
      <div class="col-sm-6 p-10 h3 p-b-10" style="padding-left: 60px !important;">
        <img src="/images/square-check.png" style="width:60px; float: left" class="p-r-20 "/>
        Unlimited therapy whenever<br/>
        and wherever you want it
      </div>
      <div class="col-sm-6 p-10 h3 p-b-10" style="padding-left: 60px !important;">
        <img src="/images/square-check.png" style="width:60px; float: left" class="p-r-20"/>
        An affordable way to continue improving between sessions
      </div>
      <div class="col-sm-6 p-10 h3 p-b-10" style="padding-left: 60px !important;">
        <img src="/images/square-check.png" style="width:60px; float: left" class="p-r-20"/>
        Designed and used by<br/>
        aphasia experts
      </div>
      <div class="col-sm-6 p-10 h3 p-b-10" style="padding-left: 60px !important;">
        <img src="/images/square-check.png" style="width:60px; float: left" class="p-r-20"/>
        Work independently or<br/>
        with your Speech Therapist
      </div>
      <div class="col-sm-6 p-10 h3 p-b-10" style="padding-left: 60px !important;">
        <img src="/images/square-check.png" style="width:60px; float: left" class="p-r-20"/>
        Automatically adjusts to your level and pace
      </div>
      <div class="col-sm-6 p-10 h3 p-b-10" style="padding-left: 60px !important;">
        <img src="/images/square-check.png" style="width:60px; float: left" class="p-r-20"/>
        Works on all devices: Apple, Android, and Web
      </div>
    </div>

    <div class="center p-30 h2">
      Online therapy help for less than $1 a day!
    </div>
  </div>
</div>

<div class="container-fluid bg-teal">
  <div class="container p-30 center">
    <h2>Start your Free Trial</h2>
  </div>
</div>
<div class="container-fluid bg-white">
  <div class="container">
    <div class="center">
      <svg class="p-b-30" style="width:100px" viewBox="0 0 42 21">
        <polygon class="triangle" points="0,0 42,0 21,21"/>
      </svg>
    </div>
  </div>
</div>


<div class="container-fluid p-t-10 p-b-30 bg-white">
  <div class="container p-30">
    Choose the subscription plan that best fits your long-term goals. Don't worry, there's no credit card required to start your trial and you can always change your plan later.
    <br/>
    <div class="row p-t-30 p-10">
      <div class="col-sm-4 p-5">
        <div class="pricing-1">
          <div class="text-center p-15 pricing-4 bg-teal">
            <h3 class="p-t-10 p-b-0 m-b-0">Video Only</h3>
            14-days free, then only
            <h5 class="m-b-0"> $9.99
              <wbr>
              /month
            </h5>
          </div>
          <div class="p-10">
            <table>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">1700+ videos to speak in words and sentences</td>
              </tr>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">Messages</td>
              </tr>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">Connect with your speech therapist</td>
              </tr>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">Save your videos</td>
              </tr>
              <!--              <tr>
                              <td><span class="material-icons middle h1 m-0 text-gray">close</span></td>
                              <td class="text-gray p-5">50,000+ exercises for reading, writing, and understanding</td>
                            </tr>
                            <tr>
                              <td><span class="material-icons middle h1 m-0 text-gray">close</span></td>
                              <td class="text-gray p-5">Promotion to new levels and exercises</td>
                            </tr>-->
            </table>
          </div>
          <button class="trial p-30" routerLink="/register" routerLinkActive="active">Start your free trial</button>
        </div>
      </div>

      <div class="col-sm-4 p-5">
        <div class="pricing-1">
          <div class="text-center p-15 pricing-4 bg-teal">
            <h3 class="p-t-10 p-b-0 m-b-0">Monthly Subscription</h3>
            14-days free, then only
            <h5 class="m-b-0">
              $19.99
              <wbr>
              /Month
            </h5>
          </div>
          <div>
            <div class="p-10">
              <table>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">1700+ videos to speak in words and sentences</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Messages</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Connect with your speech therapist</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Save your videos to my videos</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">50,000+ exercises for reading, writing, and understanding</td>
                </tr>
                <tr>
                  <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                  <td class="p-5">Promotion to new levels and exercises</td>
                </tr>
              </table>
            </div>
          </div>
          <button class="trial p-30" routerLink="/register" routerLinkActive="active">Start your free trial</button>
        </div>
      </div>

      <div class="col-sm-4 p-5">
        <div class="pricing-1">
          <div class="text-center p-15 bg-teal">
            <h3 class="p-t-10 p-b-0 m-b-0">Yearly Subscription</h3>
            14-days free, then only
            <h5 class="m-b-0">$199
              <wbr>
              /Year
            </h5>
          </div>
          <div class="p-10">
            <table>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">1700+ videos to speak in words and sentences</td>
              </tr>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">Messages</td>
              </tr>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">Connect with your speech therapist</td>
              </tr>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">Save your videos to my videos</td>
              </tr>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">50,000+ exercises for reading, writing, and understanding</td>
              </tr>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5">Promotion to new levels and exercises</td>
              </tr>
              <tr>
                <td><span class="material-icons middle h1 m-0 text-teal">check</span></td>
                <td class="p-5"><b>2 months free</b></td>
              </tr>
            </table>
          </div>
          <button class="trial p-30" routerLink="/register" routerLinkActive="active">Start your free trial</button>
        </div>
      </div>


    </div>

  </div>
</div>
<app-my-footer></app-my-footer>
