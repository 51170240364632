<div class="container p-t-20">

  <div class="row" id="get-access">

    <div class="col-sm-3 m-l-15 p-15" style="background-color: rgba(255, 255, 255, 0.3);">

      <div *ngIf="!status || (!status.success && !status.message)">
        Welcome to My Aphasia Coach. You must be logged in to get access. After you have created an account or signed in,
        click on the link in the email again to get access.<br/>
        <br/>
        <a routerLink="/register">Create an account</a>, or <a routerLink="/login">sign in</a>.
      </div>

      <div *ngIf="status && !status.success && status.message">
        {{status.message}}
      </div>

      <div *ngIf="status && status.success">
        <div class="h3 p-l-15">You are now connected with your therapist.</div>
      </div>

    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
