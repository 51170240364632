<app-error-message></app-error-message>
<div *ngIf="stimuli && alternatives && type && typeSettings" class="center">
  <h2>Now say each word</h2>
  <img [src]="getStimuliImage()" class="stimuli-image" ng-if="level.mainImage && stimuli.image">
  <br clear="all">

  <div class="center-text p-b-10">
    <span *ngFor="let sound of stimuli.postSounds; let index = index" [innerHtml]="sound.word" class="h1 p-5" id="word{{index}}"></span>
  </div>
</div>
