<div class="container-fluid bg-white">
  <div class="container bg-white p-t-20">
  <div *ngIf="!user">
    This page is only available to registered users. <a routerLink="/register">Register here</a>.
  </div>
  <div *ngIf="user" class="row">
    <div class="col-sm-12">
      <app-trial></app-trial>
      <h1>Progress Report</h1>
      <app-error-message></app-error-message>
      <h4 *ngIf="user && user.tasks && user.tasks.length>0">You are doing great work!</h4>
      <app-stats *ngIf="user" [user]="user"></app-stats>
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
</div>
