import {Component, OnInit} from '@angular/core';

import {ModalService} from '../../services/modal.service';

@Component({
    selector: 'faq-therapist',
    templateUrl: './faq-therapist.component.html',
    styleUrls: ['./faq-therapist.component.css']
})
export class FaqTherapistComponent implements OnInit {

    constructor(private modalService: ModalService,) {
    }

    ngOnInit(): void {
    }

    open(videoUrl): Promise<any> {
        return this.modalService.tutorial(videoUrl);
    }

}
