<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 center p-t-10 bg-teal">
        <h1>What is expressive aphasia?</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 p-15">
      Please see the page <a routerLink="/aphasia/receptive">receptive aphasia</a>.
    </div>
  </div>
</div>
<app-my-footer></app-my-footer>
