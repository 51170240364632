import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-aphasia',
    templateUrl: './aphasia-global.component.html',
})
export class AphasiaGlobalComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
