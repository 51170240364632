import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';

@Component({
    templateUrl: './beta.component.html',
    styleUrls: ['./beta.component.css']
})
export class BetaComponent implements OnInit {
    @ViewChild('fileInput') fileInput;

    formData: any = {
        name: null,
        email: null,
        message: null,
        errorLocation: null
    };

    emailSent;
    errorMessage;

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
    }


    processContact(): Subscription {
        const fd: FormData = new FormData();
        const files: FileList = this.fileInput.nativeElement.files;

        if (files.length > 0) {
            // fd.append(files[0].name, files[0]);
            fd.append('file', files[0]);
        }

        fd.append('email', this.formData.email);
        fd.append('name', this.formData.name);
        fd.append('message', this.formData.message);
        fd.append('errorLocation', this.formData.errorLocation);

        return this.userService.betaFeedback(fd).subscribe(res => {
            this.emailSent = res.success;
            this.errorMessage = res.error;
        });
    }
}
