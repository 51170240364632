<div class="container-fluid bg-white" id="about-us">
  <a href="/"><img class="p-t-15 p-l-15" src="/images/logo2.png" width="300"/></a>

  <div class="container" id="fp-2">
    <div class="" id="fp-3">
      <br/>
      <br/>
      <h1 id="fp-4">Software for Faster Aphasia Progress</h1>
      <br/>
      <h3>Expert therapy you can do from home for stroke and brain injury
rehabilitation</h3>
      <br/>
      <br/>


      <div class="inline-block">
        To delete your account, select 'Account' from the menu in the app , and then tap the 'Delete Account' button. Your account will be deleted
        within 72 hours.
    </div>
    </div>
  </div>
</div>


<app-my-footer></app-my-footer>
