import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user';
import {settings} from '../../settings';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {
    settings;
    user: User | null = null;
    userInfo;
    constructor(private userService: UserService) {

        this.userInfo = userService.userInfo;
        this.settings = settings;

    }

    ngOnInit(): void {
        this.userService.bsUser.subscribe(result => {
            this.user = result;
        });
    }

    ngOnDestroy(): void {
    }

    logout(): void {
        this.userService.logout().subscribe();
    }

    isTherapist(): boolean {
        return this.userService.isTherapist();
    }

    isAdmin(): boolean {
        return this.userService.isAdmin();
    }

    isPremiumUser(): boolean {
        return this.userService.userInfo.premiumSubscriber;
    }

    isBasicUser(): boolean {
        return this.userService.userInfo.basicSubscriber;
    }

    isBetaUser(): boolean {
        return this.userService.userInfo.beta;
    }

    isIE(): boolean {
        return false;
        /*
                const ua = window.navigator.userAgent;
                const ie = ua.indexOf('MSIE');
                const tr = ua.indexOf('Trident/');

                if (ie !== -1 || tr !== -1) {
                    return true;
                }
                else {
                    return false;
                }
        */
    }

    hideMessages(): boolean {
        return this.userService.hideMessages();
    }

}

