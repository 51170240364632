import {Component, OnInit} from '@angular/core';

@Component({
    templateUrl: './landing-mobile.component.html'
})
export class LandingMobileComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
