<div *ngIf="user" class="container-fluid bg-white p-t-20 ">
  <div class="row bg-white">
    <div class="col-sm-12 p-15">
      <app-version></app-version>
      <app-trial></app-trial>
    </div>
  </div>
</div>

<div *ngIf="isPremiumUser()" class="bg-white">

  <div [hidden]="!hasStats()" class="container">
    <div class="text-center h2">What do you want to do?</div>

    <div class="" style="display:flex; justify-content:center;  flex-wrap: wrap; margin-left: -30px; margin-right:-30px;">
      <div  [routerLink]="'/my-tasks'" class="pointer p-10" style="width:20%; min-width:120px;">
        <div [class]="'p-10 h100p pointer btn-primary'">
          <h3 class="text-center">
            <span class="material-icons h1">list</span><br/>
            My Tasks</h3>
        </div>
      </div>

      <div (click)="myClients()" *ngIf="isTherapist()" class="pointer p-10" style="width:20%; min-width:120px;">
        <div [class]="'p-10 h100p pointer btn-primary'">
          <h3 class="text-center">
            <span class="material-icons h1">groups</span><br/>
            My Patients</h3>
        </div>
      </div>
      <div [routerLink]="'/where-do-i-start'" class="pointer p-10" style="width:20%; min-width:120px;">
        <div [class]="'p-10 h100p pointer btn-danger'" style="background-color: #802021;">
          <h3 class="text-center">
            <span class="material-icons h1">fact_check</span><br/>
            Quiz</h3>
        </div>
      </div>
    </div>
    <div>
      <h2 class="text-center p-t-15">Or choose a skill</h2>
    </div>

    <div class="row" style="display:flex; justify-content:center;  flex-wrap: wrap;  margin-left: -30px; margin-right:-30px;">
      <div (click)="setSelectedType('reading')" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-focus p-10 ' + selectedType('reading')" [class.pulse4]="highlightType('reading')">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">menu_book</span><br/>
            Reading
          </h3>
        </div>
      </div>
      <div (click)="setSelectedType('writing')" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-focus p-10 ' + selectedType('writing')" [class.pulse4]="highlightType('writing')">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">edit</span><br/>
            Writing
          </h3>
        </div>
      </div>
      <div (click)="setSelectedType('comprehension')" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-focus p-10 ' + selectedType('comprehension')" [class.pulse4]="highlightType('comprehension')">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">headset</span><br/>
            Listening</h3>
        </div>
      </div>
      <div (click)="setSelectedType('speech')" class="col-md-3 col-sm-6 pointer p-10" [class.pulse4]="highlightType('speech')">
        <div [class]="'m-1 btn-focus p-10 ' + selectedType('speech')">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">message</span><br/>
            Speech</h3>
        </div>
      </div>
    </div>
  </div>

  <div [hidden]="hasStats()" class="container">
    <div class="text-center h4">What do you want to do?</div>
    <div class="row" style="display:flex; justify-content:center;  flex-wrap: wrap;">
      <div (click)="setSelectedType('reading')" class="pointer p-10" style="width:20%">
        <div [class]="'m-1 btn-focus p-10 ' + selectedType('reading')" [class.pulse4]="highlightType('reading')">
          <h5 class="text-center p-t-5">
            <span class="material-icons h1">menu_book</span><br/>
            Reading
          </h5>
        </div>

      </div>
      <div (click)="setSelectedType('writing')" class="pointer p-10" style="width:20%">
        <div [class]="'m-1 btn-focus p-10 ' + selectedType('writing')" [class.pulse4]="highlightType('writing')">
          <h5 class="text-center p-t-5">
            <span class="material-icons h1">edit</span><br/>
            Writing
          </h5>
        </div>
      </div>
      <div (click)="setSelectedType('comprehension')" class="pointer p-10" style="width:20%">
        <div [class]="'m-1 btn-focus p-10 ' + selectedType('comprehension')" [class.pulse4]="highlightType('comprehension')">
          <h5 class="text-center p-t-5">
            <span class="material-icons h1">headphones</span><br/>
            Listening
          </h5>
        </div>
      </div>
      <div (click)="setSelectedType('speech')" class="pointer p-10" [class.pulse4]="highlightType('speech')" style="width:20%">
        <div [class]="'m-1 btn-focus p-10 ' + selectedType('speech')">
          <h5 class="text-center p-t-5">
            <span class="material-icons h1">message</span><br/>
            Speech</h5>
        </div>
      </div>
      <div [routerLink]="'/where-do-i-start'" class="pointer p-10" style="width:20%">
        <div [class]="'p-10 h100p pointer btn-danger'" style="background-color: #802021;">
          <h5 class="text-center p-t-5">
            <span class="material-icons h1">fact_check</span><br/>
            Take the quiz</h5>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="filter" class="container">
    <div class="h4 m-t-15 m-b-5 text-center">
      Choose your level
    </div>
    <div class="row" style="display:flex; justify-content:center;  flex-flow: row wrap;">
      <div (click)="setLevel(0)" [hidden]="selectedType('speech')" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 p-10">
        <div [class]="'p-10 center h100p pointer bg-menu rounded ' + selectedLevel(0)" [class.pulse4]="highlightLevel(0)">
          <h4>Practice<br/>(Introductory)</h4>
          <h5>Simplified exercises</h5>
        </div>
      </div>
      <div (click)="setLevel(1)" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 p-10">
        <div [class]="'p-10 center h100p pointer bg-menu rounded ' + selectedLevel(1)" [class.pulse4]="highlightLevel(1)">
          <h4>Level 1<br/>(Beginning)</h4>
          <h5>Words</h5>
        </div>
      </div>
      <div (click)="setLevel(2)" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 p-10">
        <div [class]="'p-10 center h100p pointer bg-menu rounded ' + selectedLevel(2)" [class.pulse4]="highlightLevel(2)">
          <h4>Level 2<br/>(Emerging)</h4>
          <h5>Words and short sentences</h5>
        </div>
      </div>
      <div (click)="setLevel(3)" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 p-10">
        <div [class]="'p-10 center h100p pointer bg-menu rounded ' + selectedLevel(3)" [class.pulse4]="highlightLevel(3)">
          <h4>Level 3<br/>(Challenging)</h4>
          <h5>Sentences and paragraphs</h5>
        </div>
      </div>
    </div>
  </div>

  <div [hidden]="!showMyTasks" class="container">
    <div class="h4 m-t-15 m-b-5 text-center">
      My Tasks
    </div>
  </div>

  <div *ngIf="tasks && tasks.length>0" class="container">
    <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
      <div (click)="navigate(type, $event)" (auxclick)="navigate(type, $event)" *ngFor="let type of tasks" class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
        <div [class]="getClass(type)" style="height:100%;" [class.pulse4]="highlightModule(type.name)">
          <img [src]="getImage(type)" width="100%"/>
          <div class="p-10 text-center btn-secondary">
            <h5>
              {{type.visibleName || type.name}}
            </h5>
            {{type.description}}
            <span *ngIf="isAlpha(type)" class="text-danger bg-white">Alpha</span>
            <span *ngIf="isBeta(type) && !isAlpha(type)" class="text-danger bg-white">Beta</span>
          </div>
        </div>
      </div>

      <div *ngIf="selectedType('speech') && settings.menuLevel === 1" class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/1" routerLinkActive="active">
        <div class="p-2 fg-white bg-blue h-100">
          <img src="/images/modules/video-1.png" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              Apraxia Video 1
            </h5>
            1 syllable, 2 sounds
          </div>
        </div>
      </div>
      <div *ngIf="selectedType('speech') && settings.menuLevel === 1" class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/2" routerLinkActive="active">
        <div class="p-2 fg-white bg-blue h-100">
          <img src="/images/modules/video-2.png" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              Apraxia Video 2
            </h5>
            1 syllable, 3 sounds
          </div>
        </div>
      </div>
      <div *ngIf="selectedType('speech') && settings.menuLevel === 1" class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/3" routerLinkActive="active">
        <div class="p-2 fg-white bg-blue h-100">
          <img src="/images/modules/video-3.png" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              Apraxia Video 3
            </h5>
            1 syllable, blends
          </div>
        </div>
      </div>
      <div *ngIf="selectedType('speech') && settings.menuLevel === 2" class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/4" routerLinkActive="active">
        <div class="p-2 fg-white bg-blue h-100">
          <img src="/images/modules/video-4.png" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              Apraxia Video 4
            </h5>
            3 word sentences, 3-5 syllable words
          </div>
        </div>
      </div>
      <div *ngIf="selectedType('speech') && settings.menuLevel === 2" class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/5" routerLinkActive="active">
        <div class="p-2 fg-white bg-blue h-100">
          <img src="/images/modules/video-5.png" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              Apraxia Video 5
            </h5>
            3 word sentences, harder sounds
          </div>
        </div>
      </div>
      <div *ngIf="selectedType('speech') && settings.menuLevel === 2" class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/6" routerLinkActive="active">
        <div class="p-2 fg-white bg-blue h-100">
          <img src="/images/modules/video-6.png" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              Apraxia Video 6
            </h5>
            4-6 word sentences<br/><br/>
          </div>
        </div>
      </div>
      <div *ngIf="selectedType('speech') && settings.menuLevel === 3" class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/7" routerLinkActive="active">
        <div class="p-2 fg-white bg-blue h-100">
          <img src="/images/modules/video-7.png" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              Apraxia Video 7
            </h5>
            3+ syllable words, 4-8 word sentences<br/>
          </div>
        </div>
      </div>
      <div *ngIf="selectedType('speech') && settings.menuLevel === 3" class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/8" routerLinkActive="active">
        <div class="p-2 fg-white bg-blue h-100">
          <img src="/images/modules/video-8.png" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              Apraxia Video 8
            </h5>
            4-6 word sentences, multisyllabic words
          </div>
        </div>
      </div>
      <div *ngIf="selectedType('speech') && settings.menuLevel === 3" class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/9" routerLinkActive="active">
        <div class="p-2 fg-white bg-blue h-100">
          <img src="/images/modules/video-9.png" width="100%"/>
          <div class="p-10 text-center">
            <h5>
              Apraxia Video 9
            </h5>
            Sentences for faster pacing
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-my-footer></app-my-footer>
