import {Component, OnDestroy, OnInit} from '@angular/core';

import * as _ from 'lodash';

import {UserService} from '../../services/user.service';
import {User} from '../../models/user';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';


@Component({
    selector: 'app-trial',
    templateUrl: './trial.component.html',
    styleUrls: ['./trial.component.css']
})
export class TrialComponent implements OnInit, OnDestroy {
    userInfo;
    user: User | null = null;

    constructor(private router: Router,
                private userService: UserService) {
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        });
    }

    ngOnDestroy(): void {
    }

    payment(): Promise<any> {
        const route = ['/payment'];
        return this.router.navigate(route);
    }

    dayDiff(): number {
        return this.userService.dayDiff();
    }
}
