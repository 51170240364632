// @ts-nocheck
import * as _ from 'lodash';

import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';

import {UserService} from '../../services/user.service';
import {AnswerService} from '../../services/answer.service';
import {BuilderService} from '../../services/builder.service';
import {StimuliService} from '../../services/stimuli.service';

import {config} from '../../config';
import {settings} from '../../settings';

@Component({
    templateUrl: './csat-therapist.component.html',
    styleUrls: ['./csat-therapist.component.css']
})
export class CsatTherapistComponent implements OnInit, OnDestroy, AfterViewInit {
    config;
    settings;
    ws;

    alternatives: Array<any> = [];
    typeSettings: any = {};
    userInfo;
    type;
    stimuli;

    message = {
        from: '',
        to: '',
        text: '',
        alternatives: []
    };

    response = {
        from: '',
        text: ''
    };

    user;

    clientId;
    client;

    private oClientData;
    private oType;
    private oStimuli;
    private oAlternatives;
    private oTypeSettings;
    private oGetType;
    private oUser;

    constructor(private userService: UserService,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private answerService: AnswerService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.config = config;
        this.settings = settings;
        this.clientId = this.location.path(true).split('/')[2];
    }

    ngOnInit(): void {
        this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');

        this.oUser = this.userService.getUserInfo().subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                this.message.from = this.user._id;
            }

        });

        this.oClientData = this.userService.getClientList().subscribe(result => {
            const client = _.find(result, ['_id', this.clientId]);

            this.client = client.local.name + ', (' + client.local.email + ')';

            this.message.to = client._id;
        });

        this.oType = this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        });

        this.oStimuli = this.stimuliService.stimuli.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.stimuli = result;
            }
        });

        this.oAlternatives = this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        });

        this.oTypeSettings = this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        });

        this.builderService.skipPlay = true;
        this.oGetType = this.builderService.getType('4pics1sentence').subscribe();

        return this.ws.getDataStream().subscribe(
            (msg: MessageEvent) => {
                return this.receiveMessage(msg);
            },
            (err) => {
                console.error(new Error(err));
            });
    }

    ngOnDestroy(): void {
        this.ws.close();
        this.oUser.unsubscribe();
        this.oClientData.unsubscribe();
        this.oType.unsubscribe();
        this.oStimuli.unsubscribe();
        this.oAlternatives.unsubscribe();
        this.oTypeSettings.unsubscribe();
        this.oGetType.unsubscribe();
    }

    ngAfterViewInit(): void {
    }

    receiveMessage(msg?): void {
        try {
            const message = JSON.parse(msg.data);
            // tslint:disable-next-line:triple-equals
            if (this.user && message.to == this.user._id) {
                if (message.text) {
                    this.response.text = message.text;
                }
            }
        } catch (err: any) {
            console.error(new Error(err));
        }
    }

    sendAnswer(id): void {
        this.message.alternatives = null;
        this.message.text = id;
        return this.send();
    }

    sendAlternatives(): void {
        this.message.alternatives = this.alternatives;
        return this.send();
    }

    get() {
        return this.builderService.getType('4pics1sentence').subscribe();
    }

    send() {
        return this.ws.send(this.message).subscribe(
            (res) => {
            },
            (err) => {
                return setTimeout(() => {
                    return this.send();
                }, 500);
            });
    }

    check() {
        if (this.user && this.clientId) {
            const msg = {
                check: true,
                from: this.clientId,
                to: this.user._id,
            }
            return this.ws.send(msg).subscribe();
        }
    }

    getAnswerImage(image) {
        return this.screenService.getAnswerImage(image);
    }

    selected(id) {
        if (id == this.response.text) {
            return true;
        }
    }

    correct(id) {
        if (id == this.message.text) {
            return true;
        }
    }

}
