import {settings} from '../../settings';
import {User} from '../../models/user';
import {UserService} from '../../services/user.service';
import {VideoService} from '../../services/video.service';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';


import * as _ from 'lodash';


import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {StimuliService} from '../../services/stimuli.service';
import {Subscription, zip} from 'rxjs';

@Component({
    selector: 'app-my-homework',
    templateUrl: './my-homework.component.html'
})

export class MyHomeworkComponent implements OnInit, OnDestroy {
    settings;
    userInfo;

    user: User | null = null;
    videos;
    myVideos;
    types;
    homework: Array<any> = [];

    show = true;

    private oUser;
    private oTypes;
    private oVideosUnfiltered;
    private oMyVideos;


    constructor(private stimuliService: StimuliService,
                private userService: UserService,
                private videoService: VideoService,
                private modalService: ModalService,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private router: Router) {
        this.settings = settings;
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): void {
        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
                // @ts-ignore
                this.myVideos = this.user.homeworkVideos;
            }
        });

        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            if (result) {
                result = _.sortBy(result, 'visibleName');
                this.types = result;
            }
        });

        zip(this.userService.bsUser, this.stimuliService.getTypes()).subscribe(res => {
            // @ts-ignore
            _.forEach(this.user.homework, work => {
                _.forEach(res[1], (type, ix) => {
                    if ((work as any).module && (work as any).module === type.name) {
                        type.todo = (work as any).todo;
                        type.done = (work as any).done;

                        this.homework.push(type);
                    }
                });
            });
        });
    }

    ngOnDestroy(): void {
        if (this.oUser) {
            this.oUser.unsubscribe();
        }
        if (this.oVideosUnfiltered) {
            this.oVideosUnfiltered.unsubscribe();
        }
    }

    open(videoUrl): Promise<any> {
        if (!this.userService.userInfo.basicSubscriber) {
            return this.router.navigate(['/account']);
        }
        else {
            return this.modalService.video(videoUrl);
        }
    }


    bookmarked(id): boolean {
        if (this.user && this.user.videos && this.user.videos.indexOf(id) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }

    addVideo(id): Subscription {
        // @ts-ignore
        this.user.videos.push(id);

        // @ts-ignore
        return this.videoService.updateVideos({videos: this.user.videos}).subscribe(() => {
            this.show = false;
            this.cdr.detectChanges();
            this.show = true;
            this.cdr.detectChanges();
        });
    }

    removeVideo(id): void {
        // @ts-ignore
        _.pull(this.user.videos, id);
        // @ts-ignore
        return this.videoService.updateVideos({videos: this.user.videos}).subscribe(() => {
            this.show = false;
            this.cdr.detectChanges();
            this.show = true;
            this.cdr.detectChanges();

        });
    }

    getClass(type): string {
        let returnData = 'p-2 ';

        if (type.menu && type.menu.color) {
            returnData = returnData + ' ' + type.menu.color;
        }
        else {
            returnData = returnData + ' fg-default ';
        }

        if (type.menu && type.menu.backgroundColor) {
            returnData = returnData + ' ' + type.menu.backgroundColor;
        }
        else {
            returnData = returnData + ' bg-default ';
        }

        if (type.done >= type.todo) {
            returnData = returnData + ' homework-completed';
        }

        return returnData;
    }

    getImage(type): string {
        if (type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }

    moduleCompleted(type){
        if (this.user && this.user.tasks) {
            const t2 = _.find(this.user.tasks, {task: type.name});
            const t3 = _.find(this.types, {name: type.name}) as any;
            if (t2 && t3 && t2.onLevel > t3.maxLevel) {
                return true;
            }
        }
        return false;
    }

    navigate(type): Promise<any> {
        if (this.user && this.user.tasks) {
            const t2 = _.find(this.user.tasks, {task: type.name});
            const t3 = _.find(this.types, {name: type.name}) as any;
            if (t2 && t3 && t2.onLevel > t3.maxLevel) {
                return Promise.resolve(false);
            }
        }

        if (!this.userService.userInfo.premiumSubscriber) {
            if (this.userService.userInfo.basicSubscriber) {
                const route = ['/modules-videos'];
                return this.router.navigate(route);
            }
            else {
                const route = ['/account'];
                return this.router.navigate(route);
            }
        }
        else {
            const route = ['/therapy/' + type.templateType + '/' + type.name];
            return this.router.navigate(route);
        }
    }

    copy(): Subscription {
        let videos: Array<any> = [];

        // @ts-ignore
        _.forEach(this.user.homeworkVideos, video => {
            // @ts-ignore
            this.user.videos.push(video.video.toString());
        });

        // @ts-ignore
        videos = _.uniq(this.user.videos);

        return this.videoService.updateVideos({videos}).subscribe(() => {
            this.show = false;
            this.cdr.detectChanges();
            this.show = true;
            this.cdr.detectChanges();
        });
    }


}
