import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
})
export class AboutUsComponent implements OnInit {
    user;
    oUser;
    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
        this.oUser = this.userService.bsUser.subscribe(result => {
            if (result !== null) {
                this.user = result;
            }
        });
    }

}
