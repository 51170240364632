<div class="bg-light-gray">
  <div class="container p-t-20 p-b-20">
    <div class="row">
      <div class="col-sm-12 p-15">
        <h1>Invite Patient</h1>

        <p *ngFor="let alert of alerts">
          <ngb-alert (close)="closeAlert(alert)" [type]="alert.type">{{ alert.message }}</ngb-alert>
        </p>

        Connect with a patient, assign homework, and monitor their progress.<br/>
        <br/>
        Type their email address below.&nbsp; Enter one email address at a time.<br/><br/>

        <form class="form-inline">
          <div class="form-group">
            <label class="m-r-10">Patient's Email</label>
            <input #email="ngModel" [(ngModel)]="form.email" [email]="true" class="form-control" class="m-r-10" name="email" required type="email">
            <button (click)="inviteClient()" [disabled]="email.invalid" class="btn btn-primary float-right m-l-5" type="button">Invite Patient</button>
          </div>
        </form>

        <div *ngIf="emailSent" class="alert alert-success m-t-15 m-b-0">
          A message has been sent.
        </div>

      </div>
    </div>
  </div>
</div>

<app-my-footer></app-my-footer>
