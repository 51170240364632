import {Component, OnInit} from '@angular/core';
import {AudioService} from '../../services/audio.service';

@Component({
    selector: 'app-hint',
    templateUrl: './hint.component.html',
    styleUrls: ['./hint.component.css']
})
export class HintComponent implements OnInit {

    constructor(private audioService: AudioService) {
    }

    ngOnInit(): void {
    }

    showMainSoundHint(): boolean {
        return this.audioService.showMainSoundHint();
    }

    showPreSoundHint(): boolean {
        return this.audioService.showPreSoundHint();
    }

    showPostSoundHint(): boolean {
        return this.audioService.showPostSoundHint();
    }

    showAnswerSoundHint(): boolean {
        return this.audioService.showAnswerSoundHint();
    }

    playMainSound(): Promise<any> {
        return this.audioService.playMainSound();
    }

    playPreSound(): Promise<any> {
        return this.audioService.playPreSound();
    }

    playPostSound(): Promise<any> {
        return this.audioService.playPostSound();
    }

    playAnswerSound(ix?): Promise<any> {
        return this.audioService.playAnswerSound(ix);
    }


}
