"use strict";
const WavEncoder = require("wav-encoder");

window.OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
window.AudioContext = window.AudioContext || window.webkitAudioContext;

try {
    var audioContext = new AudioContext();
} catch (err) {

    console.error(new Error(err));
}
const wal = new WebAudioLoader({
    context: audioContext,
    cache: false
});

function resampler(input, targetSampleRate, oncomplete) {
    if (!input && !targetSampleRate) {
        return returnError('Error: First argument should be either a File, URL or AudioBuffer');
    }

    var inputType = Object.prototype.toString.call(input);
    if (inputType !== '[object String]' &&
        inputType !== '[object File]' &&
        inputType !== '[object AudioBuffer]' &&
        inputType !== '[object Object]') {
        return returnError('Error: First argument should be either a File, URL or AudioBuffer');
    }

    if (typeof targetSampleRate !== 'number' ||
        targetSampleRate > 192000 || targetSampleRate < 3000) {
        return returnError('Error: Second argument should be a numeric sample rate between 3000 and 192000');
    }

    if (inputType === '[object String]' || inputType === '[object File]') {
        // console.log('Loading/decoding input', input);
        wal.load(input, {
            onload: function (err, audioBuffer) {
                if (err) {
                    return returnError(err);
                }
                resampleAudioBuffer(audioBuffer);
            }
        });
    }
    else if (inputType === '[object AudioBuffer]') {
        resampleAudioBuffer(input);
    }
    else if (inputType === '[object Object]' && input.leftBuffer && input.sampleRate) {
        var numCh_ = input.rightBuffer ? 2 : 1;
        var audioBuffer_ = audioContext.createBuffer(numCh_, input.leftBuffer.length, input.sampleRate);
        resampleAudioBuffer(audioBuffer_);
    }
    else {
        return returnError('Error: Unknown input type');
    }

    function returnError(errMsg) {
        console.error(errMsg);
        if (typeof oncomplete === 'function') {
            oncomplete(new Error(errMsg));
        }

    }

    function resampleAudioBuffer(audioBuffer) {
        var numCh_ = audioBuffer.numberOfChannels;
        var numFrames_ = audioBuffer.length * targetSampleRate / audioBuffer.sampleRate;

        var offlineContext_ = new OfflineAudioContext(numCh_, numFrames_, targetSampleRate);
        var bufferSource_ = offlineContext_.createBufferSource();
        bufferSource_.buffer = audioBuffer;

        offlineContext_.oncomplete = function (event) {
            var resampeledBuffer = event.renderedBuffer;
            // console.log('Done Rendering');
            if (typeof oncomplete === 'function') {
                oncomplete({
                    getAudioBuffer: function () {
                        return resampeledBuffer;
                    },
                    getFile: function (fileCallback) {
                        var audioData = {
                            sampleRate: resampeledBuffer.sampleRate,
                            channelData: []
                        };
                        for (var i = 0; i < resampeledBuffer.numberOfChannels; i++) {
                            audioData.channelData[i] = resampeledBuffer.getChannelData(i);
                        }
                        WavEncoder.encode(audioData).then(function (buffer) {
                            var blob = new Blob([buffer], {
                                type: "audio/wav"
                            });
                            fileCallback(URL.createObjectURL(blob));
                        });
                    }
                });
            }
        };

        // console.log('Starting Offline Rendering');
        bufferSource_.connect(offlineContext_.destination);
        bufferSource_.start(0);
        offlineContext_.startRendering();
    }
}

function WebAudioLoader(options) {
    if (!(this instanceof WebAudioLoader)) {
        throw new TypeError("WebAudioLoader constructor cannot be called as a function.");
    }

    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    // Singleton using a global reference.
    if (window.webAudioLoader) {
        return window.webAudioLoader;
    }

    // setup cache object
    this._cachedAudio = null;

    // Define default local properties
    this.cache = true;
    this.onload = null;
    this.onprogress = null;
    Object.defineProperty(this, 'maxCacheSize', {
        enumerable: true,
        configurable: false,
        set: function (maxSize) {
            if (this._cachedAudio) {
                this._cachedAudio.max = maxSize;
            }
        },
        get: function () {
            return this._cachedAudio.max;
        }
    });

    // Options parsing.
    options = options || {};
    for (var opt in options) {
        if (this.hasOwnProperty(opt) && options[opt] !== undefined) {
            this[opt] = options[opt];
        }
    }

    try {
        this.context = options.audioContext || new AudioContext();
    } catch (err) {
        console.error(new Error(err));
    }
    // Setup Cache
    var cacheOptions = {
        max: options.maxCacheSize || 1000,
        length: function (audioBuffer) {
            return (audioBuffer.length * audioBuffer.numberOfChannels * 4) / 1000;
        }
    };
    this._cachedAudio = new (require('lru-cache'))(cacheOptions);


    // Resgiter as global
    window.webAudioLoader = this;

    // Helper functions
    this._loadURLOrFile = function (URL, onprogress, onload) {
        var urlType = Object.prototype.toString.call(URL);
        var request = null;
        if (urlType === '[object String]') {
            request = new XMLHttpRequest();
            request.open('GET', URL, true);
            request.responseType = 'arraybuffer';
        }
        else if (urlType === '[object File]' || urlType === '[object Blob]') {
            request = new FileReader();
        }
        else {
            return;
        }

        request.onload = function () {
            if (urlType === '[object String]' && request.status === 200) {
                if (typeof onload === 'function') {
                    onload(null, request.response);
                }
            }
            else if (urlType === '[object File]' || urlType === '[object Blob]') {
                if (typeof onload === 'function') {
                    onload(null, request.result);
                }
            }
            else {
                if (typeof onload === 'function') {
                    onload(new Error("Loading Error"), null);
                }
            }
        };
        request.onerror = function () {
            if (typeof onload === 'function') {
                onload(new Error("Loading Error"), null);
            }
        };
        request.onprogress = function (event) {
            if (typeof onprogress === 'function') {
                onprogress(event);
            }

            if (typeof this.onprogress === 'function') {
                this.onprogress(event);
            }
        }.bind(this);

        if (urlType === '[object String]') {
            request.send();
        }
        else if (urlType === '[object File]' || urlType === '[object Blob]') {
            request.readAsArrayBuffer(URL);
        }

    };
}

/*
	load method.
	wal.load('http://www.example.com/audio.mp3');
	wal.load([object File]);
	wal.load('http://www.example.com/audio.mp3', {decode: false,cache : false , onload: function(){}, onprogress: function(){}});
 */
WebAudioLoader.prototype.load = function (source, options) {

    var decode = true;
    var thisLoadCache = true;

    if (!options) {
        options = {};
    }
    var thisLoadOnload = options.onload || null;
    var thisLoadOnprogress = options.onprogress || null;
    // var startPoint = options.startPoint || 0;
    // var endPoint = options.endPoint || 0;


    if (options.cache !== null && options.cache !== undefined) {
        thisLoadCache = options.cache;
    }
    else {
        thisLoadCache = this.cache;
    }

    if (options.decode !== null && options.decode !== undefined) {
        decode = options.decode;
    }

    var onLoadProxy = function (err, audioBuffer) {
        if (typeof thisLoadOnload === 'function') {
            thisLoadOnload(err, audioBuffer);
        }
        if (typeof this.onload === 'function') {
            this.onload(err, audioBuffer);
        }
    }.bind(this);

    if (this.cache && thisLoadCache) {
        var testCache = this._cachedAudio.get(source);
        if (testCache) {
            onLoadProxy(null, testCache);
            return;
        }
    }

    this._loadURLOrFile(source, thisLoadOnprogress, function (err, arrayBuffer) {
        if (err || !decode) {
            onLoadProxy(err, arrayBuffer);
        }
        else {
            this.context.decodeAudioData(arrayBuffer, function (audioBuffer) {
                if (thisLoadCache) {
                    this._cachedAudio.set(source, audioBuffer);
                }
                onLoadProxy(err, audioBuffer);
            }.bind(this), function () {
                onLoadProxy(new Error("Decoding Error"), null);
            }.bind(this));
        }
    }.bind(this));
};

/*
	flushCache method
	Resets and empties the cache.
 */
WebAudioLoader.prototype.flushCache = function () {
    this._cachedAudio.reset();
};

module.exports = resampler;
