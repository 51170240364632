import {Subscription} from 'rxjs';

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {$WebSocket} from 'angular2-websocket';

import {UserService} from '../../services/user.service';
import {CsatService} from '../../services/csat.service';

import {config} from '../../config';

@Component({
    templateUrl: './csat.component.html',
    styleUrls: ['./csat.component.css']
})
export class CsatComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();

    constructor(private userService: UserService,
                private csatService: CsatService,
                private cdr: ChangeDetectorRef,
                private location: Location) {
        this.csatService.clientId = this.location.path(true).split('/')[2];
    }

    ngOnInit(): void {
        this.csatService.outgoing.therapistMessage = true;
        this.csatService.ws = new $WebSocket(config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');

        this.csatService.ws.onOpen(() => {
            return this.csatService.announce();
        });

        this.csatService.ws.getDataStream().subscribe((msg: MessageEvent) => {
                return this.csatService.receiveMessage(msg);
            },
            (err) => {
                console.error(new Error(err));
            });
    }

    ngOnDestroy(): void {
        this.csatService.ws.close();
    }

    exit(): void {
        return this.location.back();
    }

    back(): Promise<any> {
        this.csatService.outgoing.image = null;
        this.csatService.outgoing.alternatives = [];

        return this.csatService.send().then(() => {
            return this.location.back();
        });

    }

}
