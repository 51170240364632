<div class="container-fluid bg-white bb-1 p-0 sticky-top">
  <div class="container p-0">
    <nav class="navbar navbar-expand-lg">
      <button (click)="settings.isCollapsed = !settings.isCollapsed" aria-controls="exCollapsingNavbar2" aria-expanded="false"
              aria-label="Toggle navigation" class="navbar-toggler" type="button">
        <span class="material-icons middle">menu</span>

      </button>


      <div [ngbCollapse]="settings.isCollapsed" class="collapse navbar-collapse justify-content-end align-middle" id="exCollapsingNavbar2">

        <div *ngIf="userInfo.loggedIn" class="navbar-nav middle">
          <a *ngIf="isPremiumUser()" class="nav-item nav-link buttonGreen uppercase" routerLink="/modules" routerLinkActive="active">Therapy</a>
          <a *ngIf="isBasicUser() && !isPremiumUser()" class="nav-item nav-link uppercase" routerLink="/modules-videos" routerLinkActive="active">Therapy</a>
          <a *ngIf="userInfo.showTrial && !userInfo.validTrial && !userInfo.basicSubscriber" class="nav-item nav-link uppercase" routerLink="/account" routerLinkActive="active">Therapy</a>

          <div class="d-inline-block" ngbDropdown>
            <button class="nav-link pointer middle" id="dropdownBasic1" ngbDropdownToggle>
              <span class="material-icons h4 m-0">account_circle</span>
              <span class="vertical-top p-l-5 p-t-10">{{user?.local?.name || user?.local?.email}}</span>
            </button>
            <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
              <!--
                            <button class="pointer" ngbDropdownItem routerLink="/my-homework">HOMEWORK</button>
                            <button class="pointer" ngbDropdownItem routerLink="/my-videos">MY VIDEOS</button>
              -->
              <button class="pointer" ngbDropdownItem routerLink="/account">My Account</button>
              <button class="pointer" ngbDropdownItem routerLink="/messages">Messages </button>
              <button class="pointer" ngbDropdownItem routerLink="/progress">Progress Report</button>
              <button *ngIf="isPremiumUser() && !isTherapist()" class="pointer" ngbDropdownItem routerLink="/my-therapist">My Therapist</button>
              <button *ngIf="isTherapist()" class="pointer" ngbDropdownItem routerLink="/client-list">My Patients</button>
            </div>
          </div>


          <div class="d-inline-block" ngbDropdown>
            <button class="nav-link pointer" id="dropdownBasic22" ngbDropdownToggle>WHERE DO I START?</button>
            <div aria-labelledby="dropdownBasic22" ngbDropdownMenu>
              <a class="pointer" ngbDropdownItem routerLink="/aphasia">By Aphasia Type</a>
              <a class="pointer" ngbDropdownItem routerLink="/where-do-i-start">Quiz</a>
              <button *ngIf="isAdmin()" class="pointer" ngbDropdownItem routerLink="/modules-search">Search Exercises</button>
            </div>
          </div>

          <div class="d-inline-block" ngbDropdown>
            <button class="nav-link pointer" id="dropdownBasic2" ngbDropdownToggle>HELP</button>
            <div aria-labelledby="dropdownBasic2" ngbDropdownMenu>
              <button *ngIf="isBetaUser()" class="pointer" ngbDropdownItem routerLink="/beta">Beta Feedback</button>
              <button *ngIf="isAdmin()" class="pointer" ngbDropdownItem routerLink="/speech-debug">Speech Debug</button>
              <button class="pointer" ngbDropdownItem routerLink="/audio-test">Check Audio</button>
              <button *ngIf="!isTherapist()" class="pointer" ngbDropdownItem routerLink="/faq">FAQ</button>
              <button *ngIf="isTherapist()" class="pointer" ngbDropdownItem routerLink="/faq-therapist">Therapists' FAQ</button>
              <button class="pointer" ngbDropdownItem routerLink="/contact">Contact Us</button>
              <button class="pointer" ngbDropdownItem routerLink="/about-us">About Us</button>
            </div>
          </div>

          <a *ngIf="isAdmin()" class="nav-item nav-link buttonRed" href="/admin/">Admin</a>
        </div>

        <div *ngIf="!userInfo.loggedIn" class="navbar-nav">

          <a class="d-none d-lg-block nav-item nav-link" routerLink="/about-us" routerLinkActive="active">About Us</a>
          <a class="nav-item nav-link" fragment="howitworks" routerLink="/" routerLinkActive="active">How It Works</a>
          <a class="nav-item nav-link" routerLink="/pricing" routerLinkActive="active">Pricing</a>
          <a class="nav-item nav-link" routerLink="/register" routerLinkActive="active">Join Now</a>
          <a class="nav-item nav-link buttonGreen" routerLink="/login" routerLinkActive="active">Sign In</a>
        </div>
      </div>
    </nav>
  </div>
</div>
