<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 center p-t-10 bg-teal">
        <h1>What is receptive aphasia?</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 p-15">
        Expressive, receptive and mixed aphasia are terms that get tossed around a lot in the hospital after the stroke or brain injury. Medical professionals tend to describe aphasia to the families as receptive aphasia, expressive aphasia, or mixed
        aphasia. This is a generic label families hear early in the recovery process. This is your introduction to the world of aphasia.
        <br/><br/>
        Thinking of aphasia in this way can be confusing. I frequently see clients incorrectly diagnosed because the physician isn’t familiar with the predominant features of each type of aphasia. Their job is to say that aphasia is present, so they
        try to classify it very early in the recovery.
        <br/><br/>
        All aphasia is both receptive and expressive. Each person with aphasia, no matter what part of the brain was damaged, has difficulty with ALL language skills —reading, writing, speaking/language, and understanding — when compared to
        pre-stroke or pre-injury language skills. These skills are all affected by aphasia, but some of those skills are relatively better than the others. The key word here is “relatively”.
        <br/><br/>
        Aphasia affects language input, such as reading and understanding speech. “Input” is something that goes IN to your language system. Reading and understanding are two different forms of input (written words, photos, or speech) and processing
        them in your brain to understand them.
        <br/><br/>
        Aphasia also affects outputs, such as writing and speaking/language. These are skills that let you get things OUT of your brain. You think of what you want to say or write and you say or write it. Or you listen to what someone said and say
        something back to them. You can have other physical issues that make output processes more difficult, such as arm/hand weakness or apraxia.
        <br/><br/>
        If someone has an expressive aphasia, it’s supposed to mean that they can’t speak or write well. In terms of inputs and outputs, the outputs are much more affected than the inputs. This is how we would describe something like Broca’s aphasia
        or Transcortical motor aphasia. Understanding in these aphasias is relatively better than speech. The predominant feature is that the person can’t speak effectively. Broca’s aphasia, for example, means that while the person doesn’t understand
        the same as before the stroke, their understanding appears to be much better than their talking.
        <br/><br/>
        If someone is described as having a receptive aphasia, it’s supposed to mean the opposite — that the inputs are much more affected than the outputs. This is how we would think of Wernicke’s aphasia or Conduction aphasia. Understanding in
        these aphasias is relatively worse than speech. Although the first thing you may notice about Wernicke’s aphasia is that there’s a lot of speaking but no real content, the speech itself is flowing. What it lacks is real language, so it’s the
        opposite of expressive issues.
        <br/><br/>
        Mixed aphasia should mean that the client’s inputs and outputs are “equally” affected. This would be closer to a Global aphasia diagnosis in which the person has very little speech, says the same words over and over, or has no speech. Their
        comprehension has also been severely affected. The majority of people have this aphasia right after their injury while the brain heals.
        <br/><br/>
        Receptive aphasia, expressive aphasia or mixed aphasia may be used incorrectly to help categorize your loved one’s aphasia. It can be very confusing to families to learn something completely different in the beginning. This affects the
        treatment progress of the person with aphasia. If you’re starting off with incorrect information, you’re going to make treatment choices based on that information. If you have acid reflux but are diagnosed with heart problems, you’re treating
        the wrong problem. There is a distinct difference between treatment approaches for receptive, expressive or mixed aphasias. Don’t be afraid to get more than one opinion — knowledge is power!

      </div>
    </div>


  </div>
</div>
<app-my-footer></app-my-footer>
