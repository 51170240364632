<div>
  <app-error-message></app-error-message>
  <div *ngIf="type" [style.height]="(sequences.length * 60) + 'px'" class="center w-100 p-b-15 p-t-10">


    <div *ngFor="let sequence of sequences; let ix=index">
      <div *ngIf="s>ix">
      <span *ngFor="let word of sequence.words; let i=index" class="" id="sequence_{{ix}}">
        <span class="h1 m-l-5 m-r-5" id="s_{{ix}}_w_{{i}}">{{word.word}}</span>
      </span>
      </div>

      <!--
        <span class="" *ngFor="let word of words; let i=index">
          <span class="h1 m-l-5 m-r-5" id="word_{{i}}">{{word.word}}</span>
        </span>
      -->
    </div>
    <div class="clearfix">
      <div class="pull-left">
        <span (click)="readAgain()" class="audioButton pointer btn listen-again pull-left">
          <span class="m-b-10 m-l-15">Read again</span>
        </span>
      </div>
    </div>
  </div>
