import * as _ from 'lodash';
import {Component, OnDestroy, OnInit} from '@angular/core';

import {UserService} from '../../services/user.service';
import {MessagingService} from '../../services/messaging.service';

import {settings} from '../../settings';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-account',
    templateUrl: './how-it-works.component.html'
})
export class HowItWorksComponent implements OnInit, OnDestroy {
    settings = settings;
    userInfo;
    user;
    invoices;
    constructor(public userService: UserService,
                private messagingService: MessagingService) {
        this.userInfo = userService.userInfo;
    }

    ngOnInit(): Subscription {
        return this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;
            }
        });
    }

    ngOnDestroy(): void {
    }


}

