<div class="container-fluid bg-white vh-100">
  <div class="container bg-white p-t-20">
    <div class="row">
      <div *ngIf="types && clientData" class="col-sm-12 p-15">

        <h1>
          Assigned Homework
        </h1>

        <div *ngIf="clientData">

          You are assigning homework to {{clientData.local.name || 'Unnamed User'}} ({{clientData.local.email}}). Pick modules and videos, then notify your client.
          <br/><br/>
          <div class="center">
            <button *ngIf="clientData" [routerLink]="'/assign-homework/' + clientData._id" class="btn btn-primary m-l-10">Assign Modules</button>
            <button *ngIf="clientData" [routerLink]="'/assign-homework-videos/' + clientData._id" class="btn btn-primary m-l-10">Assign Videos</button>

            <button *ngIf="clientData" [routerLink]="'/client-progress/' + clientData._id" class="btn btn-primary m-l-10">See Progress</button>
          </div>
          <br/>
        </div>

        <h3>
          <button (click)="clear()" class="btn btn-secondary float-right m-b-10"> Clear Homework</button>
          Assigned Modules
        </h3>
        <hr class="hr-2"/>
        <table *ngIf="clientData" class="table table-striped table-condensed">
          <tr>
            <th width="50%">Name</th>
            <th class="nowrap">Level</th>
            <th class="nowrap">Assigned</th>
            <th class="nowrap center">Number</th>
            <th class="nowrap">Completed</th>
            <th></th>
          </tr>

          <ng-container *ngFor="let type of clientData.homework">
            <tr *ngIf="type.module">
              <td>
                {{getName(type.module)}}</td>
              <td class="center">
                {{clientLevel(type.module)}}
              </td>
              <td class="vertical-top">
                <button (click)="deleteHomework(type)" class="btn btn-sm btn-primary"> Remove</button>
              </td>
              <td class="vertical-top center">
                <input #myTemplateVar="ngModel" (change)="updateHomework(type, myTemplateVar.value)" [(ngModel)]="type.todo" class="middle m-l-15" name="todo" size="2" type="text" style="border-bottom:2px solid black;">
              </td>
              <td class="center">
                <div [hidden]="!type.done || type.todo > type.done"><span class="material-icons middle">done</span></div>
              </td>
              <td>
                <button (click)="reset(type)" [hidden]="!type.done || type.todo > type.done" class="btn btn-sm btn-primary m-l-5"> Reset</button>
              </td>
            </tr>
          </ng-container>
        </table>


        <div class="m-t-35">
          <h3>
            <button (click)="clearVideos()" class="btn btn-secondary float-right"> Clear Videos</button>
            Assigned Videos
          </h3>
          <hr class="hr-2"/>
          <div *ngFor="let video of clientData.homeworkVideos" class="m-b-5">
            <label (click)="removeVideo(video)" class="btn btn-sm btn-primary">
              <div class="">Remove</div>
            </label>

            <a (click)="open(video.url)" class="pointer p-l-10">{{video.name}}</a>
          </div>

        </div>

        <div class="m-t-50 float-right">
          <button (click)="send()" class="btn btn-primary  m-l-10">Notify Patient</button>
          <button [routerLink]="'/notification-addresses/' + clientData._id" class="btn btn-primary m-l-10"> + Email</button>
          <button (click)="back()" class="btn stop m-l-10" type="button"><span class="material-icons middle">arrow_back_ios</span> Back</button>
        </div>

        <br clear="both"/>

        <div class="m-t-50">
          <div [hidden]="!messageStatus.success" class="alert alert-success">Message sent.</div>
          <div [hidden]="!messageStatus.error" class="alert alert-danger">Something went wrong. Please contact support.</div>
        </div>
      </div>
    </div>
  </div>
  <app-my-footer></app-my-footer>
</div>
