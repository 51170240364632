<div class="container m-t-30 p-15 bg-white" style="clear: both">
  <div class="row">
    <div class="col-sm-12 form-group">
      Level:
      <select *ngIf="type" [(ngModel)]="onLevel" class="form-control inline-block" name="level" style="width: 60px;">
        <option *ngFor="let level of type.levels" [value]="level.level">
          {{level.level}}
        </option>
      </select>

      <button (click)="get()" class="btn btn-primary m-l-15 ">GET STIMULI</button>
      <button (click)="send()" *ngIf="stimuli" class="btn btn-primary m-l-15">SEND ALL</button>
      <button (click)="sendText()" *ngIf="stimuli && stimuli.description" class="btn btn-primary m-l-15">SEND TEXT</button>
      <button (click)="sendImage()" *ngIf="stimuli && stimuli.image" class="btn btn-primary m-l-15">SEND IMAGE</button>
      <button (click)="sendAlternatives()" *ngIf="alternatives && alternatives.length>0" class="btn btn-primary m-l-15">SEND ALTERNATIVES</button>

      <button (click)="clear()" class="btn stop m-l-15">CLEAR</button>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-sm-12">

      <div *ngIf="stimuli && alternatives && type && typeSettings" class="center w-100 p-b-15 p-t-10">

        <h1 *ngIf="typeSettings.question && stimuli.description" class="click m-t-20 m-b-20">
          {{stimuli.description}}
        </h1>

        <div *ngIf="(typeSettings?.mainImage && stimuli?.image)" class="p-15">
          <img [src]="getStimuliImage()"/>
        </div>

        <div (click)="sendAnswer(answer.id)" *ngFor="let answer of alternatives; let index=index; let isOdd=odd; let isEven=even" class="inline-block p-5" style="width: 50%">

          <div *ngIf="answer.image && typeSettings.image" class="picture-container" style="border:4px solid white">
            <div class="picture-dummy"></div>
            <div [class.correct]="correct(answer.id)" [class.picture-even]="isEven" [class.picture-odd]="isOdd" [class.selected]="selected(answer.id)" class="picture-element">
              <img [src]="getAnswerImage(answer.image)" class="picture" id="image_{{answer.id}}" width="100%"/>
            </div>
          </div>

          <button *ngIf="answer.word" [class.correct]="correct(answer.id)" [class.selected]="selected(answer.id)" class="answerbutton btn btn-lg btn-primary" id="{{answer.id}}">
            {{answer.word}}
          </button>
        </div>
      </div>
      <div (click)="back()" class="btn stopbutton pointer stop"><span class="material-icons middle">arrow_back_ios</span> Stop</div>
    </div>
  </div>
</div>
