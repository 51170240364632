<div class="container-fluid bg-white p-t-20">
<div class="container">
  <div class="row">
    <div class="col-sm-12 p-15">
      <h1>Add My Therapist</h1>

      <p *ngFor="let alert of alerts">
        <ngb-alert (close)="closeAlert(alert)" [type]="alert.type">{{ alert.message }}</ngb-alert>
      </p>

      Adding your speech therapist lets them see your progress and give you homework.<br/>

      <form class="form-inline p-t-10 p-b-10">
        <div class="form-group">
          <label class="m-r-10">Email address</label>
          <input #email="ngModel" [(ngModel)]="form.email" [email]="true" class="form-control" class="m-r-10" name="email" required type="email">
          <button (click)="inviteTherapist()" [disabled]="email.invalid" class="btn btn-primary float-right btn-sm m-l-5" type="button">
            <span class="material-icons middle">email</span> Send</button>
        </div>
      </form>
      Type one email address at a time<br/><br/>

      <div *ngIf="emailSent" class="alert alert-success m-t-15 m-b-0">
        A message has been sent.
      </div>

      <div *ngIf="sharedWith && sharedWith.length>0" class="m-t-30">
        <h4>Your therapists</h4>

        <table class="table table-hover m-t-15">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th></th>
          </tr>
          <ng-container *ngFor="let user of sharedWith">
          <tr *ngIf="user?.local">
            <td>
              {{user.local.name}}
            </td>
            <td>
              {{user.local.email}}
            </td>
            <td class="p-r-0">
              <button (click)="revokeAccess(user._id)" class="btn btn-danger float-right btn-sm" type="button">Remove</button>
              <!--<a [routerLink]="'/csat-client/' + user._id" class="btn btn-primary btn-sm float-right m-b-5 m-r-10">Online Therapy</a>-->
            </td>
          </tr>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
</div>
</div>
<app-my-footer></app-my-footer>
