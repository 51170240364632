import * as _ from 'lodash';

import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {StimuliService} from '../../services/stimuli.service';
import {BuilderService} from '../../services/builder.service';
import {AnswerService} from '../../services/answer.service';

import {settings} from '../../settings';
import {Observable, of, Subscription} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ScreenService} from '../../services/screen.service';
import {AudioService} from '../../services/audio.service';

@Component({
    selector: 'app-scramble',
    templateUrl: './scramble.component.html',
    styleUrls: ['./scramble.component.css']
})

export class ScrambleComponent implements OnInit, OnDestroy, AfterViewInit {
    highlight;
    settings = settings;
    typeSettings: any = {};
    user;
    userInfo;
    type;
    stimuli;
    show = true;

    alternatives;

    message;
    answer;

    input = {
        word: ''
    };
    @ViewChild('box') box: ElementRef | any;
    @ViewChild('box2') box2: ElementRef | any;
    private oCorrect;
    private oUser;
    private oType;
    private oStimuli;
    private oAlternatives;
    private oTypeSettings;

    constructor(private router: Router,
                private cdr: ChangeDetectorRef,
                private userService: UserService,
                private answerService: AnswerService,
                private audioService: AudioService,
                private screenService: ScreenService,
                private builderService: BuilderService,
                private stimuliService: StimuliService) {
        this.userInfo = this.userService.userInfo;
        this.highlight = this.audioService.highlight;
    }

    ngOnInit(): Subscription {
        this.oUser = this.userService.bsUser.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.user = result;

                if (this.userService.userInfo.loggedIn) {
                    if (!this.userService.userInfo.premiumSubscriber && this.userService.userInfo.basicSubscriber) {
                        const route = ['/modules-videos'];
                        return this.router.navigate(route);
                    }
                    else if (!this.userService.userInfo.basicSubscriber) {
                        const route = ['/payment'];
                        return this.router.navigate(route);
                    }
                }
            }
            return false;
        });

        this.oCorrect = this.answerService.correct.subscribe(result => {
            if (this.stimuli && this.stimuli.levels) {
                if (this.answerService.wrongCount.value >= 0 && result === false) {
                    this.show = false;
                    this.cdr.detectChanges();

                    this.show = true;
                    this.cdr.detectChanges();

                    const correctAnswer = this.stimuli.levels[0].word.split(' ');
                    const answer = this.answer.split(' ');

                    _.forEach(correctAnswer, (correct, ix: number) => {
                        if (answer[ix] === correct || this.answerService.wrongCount.value >= 2) {
                            _.forEach(this.alternatives, (alt, iter2) => {
                                if (correct === alt) {
                                    // @ts-ignore
                                    document.getElementById('land' + ix).appendChild(document.getElementById(iter2 + '' + alt));
                                }
                            });
                        }
                    });
                }
            }
        });

        this.oType = this.stimuliService.type.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.type = result;
            }
        });

        this.oStimuli = this.stimuliService.stimuli.subscribe(result => {
            if (!_.isEmpty(result)) {

                this.input.word = '';
                this.show = false;
                this.cdr.detectChanges();

                this.stimuli = result;
                this.show = true;
                this.cdr.detectChanges();
            }
        });

        this.oTypeSettings = this.stimuliService.bsTypeSettings.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.typeSettings = result;
            }
        });

        this.oAlternatives = this.stimuliService.bsAlternatives.subscribe(result => {
            if (!_.isEmpty(result)) {
                this.alternatives = result;
            }
        });

        return this.builderService.getType().subscribe();
    }

    ngOnDestroy(): void {
        this.oUser.unsubscribe();
        this.oType.unsubscribe();
        this.oStimuli.unsubscribe();
        this.oAlternatives.unsubscribe();
        this.oTypeSettings.unsubscribe();
        this.oCorrect.unsubscribe();
    }

    ngAfterViewInit(): any {
        return setTimeout(() => {
            if (this.box?.nativeElement?.offsetHeight > 100) {
                this.box.nativeElement.style.textAlign = 'left';
                this.box2.nativeElement.style.textAlign = 'left';
            }
        }, 500);
    }

    checkAnswer(): any {
        let answer = '';
        const answerArray: Array<any> = [];

        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                answer = answer + (child as any).innerText + ' ';
                answerArray.push((child as any).innerText);
            });
        });

        answer = answer.trim();
        this.answer = answer;
        return this.answerService.scramble(answer);
    }

    getStimuliImage(): SafeResourceUrl {
        return this.screenService.getStimuliImage();
    }

    alignment(): string {
        this.cdr.detectChanges();
        if (this.box) {
            return 'left';
        }
        else {
            return 'center';
        }
    }

    dragDropReplacement(id): Observable<any> {
        const selected = document.getElementsByClassName('scrambling-selected');
        const clicked
            :
            any = document.getElementById(id);
        let setClass = true;

        if (clicked.children.length > 0 && clicked.children[0] === selected[0]) {
            if ((clicked.children[0] as any).classList) {
                clicked.children[0].classList.remove('scrambling-selected');
            }
            setClass = false;
        }

        if (selected.length > 0 && (clicked as any).children.length === 0) {
            clicked.appendChild(selected[0]);
            setClass = false;
        }

        _.forEach(document.getElementsByClassName('scrambling-drop'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        _.forEach(document.getElementsByClassName('scrambling-start'), (children) => {
            _.forEach(children.getElementsByClassName('scrambling-word'), (child) => {
                if ((child as any).classList) {
                    (child as any).classList.remove('scrambling-selected');
                }
            });
        });

        // Highlight selected
        if (setClass && (clicked.children[0] !== selected[0])) {
            // @ts-ignore
            const elem = document.getElementById(id).children;
            let word;

            _.forEach(elem, (child) => {
                (child as any).classList.add('scrambling-selected');
                word = (child as any).innerText;
            });

            // Play sound on drop
            if (word) {
                return this.audioService.buildDragAndDropAudio(word);
            }
        }
        return of(false);
    }

    drop(el): void {
        // @ts-ignore
        event.preventDefault();
        const id = el.dataTransfer.getData('Text');
        if (!
            el.target.classList.contains('scrambling-word')
        ) {
            if (document.getElementById(id)) {
                el.target.appendChild(document.getElementById(id));
            }
        }

        const words = el.target.getElementsByTagName('p');
        if (words.length > 0) {
            this.audioService.buildDragAndDropAudio(words[0].innerText);
        }

        el.stopPropagation();
    }

    dragStart(el): boolean {
        if (el && el.target) {
            el.dataTransfer.effectAllowed = 'move';
            el.dataTransfer.setData('Text', el.target.getAttribute('id'));
            el.dataTransfer.setDragImage(el.target, 30, 20);
        }
        return true;
    }

    dragEnter(): boolean {
        // @ts-ignore
        event.preventDefault();
        return true;
    }

    dragOver(): boolean {
        return false;
    }

    highlight2(ix): string {
        if (ix === 0 && this.highlight['highlight-drop']) {
            return 'highlight-drop';
        }
        else {
            return '';
        }
    }

}
