<div>
  <div class="modal-header" style="border-bottom: 0px">
    <h4 class="modal-title" id="myModalLabel">Apraxia Video</h4>
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0 m-0" style="">
    <div id="vimeo"></div>
    <div class="center">
      <button [hidden]="!playing" (click)="pausePlayer()" class="btn btn-primary m-5">Pause</button>
      <button [hidden]="playing" (click)="playPlayer()" class="btn btn-primary m-5">Play</button>
      <button (click)="record()" class="btn btn-danger m-5">Practice</button>
    </div>
  </div>
</div>
