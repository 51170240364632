<div class=" bg-white vh-100">

  <div class="container p-t-20">
    <div class="" [hidden]="!showCategories">
      <div class="h5 text-center">Take the quiz to find recommended exercises</div>
      <div class="bold h6 p-t-15">Choose a skill</div>
    </div>

    <div class="row p-b-30" [hidden]="!showCategories" style="display:flex; justify-content:center;  flex-flow: row wrap;">
      <div (click)="test('reading')" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-focus p-10 '">
          <h3 class="text-center p-t-5">
            <span class="material-icons middle h1">menu_book</span><br/>
            Reading
          </h3>
        </div>
      </div>

      <div (click)="test('writing')" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-focus p-10'">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">edit</span><br/>
            Writing
          </h3>
        </div>
      </div>
      <div (click)="test('listening')" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-focus p-10 '">
          <h3 class="text-center p-t-5">
            <span class="material-icons h1">headphones</span><br/>
            Listening</h3>
        </div>
      </div>
      <div (click)="test('speech')" class="col-md-3 col-sm-6 pointer p-10">
        <div [class]="'m-1 btn-focus p-10 '">
          <h3 class="text-center p-t-5">
            <span class="material-icons middle h1">message</span><br/>
            Speech</h3>
        </div>

      </div>
    </div>

    <div class="row">
      <div *ngIf="stimuli?.image" class="col-sm-12 d-flex justify-content-center" id="highlight-image">
        <img [src]="getStimuliImage()" class="stimuli-image"/>
      </div>

      <div *ngIf="stimuli?.text" class="col-sm-12 d-flex justify-content-center">
        <h2 id="highlight-text" class="">{{stimuli.text}}</h2>
      </div>
      <div *ngIf="type === 'listening' && !stimuli.text" class="col-sm-12 d-flex justify-content-center">
        <span class="material-icons p-15" style="font-size: 48pt;" id="highlight-ear">hearing</span><br/>
      </div>
      <div *ngIf="stimuli?.question" class="col-sm-12 d-flex justify-content-center">
        <h3 id="highlight-question" class="p-10 bg-light-gray">{{stimuli.question}}</h3>
      </div>
    </div>

    <div *ngIf="stimuli?.alternatives" class="d-flex flex-row flex-wrap justify-content-center p-t-15" id="highlight-alternatives">

      <div *ngFor="let answer of stimuli?.alternatives; let index=index; let isOdd=odd; let isEven=even" class="d-inline-flex">
        <div class="p-5">
          <button (click)="checkClickAnswer(answer)" class="answerbutton btn btn-lg btn-primary" id="{{answer.id}}">
            <div style="white-space: normal">{{answer}}</div>
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap justify-content-center">
      <div *ngIf="stimuli?.imageAlternatives" class="d-flex flex-row flex-wrap align-content-center" id="highlight-image-alternative" style="max-width: 600px;">
        <div *ngFor="let answer of stimuli?.imageAlternatives; let index=index; let isOdd=odd; let isEven=even" class="d-inline-flex p-5 col-sm-6">
          <img (click)="checkClickAnswer(answer)" [src]="getAnswerImage(index)" style="width: 100%;  height: 100%;  object-fit: cover;"><br/>
        </div>
      </div>
    </div>

    <div *ngIf="stimuli?.hintText && showHint" class="col-sm-12 d-flex justify-content-center">
      <h3 class="p-10 m-10" id="highlight-hint">{{stimuli.hintText}}</h3>
    </div>

    <div *ngIf="type==='speech' && stimuli && stimuli.preSounds" class="d-flex flex-row flex-wrap justify-content-center p-b-15">
      <div class="center h1" id="highlight-pre-sounds">
        <ng-container *ngFor="let sound of stimuli.preSounds; let index = index">
          <div *ngIf="sound.word" [ngClass]="{'highlight-word': index === audioIndex}" [textContent]="sound.word" class="inline modalWord p-5" id="word{{index}}"></div>
        </ng-container>
      </div>
    </div>

    <form>
      <div class="row" *ngIf="stimuli?.writing">
        <div class="col-sm-12 d-flex justify-content-center" id="highlight-input">
          <input [(ngModel)]="input" id="writing" name="writing" required style="width:160px;" class="m-15">
        </div>

        <div *ngIf="stimuli?.hintAudio" class="d-flex flex-row flex-wrap justify-content-center p-b-15">
          <div (click)="playHintAudio()" class="audioButton pointer btn hint" style="width:160px;">
            <span class="material-icons middle">headphones</span>
            <span class="m-l-15">Hint</span>
          </div>
        </div>

        <div *ngIf="stimuli?.writing" class="col-sm-12 d-flex justify-content-center p-b-15">
          <button (click)="checkWritingAnswer()" class="btn btn-primary high-z center-block" type="submit"><span class="material-icons middle">done</span> Done</button>
        </div>
      </div>

    </form>

    <div class="d-flex flex-row flex-wrap justify-content-center" *ngIf="type==='speech' && stimuli">
      <div id="highlight-speech" class="inline-block p-15">
        <button (click)="startRec()" *ngIf="!recording" class="btn btn-primary">
          <span class="material-icons middle">mic</span> Start
        </button>

        <button (click)="stopRec()" *ngIf="recording" class="btn btn-danger">
          <span class="material-icons middle">mic_off</span>
          Pause
        </button>

      </div>
    </div>

    <div class="center h3" *ngIf="showRecommendations">
      We recommend starting with one of these exercises
      <div class="row p-b-30 p-t-15">
        <div (click)="navigate(type, $event)" (auxclick)="navigate(type, $event)" *ngFor="let type of recommendations" class="col-lg-3 col-md-4 col-sm-6 pointer p-10">
          <div [class]="getClass(type)" style="height:100%;">
            <img [src]="getImage(type)" width="100%"/>
            <div class="p-10 text-center">
              <h5>{{type.visibleName || type.name}}</h5>
              <h6>{{type.description}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-t-30">
      <button *ngIf="stimuli || recommendations" class="btn btn-primary m-r-15" (click)="startOver()">
        <span class="material-icons middle">restart_alt</span> Start Over
      </button>

      <button *ngIf="stimuli?.audio" (click)="playAudio()" class="audioButton pointer btn listen-again m-r-15">
        <span class="material-icons middle">hearing</span> Listen
      </button>

      <button *ngIf="stimuli?.intro && stimuli.intro[0]" (click)="playIntroButton()" class="pointer btn btn help">
        <span class="material-icons middle">help</span> Help
      </button>
    </div>

    <div class="row p-t-30" *ngIf="showSkip()">
      <div class="col-sm-12">
        <a [routerLink]="['/modules']" class="float-right">Skip testing</a>
      </div>
    </div>


  </div>

</div>
