<div class="container m-t-20">
    <h1 class="m-t-30">Tutorials</h1>
  These tutorial videos show how to use the different type of modules. Click on any exercise below to watch the video.

  <h2 class="p-t-30">Matching</h2>
  <hr class="hr-2"/>

  <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387205937/18441ab63b')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/word-picture-match.png"/><br/>
        <div class="p-10"><h5>Picture match nouns</h5>
          Choose photos for written nouns
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387205952/d3458b09d1')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/4pics1verb.png"/><br/>
        <div class="p-10"><h5>Picture match verbs</h5>
          Choose photos for written actions
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387205970/b41ca8e220')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/reading-sentence-picture-match.png"/><br/>
        <div class="p-10"><h5>Sentence Picture Match</h5>
          Choose photos for written sentences
        </div>
      </div>
    </div>
  </div>

  <h2 class="p-t-60">WH Questions</h2>
  <hr class="hr-2"/>
  <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387206067/ec0dd0bfc5')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/where.png"/><br/>
        <div class="p-10"><h5>Where</h5>
          Answer "where" questions
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387206078/f7a6b72742')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/who-questions.png"/><br/>
        <div class="p-10"><h5>Who</h5>
          Answer "who" questions
        </div>
      </div>
    </div>
  </div>


  <h2 class="p-t-60">Writing</h2>
  <hr class="hr-2"/>
  <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387205877/daab718786')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/word-writing-nouns.png"/><br/>
        <div class="p-10"><h5>Noun Pictures 1</h5>
          Type picture names
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387205893/d6689fa7ff')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/writing2-nouns.png"/><br/>
        <div class="p-10"><h5>Noun Writing 2</h5>
          Type the noun
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387206022/2797aa90ab')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/verb-fib-writing2.png"/><br/>
        <div class="p-10"><h5>Verb Completion 2</h5>
          Type verb in sentences
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387206030/1d2b38cf3b')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/definitions-writing.png"/><br/>
        <div class="p-10"><h5>What's the Word 1</h5>
          Write the defined word
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387206045/2329fe7745')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/definitions-writing2.png"/><br/>
        <div class="p-10"><h5>What's the Word 2</h5>
          Write the defined word
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387206088/865374e17e')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/noun-pictures-3.png"/><br/>
        <div class="p-10"><h5>Writing Nouns 3</h5>
          Type picture names
        </div>
      </div>
    </div>
  </div>


  <h2 class="p-t-60">Speech</h2>
  <hr class="hr-2"/>
  <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387205847/9bf28abc4b')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class=" tutorial-thumb" src="/images/modules/video-1.png"/><br/>
        <div class="p-10"><h5>Apraxia videos</h5>
          Learn how to use the apraxia videos
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387205867/b27850492d')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/speech-2-cloze.png"/><br/>
        <div class="p-10"><h5>Naming nouns 1</h5>
          Say the pictured word
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387205960/f308c7c811')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/naming-speech.png"/><br/>
        <div class="p-10"><h5>Repeating nouns</h5>
          Say the word you hear
        </div>
      </div>
    </div>
  </div>


  <h2 class="p-t-60">Sequencing tasks</h2>
  <hr class="hr-2"/>
  <div class="row" style="display:flex; justify-content:left;  flex-flow: row wrap;">
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387205910/a64e13d118')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/numbers.png"/><br/>
        <div class="p-10"><h5>Numbers</h5>
          Learn numbers 0-9
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">
      <div (click)="open('https://vimeo.com/387206010/453af2ede9')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/sentence-structure-training.png"/><br/>
        <div class="p-10"><h5>Sentence structure training</h5>
          Order, repeat, write sentences
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-md-4 col-lg-3 p-10 pointer">

      <div (click)="open('https://vimeo.com/387205983/7589b876b4')" class="p-2 btn-primary text-center" style="height:100%;">
        <img class="tutorial-thumb" src="/images/modules/sentence-construction.png"/><br/>
        <div class="p-10"><h5>Sentence Construction</h5>
          Move the words to form a sentence
        </div>
      </div>
    </div>
  </div>

</div>


<app-my-footer></app-my-footer>
