import {Component,} from '@angular/core';

@Component({
    selector: 'no-stimuli',
    templateUrl: './no-internet.component.html'
})
export class NoInternetComponent {
    type;

    constructor() {
    }

}
