import {Observable, of, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {$WebSocket} from 'angular2-websocket';
import * as reSampler from '../../../audio.service';

import {AudioService} from '../../services/audio.service';
import {BuilderService} from '../../services/builder.service';
import {MessagingService} from '../../services/messaging.service';
import {ModalService} from '../../services/modal.service';
import {ScreenService} from '../../services/screen.service';
import {StimuliService} from '../../services/stimuli.service';
import {UserService} from '../../services/user.service';

import {config} from '../../config';

@Component({
    templateUrl: './where-do-i-start.component.html'
})
export class WhereDoIStartComponent implements OnInit, OnDestroy {
    config = config;

    processor;
    source;
    ws;
    context;

    playPromise;
    audioIndex = 0;
    introIndex = 0;
    index = 0;
    input: string | null = null;
    questions = 0;
    showCategories = true;
    showHint = false;
    type: string | null = null;
    recording = false;
    checking = false;

    recommendations: Array<any> | null = null;
    showRecommendations = false;
    showStart = true;

    stimulis = [
        {
            index: 0,
            type: 'reading',
            image: '59387ebd240fa12a0069fcf2',
            alternatives: ['helm', 'motorcycle', 'helmet', 'visor'],
            answer: 'helmet',
            recommendations: ['53626e730e6ec80000a2f8e6', '561d9ac945ce1511008f1bf2', '57d606d73fa65939001bda35', '5b06e0b7a5dd3a20002c40b7'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b6384d1c18004bf7a884', id: 'highlight-image'}
            ]
        },
        {
            index: 1,
            type: 'reading',
            text: 'juice',
            imageAlternatives: ['537cb80e84f1e4000050ef7b', '537b605bc948760000173797', '537b5f7fc94876000017366e', '537b604fc948760000173783'],
            answer: '537cb80e84f1e4000050ef7b',
            recommendations: ['57d606d73fa65939001bda35', '5b06e0b7a5dd3a20002c40b7', '5fe3a93dae9a39dcb78428e1', '5fe3a89eae9a39dcb78420c3'],
            intro: [
                {index: 0, audio: '61e5b637378c97002227f1fa', id: 'highlight-image-alternatives'},
                {index: 1, audio: '61e86d7ea1fff6001fd25d5b', id: 'highlight-text'}
            ]
        },
        {
            index: 2,
            type: 'reading',
            text: 'The team is rowing.',
            imageAlternatives: ['5e1f6434dc3e8500441cc2e0', '58320a1aebcd371d0017d076', '5390a647841b6300001a0314', '53baf52ff1ec870000e7c86c'],
            answer: '5e1f6434dc3e8500441cc2e0',
            recommendations: ['567c4ba607bf82110095b6fc', '5d01445a7b620600425ef551', '5e273aefe4ab7c0028998e25', '5fe3a592ae9a39dcb783f971'],
            intro: [
                {index: 0, audio: '61e5b637378c97002227f1fa', id: 'highlight-image-alternatives'},
                {index: 1, audio: '61e5b638ba14ad001f0664e0', id: 'highlight-text'}
            ]
        },
        {
            index: 3,
            type: 'reading',
            image: '554ab2bdc185ed110002828f',
            answer: 'The woman is sewing',
            alternatives: ['The woman is sawing', 'The woman is sewing', 'The woman is knitting', 'The woman is fixing'],
            recommendations: ['5fe3a643ae9a39dcb784029b', '568c2013ae35c211002dbdde', '5fe38b90ae9a39dcb782a3a9', '5fe38c63ae9a39dcb782af15'],
            intro: [
                {index: 0, audio: '61e5b63762b7b8002d02155b', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b6384d1c18004bf7a884', id: 'highlight-image'}
            ]
        },
        {
            index: 4,
            type: 'reading',
            text: 'My watch needs a new _____.',
            answer: 'battery',
            alternatives: ['battery', 'clock', 'glasses', 'time'],
            recommendations: ['56f6e63db6ac92110053f6fa', '5398b1616662bf006144e091', '5399c03c4b4e0f0000260ae4', '57c893408be4662000b538f8'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 5,
            type: 'reading',
            text: 'Hammer the nails into the _____.',
            answer: 'wood',
            alternatives: ['wood', 'screws', 'wrench', 'hand'],
            recommendations: ['53970792a7fbda00009e8946', '5398b1616662bf006144e091', '5399c03c4b4e0f0000260ae4', '57c893408be4662000b538f8'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 6,
            type: 'reading',
            text: 'Dana\'s coffee was bitter, so she added some _____ to it.',
            answer: 'sugar',
            alternatives: ['sugar', 'coffee beans', 'dog food', 'hot'],
            recommendations: ['5fe3a7e5ae9a39dcb78417a6', '5fe38b90ae9a39dcb782a3a9', '5fe38c63ae9a39dcb782af15', '5fe3a726ae9a39dcb7840dc8'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 7,
            type: 'reading',
            text: '_____ your face with a napkin.',
            answer: 'Wipe',
            alternatives: ['Wipe', 'Whip', 'Wine', 'Win'],
            recommendations: ['5fe3a726ae9a39dcb7840dc8', '57c893408be4662000b538f8', '5fe379b8ae9a39dcb781aa7a', '587a9157ab3c6417007b4a33'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 8,
            type: 'reading',
            text: 'My brothers like hiking in the mountains. Some of the trails are very steep and high. They have to be careful when walking to keep their _____.',
            answer: 'balance',
            alternatives: ['balance', 'cliff', 'trails', 'boots'],
            recommendations: ['602d73db68f688003e44f3ce', '57c596e74f2162330062b678', '602d738b68f688003e44f3c8', '602d74639f6bda002f1fff05'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 9,
            type: 'reading',
            text: 'They\'re driving to Illinois, but they aren\'t leaving until after work on Wednesday',
            question: 'Where are they going?',
            answer: 'Illinois',
            alternatives: ['Illinois', 'they\'re driving', 'after work', 'Wednesday'],
            recommendations: ['57c596e74f2162330062b678', '602d738b68f688003e44f3c8', '602d74639f6bda002f1fff05', '602d73db68f688003e44f3ce'],
            intro: [
                {index: 0, audio: '61e5b63768059800326e02e3', id: 'highlight-text'},
                {index: 1, audio: '61e5b638be71570021694bad', id: 'highlight-alternatives'},
                {index: 2, audio: '61e5b638378c97002227f1fb', id: 'highlight-question'}
            ]
        },
        {
            index: 10,
            type: 'reading',
            text: 'Elizabeth changed her last name when she got married. She took her marriage certificate with her to get a new driver\'s license. Once that was done, she changed her social security number and all of her other _____.',
            answer: 'accounts',
            alternatives: ['accounts', 'husband', 'addresses', 'financial'],
            recommendations: ['5c1bf6657776ef002fede60a', '57c596e74f2162330062b678', '583396d082d9593500f859c1', '5c1bf48efd62c900392d4539'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 11,
            type: 'reading',
            text: 'Nancy stood up and began clapping with the other attendees as the speaker arrived on the stage.',
            question: 'Why did Nancy clap?',
            answer: 'The speaker arrived',
            alternatives: ['The speaker arrived', 'There was a stage', 'She stood up', 'With the attendees'],
            recommendations: ['602d738b68f688003e44f3c8', '5c1bf5327776ef002fede606', '602d73db68f688003e44f3ce', '602d73db68f688003e44f3ce'],
            intro: [
                {index: 0, audio: '61e5b63768059800326e02e3', id: 'highlight-text'},
                {index: 1, audio: '61e5b638be71570021694bad', id: 'highlight-alternatives'},
                {index: 2, audio: '61e5b638378c97002227f1fb', id: 'highlight-question'}
            ]
        },
        {
            index: 12,
            type: 'reading',
            text: 'John started Bob\'s boat early in the morning near the dock.',
            question: 'Who started the boat?',
            answer: 'John',
            alternatives: ['John', 'in the morning', 'near the dock', 'Bob'],
            recommendations: ['602d738b68f688003e44f3c8', '60490eb0f172f1003bbbed01', '602d74affb8e910043462e3d', '583396d082d9593500f859c1'],
            intro: [
                {index: 0, audio: '61e5b63768059800326e02e3', id: 'highlight-text'},
                {index: 1, audio: '61e5b638be71570021694bad', id: 'highlight-alternatives'},
                {index: 2, audio: '61e5b638378c97002227f1fb', id: 'highlight-question'}
            ]
        },
        {
            index: 13,
            type: 'reading',
            text: '_____ do you need from the store?',
            answer: 'What',
            alternatives: ['What', 'How', 'Her', 'Went'],
            recommendations: ['583396d082d9593500f859c1', '583396d082d9593500f859c1', '602d74affb8e910043462e3d', '602d74639f6bda002f1fff05'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 14,
            type: 'reading',
            text: 'She was naturally long-winded and had so much she wanted to say aphasia her chosen topic. Unfortunately, the assignment only allowed a total of 500 words. She wrote a rough draft, but had to review it to _____  some words and be more concise.',
            answer: 'eliminate',
            alternatives: ['eliminate', 'add', 'elongate', 'expand'],
            recommendations: ['583396d082d9593500f859c1', '583396d082d9593500f859c1', '5a6f69ce0698f91a008dfc6c'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 15,
            type: 'reading',
            text: 'I don\'t _____ any money in my account.',
            answer: 'have',
            alternatives: ['have', 'had', 'haven\'t', 'make'],
            recommendations: ['605a123d55f2a7002be23fff', '5c894388973d00004d4cafd0', '5c894388973d00004d4cafd0', '5dda9fc9cab4f300361213e2'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 16,
            type: 'reading',
            text: 'I don\'t _____ any money in my account.',
            answer: 'have',
            alternatives: ['have', 'had', 'haven\'t', 'make'],
            recommendations: ['605a123d55f2a7002be23fff', '5c894388973d00004d4cafd0', '5c894388973d00004d4cafd0', '5dda9fc9cab4f300361213e2'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 17,
            type: 'reading',
            text: 'Throw those towels _____ the washer now.',
            answer: 'in',
            alternatives: ['in', 'it', 'on', 'if'],
            recommendations: ['605a123d55f2a7002be23fff', '5c894388973d00004d4cafd0', '606341af0f35ed003918f814', '5dda9fc9cab4f300361213e2'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 18,
            type: 'reading',
            recommendations: ['5c894388973d00004d4cafd0']
        },

        {
            index: 0,
            type: 'listening',
            audio: ['5ebc2d8fbbce7600354e281b'],
            imageAlternatives: ['5bfc0988ec4b3000425a92d1', '5e1f64325f1cf2003ebee471', '5b3515dab38922001ff36192', '5830db0a120e412700e4682e'],
            answer: '5bfc0988ec4b3000425a92d1',
            recommendations: ['5e4467069517860041a89629', '567c4ba607bf82110095b6fc', '562028464082eb1100f02e4e'],
            intro: [
                {index: 0, audio: '61e9c4979b17ba0036dfff10', id: 'highlight-image-alternatives'},
                {index: 1, audio: '61e9c497f0082600224926d4', id: 'highlight-ear'}
            ]
        },
        {
            index: 1,
            type: 'listening',
            audio: ['57c1f2de3e97a92e00335e6c'],
            alternatives: ['Throw', 'Touchdown', 'Defend', 'Hit'],
            answer: 'Throw',
            recommendations: ['5399c03c4b4e0f0000260ae4', '562028464082eb1100f02e4e', '57c893408be4662000b538f8'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-ear'}
            ]
        },
        {
            index: 2,
            type: 'listening',
            audio: ['5efb5fe4989871003f00040f'],
            alternatives: ['759', '755', '758', '749'],
            answer: '759',
            recommendations: ['5b6845f79a506d001a9c770f', '5bd89e75f16c69001b903c0f', '5c89591e0038d6001e08a87a'],
            intro: [
                {index: 0, audio: '61e9c4969feb2d00472dda41', id: 'highlight-alternatives'},
                {index: 1, audio: '61e9c498e4271e0023f0dc25', id: 'highlight-ear'}
            ]
        },
        {
            index: 3,
            type: 'listening',
            audio: ['5c69ba131202ea003a154517'],
            alternatives: ['stranger', 'introduce', 'meeting', 'friend'],
            answer: 'stranger',
            recommendations: ['5398b1616662bf006144e091', '5399c03c4b4e0f0000260ae4', '57c893408be4662000b538f8'],
            intro: [
                {index: 0, audio: '61e5b637ad5532004491e0c5', id: 'highlight-alternatives'},
                {index: 1, audio: '61eeb22df3aa4400344b469b', id: 'highlight-ear'}
            ]
        },
        /*
                {
                    index: 4,
                    type: 'listening',
                    question: 'Where was she going?',
                    audio: ['5c69ba19ba4abd001e413653'],
                    alternatives: ['the airport', 'Ron', 'his wife', 'hugged her goodbye'],
                    answer: 'the airport',
                    recommendations: ['5c1bf5b52d460f001deb3853', '5c1bf6657776ef002fede60a'],
                },
        */
        {
            index: 4,
            type: 'listening',
            // text: 'Mark handed the envelope to Sue.',
            question: 'Who received the envelope?',
            // audio: ['5a9b20026cb7cc1a009870a2'],
            alternatives: ['Sue', 'envelope', 'Mark', 'handed it'],
            answer: 'Sue',
            recommendations: ['602d761ac2548b001e552ae1', '5c1bf48efd62c900392d4539'],
            intro: [
                {index: 0, audio: '61e9c497669dfe0041a16824', id: 'highlight-ear'},
                {index: 1, audio: '5a9b20026cb7cc1a009870a2', id: 'highlight-text'},
                {index: 2, audio: '61e5b638be71570021694bad', id: 'highlight-alternatives'},
                {index: 3, audio: '61e5b638378c97002227f1fb', id: 'highlight-question'}
            ]
        },
        {
            index: 5,
            type: 'listening',
            writing: true,
            audio: ['5fdcb19feca792001ec1e4ae'],
            answer: '972',
            recommendations: ['5efa1707b88769002a3d3986', '5fbeb21a384c51003de763c2'],
            intro: [
                {index: 0, audio: '61e9c499f0082600224926d5', id: 'highlight-input'},
                {index: 1, audio: '61e9c4989b17ba0036dfff11', id: 'highlight-ear'}
            ]
        },
        {
            index: 6,
            type: 'listening',
            /*audio: ['63e51f247fe84b00148ed9a8', '57bba7e0195e843400b48793'],*/
            alternatives: ['off', 'on', 'of', 'wash'],
            answer: 'off',
            recommendations: ['602d761ac2548b001e552ae1', '5c1bf48efd62c900392d4539'],
            intro: [
                {index: 0, audio: '63e51f247fe84b00148ed9a8', id: 'highlight-ear'},
                {index: 1, audio: '57bba7e0195e843400b48793', id: 'highlight-alternatives'}
            ]
        },
        {
            index: 7,
            type: 'listening',
            /*text: 'For the cookout next Saturday, we\'ll bring potato salad and hamburger buns.',*/
            question: 'When is it?',
            audio: ['5d2b9a920ad655002469e7c3'],
            alternatives: ['Saturday', 'we\'ll bring buns', 'next weekend', 'a cookout'],
            answer: 'Saturday',
            recommendations: ['5a6f69ce0698f91a008dfc6c', '602d7894623abc0023f69c76'],
            intro: [
                {index: 0, audio: '61e9c497669dfe0041a16824', id: 'highlight-text'},
                {index: 1, audio: '61e5b638be71570021694bad', id: 'highlight-alternatives'},
                {index: 1, audio: '61e9c4985f7ef7001fb41041', id: 'highlight-question'}
            ]
        },
        {
            type: 'listening',
            index: 8,
            recommendations: ['5a6f69ce0698f91a008dfc6c', '602d7894623abc0023f69c76']
        },

        {
            index: 0,
            type: 'writing',
            text: 'HORSE',
            image: '537cb80684f1e4000050ef69',
            writing: true,
            audio: ['57923ffa0c03b91a007eea52'],
            answer: 'horse',
            recommendations: ['60130b590041b4002ba2bbdf', '56b8e8b2a976631100058bc5', '56af72a980e28d1100e55bae', '5e8b329e389d5900251fc956', '5e7ba9e7678df1003b7ac17c'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id2: 'highlight-input', id: 'highlight-text'},
                {index: 1, audio: '61eb1ed969ce810027d4c9e3', id: 'highlight-image'}
            ]
        },
        {
            index: 1,
            type: 'writing',
            text: 'MOUNTAIN',
            image: '537b6055c948760000173787',
            writing: true,
            audio: ['57a3c1ef6c30042600fec742'],
            answer: 'mountain',
            recommendations: ['60130b590041b4002ba2bbdf', '56b8e8b2a976631100058bc5', '56af72a980e28d1100e55bae', '5e8b329e389d5900251fc956', '5e7ba9e7678df1003b7ac17c'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id2: 'highlight-input'},
                {index: 1, audio: '61eb1ed969ce810027d4c9e3', id: 'highlight-image'}
            ]
        },
        {
            index: 2,
            type: 'writing',
            image: '537cb7c984f1e4000050eee8',
            writing: true,
            audio: ['5ef4efe5c4de82003fd9d0ce'],
            answer: 'coffee',
            recommendations: ['5c6b129401b4bc003b47d289', '602fff91810042001e69b946', '603fe4f7a5ac850027b31637'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id: 'highlight-input'},
                {index: 1, audio: '61eb1ed969ce810027d4c9e3', id: 'highlight-image'}
            ]
        },
        {
            index: 3,
            type: 'writing',
            image: '5390a49ccc7b320000a2e661',
            writing: true,
            audio: ['57a3c1af8d917c200038f242'],
            answer: 'envelope',
            recommendations: ['5c6b129401b4bc003b47d289', '602fff91810042001e69b946', '603fe4f7a5ac850027b31637'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id: 'highlight-input'},
                {index: 1, audio: '61e5b6384d1c18004bf7a884', id: 'highlight-image'}
            ]
        },
        {
            index: 4,
            type: 'writing',
            text: 'To see better, put on your _____.',
            hintText: 'glasses',
            writing: true,
            audio: ['5731e79a7b54a325002dfe54'],
            hintAudio: ['5ef4efef18dfb0003b266510'],
            answer: 'GLASSES',
            recommendations: ['5b743a8de10aeb0026fd044d', '602fff91810042001e69b946', '603fe4f7a5ac850027b31637', '5692cbe5376c6a110050c370'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id2: 'highlight-input', id: 'highlight-hint'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 5,
            type: 'writing',
            text: 'I want to _____ some tea',
            hintText: 'DRINK',
            writing: true,
            audio: ['57c1f1f73e97a92e00335dca'],
            hintAudio: ['5ef4efea7cf758001e269a54'],
            answer: 'drink',
            recommendations: ['56bcd3848b9a3a11007f3dbc', '5c6b0e8d01b4bc003b47d1ce', '5c742ce38324c3004bd05969'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id2: 'highlight-input', id: 'highlight-hint'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 6,
            type: 'writing',
            text: 'Go to the gym to _____.',
            hintText: 'EXERCISE',
            writing: true,
            audio: ['57bde389c5a9112100f019d1'],
            hintAudio: ['5ebb052ca8c7fa002c4caefb'],
            answer: 'exercise',
            recommendations: ['56bcd3848b9a3a11007f3dbc', '5c6b0e8d01b4bc003b47d1ce', '5c742ce38324c3004bd05969'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id2: 'highlight-input', id: 'highlight-hint'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 7,
            type: 'writing',
            text: '______ is John going to buy a car?',
            writing: true,
            audio: ['5ddaa3c3f8f8720021b10fc2'],
            hintAudio: ['57bcfbf3534e07240083fd09'],
            answer: 'when',
            hintText: 'WHEN',
            recommendations: ['5d8a52c348638100331451e4', '5c6b0e8d01b4bc003b47d1ce', '5c742ce38324c3004bd05969'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id2: 'highlight-input', id: 'highlight-hint'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 8,
            type: 'writing',
            text: '_______ can I catch the bus that goes downtown?',
            writing: true,
            audio: ['5ddaa3ba0e644b00411b0a7b'],
            hintAudio: ['5786e91751fb813400b4b2d7'],
            answer: 'where',
            hintText: 'WHERE',
            recommendations: ['5d8a52c348638100331451e4', '5c6b0e8d01b4bc003b47d1ce', '5c742ce38324c3004bd05969'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id2: 'highlight-input', id: 'highlight-hint'},
                {index: 1, audio: '61e5b638f1dc270039479425', id: 'highlight-text'}
            ]
        },
        {
            index: 9,
            type: 'writing',
            writing: true,
            audio: ['5d8c00f911e2da0020d3cd87'],
            answer: 'on',
            recommendations: ['5f0722bdd5deed00268b8e4a', '5d8a4f474905b4002c9c91e4', '5cab9c1ac734a50039d9e874', '603571b8733163003e879723'],
            intro: [
                {index: 0, audio: '61eb1ed9e61fa30036b8d390', id2: 'highlight-input'},
                {index: 1, audio: '61e9c498e4271e0023f0dc25', id: 'highlight-ear'}
            ]
        },
        {
            index: 10,
            type: 'writing',
            recommendations: ['5f0722bdd5deed00268b8e4a']
        },

        {
            index: 0,
            type: 'speech',
            speech: true,
            image: ['537b6055c948760000173787'],
            audio: ['57a3c1ef6c30042600fec742'],
            answer: 'mountain',
            recommendations: ['5ae2161c82b1de3300ef0f71'],
            intro: [
                {index: 0, audio: '61f2ba74168c5b003b2a81c6', id: ''},
                {index: 0, audio: '60cb9ff6cc37f20041dec894', id: 'highlight-speech'},
                {index: 0, audio: '61eec9447fbec90038893349', id: ''}
            ]
        },
        {
            index: 1,
            type: 'speech',
            speech: true,
            image: ['537b60ddc948760000173836'],
            audio: ['60622b2ea6d935002fe0b0c0'],
            answer: 'umbrella',
            recommendations: ['5ae2161c82b1de3300ef0f71', '5b7ac19f249253003e700409', '605a0bf0c736820024806c2c'],
            intro: [
                {index: 0, audio: '61f2ba74168c5b003b2a81c6', id: ''},
                {index: 0, audio: '60cb9ff6cc37f20041dec894', id: 'highlight-speech'},
                {index: 0, audio: '61eec944b282650027b7e2e1', id: 'highlight-image'},
                {index: 0, audio: '61eec9446f3405003760600f', id: 'highlight-image'}
            ]
        },
        {
            index: 2,
            type: 'speech',
            speech: true,
            answer: 'plant some flowers',
            preSounds: [
                {_id: '5ffcb6bcc7d5a37276f9fec7', word: 'Plant', sound: '5ffcb5d838f74e0026c37958'},
                {_id: '5ffcb6bcc7d5a37276f9fec6', word: 'some', sound: '5ffcb5ec04a4340020f7da05'},
                {_id: '5ffcb6bcc7d5a37276f9fec5', word: 'flowers', sound: '5ffcb5653bb7550032517dd2'},
                {_id: '5ffcb6bcc7d5a37276f9fec4', sound: '5ffcb5d8b5fde400284a1271'}],
            recommendations: ['605a0bf0c736820024806c2c', '582bb331c0a29717009ece8c'],
            intro: [
                {index: 0, audio: '61f2ba74168c5b003b2a81c6', id: ''},
                {index: 0, audio: '60cb9ff6cc37f20041dec894', id: 'highlight-speech'},
                {index: 0, audio: '61eec944247952002309c438', id: 'highlight-pre-sounds'}
            ]
        },
        {
            index: 3,
            type: 'speech',
            speech: true,
            text: '832',
            audio: ['5fdcb19dbf6e9c002f656b34'],
            answer: '832',
            recommendations: ['5b6b2d660b372d001fb88c6a', '5bd89e75f16c69001b903c0f', '5c89591e0038d6001e08a87a'],
            intro: [
                {index: 0, audio: '61f2ba74168c5b003b2a81c6', id: ''},
                {index: 0, audio: '60cb9ff6cc37f20041dec894', id: 'highlight-speech'},
                {index: 0, audio: '61eec945d71138001f701ed7', id: 'highlight-text'}
            ]
        },
        {
            index: 4,
            type: 'speech',
            speech: true,
            text: 'another',
            audio: ['5c8e69e3fdc97200337efc19'],
            answer: 'another',
            recommendations: ['5f0722bdd5deed00268b8e4a', '60294dc8a0d501001e3ec4e0', '605a145750f18f002d12bdf9', '5c9102958d2b53004d67c6da'],
            intro: [
                {index: 0, audio: '61f2ba74168c5b003b2a81c6', id: ''},
                {index: 0, audio: '60cb9ff6cc37f20041dec894', id: 'highlight-speech'},
                {index: 0, audio: '61eec9447fbec90038893349', id: 'highlight-text'}
            ]
        },
        {
            index: 5,
            type: 'speech',
            speech: true,
            text: 'of',
            audio: ['5fe0da94f75a9a001ea4db3b'],
            answer: 'of',
            recommendations: ['5f0722bdd5deed00268b8e4a', '60294dc8a0d501001e3ec4e0', '605a145750f18f002d12bdf9', '5c9102958d2b53004d67c6da'],
            intro: [
                {index: 0, audio: '61f2ba74168c5b003b2a81c6', id: ''},
                {index: 0, audio: '60cb9ff6cc37f20041dec894', id: 'highlight-speech'},
                {index: 0, audio: '61eec943f3aa4400344b48a6', id: 'highlight-text'}
            ]
        },
        {
            index: 6,
            type: 'speech',
            speech: true,
            text: '1795',
            audio: ['602beef9ca047d0022d3b7a8'],
            hintAudio: ['5efb5ffb0aef4a001eb4133f'],
            answer: '1795',
            recommendations: ['5fbeb21a384c51003de763c2', '60294dc8a0d501001e3ec4e0', '5b6b2d660b372d001fb88c6a'],
            intro: [
                {index: 0, audio: '61f2ba74168c5b003b2a81c6', id: ''},
                {index: 0, audio: '60cb9ff6cc37f20041dec894', id: 'highlight-speech'},
                {index: 0, audio: '61eec94303ffa600298e1995', id: 'highlight-text'}
            ]
        },
        {
            index: 7,
            type: 'speech',
            recommendations: ['5fbeb21a384c51003de763c2', '60294dc8a0d501001e3ec4e0', '5b6b2d660b372d001fb88c6a']
        }
    ];

    stimuli: any | null = null;

    private subscriptions = new Subscription();


    constructor(private cdr: ChangeDetectorRef,
                private router: Router,
                private sanitizer: DomSanitizer,
                private modalService: ModalService,
                private userService: UserService,
                private audioService: AudioService,
                private builderService: BuilderService,
                private screenService: ScreenService,
                private messagingService: MessagingService,
                private stimuliService: StimuliService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.userService.bsRecommendations.subscribe(result => {
            this.recommendations = result;
        }));
        this.subscriptions.add(this.userService.bsShowRecommendations.subscribe(result => {
            this.showRecommendations = result;
        }));
        this.subscriptions.add(this.userService.bsShowStart.subscribe(result => {
            this.showStart = result;
        }));

        this.userService.getUserInfo().subscribe();

        this.userService.bsShowStart.next(true);
    }

    ngOnDestroy(): void {
        this.stimuliService.unsubscribe.next();
        this.stimuliService.unsubscribe.complete();
        this.subscriptions.unsubscribe();
        this.stopRec(true).then(() => {
        });
    }

    startOver(): Promise<any> {
        return this.stopRec(true).then(() => {
            this.userService.bsShowStart.next(false);
            this.userService.bsShowRecommendations.next(false);
            this.stimuli = null;
            this.showCategories = true;
            this.type = null;
            this.index = 0;
            this.audioIndex = 0;
            this.introIndex = 0;
        });
    }

    test(type): any {
        return this.pause().then(() => {
            this.userService.bsShowRecommendations.next(false);
            this.checking = false;
            this.audioIndex = 0;
            this.introIndex = 0;
            // this.index = 0;
            this.showCategories = false;
            this.showStart = false;
            this.input = null;
            this.type = type;
            this.stimuli = _.find(this.stimulis, {index: this.index, type: this.type});
            this.questions = _.filter(this.stimulis, {type: this.type}).length;

            if (this.stimuli?.alternatives) {
                this.stimuli.alternatives = _.shuffle(this.stimuli.alternatives);
            }
            else if (this.stimuli?.imageAlternatives) {
                this.stimuli.imageAlternatives = _.shuffle(this.stimuli.imageAlternatives);
            }

            if (this.stimuli?.hintText) {
                this.showHint = true;

                setTimeout(() => {
                    this.showHint = false;
                    this.cdr.detectChanges();
                }, 3000);
            }

            if (this.stimuli?.writing) {
                setTimeout(() => {
                    try {
                        // @ts-ignore
                        document.getElementById('writing').focus();
                    } catch (err: any) {
                        console.error(new Error(err));
                    }
                }, 500);
            }

            if (this.stimuli?.intro && this.stimuli?.intro[0]?.audio) {
                if (this.stimuli?.preSounds) {
                    this.audioIndex = -1;
                }
                return setTimeout(() => {
                    return this.playIntro();
                }, 1000);
            }
            else if (this.stimuli?.preSounds && this.stimuli?.preSounds[0]?.sound) {
                return setTimeout(() => {
                    return this.playPreSoundAudio();
                }, 1000);
            }
            else if (this.stimuli?.audio) {
                return setTimeout(() => {
                    return this.playAudio();
                }, 1000);
            }
            return;
        });
    }

    getStimuliImage(): SafeResourceUrl | null {
        if (this.stimuli?.image) {
            const url = config.mediaServer + 'blob.500/' + this.stimuli.image + '.jpg';
            return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
        else {
            return null;
        }
    }

    getAnswerImage(ix): SafeResourceUrl | null {
        if (this.stimuli?.imageAlternatives) {
            const url = config.mediaServer + 'blob.400/' + this.stimuli.imageAlternatives[ix] + '.jpg';
            return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
        else {
            return null;
        }
    }

    checkClickAnswer(answer): any {
        return this.pause().then(() => {
            if (this.checking === true) {
                return;
            }
            else {
                this.checking = true;
                if (answer === this.stimuli?.answer) {
                    return this.modalService.therapyResponse(true, null).subscribe(() => {
                        this.index++;
                        if (this.index === this.questions - 1) {
                            return this.makeRecommendations();
                        }
                        else {
                            return this.test(this.type);
                        }
                    }, (err) => {
                        this.index++;
                        if (this.index === this.questions - 1) {
                            return this.makeRecommendations();
                        }
                        else {
                            return this.test(this.type);
                        }
                    });
                }
                else {
                    return this.modalService.therapyResponse(false, null).subscribe(() => {
                        return this.makeRecommendations();
                    }, (err) => {
                        return this.makeRecommendations();
                    });
                }
            }
        });
    }

    checkWritingAnswer(): Promise<any> {
        try {
            // @ts-ignore
            document.getElementById('writing').blur();
            this.cdr.detectChanges();
        } catch {
        }

        return this.pause().then(() => {
            if (this.input?.toLowerCase().trim() === this.stimuli?.answer.toLowerCase().trim()) {
                return this.modalService.therapyResponse(true, null).subscribe(() => {
                    this.index++;

                    if (this.index === this.questions - 1) {
                        return this.makeRecommendations();
                    }
                    else {
                        return this.test(this.type);
                    }
                }, () => {
                    this.index++;

                    if (this.index === this.questions - 1) {
                        return this.makeRecommendations();
                    }
                    else {
                        return this.test(this.type);
                    }
                });
            }
            else {
                return this.modalService.therapyResponse(false, null).subscribe(() => {
                    return this.makeRecommendations();
                }, () => {
                    return this.makeRecommendations();
                });
            }
        });
    }

    playIntro(): any {
        return this.pause().then(() => {
            if (this.stimuli?.intro && this.stimuli.intro[this.introIndex] && this.stimuli.intro[this.introIndex].audio) {
                const audio = new Audio();
                audio.src = config.mediaServer + 'blob.mp3/' + this.stimuli.intro[this.introIndex].audio;
                this.playPromise = audio;

                audio.addEventListener('ended', (e) => {
                    this.checking = false;
                    try {
                        const id = document.getElementById(this.stimuli.intro[this.introIndex].id);
                        const id2 = document.getElementById(this.stimuli.intro[this.introIndex].id2);
                        if (id) {
                            this.screenService.removeClass(id, 'highlight');
                        }
                        if (id2) {
                            this.screenService.removeClass(id2, 'highlight');
                        }

                        if (this.stimuli?.intro && (this.stimuli.intro.length > (this.introIndex + 1))) {
                            this.introIndex++;
                            return setTimeout(() => {
                                return this.playIntro();
                            }, 1000);

                        }
                        else if (this.stimuli?.audio) {
                            return setTimeout(() => {
                                return this.playAudio();
                            }, 1000);
                        }
                        else if (this.stimuli?.preSounds) {
                            // FIXME Why?
                            this.audioIndex = 0;
                            return setTimeout(() => {
                                return this.playPreSoundAudio();
                            }, 1000);
                        }
                        else {
                            this.screenService.removeClass(id, 'highlight');
                            return Promise.resolve();
                        }
                    } catch (err: any) {
                        if (this.stimuli?.audio) {
                            return setTimeout(() => {
                                return this.playAudio();
                            }, 1000);
                        }
                        else if (this.stimuli?.preSounds) {
                            this.audioIndex = 0;
                            return setTimeout(() => {
                                return this.playPreSoundAudio();
                            }, 1000);
                        }
                        else {
                            return Promise.resolve();
                        }
                    }
                });

                return audio.play().then(res => {
                    const id = document.getElementById(this.stimuli.intro[this.introIndex].id);
                    const id2 = document.getElementById(this.stimuli.intro[this.introIndex].id2);

                    if (id) {
                        this.screenService.addClass(id, 'highlight');
                    }
                    if (id2) {
                        this.screenService.addClass(id2, 'highlight');
                    }
                }, (err): any => {
                    if (this.stimuli?.audio) {
                        return setTimeout(() => {
                            return this.playAudio();
                        }, 1000);
                    }
                    else if (this.stimuli?.preSounds) {
                        this.audioIndex = 0;
                        return setTimeout(() => {
                            return this.playPreSoundAudio();
                        }, 1000);
                    }
                    else {
                        return Promise.resolve();
                    }
                });
            }
            else {
                return Promise.resolve();
            }
        });
    }

    playAudio(): Promise<void> {
        return this.pause().then(() => {
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.stimuli.audio[this.audioIndex];
            this.playPromise = audio;
            audio.addEventListener('ended', (e) => {
                if (this.stimuli?.audio && (this.stimuli.audio.length > (this.audioIndex + 1))) {
                    this.audioIndex++;
                    return this.playAudio();
                }
                else if (this.stimuli?.speech) {
                    return this.startRec();
                }
                else {
                    return Promise.resolve();
                }
            });

            return audio.play().then(res => {
            }, (err) => {
                console.error(new Error(err));
            });
        });
    }

    playPreSoundAudio(): Promise<void> {
        return this.pause().then(() => {
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.stimuli.preSounds[this.audioIndex].sound;
            this.playPromise = audio;
            audio.addEventListener('ended', (e) => {
                if (this.stimuli?.preSounds && (this.stimuli.preSounds.length > (this.audioIndex + 1))) {
                    this.audioIndex++;
                    return this.playPreSoundAudio();
                }
                else if (this.stimuli?.speech) {
                    return this.startRec();
                }
                else {
                    return Promise.resolve();
                }
            });

            return audio.play().then(res => {
            }, (err) => {
                console.error(new Error(err));
            });
        });
    }

    playHintAudio(): Promise<void> {
        return this.pause().then(() => {
            const audio = new Audio();
            audio.src = config.mediaServer + 'blob.mp3/' + this.stimuli.hintAudio[0];
            this.playPromise = audio;
            return audio.play().then(res => {
            }, (err) => {
                console.error(new Error(err));
            });
        });
    }

    playIntroButton(): Promise<void> {
        this.introIndex = 0;
        return this.playIntro();
    }

    startRec(): Promise<any> {
        return this.pause().then(() => {
            return this.audioService.startSound().then(() => {
                this.recording = true;

                try {
                    this.context = new AudioContext();
                    this.ws = new $WebSocket(this.config.webSocket, undefined, {reconnectIfNotNormalClose: true}, 'arraybuffer');
                } catch (err: any) {
                    this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
                }

                this.ws.getDataStream().subscribe((msg: MessageEvent) => {
                    return this.receiveMessage(msg);
                });

                return navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
                    this.source = this.context.createMediaStreamSource(stream);
                    this.processor = this.context.createScriptProcessor(8192, 1, 1);

                    this.source.connect(this.processor);
                    this.processor.connect(this.context.destination);

                    return this.processor.onaudioprocess = (data) => {
                        return reSampler(data.inputBuffer, 16000, (event) => {
                            try {
                                if (this.ws && this.ws.send()) {
                                    return this.ws.send(this.convertFloat32ToInt16(event.getAudioBuffer().getChannelData(0)), undefined, true).subscribe();
                                }
                            } catch (err: any) {
                                console.error(new Error(err));
                            }
                        });
                    };
                }, () => {
                    this.messagingService.setMessage('Can\'t find microphone. Please enable, then restart the module', false);
                });
            });
        });
    }

    stopRec(skip?): Promise<any> {
        this.audioService.bsListen.next(false);
        clearTimeout(this.builderService.timer);

        if (this.ws) {
            this.ws.send('stop');
            this.ws.close();
            this.ws = null;
        }

        if (this.processor) {
            this.processor.disconnect();
            this.processor = null;
        }

        this.source = null;
        this.recording = false;

        this.builderService.timer = null;

        if (!skip) {
            return this.stopSound();
        }
        else {
            return Promise.resolve(true);
        }
    }

    makeRecommendations(): Promise<any> {
        return this.pause().then(() => {

            this.stimuli = null;

            const rec: any = _.find(this.stimulis, {type: this.type, index: this.index});
            const res: Array<any> = [];
            _.forEach(this.stimuliService.bsTypes.value, (type) => {
                _.forEach(rec.recommendations, (r) => {
                    if (r === type._id) {
                        res.push(type);
                    }
                });
            });

            if (this.type === 'speech' && this.index === 0) {
                res.push({
                    video: true,
                    level: 1,
                    visibleName: 'Apraxia Video Level 1',
                    description: '1 syllable, 2 sounds',
                    menu: {image: 'video-1.png'},
                });

                res.push({
                    video: true,
                    level: 2,
                    visibleName: 'Apraxia Video Level 2',
                    description: '1 syllable, 3 sounds',
                    menu: {image: 'video-2.png'},
                });
            }
            if (this.type === 'speech' && this.index === 2) {
                res.push({
                    video: true,
                    level: 1,
                    visibleName: 'Apraxia Video Level 3',
                    description: '1 syllable, blends',
                    menu: {image: 'video-3.png'},
                });

                res.push({
                    video: true,
                    level: 2,
                    visibleName: 'Apraxia Video Level 4',
                    description: '3 word sentences, 3-5 syllable words',
                    menu: {image: 'video-4.png'},
                });
            }
            if (this.type === 'speech' && this.index === 7) {
                res.push({
                    video: true,
                    level: 1,
                    visibleName: 'Apraxia Video Level 7',
                    description: '3+ syllable words, 4-8 word sentences',
                    menu: {image: 'video-7.png'},
                });

                res.push({
                    video: true,
                    level: 2,
                    visibleName: 'Apraxia Video Level 8',
                    description: '4-6 word sentences, multisyllabic words',
                    menu: {image: 'video-8.png'},
                });
            }

            this.type = null;
            this.userService.bsRecommendations.next(res);
            this.userService.bsShowRecommendations.next(true);

            const user = JSON.parse(JSON.stringify(this.userService.bsUser.value));
            if (!user.recommendations) {
                user.recommendations = [];
            }

            _.forEach(res, r => {
                const recs = {
                    task: r.name,
                    modified: new Date()
                };
                user.recommendations.push(recs);
            });

            user.recommendations = _.uniqBy(user.recommendations as Array<any>, 'task');

            const message = {
                messageDate: new Date(),
                quizMessage: true,
                quizModules: user.recommendations
            };

            if (!user.messages) {
                user.messages = [];
            }

            user.messages.push(message);
            this.userService.bsUser.next(user);
            this.userService.updateRecommendations(user).subscribe();
            this.userService.updateRecommendationsTherapist(user, message).subscribe();
        });
    }

    navigate(type, event): Promise<any> {
        if (!this.userService.userInfo.premiumSubscriber) {
            if (this.userService.userInfo.basicSubscriber) {
                const route = ['/modules-videos'];
                return this.router.navigate(route);
            }
            else {
                const route = ['/account'];
                return this.router.navigate(route);
            }
        }
        else {
            let route = ['/therapy/' + type.templateType + '/' + type.name];

            if (type.video) {
                route = ['/videos/' + type.level];
            }

            if (event.button === 1) {
                window.open(route[0], '_blank');
                return Promise.resolve();
            }
            else {
                return this.router.navigate(route);
            }
        }
    }

    getClass(type): string {
        let returnData = 'p-2 btn-primary ';

        if (type.menu && type.menu.color) {
            returnData = returnData + ' ' + type.menu.color;
        }
        else {
            returnData = returnData + ' fg-default ';
        }
        if (type.menu && type.menu.backgroundColor) {
            returnData = returnData + ' ' + type.menu.backgroundColor;
        }
        else {
            returnData = returnData + ' bg-default ';
        }
        return returnData;
    }

    getImage(type): string {
        if (type.menu && type.menu.image) {
            return '/images/modules/' + type.menu.image;
        }
        else {
            return '/images/modules/default.png';
        }
    }

    convertFloat32ToInt16(buffer): any {
        let l = buffer.length;
        const buf = new Int16Array(l);
        while (l--) {
            buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
        }
        return buf.buffer;
    }

    receiveMessage(msg?): any {
        let message;
        try {
            message = JSON.parse(msg.data);
        } catch (err: any) {
            console.error(new Error(err));
        }

        if (message) {
            return this.stopRec().then(() => {
                let correct = false;

                if (message && message[0] && message[0].alternatives) {
                    const result = _.filter(message[0].alternatives, (alternative) => {
                        if (_.toLower(alternative.transcript).includes(_.toLower(this.stimuli.answer))) {
                            return true;
                        }
                        return false;
                    });

                    if (result.length > 0) {
                        correct = true;
                    }
                }

                if (correct) {
                    return this.modalService.therapyResponse(true, null).subscribe(() => {
                        this.index++;

                        if (this.index === this.questions - 1) {
                            this.makeRecommendations();
                        }
                        else {
                            this.test(this.type);
                        }
                    }, () => {
                        return this.makeRecommendations();
                    });
                }
                else {
                    return this.modalService.therapyResponse(false, null).subscribe(() => {
                        return this.makeRecommendations();
                    }, () => {
                        return this.makeRecommendations();
                    });
                }
            });
        }
        else {
            console.log('no msg');
        }
        return of(false);
    }

    showSkip(): boolean {
        if (this.userService.bsUser?.value?.lastSeen && this.userService.bsUser?.value?.lastSeen.length > 0) {
            return false;
        }
        return true;
    }

    stopSound(): any {
        return this.pause().then(() => {

            const audio = new Audio();
            audio.src = '/images/stop.mp3';
            return audio.play();
        });
    }

    pause(): Promise<any> {
        _.forEach(document.getElementsByClassName('highlight'), el => {
            if (el?.classList) {
                el.classList.remove('highlight');
            }
        });


        if (this.playPromise && this.playPromise.pause()) {
            return this.playPromise.pause().then(() => {
                this.playPromise = null;
            });
        }
        else {
            return Promise.resolve();
        }
    }
}
