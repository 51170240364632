<app-error-message></app-error-message>
<div *ngIf="type && typeSettings" class="center w-100">
  <div *ngIf="onStep === 0">
    <h1 class="click m-b-10">
      Which number is
    </h1>

    <div>
      <span class="material-icons middle h1" color="#1d3557" [class.highlight-word]="highlight['highlight-ear']">hearing</span>
    </div>

    <div style="display: flex; flex-direction: row; flex-wrap: nowrap;  justify-content: space-evenly; max-width:720px; margin:auto;" [class.highlight-word]="highlight['highlight-alternatives']">
      <div *ngFor="let answer of numbers; let index=index; let isOdd=odd; let isEven=even" (click)="checkStep0(answer)" class="p-5" style="flex-basis: 0; flex-grow: 1;">
        <span class="btn btn-primary m-5 speech-number" id="{{index}}" style="width: 100%;">
          <h1>{{answer?.word}}</h1>
            <h3>{{answer?.alternateAnswer}}</h3>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="onStep === 1">
    <h1>What number is this?</h1>
    <div *ngIf="(correctAnswer && onNumber > -1 && correctAnswer[onNumber])" class="btn btn-primary m-5 speech-number" [class.highlight-word]="highlight['highlight-text']">
      <h1>{{correctAnswer[onNumber].word}}</h1>
      <h3>{{correctAnswer[onNumber]?.alternateAnswer}}</h3>
    </div>

    <div class="p-b-10 p-t-30" [class.highlight-word]="highlight['highlight-speech']">
      <button (click)="start()" [hidden]="screen.recording" class="btn btn-primary" style="min-width: 100px;">
        <span class="material-icons middle">mic</span>
        Start
      </button>

      <button (click)="stopButton()" [hidden]="!screen.recording" class="btn btn-danger" style="min-width: 100px;">
        <span class="material-icons middle">mic_off</span>
        Pause
      </button>
    </div>
  </div>

  <div *ngIf="onStep === 2" style="display: flex; justify-content: center">
    <div class="center" style="width: 600px;">
      <div>
        <span class="material-icons middle h1" color="#1d3557" [class.highlight-word]="highlight['highlight-ear']">hearing</span>
      </div>
      <div [class.highlight-word]="highlight['highlight-alternatives']" class="p-5">
        <button *ngFor="let answer of numbers; let index=index; let isOdd=odd; let isEven=even" (click)="checkStep2(answer)" [disabled]="isDisabled(answer)" class="btn" style="width: 33.3%">
          <span [class.highlight-numbers]="highlightAnswer(answer)" class="btn btn-primary speech-number" id="{{index}}" style="width: 100%;">
            <h1>{{answer?.word}}</h1>
              <h3>{{answer?.alternateAnswer}}</h3>
          </span>
        </button>
      </div>
    </div>
  </div>

</div>
