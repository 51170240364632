<app-error-message></app-error-message>

<div *ngIf="stimuli && question && type && typeSettings" class="center">

  <div *ngIf="typeSettings.mainImage && stimuli.image" class="p-t-15" [class.highlight-word]="highlight['highlight-image']">
    <img [ngClass]="{ 'fs-stimuli-image': settings.fullScreen, 'stimuli-image': !settings.fullScreen}" [src]="getStimuliImage()"/>
  </div>

  <div *ngIf="typeSettings.question && stimuli.description">
    <h2> <span class="p-5" [class.highlight-word]="highlight['highlight-text']">{{stimuli.description}}</span></h2>
  </div>


  <form name="answerForm">
    <div *ngFor="let word of answer; let i = index" class="inline-block center p-10">

      <div class="text-left">
        <p *ngIf="help > 1 || showHelp" [innerText]="answer[i].output" class="uppercase writing2-copy"></p>

        <input (keyup)="nextField(i)" *ngIf="showInput"
               [(ngModel)]="word.input"
               [ngStyle]="makeInput(word.output)"
               autocomplete="off"
               class="uppercase writing2-input"
               id="input_{{i}}"
               maxlength="{{word.output.length}}"
               [class.highlight-word]="highlight['highlight-input']"
               name="input_{{i}}">
      </div>
    </div>
    <app-hint></app-hint>
    <div class="clear m-t-15">
      <button type="submit" (click)="checkAnswer()" class="btn btn-primary high-z center-block" type="submit"><span class="material-icons middle">done</span> Done</button>
    </div>

  </form>
  <br clear="all"/>
</div>
