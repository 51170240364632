<div class="container-fluid bg-white">
  <div class="m-b-15" id="my-account"></div>
  <div class="container">
    <div *ngIf="!user" class="row">
      <div class="col-sm-12 center">
        <h4>Current Subscription</h4>
        You are not signed in. Please sign.
      </div>
    </div>

    <div *ngIf="user" class="row">
      <div class="col-sm-12 center">
        <h4>Current Subscription</h4>
        <h5 *ngIf="user.payment.subscriptionType || user.applePay.productIdentifier || user.googlePay.productIdentifier">{{subscriptionType(user.payment.subscriptionType || user.applePay.productIdentifier || user.googlePay.productIdentifier)}}</h5>
        <!-- <h5 *ngIf="!user.payment.subscriptionType && (!userInfo.subscriber.stripePay|| userInfo.expired.applePay || userInfo.expired.googlePay)">
          You don't have a subscription.
        </h5> -->
        <h5 *ngIf="!(user.payment.subscriptionType || user.applePay.productIdentifier || user.googlePay.productIdentifier)">
          You don't have a subscription.
        </h5>
        <div *ngIf="isExpired(user?.payment?.subscriptionExpires || user?.applePay?.subscriptionExpires || user?.googlePay?.subscriptionExpires)" class="m-t-20 m-b-20">
          <div class="p-10 bg-red bold">
            Your subscription has expired.
          </div>
        </div>

        <div *ngIf="user.local && user.local.name">{{user.local.name}}</div>
        <div *ngIf="user.local && user.local.email">{{user.local.email}}</div>
      </div>


      <div *ngIf="user" class="row">
        <div class="col-sm-12">
          <div *ngIf="user.type === 'Therapist'">
            Your account is always free! Share our software with your colleagues!<br/>
          </div>

          <div *ngIf="!(user.payment.subscriptionType || user.applePay.productIdentifier || user.googlePay.productIdentifier) && userInfo.showTrial && !userInfo.validTrial && !userInfo.basicSubscriber">
            Your free trial has ended. If you want to continue using My Aphasia Coach, you must become a subscriber.
          </div>

          <div *ngIf="userInfo.showTrial && userInfo.validTrial">
            <div *ngIf="dayDiff() > 0" class="">
              Your free trial ends {{user.payment.trialEnds|date}}.
            </div>

            <div *ngIf="dayDiff() === 0" class="alert alert-warning">Your free trial ends today.</div>
          </div>

          <div *ngIf="!(user.userType === 'Therapist' || user.userType === 'Staff')" class="p-t-15">
            <div *ngIf="user.payment.delinquent" class="p-10 bg-red bold m-t-20 m-b-20">
              There is a problem with your card. Please set up a new credit card or <a routerLink="/contact/">contact support</a>.
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="user">
      <div *ngIf="!(user.payment.subscriptionType || user.applePay.productIdentifier || user.googlePay.productIdentifier) && (!userInfo.subscriber.stripePay|| userInfo.expired.applePay || userInfo.expired.googlePay)" routerLink="/payment" class="col-sm-6 p-15">
        <div class="border d-flex justify-content-center p-15 btn btn-primary">
          <span class="material-icons middle p-r-10">shopping_cart</span>
          Subscribe
        </div>
      </div>
      <div *ngIf="user.payment.subscriptionType" routerLink="/change-card" class="col-sm-6 p-15">
        <div class="border d-flex justify-content-center p-15 btn btn-primary">
          <span class="material-icons middle p-r-10">payment</span>
          Update Credit Card
        </div>
      </div>
      <div *ngIf="user.payment.subscriptionType || user.applePay.productIdentifier || user.googlePay.productIdentifier" routerLink="/payment" class="col-sm-6 p-15">
        <div class="border d-flex justify-content-center p-15 btn btn-primary">
          <span class="material-icons middle p-r-10">payment</span>
          Change or Cancel Subscription
        </div>
      </div>
      <div class="col-sm-6 p-15" routerLink="/change-password">
        <div class="border d-flex justify-content-center p-15 btn btn-primary">
          <span class="material-icons middle p-r-10">password</span>
          Change password
        </div>
      </div>
      <div class="col-sm-6 p-15" routerLink="/close-account">
        <div class="border d-flex justify-content-center p-15 btn btn-primary">
          <span class="material-icons middle p-r-10">close</span>
          Close Account
        </div>
      </div>
      <div class="col-sm-6 p-15">
        <div class="border d-flex justify-content-center p-15 btn btn-primary" (click)="logout()">
          <span class="material-icons middle p-r-10">logout</span>
          Sign Out
        </div>
      </div>
      <div  class="col-sm-6 p-15">
        <div class="border d-flex justify-content-center p-15 btn btn-primary" (click)="clear()">
          <span class="material-icons middle p-r-10 p-r-10">delete</span>
          Reset Progress
        </div>
      </div>
    </div>

    <app-error-message></app-error-message>


  </div>
  <app-my-footer></app-my-footer>
</div>
