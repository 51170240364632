import * as _ from 'lodash';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {StimuliService} from '../../services/stimuli.service';

import {settings} from '../../settings';

@Component({
    selector: 'prerender',
    templateUrl: './prerender.component.html'
})
export class PreRenderComponent implements OnInit, OnDestroy {
    types;
    settings;

    private oTypes;

    constructor(private stimuliService: StimuliService) {
        this.settings = settings;
    }

    ngOnInit(): void {
        this.oTypes = this.stimuliService.getTypes().subscribe(result => {
            this.types = _.sortBy(result, ['sortCode']);
        });
    }

    ngOnDestroy(): void {
        if (this.oTypes) {
            this.oTypes.unsubscribe();
        }
    }

}
