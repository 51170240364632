import {Component, OnInit} from '@angular/core';

@Component({
    templateUrl: './upgrade-browser.component.html'
})
export class UpgradeBrowserComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
