<div *ngIf="stimuli && alternatives && type && typeSettings" class="center m-t-10 p-10">
  <app-error-message></app-error-message>

  <h3 *ngIf="type.buildType === 'repeatNumber'" class="p-b-15">
    Repeat each number
  </h3>
  <!--
      <h3 class="p-b-15" *ngIf="type.buildType === 'thisNumberIs'">
          This number is
      </h3>
  -->

  <h1 *ngIf="typeSettings.question && stimuli.description" class="click">
    <span class="p-5" [class.highlight-word]="highlight['highlight-text']">{{stimuli.description}}</span>
  </h1>

  <div *ngIf="stimuli && stimuli.sound && !typeSettings?.question && !typeSettings?.mainImage && showEar">
    <span class="material-icons middle h1" color="#1d3557" [class.highlight-word]="highlight['highlight-ear']">hearing</span>
  </div>

  <!--
      <h1 class="click" *ngIf="typeSettings.question && stimuli.levels[0].word">
          {{stimuli.levels[0].word}}
      </h1>
  -->

  <div *ngIf="(typeSettings.mainImage && stimuli.image)" class="p-5" [class.highlight-word]="highlight['highlight-image']">
    <img [ngClass]="{ 'fs-stimuli-image': settings.fullScreen, 'stimuli-image': !settings.fullScreen}" [src]="getStimuliImage()"/>
  </div>

  <div *ngIf="typeSettings.preSound && stimuli.preSounds" class="modalBody h1" [class.highlight-word]="highlight['highlight-presounds']">
    <div class="center modalWords">
      <ng-container *ngFor="let sound of stimuli.preSounds; let index = index">
        <div *ngIf="sound.word" [ngClass]="{'highlight-word': index === 0}" [textContent]="sound.word" class="inline modalWord p-5" id="word{{index}}"></div>
      </ng-container>
    </div>
  </div>


  <div class="p-b-10 p-t-30" [class.highlight-word]="highlight['highlight-speech']">
    <button (click)="start()" *ngIf="!recording" class="btn btn-primary" style="min-width: 100px;">
      <span class="material-icons middle">mic</span>
      Start
    </button>

    <button (click)="stop()" *ngIf="recording" class="btn btn-danger">
      <span class="material-icons middle">mic_off</span>
      Pause
    </button>
  </div>

  <div *ngIf="showSpeechAnswer">
    <div id="display" class="h2"></div>
  </div>

  <div *ngIf="type.alternativeSounds && alternatives.length>1" [class.highlight-word]="highlight['highlight-ear']">
    <div *ngFor="let answer of alternatives; let index=index; let isOdd=odd; let isEven=even" class="inline-block">
      <div *ngIf="answer.sound" class="btn btn-secondary m-15">
        <div (click)="playAlternative(index)" class="m-0">
          <div class="btn listen-again">
            <span class="material-icons middle">headphones</span>
            <span class="m-b-10 m-l-10">Listen</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-hint></app-hint>

</div>

