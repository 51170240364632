<div class="container m-t-30" style="clear: both">
  <div class="row">
    <div class="col-sm-12 form-group">
      Level:
      <select *ngIf="type" [(ngModel)]="onLevel" class="form-control inline-block" name="level" style="width: 60px;">
        <option *ngFor="let level of type.levels" [value]="level.level">
          {{level.level}}
        </option>
      </select>

      <button (click)="getStimuli()" class="btn btn-primary m-l-15 ">GET STIMULI</button>
      <button (click)="sendImage()" *ngIf="stimuli && stimuli.image" class="btn btn-primary m-l-15">SEND IMAGE</button>
      <button (click)="sendText()" *ngIf="stimuli" class="btn btn-primary m-l-15">SEND ANSWER</button>
      <button (click)="clear()" class="btn stop m-l-15">CLEAR</button>
      <button (click)="back()" class="btn stop m-l-15" type="button">BACK</button>

    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-sm-12">

      <div *ngIf="stimuli && alternatives && type && typeSettings" class="p-b-15 p-t-10">

        <div class="center">
          <h1 *ngIf="typeSettings.question && stimuli.description" class="click m-t-20 m-b-20">
            {{stimuli.description}}
          </h1>

          <h1 *ngIf="typeSettings.question && stimuli.description" class="click m-t-20 m-b-20">
            {{stimuli.description}}
          </h1>

          <div *ngIf="(typeSettings.mainImage && stimuli.image)" class="p-15">
            <img [src]="getStimuliImage()"/>
          </div>
        </div>

        <form class="p-t-15" name="answerForm">
          <div class="h3">
            Correct answer: {{stimuli.levels[0].word}}
          </div>

          <div class="h3">
            Patient answer: {{text()}}
          </div>

        </form>

      </div>
    </div>
  </div>
</div>
