import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Component} from '@angular/core';

@Component({
    selector: 'revoke-access',
    templateUrl: './revoke-access-modal.component.html'
})
export class RevokeAccessModalComponent {

    constructor(public activeModal: NgbActiveModal) {
    }
}
