<div class="container-fluid bg-white">
  <div class="container p-t-20">
    <div class="row">
      <div class="col-sm-12 center p-t-15 p-b-10 bg-teal">
        <h1>What is Conduction Aphasia?</h1>
      </div>
    </div>
    <div class="col-sm-12 p-15 center">
      <img src="/images/conduction.png" style="height: 350px;"/><br/>
    </div>

    <div class="row">
      <div class="col-sm-12 p-15">

        <b>Conduction aphasia</b> is a type of fluent aphasia that may not occur as frequently as other types of aphasia. Conduction aphasia can be mild, moderate, or severe. The hallmark of this aphasia is poor repetition of words/sentences,
        along with decreased understanding. Like Wernicke’s aphasia, their fluent speech can contain some nonsense words or words with mixed-up sounds, but less frequently. For example, saying “She wasn’t compling over againer, right?”
        <br/><br/>
        <b>Conduction aphasia affects the person’s awareness of their speech errors and their ability to correct those errors themselves or by repeating after others.</b> All language skills are affected to different degrees, but disordered speech is
        the most noticeable to listeners.
        <br/><br/>
        <b>Speech:</b> The person speaks in sentences in which the gist is largely understood by the listener, with mild to severe word or sound substitutions. Repeating words and sounds is severely affected. <b>It is NOT apraxia.</b> When speaking,
        the person may decrease their volume as they get to words that are harder to say so their errors are less obvious.
        <br/><br/>
        <b>Writing:</b> Since speaking and writing are both expressive forms of communication, writing issues will be very similar to speech errors. They often do not recognize their errors and may need to copy words.
        <br/><br/>
        <b>Reading:</b> Typically poor or at a limited single word/phrase level, limited to largely nouns and verbs.
        <br/><br/>
        <b>Understanding:</b> While understanding is affected, social and contextual conversation is usually understood. They will need written and gestured support to help comprehension.
        <br/><br/>
        <b>How Can My Aphasia Coach Software Help Anomic Aphasia?</b>
        <br/><br/>
        Learners with <b>Conduction aphasia</b> can improve within each language system–reading words and sentences, copying or writing words, repeating words and sentences, understanding spoken and written information, and more!
        <br/><br/>
        These exercises are designed to target specific processes that begin at their current skill level, building on existing strengths. <b>To get the fastest results, we recommend that learners with aphasia work independently after they get
        started.</b>
        <br/><br/>
        <b>Where Should I Start My Aphasia Coach?</b>
        <br/>
        Take the <a [routerLink]="'/where-do-i-start'">placement quiz</a> to get recommendations.
        <br/><br/>
        You can also try these exercises:
        <br/><br/>
        <h5>Reading</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/Naming">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/naming.png">
              <div class="p-10 text-center"><h5>Noun Choice 1</h5>
                Choose the defined word
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/SentencePicMatchwritten">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/sentence-pic-match-written.png">
              <div class="p-10 text-center"><h5>Sentence Matching 2</h5>
                Choose written sentences for photos
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/Grammar 1 reading">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/grammar-1.png">
              <div class="p-10 text-center"><h5>Completion Grammar</h5>
                Choose the verb tense
              </div>
            </div>
          </div>

        </div>
        <br/><br/>
        <h5>Writing</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing1/NounSentencePictures 1">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/noun-sentence-pictures1.png">
              <div class="p-10 text-center"><h5>Noun Sentence Pictures 1</h5>
                Type the missing noun
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing1/DefinitionsWriting">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/definitions-writing.png">
              <div class="p-10 text-center"><h5>What’s the Word 1</h5>
                Write the defined word
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing2/DefinitionsWriting2">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/definitions-writing2.png">
              <div class="p-10 text-center"><h5>What’s the Word 2</h5>
                Write the defined word
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/writing2/Writing2Nouns">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/writing2-nouns.png">
              <div class="p-10 text-center"><h5>Noun Writing 2</h5>
                Type the noun
              </div>
            </div>
          </div>
        </div>

        <br/><br/>
        <h5>Speech</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/1" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-1.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 1
                </h5>
                1 syllable, 2 sounds
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/2" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-2.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 2
                </h5>
                1 syllable, 3 sounds
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/3" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-3.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 3
                </h5>
                1 syllable, blends
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/videos/4" routerLinkActive="active">
            <div class="p-2 fg-white bg-blue h-100">
              <img src="/images/modules/video-4.png" width="100%"/>
              <div class="p-10 text-center">
                <h5>
                  Apraxia Video 4
                </h5>
                3 word sentences, 3-5 syllable words
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/speech/Naming Speech">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2"><img width="100%" src="/images/modules/naming-speech.png">
              <div class="p-10 text-center"><h5> Repeating Nouns </h5> Say the word you hear</div>
            </div>
          </div>

        </div>

        <h5>Listening</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/scramble/Scrambling">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2"><img width="100%" src="/images/modules/sentence-construction.png">
              <div class="p-10 text-center"><h5>Sentence Construction 2</h5>
                Move the words to form a sentence
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/AudioPicSent">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/auditory-picture-sentence-match.png">
              <div class="p-10 text-center"><h5>Verb Sentence Match 1</h5>
                Match spoken sentence to photos
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/VerbFIB2">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2"><img width="100%" src="/images/modules/sentence-writing-verbs-2.png">
              <div class="p-10 text-center"><h5>Verb Sentences Beginnings 1</h5>
                Choose the verb that begins the sentence
              </div>
            </div>
          </div>
        </div>


        <br/><br/>
        <h5>Numbers</h5>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/numbers/Numbers">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/numbers.png">
              <div class="p-10 text-center"><h5>Learn Numbers</h5>
                Learn numbers 0-9
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/numbers/Numbers2">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/numbers2.png">
              <div class="p-10 text-center"><h5>Learn Numbers 2</h5>
                Learn numbers 10-20
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pointer p-10" routerLink="/therapy/click/NumberMatch2">
            <div style="height: 100%;" class="bg-blue btn-primary fg-white p-2">
              <img width="100%" src="/images/modules/number-match-2.png">
              <div class="p-10 text-center"><h5>Number Match 2</h5>
                Choose the number that you hear
              </div>
            </div>
          </div>
        </div>
        <br/><br/>
        Conduction aphasia can improve, even years after a stroke or brain injury. <b>There is no plateau or 6-month time limit–you can always make progress.</b> The best results occur when you practice My Aphasia Coach <b>for at least 30 minutes a
        day</b>.
      </div>
    </div>

  </div>
</div>
<app-my-footer></app-my-footer>
