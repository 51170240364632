<div class="container m-t-30" style="clear: both">

  <div class="row">
    <div class="col-sm-12">
      Level:
      <select *ngIf="type" [(ngModel)]="onLevel" class="form-control inline-block" name="level" style="width: 60px;">
        <option *ngFor="let level of type.levels" [value]="level.level">
          {{level.level}}
        </option>
      </select>

      <button (click)="get()" class="btn btn-primary m-l-15" type="button">GET STIMULI</button>
      <button (click)="sendAlternatives()" class="btn btn-primary m-l-15" type="button">SEND</button>
      <button (click)="clear()" class="btn stop m-l-15" type="button">CLEAR</button>
      <button (click)="back()" class="btn stop m-l-15" type="button">BACK</button>

    </div>
  </div>

  <hr>

  <div *ngIf="alternatives && alternatives.length>0" class="center">
    <div (click)="dragDropReplacement('land' + index)"
         (dragenter)="dragEnter()"
         (dragover)="dragOver()"
         (drop)="drop($event)"
         *ngFor="let letter of alternatives; let index = index"
         class="scrambling-drop"
         draggable="false"
         id="land{{index}}">
    </div>
    <hr width="50%" style="margin: auto;">

    <div>
      <div (click)="dragDropReplacement('start' + index)"
           (dragenter)="dragEnter()"
           (dragover)="dragOver()"
           (drop)="drop($event)"
           *ngFor="let letter of alternatives; let index = index"
           class="scrambling-start"
           draggable="false"
           id="start{{index}}">

        <p (dragstart)="dragStart($event)" [innerHTML]="letter" class="scrambling-word pointer" draggable="true" id="{{index + letter}}"></p>

      </div>
    </div>
  </div>

  <br/>
  <br/>
  <b>Patient Response:</b><br/>
  <h2>
    <span *ngFor="let alt of getAnswer()">{{alt}} </span>
  </h2>
  <br/>
  <br/>

</div>
