<!--<button (click)="toggleSort()" class="btn btn-secondary btn-sm float-right m-b-15">
  <span *ngIf="!dateSort">Sorted by Name</span>
  <span *ngIf="dateSort">Sorted by Most Recent</span>
</button>-->


<div *ngIf="user">
  <div [hidden]="user.tasks && user.tasks.length>0">The patient has no stats.</div>

  <table *ngIf="typesSorted" class="table table-striped">
    <tr class="h6">
      <th>Module Name</th>
      <th>On Level</th>
      <th>Score</th>
      <th>Last Used</th>
      <th>Homework</th>
      <th>Number</th>
      <th>Completed</th>
      <th></th>
    </tr>
    <ng-container *ngFor="let ts of __.toArray(typesSorted); index as i ">
      <tr><td colspan="6" class="h3">{{__.keys(typesSorted)[i] | titlecase}}</td></tr>

      <ng-container *ngFor="let r of __.toArray(ts); index as ix">
      <tr>
        <th *ngIf="r.length" colspan="8" class="h6">Level {{r[0].level[0]}}</th>
      </tr>
      <tr *ngFor="let task of r">

        <td class="p-l-10 vertical-top">
          <a routerLink="{{getLinkByName(task.task)}}">{{getVisibleName(task.task)}}</a>
        </td>

        <td class="vertical-top">
          {{task?.onLevel}}
        </td>

        <td class="vertical-top">
          <ng-container *ngFor="let lev of task.levels">
            <ng-container *ngIf="lev.level === task.onLevel">
              {{lev.answeredCorrectly / lev.answered * 100 | number:'1.0-0'}}%
            </ng-container>
          </ng-container>
        </td>

        <td class="vertical-top">
          {{task.modified | date}}
        </td>

        <td class="vertical-top">
          <button (click)="toggleAssigned(task)" *ngIf="canAssign(task)" class="btn btn-primary m-l-15">Assign</button>
          <button (click)="toggleAssigned(task)" *ngIf="isAssigned(task)" class="btn btn-secondary m-l-15">Remove</button>
        </td>
        <td class="vertical-top">
          <input #myTemplateVar="ngModel" (change)="updateHomework(task.task, myTemplateVar.value)" *ngIf="isAssigned(task)" [ngModel]="getCount(task)" class="m-l-10 middle b-black-2" size="2" type="text">
        </td>
        <td class="center">
          <div *ngIf="isHomeworkDone(task)"><span class="material-icons middle">done</span> </div>
        </td>
        <td>
          <button (click)="reset(task)" *ngIf="showReset(task)" class="btn btn-primary m-l-15"> Reset</button>

        </td>

      </tr>
    </ng-container>
    </ng-container>


  </table>

</div>

