<div class="container" id="lp-desktop" style="font-size: 1.3rem">
  <a routerLink="/" routerLinkActive="active">
    <img class="p-30" src="/images/logo.svg" width="250"/><br/>
  </a>

  <div style="background-image: linear-gradient(#00848b, #60e4eb); height:10px;">
  </div>
  <div class="" id="lp-d1">
    <div class="container-fluid" id="fp-2">
      <div class="" id="fp-3">
        <h2 class="p-b-15" id="fp-4">Aphasia Therapy Software</h2>
        <h3 class="">Free for Speech Pathologists</h3>
        Download from the app store for FREE today!

        <div class="center p-t-15">
          <a href="https://itunes.apple.com/us/app/my-aphasia-coach/id1163288893?ls=1&mt=8" rel="noreferrer"
             target="_blank" title="Get My Aphasia Coach in the Apple App Store">
            <img alt="Get MyAphasiaCoach in the Apple App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/appstore.png" width="150">
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.myaphasiacoach" rel="noreferrer" target="_blank"
             title="Get My Aphasia Coach in the Google App Store">
            <img alt="Get My Aphasia Coach in the Google App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/googleplay.png" width="150">
          </a>
          <br/>
        </div>

      </div>
    </div>
  </div>
  <div style="background-color:#60e4eb; height:5px;"></div>

  <div style="display: flex; flex-direction:row; flex-wrap: wrap; justify-content: flex-start; align-items:center; margin-top:40px;">
    <div class="inline-block">
      <img src="/images/callout-desktop-1.png" style="width:200px;"><br/>
    </div>
    <div style="width:400px; padding-left: 20px; flex-grow: 1; flex-shrink: 1;">
      Wish you had more therapeutic time with your patients?<br/>
      <br/>
      <b>My Aphasia Coach™</b> software targets foundational
      language skills so you can maximize your skilled
      treatment time.<br/>
    </div>
  </div>

  <div class="center h4 m-t-30">
    <p>Join thousands of aphasia therapists for FREE!</p>
    <p>Download from the app store today!</p>


    <div class="center p-t-30">
      <a href="https://itunes.apple.com/us/app/my-aphasia-coach/id1163288893?ls=1&mt=8" rel="noreferrer"
         target="_blank" title="Get My Aphasia Coach in the Apple App Store">
        <img alt="Get MyAphasiaCoach in the Apple App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/appstore.png" width="250">
      </a>

      <a href="https://play.google.com/store/apps/details?id=com.myaphasiacoach" rel="noreferrer" target="_blank"
         title="Get My Aphasia Coach in the Google App Store">
        <img alt="Get My Aphasia Coach in the Google App Store" border="0" class="m-l-15 m-r-15 p-b-15" src="/images/googleplay.png" width="250">
      </a>
    </div>
  </div>
</div>

<app-my-footer></app-my-footer>
