<div class="container-fluid bg-white" id="aphasia-1">

  <a href="/"><img class="p-t-15 p-l-15" src="/images/logo2.png" width="300"/></a>

  <div class="container" id="fp-2">
    <div class="" id="fp-3">
      <br/>
      <br/>
      <h1 id="fp-4">Software for Faster Aphasia Progress</h1>
      <br/>
      <h3>Expert therapy you can do from home for stroke and brain injury
rehabilitation</h3>
      <br/>
      <br/>

      <div class="inline-block">
      <a [hidden]="user" [routerLink]="'/register'" class="btn trial p-30 h3">
        Get started for free
      </a>

      <a [hidden]="!user" [routerLink]="'/modules'" class="btn trial p-30 h3">
        Get started for free
      </a>
      <br/><div class="text-small center">* No Credit Card Required</div>
    </div>
    </div>
  </div>
</div>



<div class="container-fluid bg-teal">
<div class="row">
  <div class="col-sm-12 center p-t-10">
    <h1>What Kind of Aphasia Do You Have?</h1>
    <h4 style="font-weight: 300">Exercise Recommendations for Your Aphasia Type</h4>
  </div>
</div>
</div>


<div class="container-fluid bg-white">
    <div class="center">
      <svg class="p-b-30" style="width:100px" viewBox="0 0 42 21">
        <polygon class="triangle" points="0,0 42,0 21,21"/>
      </svg>
    </div>

  <div class="container p-t-20">
    <!--    <div class="row">
          <div class="col-sm-12 center p-t-10 bg-teal">
            <h1>What is aphasia?</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 p-15">
            Aphasia is a language disorder.

          </div>
        </div>-->

    <div class="row">
      <div class="col-sm-2 p-15 center">
        <img src="/images/brocas.png" style="height: 120px;"/><br/>
      </div>
      <div class="col-sm-10 p-15">
        <h4><a routerLink="/aphasia/brocas">Broca's Aphasia</a></h4>
        Broca's aphasia is a language disorder that can happen when a specific area of the brain, Broca’s area, is damaged from a stroke or brain injury. Broca’s area is very close to the motor control centers for the right arm and leg, so someone
        with Broca’s aphasia often has weakness in their right arm or leg. Since the motor center to talk is also located in this area, they may also have apraxia of speech.
        <br/>
        <a routerLink="/aphasia/brocas">Read More</a>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-2 p-15 center">
        <img src="/images/wernickes.png" style="height: 120px;"/><br/>
      </div>
      <div class="col-sm-10 p-15">
        <h4><a routerLink="/aphasia/wernickes">Wernicke's Aphasia</a></h4>
        Wernicke’s aphasia is a type of fluent aphasia that can occur when a more posterior part of the left hemisphere is damaged from a stroke or brain injury. It is often thought to be harder to treat than other types of aphasia, but this isn’t
        true. The hallmark of this aphasia is poor understanding, but fluent speech that consists of nonsense words or words with mixed-up sounds. For example, saying “She wersh complirest over againer, right?”
        <br/>
        <a routerLink="/aphasia/wernickes">Read More</a>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-2 p-15 center">
        <img src="/images/anomic.png" style="height: 120px;"/><br/>
      </div>
      <div class="col-sm-10 p-15">
        <h4><a routerLink="/aphasia/anomic">Anomic Aphasia</a></h4>
        Anomic aphasia is typically considered the “mildest” type of aphasia. Anomia means the person has difficulty finding the right words while speaking or writing. Like all types of aphasia, anomic aphasia can be mild, moderate, severe, or very
        severe. Anomic aphasia may be the first diagnosis, or the person’s aphasia severity resolved over time to become anomic aphasia.
        <br/>
        <a routerLink="/aphasia/anomic">Read More</a>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-2 p-15 center">
        <img src="/images/global.png" style="height: 120px;"/><br/>
      </div>
      <div class="col-sm-10 p-15">
        <h4><a routerLink="/aphasia/global">Global Aphasia</a></h4>
        Global aphasia is a language disorder that can happen when a large area of the left hemisphere of the brain is damaged from a stroke or brain injury. Persons with global aphasia are typically (but not always) severely physically affected by
        the stroke. Since the motor center to speak is also located in this large area of the brain, they may also have severe apraxia of speech.
        <br/>
        <a routerLink="/aphasia/global">Read More</a>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-2 p-15 center">
        <img src="/images/conduction.png" style="height: 120px;"/><br/>
      </div>
      <div class="col-sm-10 p-15">
        <h4><a routerLink="/aphasia/conduction">Conduction Aphasia</a></h4>
        Conduction aphasia is a type of fluent aphasia that may not occur as frequently as other types of aphasia. Conduction aphasia can be mild, moderate, or severe. The hallmark of this aphasia is poor repetition of words/sentences, along with
        decreased understanding. Like Wernicke’s aphasia, their fluent speech can contain some nonsense words or words with mixed-up sounds, but less frequently. For example, saying “She wasn’t compling over againer, right?”
        <br/>
        <a routerLink="/aphasia/conduction">Read More</a>
      </div>
      <hr>
    </div>



        <!--
                <h4><a routerLink="/aphasia/primary-progressive">Primary Progressive Aphasia</a></h4>
                <br/><br/>
                <h4><a routerLink="/aphasia/expressive">Expressive Aphasia</a></h4>
                See receptive aphasia
                <br/><br/>
                <h4><a routerLink="/aphasia/receptive">Receptive Aphasia</a></h4>
                Expressive, receptive and mixed aphasia are terms that get tossed around a lot in the hospital after the stroke or brain injury. Medical professionals tend to describe aphasia to the families as receptive aphasia, expressive aphasia, or mixed aphasia. This is a generic label families hear early in the recovery process. This is your introduction to the world of aphasia.
                <br/><br/>
                <h4><a routerLink="/aphasia/apraxia">Apraxia</a></h4>
                There are different types of apraxia, but all forms have to do with movement. In general, there are problems with voluntary, but not automatic movements. So in the case of oral-verbal apraxia, automatic speech comes out much easier than purposeful speech. For example, when you are driving, the person with aphasia may say "look out!" very clearly and easily, but strains when they need to say something during conversation.
        -->
  </div>
</div>
<app-my-footer></app-my-footer>
